import {
  createStyles,
  InputAdornment,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import {
  TextInput,
  DateInput,
  NumberInput,
  BooleanInput,
  SelectInput,
} from 'react-admin';
import LockIcon from '@material-ui/icons/Lock';
import { isNil } from 'lodash';
import { useFormState, useForm } from 'react-final-form';

import { Income, Loan } from '../../types/schema';
import FormRow from '../../components/baseElements/FormRow';
import FormSection from '../../components/baseElements/FormSection';
import {
  CurrencyField,
  MaxAmount,
  QuotientFamilial,
  TotalIncome,
  CurrencyInput,
} from './CurrencyFields';
import TooltipRGPD from '../../components/Tooltip/TooltipRGPD';
import { LegacyDataField } from './LegacyDataField';
import { workflow, WFState, WFAction } from '../../workflow/loans';
import { ActionInput } from './ActionInput';
import { Assets } from '../../components/Fields/asset/Assets';
import { useCanAccess } from '../../hooks/use-can-access';
import { LoanPackagesSpy } from './LoanPackagesSpy';
import { useLoanPackages } from './useLoanPackages';
import { ASSET_USER_NAMESPACE } from '../../aws/cpret-config';
import { PostmailDialog } from '../shared/PostmailDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'row wrap',
      margin: theme.spacing(-1),
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    leftSide: {
      flex: '1 0 0%',

      '& $formSection': {
        display: 'flex',
        flexFlow: 'column nowrap',

        '& $field': {
          width: '100%',
        },
      },
    },
    rightSide: {
      flex: '3 0 0%',

      '& $formSection': {
        display: 'flex',
        flexFlow: 'row wrap',

        '& $field': {
          flexGrow: 1,
          flexBasis: '26%', // More that 1/4
        },
      },
    },
    formSection: {},
    field: {},
    booleanInputcontainer: {
      display: 'flex',
      alignItems: 'center',
      verticalAlign: 'middle',
      '& > div': {
        minHeight: '100%',
      },
      '& label': { height: '100%', marginRight: '0px', cursor: 'pointer' },
    },
    booleanInputLabel: {
      fontSize: '1rem',
      fontFamily: 'Avenir',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    booleanInputDisabled: {
      color: 'rgba(77, 79, 83, 0.4)',
    },
  }),
);

export interface LoanFormProps {
  kind?: 'create' | 'edit';
  record?: Loan;
  resource?: string;
}

const inputOrNull = (input: any) => input || null;
const disableOn = (condition: boolean) => ({
  disabled: condition,
  InputProps: condition
    ? {
        endAdornment: (
          <InputAdornment position="end">
            <LockIcon />
          </InputAdornment>
        ),
      }
    : {},
});

export const LoanForm: React.FC<LoanFormProps> = props => {
  const { record: loan, resource, kind = 'edit' } = props;
  const { isAdmin } = useCanAccess();
  const form = useForm();
  const {
    values: { wfAction },
  } = useFormState();
  const hasSignature = !isNil(loan?.signature);
  const classes = useStyles();

  const isEditView = kind === 'edit';
  const income: Income[] = isEditView // TODO useMemo ?
    ? loan?.income || []
    : [
        { label: 'Demandeur', amount: 0 },
        { label: 'Conjoint', amount: 0 },
        { label: 'Enfant', amount: 0 },
        { label: 'Autre', amount: 0 },
      ];

  React.useEffect(() => {
    form.change('income', income);
  }, []);
  const wfActions = loan && workflow[loan.wfStatus as WFState];
  const allowedActions =
    (wfActions &&
      Object.keys(wfActions).filter(
        a => isAdmin || !wfActions[a as WFAction]?.workerOnly,
      )) ||
    [];

  const packages = useLoanPackages();

  return (
    <div className={classes.root}>
      {!hasSignature && <LoanPackagesSpy />}
      <section className={classes.leftSide}>
        <FormSection className={classes.formSection}>
          {isEditView && (
            <FormRow>
              <TextInput
                source="wfStatus"
                label="Statut"
                className={classes.field}
                disabled={
                  !isAdmin ||
                  ['prod', 'stage'].includes(process.env.REACT_APP_ENV || '')
                }
              />
              {allowedActions.length ? (
                <ActionInput
                  source="wfAction"
                  label="Modifier"
                  allowEmpty
                  choices={allowedActions.map(a => ({
                    id: a,
                    name: a /* TODO: ACTION_LABEL ? */,
                  }))}
                />
              ) : null}
            </FormRow>
          )}
          <FormRow>
            <TooltipRGPD>
              <TextInput
                label="Commentaires"
                source="comment"
                multiline
                rows={10}
                rowsMax={10}
                fullWidth
              />
            </TooltipRGPD>
          </FormRow>
          {isEditView && (
            <FormRow>
              <Assets
                displayNamespaceSelectOnMailFile
                namespace={ASSET_USER_NAMESPACE}
                label="Pièces jointes"
                record={loan}
                resource={resource}
                source="attachments"
                fullWidth
              />
            </FormRow>
          )}
        </FormSection>
      </section>
      <section className={classes.rightSide}>
        <FormSection title="Demandeur" className={classes.formSection}>
          <FormRow>
            <TextInput
              source="sncfCP"
              label="Immatriculation"
              {...disableOn(!isAdmin || hasSignature)}
            />
            {!isAdmin || hasSignature ? (
              <TextInput
                source="contacts[0].honorificPrefix"
                label="Civilité"
                {...disableOn(true)}
                format={(e: 'MME' | 'MR' | '') => {
                  return e ? { MME: 'Madame', MR: 'Monsieur' }[e] : '';
                }}
              />
            ) : (
              <SelectInput
                source="contacts[0].honorificPrefix"
                label="Civilité"
                choices={[
                  { id: 'MME', name: 'Madame' },
                  { id: 'MR', name: 'Monsieur' },
                ]}
                allowEmpty
              />
            )}
            <TextInput
              source="contacts[0].familyName"
              label="Nom usuel"
              {...disableOn(!isAdmin || hasSignature)}
            />
            <TextInput
              source="contacts[0].patronymicName"
              label="Nom patronymique"
              {...disableOn(!isAdmin || hasSignature)}
            />
            <TextInput
              source="contacts[0].givenName"
              label="Prénom"
              {...disableOn(!isAdmin || hasSignature)}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="contacts[0].email"
              label="Email"
              {...disableOn(!isAdmin || hasSignature)}
            />
            <TextInput
              source="contacts[0].tel"
              label="Téléphone"
              {...disableOn(!isAdmin || hasSignature)}
            />
            <DateInput
              source="contacts[0].bday"
              label="Date de naissance"
              parse={inputOrNull}
              format={inputOrNull}
              {...disableOn(!isAdmin || hasSignature)}
            />
            <TextInput
              source="sncfCompany"
              label="Société d'origine"
              {...disableOn(!isAdmin || hasSignature)}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="contacts[0].streetAddress"
              label="Adresse"
              multiline
              rowsMax={2}
              {...disableOn(!isAdmin || hasSignature)}
            />
            <TextInput
              source="contacts[0].postalCode"
              label="CP"
              {...disableOn(!isAdmin || hasSignature)}
            />
            <TextInput
              source="contacts[0].addressLevel2"
              label="Ville"
              {...disableOn(!isAdmin || hasSignature)}
            />
          </FormRow>
        </FormSection>
        <FormSection title="Co-emprunteur">
          <FormRow>
            {!isAdmin || hasSignature ? (
              <TextInput
                source="contacts[1].honorificPrefix"
                label="Civilité"
                {...disableOn(true)}
                format={(e: 'MME' | 'MR' | '') => {
                  return e ? { MME: 'Madame', MR: 'Monsieur' }[e] : '';
                }}
              />
            ) : (
              <SelectInput
                source="contacts[1].honorificPrefix"
                label="Civilité"
                choices={[
                  { id: 'MME', name: 'Madame' },
                  { id: 'MR', name: 'Monsieur' },
                ]}
                allowEmpty
              />
            )}
            <TextInput
              source="contacts[1].familyName"
              label="Nom usuel"
              {...disableOn(!isAdmin || hasSignature)}
            />
            <TextInput
              source="contacts[1].patronymicName"
              label="Nom patronymique"
              {...disableOn(!isAdmin || hasSignature)}
            />
            <TextInput
              source="contacts[1].givenName"
              label="Prénom"
              {...disableOn(!isAdmin || hasSignature)}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="contacts[1].email"
              label="Email"
              {...disableOn(!isAdmin || hasSignature)}
            />
            <TextInput
              source="contacts[1].tel"
              label="Téléphone"
              {...disableOn(!isAdmin || hasSignature)}
            />
            <DateInput
              source="contacts[1].bday"
              label="Date de naissance"
              parse={inputOrNull}
              format={inputOrNull}
              {...disableOn(!isAdmin || hasSignature)}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="contacts[1].streetAddress"
              label="Adresse"
              multiline
              rowsMax={2}
              {...disableOn(!isAdmin || hasSignature)}
            />
            <TextInput
              source="contacts[1].postalCode"
              label="CP"
              {...disableOn(!isAdmin || hasSignature)}
            />
            <TextInput
              source="contacts[1].addressLevel2"
              label="Ville"
              {...disableOn(!isAdmin || hasSignature)}
            />
          </FormRow>
        </FormSection>
        {loan?.dataBlob ? (
          <FormSection title="Synthèse">
            <LegacyDataField source="dataBlob" />
          </FormSection>
        ) : (
          <FormSection title="Instruction">
            <FormRow>
              {income.map((v, i) => (
                <CurrencyInput
                  key={`income-${i}`} // eslint-disable-line react/no-array-index-key
                  source={`income[${i}].amount`}
                  label={['Revenu annuel', v.label].filter(Boolean).join(' ')}
                  {...disableOn(hasSignature)}
                />
              ))}
            </FormRow>
            <FormRow>
              <TotalIncome />
              <NumberInput
                source="taxShares"
                label="Nombre de part"
                {...disableOn(hasSignature)}
              />
              <QuotientFamilial />
              <TextInput
                source="schoolYear"
                label="Année scolaire"
                {...disableOn(hasSignature)}
              />
            </FormRow>
            <FormRow>
              <CurrencyInput
                source="cptFraisLoyer"
                label="Frais de loyer (annuel)"
                disabled={hasSignature}
              />
              <CurrencyInput
                source="cptAPL"
                label="APL étudiant (annuel)"
                disabled={hasSignature}
              />
              <CurrencyInput
                source="cptFraisScolarite"
                label="Frais de scolarité (annuel)"
                disabled={hasSignature}
              />
              <BooleanInput
                source="dependentChild"
                label="Enfant à charge"
                disabled={hasSignature}
              />
            </FormRow>
            <FormRow>
              <div className={classes.booleanInputcontainer}>
                <BooleanInput
                  source="checkEntretien"
                  label=""
                  disabled={hasSignature}
                  helperText={false}
                />
                <label
                  className={`${classes.booleanInputLabel} ${
                    hasSignature ? classes.booleanInputDisabled : ''
                  }`}
                  htmlFor="checkEntretien"
                >
                  Forfait entretien{' '}
                  <CurrencyField
                    defaultValue={0}
                    value={packages.maintenance}
                  />
                </label>
              </div>
              <div className={classes.booleanInputcontainer}>
                <BooleanInput
                  source="checkEloignement"
                  label=""
                  disabled={hasSignature}
                  helperText={false}
                />
                <label
                  className={`${classes.booleanInputLabel} ${
                    hasSignature ? classes.booleanInputDisabled : ''
                  }`}
                  htmlFor="checkEloignement"
                >
                  Forfait éloignement{' '}
                  <CurrencyField
                    defaultValue={0}
                    value={packages.estrangement}
                  />
                </label>
              </div>
              <div className={classes.booleanInputcontainer}>
                <BooleanInput
                  source="checkEnseignement"
                  label=""
                  disabled={hasSignature}
                  helperText={false}
                />
                <label
                  className={`${classes.booleanInputLabel} ${
                    hasSignature ? classes.booleanInputDisabled : ''
                  }`}
                  htmlFor="checkEnseignement"
                >
                  Forfait enseignement{' '}
                  <CurrencyField defaultValue={0} value={packages.teaching} />
                </label>
              </div>
              {!hasSignature && <MaxAmount />}
              {hasSignature && (
                <CurrencyInput source="amount" label="Montant" disabled />
              )}
            </FormRow>
            <FormRow>
              <div />
              <DateInput
                source="signature"
                label="Date de signature"
                parse={inputOrNull}
                format={inputOrNull}
                {...disableOn(hasSignature || wfAction !== 'SIGNATURE')}
              />
              <div />
            </FormRow>
          </FormSection>
        )}
        {isEditView && (
          <FormSection title="Courriers">
            <PostmailDialog record={loan as Loan} templatePrefix="loan" />
          </FormSection>
        )}
        <FormSection title="Informations bancaires">
          <FormRow>
            <TextInput
              source="ibanName"
              label="Titulaire du compte"
              {...disableOn(hasSignature)}
            />
            <TextInput
              source="iban"
              label="IBAN"
              {...disableOn(hasSignature)}
            />
            <TextInput source="bic" label="BIC" {...disableOn(hasSignature)} />
          </FormRow>
        </FormSection>
      </section>
    </div>
  );
};
