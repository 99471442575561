import AWS from 'aws-sdk';
import React, { useCallback, useMemo, useState } from 'react';
import {
  DeleteButton,
  Record,
  SaveButton,
  SaveButtonProps,
  Toolbar as RaToolbar,
  ToolbarProps as RaToolbarProps,
  useNotify,
  useRefresh,
} from 'react-admin';
import { useField, useForm } from 'react-final-form';
import clsx from 'clsx';
import { Node } from 'phicomas-client';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, WithWidthProps } from '@material-ui/core';
import ContentSave from '@material-ui/icons/Save';
import ContentSaved from '@material-ui/icons/Check';
import ContentError from '@material-ui/icons/Error';

import { UPLOAD_FIELD } from '../Fields/asset/AssetDropzone';

import { useTranslateIf } from '../../hooks/use-translate-if';
import {
  getResourceCustomization,
  ResourceKey,
} from '../../project/projectInfos';
import { useCreateAsset } from '../../dataProvider/useAsset';
import ContainerForward from '../../lib/ContainerForward';
import { ResetButton } from '../Buttons/ResetButton';
import { useCanAccess } from '../../hooks/use-can-access';
import { useCircularStyles } from '../layout/Loading';
import { useCpretHistory } from '../../workflow/history';

const useToolbarStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      flex: '0 0 auto',
      position: 'relative',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    icon: {
      fontSize: 18,
    },
    defaultButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      '& > *': {
        marginLeft: theme.spacing(1),
      },
    },
  }),
);

export type BaseToolbarProps = RaToolbarProps &
  WithWidthProps & {
    onCancel?: () => void;
    onStandaloneSuccess?: (response: { data: Record }) => void;
  } & {
    resource?: ResourceKey;
  };

type ToolbarProps = BaseToolbarProps & {
  kind?: 'create' | 'edit';
  field: string;
  assetResource?: ResourceKey;
  namespace: string;
  onSaveSuccess?: SaveButtonProps['onSuccess'];
};
const Toolbar: React.FC<ToolbarProps> = ({
  kind = 'create',
  field: connectionField,
  assetResource = 'cpretAsset',
  onCancel,
  onStandaloneSuccess,
  namespace,
  onSaveSuccess,
  ...props
}: ToolbarProps) => {
  const refresh = useRefresh();
  const translate = useTranslateIf();
  const notify = useNotify();
  const classes = useToolbarStyles();
  const saveClasses = useCircularStyles();
  const { isAdmin, canWrite } = useCanAccess();

  const form = useForm();
  const fileField = useField(UPLOAD_FIELD);
  const file = useMemo(() => {
    const fileTmp = fileField.input.value;
    return fileTmp instanceof File ? fileTmp : undefined;
  }, [fileField.input.value]);
  const createAsset = useCreateAsset(connectionField, assetResource);
  const log = useCpretHistory();

  const { resource, record } = props;

  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  React.useEffect(() => {
    setUploadProgress(null);
  }, [file]);

  const handleProgress = useCallback(
    (progress: AWS.S3.ManagedUpload.Progress) => {
      const { loaded, total } = progress;
      setUploadProgress((loaded / total) * 100);
    },
    [],
  );

  const handleSubmitUpload = useCallback(async () => {
    if (resource) {
      if (!file) {
        return;
      }
      const { values } = form.getState();
      let success = false;
      if (kind === 'create') {
        throw new Error('Upload during creation is not supported');
      } else if (kind === 'edit') {
        if (record) {
          setUploadProgress(0);
          try {
            await createAsset({
              file,
              values,
              progressCallback: handleProgress,
              prefix: values.namespace || namespace,
              connectedOther: record,
            });
            success = true;
          } catch (error) {
            setUploadProgress(-1);
            if (error instanceof Error) {
              notify(error.message, 'error');
            }
          }
          setUploadProgress(100);
        }
      }
      // and reset the upload field
      form.change(UPLOAD_FIELD, undefined);
      form.resetFieldState(UPLOAD_FIELD);
      if (success && record) {
        log(
          {
            message: 'cpret.mutation.asset.created',
            args: { filename: values?.filename || file.name },
          },
          [record as Node],
        );
      }
      refresh();
    }
  }, [
    resource,
    file,
    form,
    kind,
    refresh,
    record,
    createAsset,
    handleProgress,
    namespace,
    notify,
    log,
  ]);

  if (!resource) return null;

  // from https://github.com/marmelab/react-admin/blob/3.x/packages/ra-ui-materialui/src/form/Toolbar.tsx
  const disabled = !(!props.pristine && !props.validating);

  const custo = getResourceCustomization(resource);
  const debug = false;
  return (
    <RaToolbar {...props} className={classes.root}>
      {/* keep empty wrapper to avoid passing injected props to Button ! */}
      {file && (
        <>
          <Button
            onClick={handleSubmitUpload}
            color="primary"
            variant="contained"
            disabled={!file || uploadProgress !== null}
            className={classes.button}
          >
            {!debug && uploadProgress === null && (
              <ContentSave className={clsx(classes.leftIcon, classes.icon)} />
            )}
            {!debug &&
              uploadProgress !== null &&
              uploadProgress >= 0 &&
              uploadProgress < 100 && (
                <CircularProgress
                  size={18}
                  thickness={uploadProgress === 0 ? 2 : 4}
                  className={classes.leftIcon}
                  variant={
                    uploadProgress === 0 ? 'indeterminate' : 'determinate'
                  }
                  value={uploadProgress}
                />
              )}
            {!debug && uploadProgress === 100 && (
              <ContentSaved className={clsx(classes.leftIcon, classes.icon)} />
            )}
            {!debug && uploadProgress === -1 && (
              <ContentError className={clsx(classes.leftIcon, classes.icon)} />
            )}
            {translate('action.upload')}
          </Button>
        </>
      )}
      {!file && (
        // TODO add transform={} to remove UPLOAD_FIELD
        <SaveButton
          disabled={disabled || !canWrite}
          {...(onSaveSuccess && { onSuccess: onSaveSuccess })}
          classes={saveClasses}
        />
      )}
      <ContainerForward containerClassName={classes.defaultButtons}>
        {onCancel && (
          <>
            <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
          </>
        )}
        {kind === 'edit' &&
          custo.canCreateDelete &&
          isAdmin &&
          // disable delete in prod for now
          process.env.REACT_APP_ENV !== 'prod' && <DeleteButton />}
        <ResetButton />
      </ContainerForward>
    </RaToolbar>
  );
};

export default Toolbar;
