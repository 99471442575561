import React from 'react';
import { useNotify } from 'react-admin';
import { FORM_ERROR } from 'final-form';

import { LedgerActionType, formatActionType } from './ActionTypeField';
import { DisbursementValues } from './fields/DisbursementLedgerActionFields';
import {
  RepaymentValues,
  PaymentChoice,
  // RepaymentType,
} from './fields/RepaymentLedgerActionFields';
import { UnpaidCheckValues } from './fields/UnpaidCheckLedgerActionFields';
import { RejectedBankTransferValues } from './fields/RejectedBankTransferLedgerActionFields';
import {
  useLedgerDisbursement,
  useLedgerRepayment,
  useLedgerUserDebit,
  useLedgerUserCredit,
  useLedgerPrincipalRounding,
  useLedgerUserRounding,
  useLedgerAction,
} from '../../../workflow/ledger';
import { Values } from './types';
import { DelayedScheduleRepaymentValues } from './fields/DelayedScheduleRepaymentByCheckLedgerActionFields';
import { ExcedentRepaymentByBankTransferValues } from './fields/ExcedentRepaymentByBankTransferLedgerActionFields copy';
import { PrincipalRoundingValues } from './fields/PrincipalRoundingLedgerActionFields';
import { UserRoundingValues } from './fields/UserRoundingLedgerActionFields';
import { PrincipalUserDebitValues } from './fields/PrincipalUserDebitLedgerActionFields';
import { LedgerAccount } from '../../../dataProvider/type';
import { LossesUserCreditValues } from './fields/LossesUserCreditLedgerActionFields';
import { CustomTransactionValues } from './fields/CustomTransactionLedgerActionFields';

function formatPaymentChoice(paymentChoice: PaymentChoice) {
  switch (paymentChoice) {
    case PaymentChoice.BANK_TRANSFERT:
      return 'Virement';
    case PaymentChoice.CHECK:
      return 'Chèque';
    default:
      return null;
  }
}

// function formatRepaymentType(repaymentType: RepaymentType) {
//   switch (repaymentType) {
//     case RepaymentType.FULL:
//       return 'Remboursement Total';
//     case RepaymentType.PARTIAL:
//       return 'Remboursement Partiel';
//     default:
//       return null;
//   }
// }

export const formatPayee = (...fragments: any[]) =>
  fragments.filter(Boolean).join('/');

export function useOnSubmit(borrowerId: string, onSuccess: () => void) {
  const [initLedgerDisbursement] = useLedgerDisbursement(1);
  const [createLedgerRepayment] = useLedgerRepayment();
  const [createLedgerUserDebit] = useLedgerUserDebit();
  const [createLedgerPrincipalUserDebit] = useLedgerUserDebit(
    LedgerAccount.PRINCIPAL_ACCOUNT,
  );
  const [createLedgerUserCredit] = useLedgerUserCredit();
  const [createLedgerLossesUserCredit] = useLedgerUserCredit(
    LedgerAccount.LOSSES_ACCOUNT,
  );
  const [createLedgerPrincipalRounding] = useLedgerPrincipalRounding();
  const [createLedgerUserRounding] = useLedgerUserRounding();
  const [createLedgerAction] = useLedgerAction();
  const notify = useNotify();

  const commit = React.useCallback(
    async (callback: () => Promise<any>) => {
      try {
        await callback();
      } catch (error: any) {
        notify('cpret.ledger.error', 'error', {
          _: `Une erreur est survenue, le mouvement n'a pas été ajouté : %{error}`,
          error: error.message,
        });
        return { [FORM_ERROR]: `Une erreur est survenue: ${error.message}` };
      }
      notify('cpret.ledger.success', 'success', {
        _: `Le mouvement a bien été ajouté`, // default translation - avoids console errors
      });
      onSuccess();
      return null;
    },
    [notify, onSuccess],
  );

  const onSubmit = React.useCallback(
    async (values: Values) => {
      const { actionType, ...restValues } = values;
      switch (actionType) {
        case LedgerActionType.DISBURSEMENT: {
          const {
            loanId,
            payee: inputPayee,
            amount,
          } = restValues as DisbursementValues;
          const payee = formatPayee(
            formatActionType(LedgerActionType.DISBURSEMENT),
            inputPayee,
          );
          return commit(() =>
            initLedgerDisbursement(
              borrowerId,
              {
                amount: -amount,
                payee,
              },
              loanId,
              { ...restValues, payee },
            ),
          );
        }

        case LedgerActionType.REPAYMENT: {
          const {
            paymentChoice,
            amount,
            checkNumber,
            paymentDate,
            // repaymentType,
            bankName,
          } = restValues as RepaymentValues;
          const payee = formatPayee(
            formatActionType(LedgerActionType.REPAYMENT),
            // formatRepaymentType(repaymentType),
            paymentDate,
            checkNumber,
            bankName,
            formatPaymentChoice(paymentChoice),
          );

          return commit(() =>
            createLedgerRepayment(
              borrowerId,
              {
                principalAmount: -amount,
                interestAmount: 0, // @TODO check if this should be at 0 ?
                payee,
              },
              { ...restValues, payee, pPmt: amount, iPmt: 0 },
            ),
          );
        }

        case LedgerActionType.UNPAID_CHECK: {
          const { payee: inputPayee, amount } = restValues as UnpaidCheckValues;
          const payee = formatPayee(
            formatActionType(LedgerActionType.UNPAID_CHECK),
            inputPayee,
          );
          return commit(() =>
            createLedgerUserDebit(
              borrowerId,
              {
                amount,
                payee,
              },
              { ...restValues, payee },
            ),
          );
        }

        case LedgerActionType.REJECTED_BANK_TRANSFER: {
          const { payee: inputPayee, amount } =
            restValues as RejectedBankTransferValues;
          const payee = formatPayee(
            formatActionType(LedgerActionType.REJECTED_BANK_TRANSFER),
            inputPayee,
          );
          return commit(() =>
            createLedgerUserDebit(
              borrowerId,
              {
                amount,
                payee,
              },
              { ...restValues, payee },
            ),
          );
        }

        case LedgerActionType.DELAYED_SCHEDULE_REPAYMENT: {
          const { bankName, paymentChoice, amount, checkNumber, paymentDate } =
            restValues as DelayedScheduleRepaymentValues;
          const payee = formatPayee(
            formatActionType(LedgerActionType.DELAYED_SCHEDULE_REPAYMENT),
            paymentDate,
            checkNumber,
            bankName,
            formatPaymentChoice(paymentChoice),
          );
          return commit(() =>
            createLedgerUserCredit(
              borrowerId,
              {
                amount: -amount,
                payee,
              },
              { ...restValues, payee },
            ),
          );
        }

        case LedgerActionType.EXCEDENT_REPAYMENT_BY_BANK_TRANFER: {
          const { payee: inputPayee, amount } =
            restValues as ExcedentRepaymentByBankTransferValues;
          const payee = formatPayee(
            formatActionType(
              LedgerActionType.EXCEDENT_REPAYMENT_BY_BANK_TRANFER,
            ),
            inputPayee,
          );
          return commit(() =>
            createLedgerUserCredit(
              borrowerId,
              {
                amount: -amount,
                payee,
              },
              { ...restValues, payee },
            ),
          );
        }

        case LedgerActionType.PRINCIPAL_ROUNDING: {
          const { amount, payee: inputPayee } =
            restValues as PrincipalRoundingValues;
          const payee = formatPayee(
            formatActionType(LedgerActionType.PRINCIPAL_ROUNDING),
            inputPayee,
          );
          return commit(() =>
            createLedgerPrincipalRounding(
              borrowerId,
              {
                amount,
                payee,
              },
              { ...restValues, payee },
            ),
          );
        }

        case LedgerActionType.USER_ROUNDING: {
          const { amount, payee: inputPayee } =
            restValues as UserRoundingValues;
          const payee = formatPayee(
            formatActionType(LedgerActionType.USER_ROUNDING),
            inputPayee,
          );
          return commit(() =>
            createLedgerUserRounding(
              borrowerId,
              {
                amount,
                payee,
              },
              { ...restValues, payee },
            ),
          );
        }

        case LedgerActionType.PRINCIPAL_USER_DEBIT: {
          const { amount, payee: inputPayee } =
            restValues as PrincipalUserDebitValues;
          const payee = formatPayee(
            formatActionType(LedgerActionType.PRINCIPAL_USER_DEBIT),
            inputPayee,
          );
          return commit(() =>
            createLedgerPrincipalUserDebit(
              borrowerId,
              {
                amount,
                payee,
              },
              { ...restValues, payee },
            ),
          );
        }

        case LedgerActionType.LOSSES_USER_CREDIT: {
          const { amount, payee: inputPayee } =
            restValues as LossesUserCreditValues;
          const payee = formatPayee(
            formatActionType(LedgerActionType.LOSSES_USER_CREDIT),
            inputPayee,
          );
          return commit(() =>
            createLedgerLossesUserCredit(
              borrowerId,
              {
                amount: -amount,
                payee,
              },
              { ...restValues, payee },
            ),
          );
        }

        case LedgerActionType.CUSTOM_TRANSACTION: {
          const {
            amount,
            payee: inputPayee,
            sourceAccount,
            targetAccount,
          } = values as CustomTransactionValues;
          const payee = formatPayee(
            formatActionType(LedgerActionType.CUSTOM_TRANSACTION),
            inputPayee,
          );
          return commit(() =>
            createLedgerAction(
              borrowerId,
              {
                payee,
                postings: [
                  { account: sourceAccount, amount },
                  { account: targetAccount, amount: -amount },
                ],
              },
              { ...restValues, payee },
            ),
          );
        }

        default:
          throw new Error(`Unsupported action type ${values.actionType}`);
      }
    },
    [
      borrowerId,
      commit,
      createLedgerAction,
      initLedgerDisbursement,
      createLedgerLossesUserCredit,
      createLedgerPrincipalRounding,
      createLedgerPrincipalUserDebit,
      createLedgerRepayment,
      createLedgerUserCredit,
      createLedgerUserDebit,
      createLedgerUserRounding,
    ],
  );

  return onSubmit;
}
