import React from 'react';
import { FieldValidator } from 'final-form';
import { useFormState } from 'react-final-form';

import { AgnosticTextField } from '../../../../components/Fields/agnostic/AgnosticTextField';
import { AgnosticAmountField } from '../../../../components/Fields/agnostic/AgnosticAmountField';
import { AgnosticDateField } from '../../../../components/Fields/agnostic/AgnosticDateField';
import { AgnosticSelectField } from '../../../../components/Fields/agnostic/AgnosticSelectField';
import { Ledger } from '../../../../types/schema';
import { AgnosticFormControl } from '../../../../components/Fields/agnostic/AgnosticFormControl';
import { getAccountBalance } from '../../../../lib/ledger';
import { LedgerAccount } from '../../../../dataProvider/type';

export enum PaymentChoice {
  BANK_TRANSFERT = 'BANK_TRANSFERT',
  CHECK = 'CHECK',
}

// export enum RepaymentType {
//   FULL = 'FULL',
//   PARTIAL = 'CHECK',
// }

export interface RepaymentValues {
  bankName: string;
  paymentChoice: PaymentChoice;
  amount: number;
  checkNumber: string;
  paymentDate: string;
  // repaymentType: RepaymentType;
}

export const PaymentChoiceSelectField: React.FC<{
  validate: FieldValidator<string>;
}> = ({ validate }) => {
  const options = React.useMemo(
    () => [
      { label: 'Virement', value: PaymentChoice.BANK_TRANSFERT },
      { label: 'Chèque', value: PaymentChoice.CHECK },
    ],
    [],
  );

  return (
    <AgnosticSelectField
      options={options}
      label="Mode de réglement"
      name="paymentChoice"
      required
      validate={validate}
    />
  );
};

// const RepaymentTypeSelectField: React.FC = () => {
//   const options = React.useMemo(
//     () => [
//       { label: 'Total', value: RepaymentType.FULL },
//       { label: 'Partiel', value: RepaymentType.PARTIAL },
//     ],
//     [],
//   );

//   return (
//     <AgnosticSelectField
//       options={options}
//       label="Type de remboursement"
//       name="repaymentType"
//       required
//     />
//   );
// };

export interface RepaymentLedgerActionFieldsProps {
  ledgers: Ledger[];
}

export const RepaymentLedgerActionFields: React.FC<
  RepaymentLedgerActionFieldsProps
> = ({ ledgers }) => {
  const [, , balance] = React.useMemo(
    () => getAccountBalance(ledgers, LedgerAccount.PRINCIPAL_ACCOUNT),
    [ledgers],
  );
  const { values } = useFormState();
  const { paymentChoice } = values as RepaymentValues;

  const validatePayment = React.useCallback((value: string) => {
    if (!value) {
      return 'Vous devez saisir un mode de réglement';
    }
    return null;
  }, []);

  const validateAmount = React.useCallback((value: number) => {
    if (!value) {
      return 'Vous devez saisir un montant';
    }

    if (value <= 0) {
      return 'Le montant doit être supérieur à 0';
    }

    return null;
  }, []);

  const validateCheckNumber = React.useCallback((value: string) => {
    if (!value) {
      return 'Vous devez saisir un numéro de chèque';
    }

    return null;
  }, []);

  const validatePaymentDate = React.useCallback((value: string) => {
    if (!value) {
      return 'Vous devez saisir une date de réglement';
    }

    return null;
  }, []);

  const validateBankName = React.useCallback((value: string) => {
    if (!value) {
      return 'Vous devez saisir un nom de banque';
    }

    return null;
  }, []);

  return (
    <>
      <AgnosticFormControl fullWidth>
        <PaymentChoiceSelectField validate={validatePayment} />
      </AgnosticFormControl>
      <AgnosticFormControl fullWidth>
        <AgnosticAmountField
          name="amount"
          label="Montant (€) (ex: 12,34)"
          defaultValue={balance}
          required
          validate={validateAmount}
        />
      </AgnosticFormControl>
      {paymentChoice === PaymentChoice.CHECK && (
        <AgnosticFormControl fullWidth>
          <AgnosticTextField
            name="checkNumber"
            label="Numéro de chèque"
            required
            validate={validateCheckNumber}
          />
        </AgnosticFormControl>
      )}
      <AgnosticFormControl fullWidth>
        <AgnosticDateField
          name="paymentDate"
          label="Date du réglement"
          required
          validate={validatePaymentDate}
        />
      </AgnosticFormControl>
      <AgnosticFormControl fullWidth>
        <AgnosticTextField
          name="bankName"
          label="Nom de la banque"
          required
          validate={validateBankName}
        />
      </AgnosticFormControl>
      {/* <AgnosticFormControl fullWidth>
        <RepaymentTypeSelectField />
      </AgnosticFormControl> */}
    </>
  );
};
