import { useCallback, useContext } from 'react';
import { DataProviderContext, Identifier } from 'react-admin';
import { format } from 'date-fns';
import Table from 'cli-table3';

import { EnhancedBorrower } from '../types/schema-custom';
import { useLedgerDisbursement, useLedgerUserDebit } from './ledger';
import { TransferUpdate } from './lib/fcr';
import { formatPayee } from '../resource/borrower/LedgerActionDialog/useOnSubmit';
import {
  formatActionType,
  LedgerActionType,
} from '../resource/borrower/LedgerActionDialog/ActionTypeField';
import { centsToEur } from '../lib/utils';
import { currencyOptions as options } from '../lib/ledger';
import { Loan } from '../types/schema';

export const fcrFilename = (now: Date) =>
  `OrdresVirements-${format(now, 'yyMMdd')}.txt`;

export const reportFilename = (now: Date) =>
  `OrdresVirements-report-${format(now, 'yyMMdd')}.txt`;

const locales = ['fr-FR'];
export const reportHeader = `             Générer paiements - Certificat de virements bancaires
 par l'intermédiaire de la caisse générale, dès réception du fichier PCA0A0019
                            Traitement du ${format(new Date(), 'dd/MM/yyyy')}

`;

export const useExportFCR = () => {
  const dataProvider = useContext(DataProviderContext);
  const [completeLedgerDisbursement] = useLedgerDisbursement(2);
  const [userDebit] = useLedgerUserDebit();
  const cb = useCallback(
    (ids: Identifier[], commit = false) => {
      const commitBorrower = async (
        u: TransferUpdate,
        i: number,
        uu: TransferUpdate[],
      ) => {
        const { brwr, amount, loanId, payee: inputPayee } = u;
        // Note: amount should be positive here
        console.log(`Committing borrower #${i + 1}/${uu.length} ${brwr.id}`);
        if (loanId) {
          const payee = formatPayee(
            formatActionType(LedgerActionType.DISBURSEMENT),
            inputPayee,
          );
          await completeLedgerDisbursement(
            brwr.id,
            { amount, payee },
            loanId,
            { amount, payee, loanId }, // '%{payee} [#%{loanId}]/%{amount}/(versement)'
          );
        } else {
          const payee = inputPayee;
          await userDebit(brwr.id, { amount, payee }, { payee, amount }); // '%{payee}/%{amount}/(debit)'
        }
      };
      const action = async (): Promise<[string, string | null]> => {
        const { data } = await dataProvider.getMany<EnhancedBorrower>(
          'cpretBorrower',
          {
            ids,
          },
        );

        const ordersTable = new Table({
          head: ['MONTANT', 'NOM DU BÉNÉFICIAIRE', 'IBAN', 'BIC', 'BANQUE'],
          style: { border: [], head: [] },
        });

        const updates: TransferUpdate[] = [];
        const stats = { count: 0, amount: -0 };

        data.forEach(b => {
          let credit = b.$$userCreditor;
          if (b.$$creditedLoans?.length) {
            const loans = b.loans?.edges
              .map(({ node }) => node as Loan)
              .filter(l => b.$$creditedLoans.includes(l.id));
            updates.push(
              ...loans.map(loan => ({
                amount: loan.amount || 0,
                brwr: b,
                loanId: loan.id,
                payee: 'Virement du prêt',
              })),
            );
            const rows: Table.HorizontalTableRow[] = loans.map(l => [
              centsToEur(l.amount || 0).toLocaleString(locales, options),
              `${b.contact?.givenName} ${b.contact?.familyName}`,
              l.iban || 'N/A',
              l.bic || 'N/A',
              'N/A',
            ]);
            ordersTable.push(...rows);
            stats.count += loans.length;
            const total = loans.reduce((r, l) => r - (l.amount || 0), 0);
            stats.amount += total;
            credit -= total;
          }
          if (credit < 0) {
            const amount = -credit;
            updates.push({
              amount,
              brwr: b,
              payee: 'Remboursement par virement',
            });
            ordersTable.push([
              centsToEur(amount).toLocaleString(locales, options),
              `${b.contact?.givenName} ${b.contact?.familyName}`,
              'N/A',
              'N/A',
              'N/A',
            ]);
          }
        });

        const orders = ordersTable.toString();
        let report: string | null = null;
        if (stats.count) {
          const reportTable = new Table({
            head: [
              'CODE NATURE',
              'LIBELLÉ DES PRÊTS',
              'NOMBRE',
              'TOTAL VIREMENTS',
            ],
            style: { border: [], head: [] },
            colWidths: [19, 19, 19, 19],
          });

          reportTable.push(
            [
              '29',
              "PRÊT D'HONNEUR",
              stats.count,
              centsToEur(-stats.amount).toLocaleString(locales, options),
            ],
            [
              'TOTAL GÉNÉRAL',
              '',
              stats.count,
              centsToEur(-stats.amount).toLocaleString(locales, options),
            ],
          );
          report = `${reportHeader}${reportTable.toString()}`;
        }

        if (commit) {
          console.log(`Updating ${updates.length} borrowers`);
          const input = updates.slice(0, undefined).entries();
          // parallelize !?
          // do not await, returns files immediately
          Promise.all(
            [...new Array(1)].map(() =>
              (async () => {
                let next = input.next();
                while (!next.done) {
                  const {
                    value: [i, u],
                  } = next;
                  await commitBorrower(u, i, updates);
                  // breathe
                  await new Promise(res => {
                    requestAnimationFrame(() => {
                      setTimeout(res, 500);
                    });
                  });
                  next = input.next();
                }
              })(),
            ),
          );
        }
        return [orders, report];
      };
      return action();
    },
    [dataProvider, completeLedgerDisbursement, userDebit],
  );
  return cb;
};
