import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import { Owner } from '../../types/schema';
import { HistoryNode } from '../../history/HistoryNode';
import { HistoryPanel } from '../../history/HistoryPanel';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles<Theme>(theme => createStyles({}));

export interface OwnerHistoryProps {
  record?: Owner;
}

export const OwnerHistory: React.FC<OwnerHistoryProps> = ({ record }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classes = useStyles();

  if (!record) {
    return null;
  }

  return (
    <HistoryPanel resource="Owner" id={record.id}>
      {({ node }) => <HistoryNode key={node.id} node={node} />}
    </HistoryPanel>
  );
};
