import React from 'react';

import SimpleOption, { SimpleOptionProps } from './SimpleOption';
import PopperOption, { PopperOptionProps } from './PopperOption';

import { XOR } from '../../../../../../types/utils';

type OptionProps = XOR<PopperOptionProps, SimpleOptionProps>;

function isSimpleOptionProps(props: OptionProps): props is SimpleOptionProps {
  return typeof props.onClick !== 'undefined';
}

const Option: React.FC<OptionProps> = (props: OptionProps) => {
  if (isSimpleOptionProps(props)) {
    return <SimpleOption {...props} />;
  }
  return <PopperOption {...props} />;
};

export default Option;
