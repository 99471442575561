import _castArray from 'lodash/castArray';
import { FieldProps as RaFieldProps } from 'react-admin';
import { Exporter, InputProps as RaInputProps } from 'ra-core';

import type {
  ProjectInfosWithoutCustomization,
  ResourceInfos,
  ResourceKey,
} from '../project/projectInfos';

/**
 * Customization as defined in customization files
 * (which is enhanced later on before usage)
 */
export type Customization = {
  i18nProjectName: string;
  canAccess?: AccessRoles;
  resources: ResourceCustomization[];
};

type ResourceCustomization = {
  typename: string;
  i18nName: string;
  mainFields: FieldName | FieldName[];
  icon?: React.FC;
  canList?: AccessRoles;
  canEdit?: AccessRoles;
  canCreateDelete?: AccessRoles;
  defaultValues?: {
    [k in FieldName]: any;
  };
  i18nFields?: { [k in FieldName]: string };
  list?: {
    i18nName: string;
    fields?: CustomizationListField[];
    sort?: { field: FieldName; order: 'ASC' | 'DESC' };
    filter?: {
      fields: CustomizationFilterField[];
    };
    exporter?: boolean | FieldName[] | Exporter;
  };
  form?: {
    fields: CustomizationFormField[];
  };
  fieldsProps?: CustomizationFieldsProps;
};

type AccessRoles = boolean | string | string[];

export type FieldName = string;
export type FieldProps = {
  /** Shall not be used manually, introspection get it for us @see generateInput -> NON_NULL */
  required?: boolean;
  /** @deprecated use disabled instead */
  readonly?: never;
  disabled?: boolean;
  hidden?: boolean;
  hiddenOnCreate?: boolean;
  multiline?: boolean;
  resettable?: boolean;
  /** Text editor output as HTML */
  html?: boolean;
  /** Text editor output as Markdown */
  markdown?: boolean;
  /** Yaml editor */
  yaml?: boolean;
  /** Page editor with mutiple components */
  page?: boolean;
};

export type CustomizationListField = {
  name: FieldName;
};

type CustomizationFormFieldClassic = {
  name: FieldName;
};
type CustomizationFormFieldComponent = {
  Component: React.FC<RaFieldProps>;
};
type CustomizationFormInputComponent = CustomizationFormFieldClassic & {
  Component: React.FC<RaInputProps>;
};
export type CustomizationFormField =
  | CustomizationFormFieldClassic
  | CustomizationFormFieldComponent
  | CustomizationFormInputComponent;

export function customizationFormFieldIsInputComponent(
  formField: CustomizationFormField,
): formField is CustomizationFormInputComponent {
  return (
    typeof (formField as CustomizationFormInputComponent).name !==
      'undefined' &&
    typeof (formField as CustomizationFormInputComponent).Component !==
      'undefined'
  );
}
export function customizationFormFieldIsComponent(
  formField: CustomizationFormField,
): formField is CustomizationFormFieldComponent {
  return (
    typeof (formField as CustomizationFormFieldComponent).Component !==
    'undefined'
  );
}

export type CustomizationFilterField = {
  name: FieldName;
  alwaysOn?: boolean;
};

export type CustomizationFieldsProps = {
  [k in FieldName]?: FieldProps;
};

/*
 * Enhanced customization defaults
 */
const systemFieldsDefaultProps: CustomizationFieldsProps = {
  id: {
    disabled: true,
    hiddenOnCreate: true,
  },
  createdAt: {
    disabled: true,
    hiddenOnCreate: true,
  },
  updatedAt: {
    hiddenOnCreate: true,
    disabled: true,
  },
  status: {},
  version: {
    hidden: true,
  },
};

const assetFieldsDefaultProps: CustomizationFieldsProps = {
  key: {
    hiddenOnCreate: true,
    disabled: true,
  },
  size: {
    disabled: true,
  },
  type: {
    disabled: true,
  },
};

/**
 * Enhanced customization
 */
export type EnhancedCustomization = Omit<
  Customization,
  'resources' | 'canAccess'
> & {
  resourcesOrder: ResourceKey[];
  canAccess: EnhancedAccessRoles;
  resources: EnhancedCustomizationResources;
};
export type EnhancedAccessRoles = Exclude<AccessRoles, string>;
type EnhancedCustomizationResources = {
  [k in ResourceKey]?: EnhancedResourceCustomization;
};
export type EnhancedResourceCustomization = Omit<
  ResourceCustomization,
  'canList' | 'canEdit' | 'canCreateDelete' | 'list' | 'mainFields'
> & {
  canList: Exclude<NonNullable<ResourceCustomization['canList']>, string>;
  canEdit: Exclude<NonNullable<ResourceCustomization['canEdit']>, string>;
  canCreateDelete: Exclude<
    NonNullable<ResourceCustomization['canCreateDelete']>,
    string
  >;
  mainFields: FieldName[];
  list?: Omit<NonNullable<ResourceCustomization['list']>, 'filter'> & {
    filter: NonNullable<NonNullable<ResourceCustomization['list']>['filter']>;
  };
};

/**
 * Enhance customization:
 * - extracts key from resource asset to ease it's access using the resourceKey
 * - add system and asset default filed props to resources fieldProps
 * - set mainFields as forced array
 * - set list.filter as mandatory (mainFields used)
 */
export function enhanceCustomization(
  customization: Customization,
  projectInfos: ProjectInfosWithoutCustomization,
): EnhancedCustomization {
  const { resourcesInfos, assetResource } = projectInfos;
  const {
    canAccess: canAccessRaw = true,
    resources,
    ...customizationRest
  } = customization;
  return {
    ...customizationRest,
    canAccess: typeof canAccessRaw === 'string' ? [canAccessRaw] : canAccessRaw,
    resourcesOrder: resources.reduce<ResourceKey[]>(
      (acc, customizationResource) => {
        const resourceEntry = (
          Object.entries(resourcesInfos) as Array<[ResourceKey, ResourceInfos]>
        ).find(
          ([, resourceInfos]) =>
            resourceInfos.type.name === customizationResource.typename,
        );
        const resourceKey = resourceEntry && resourceEntry[0];
        if (resourceKey) {
          acc.push(resourceKey);
        }
        return acc;
      },
      [],
    ),
    /** Change resources = ResourceInfos[]
     *  to     resources = { [k in resourceKey]: ResourceInfos }
     */
    resources: resources.reduce<EnhancedCustomizationResources>(
      (acc, customizationResource) => {
        const resourceEntry = (
          Object.entries(resourcesInfos) as Array<[ResourceKey, ResourceInfos]>
        ).find(
          ([, resourceInfos]) =>
            resourceInfos.type.name === customizationResource.typename,
        );
        const resourceKey = resourceEntry && resourceEntry[0];
        if (resourceKey) {
          // Add system fields default props to field props
          let { fieldsProps } = customizationResource;
          Object.entries(systemFieldsDefaultProps).forEach(
            ([systemFieldName, systemFieldProps]) => {
              (customizationResource.fieldsProps =
                customizationResource.fieldsProps || {})[systemFieldName] = {
                ...systemFieldProps,
                ...fieldsProps?.[systemFieldName],
              };
            },
          );

          // Add asset fields default props to field props
          fieldsProps = customizationResource.fieldsProps;
          const isAssetResource = assetResource === resourceKey;
          if (isAssetResource) {
            Object.entries(assetFieldsDefaultProps).forEach(
              ([assetFieldName, assetFieldProps]) => {
                (customizationResource.fieldsProps =
                  customizationResource.fieldsProps || {})[assetFieldName] = {
                  ...assetFieldProps,
                  ...fieldsProps?.[assetFieldName],
                };
              },
            );
          }

          /* Make mandatory fields:
           * - canList
           * - canEdit
           * - canCreateDelete
           * - list.filter (as mainFields)
           * Set field:
           * - canList as boolean or string array (cast string to array)
           * - mainFields as array
           */
          const {
            mainFields: mainFieldsRaw,
            canList: canListRaw = true,
            canEdit: canEditRaw = true,
            canCreateDelete: canCreateDeleteRaw = true,
            list: listRaw,
          } = customizationResource;
          const mainFields = _castArray(mainFieldsRaw);
          const canList =
            typeof canListRaw === 'string' ? [canListRaw] : canListRaw;
          const canEdit =
            typeof canEditRaw === 'string' ? [canEditRaw] : canEditRaw;
          const canCreateDelete =
            typeof canCreateDeleteRaw === 'string'
              ? [canCreateDeleteRaw]
              : canCreateDeleteRaw;
          const list = listRaw
            ? {
                ...listRaw,
                filter: listRaw?.filter ?? {
                  fields: mainFields.map(fieldName => ({ name: fieldName })),
                },
              }
            : undefined;
          const enhancedCustomizationResource: EnhancedResourceCustomization = {
            ...customizationResource,
            canList,
            canEdit, // Do not fallback to canList as one can edit a connection to an unlisted resource
            canCreateDelete,
            mainFields,
            list,
          };
          acc[resourceKey] = enhancedCustomizationResource;
        }
        return acc;
      },
      {},
    ),
  };
}
