import { HonorificPrefix } from '../../types/schema';
import { EnhancedOwner } from '../../types/schema-custom';

export type NPFAddress = {
  // cms: 'string';
  immat: 'string';
  natureAdresse: 'string';
  bisTer: 'string';
  numeroAdresse: 'string';
  complementAdresse: 'string';
  cpAdresse: 'string';
  communeAdresse: 'string';
};

export type NPFCessation = {
  // cms: 'string';
  immat: 'string';
  /** YYYY-MM-DD */
  dateCess: 'string';
  /** 612 / 666 */
  codeCess: 'string';
  /** 'Depvol RH0281', 'Ret demande agent' */
  motifCess: 'string';
};

export type NPFSociete = {
  // cms: 'string';
  immat: 'string';
  civilite: 'string';
  nomUsuel: 'string';
  prenom: 'string';
  societeCourante: 'string';
};

export const honorific = (s: string): HonorificPrefix | undefined => {
  if (!s) return undefined;
  const r: HonorificPrefix | undefined = (
    {
      MR: HonorificPrefix.MR,
      MME: HonorificPrefix.MME,
      Monsieur: HonorificPrefix.MR,
      Madame: HonorificPrefix.MME,
      Mademoiselle: HonorificPrefix.MME,
      Masculin: HonorificPrefix.MR,
      Féminin: HonorificPrefix.MME,
      '1': HonorificPrefix.MR,
      '2': HonorificPrefix.MME,
      '3': HonorificPrefix.MME,
    } as { [k: string]: HonorificPrefix }
  )[s];
  return r;
};

export const borrowersToNPF = (
  oo: EnhancedOwner[],
): { [k: string]: string }[] => {
  const result = new Set<string>();
  oo.forEach(o => {
    const cp = o.sncfCP;
    const cessation =
      o.sncfContractEndDate && new Date(o.sncfContractEndDate) > new Date(); // filter by missing cessation - #257
    if (cp && !cessation) result.add(cp);
  });
  return [...result.values()]
    .sort((a, b) => a.localeCompare(b))
    .map(cp => ({ IMMAT: cp }));
};
