import React from 'react';
import { FieldProps, useTranslate } from 'react-admin';
import clsx from 'clsx';
import _get from 'lodash/get';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';

import { Connection } from 'phicomas-client';

import sanitizeRestProps from './sanitizeRestProps';

import {
  getResourceCustomization,
  getResourceInfos,
  ResourceInfos,
  ResourceKey,
} from '../../project/projectInfos';

import { isStringCohercible } from '../../types/utils';
import { useChipFieldStyle } from './chipField';

const MAX_CHIPS = 4;

const useStyles = makeStyles<Theme>(
  () =>
    createStyles({
      root: {
        display: 'inline-block',
      },
    }),
  { name: 'ConnectionField' },
);

export type ConnectionFieldProps = FieldProps & {
  connectionInfos?: NonNullable<ResourceInfos['connections']>[ResourceKey];
};
const ConnectionField: React.FC<ConnectionFieldProps> = ({
  connectionInfos,
  ...restProps
}: ConnectionFieldProps) => {
  const classes = useStyles(restProps);
  const chipClasses = useChipFieldStyle();
  const translate = useTranslate();

  const { record, source, className } = restProps;

  if (!record || !source || !connectionInfos) return null;
  const { onResource } = connectionInfos;

  const recordConnectionValue: Connection = _get(record, source);
  const {
    type: { name: connectedTypename },
  } = getResourceInfos(onResource);
  const resourceCusto = getResourceCustomization(onResource);

  if (!resourceCusto) {
    throw new Error(`No mainField defined for resource ${onResource}`);
  }

  const { mainFields } = resourceCusto;

  return (
    <div
      className={clsx(classes.root, className)}
      {...sanitizeRestProps(restProps)}
    >
      {recordConnectionValue.edges.length > MAX_CHIPS ? (
        <Chip
          label={`${
            recordConnectionValue.edges.length > 99
              ? '99+'
              : recordConnectionValue.edges.length
          } ${translate(`resources.${onResource}.name`, {
            smart_count: recordConnectionValue.edges.length,
          })}`}
          className={chipClasses.chip}
        />
      ) : (
        recordConnectionValue.edges.map(({ node }) => {
          const labels = mainFields.reduce<string[]>((acc, fieldName) => {
            const fieldValue = node[fieldName];
            if (isStringCohercible(fieldValue)) {
              acc.push(fieldValue);
            }
            return acc;
          }, []);
          if (labels.length === 0) return null;
          return (
            <Chip
              key={`${record.id}-connection-${connectedTypename}-${node.id}`}
              label={labels.join(' ')}
              className={chipClasses.chip}
            />
          );
        })
      )}
    </div>
  );
};

export default ConnectionField;
