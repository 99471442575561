import React from 'react';
import {
  FormControl as BaseFormControl,
  FormControlProps,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  }),
);

export const AgnosticFormControl: React.FC<
  Omit<FormControlProps, 'className'>
> = props => {
  const classes = useStyles();

  return <BaseFormControl className={classes.root} {...props} />;
};
