import React from 'react';
import { Route } from 'react-router-dom';
import { CustomRoute, RouteWithoutLayout } from 'react-admin';
import OAuthRedir from 'phileog-login/dist/OAuthRedir';

import CGU, { key as cguKey } from '../components/customPages/CGU';
import {
  BorrowerList as GPGList,
  key as fluxGPGKey,
} from '../components/customPages/FluxGPG';
import {
  BorrowerList as RappelsList,
  key as rappelsKey,
} from '../components/customPages/Rappels';
import {
  BorrowerList as VirementsList,
  key as virementsKey,
} from '../components/customPages/Virements';
import {
  LedgerList as ComptaList,
  key as exportsComptablesKey,
} from '../components/customPages/ExportsComptables';
import { TemplatesProvider } from '../context/templatesContext';

import { awsCreds } from '../auth/authProvider';

export const customRoutes: React.ReactElement<CustomRoute>[] = [
  <RouteWithoutLayout
    exact
    path="/oauth"
    render={(props: any) => <OAuthRedir {...props} credentials={awsCreds} />} // TODO use redirect if deep linking needed (see formtraction)
    noLayout
  />,
  <Route exact path={`/${cguKey}`} component={CGU} />,
  // https://marmelab.com/react-admin/doc/3.16/CustomApp.html
  <Route
    exact
    path={`/${fluxGPGKey}`}
    render={routeProps => (
      <GPGList
        resource="cpretBorrower"
        basePath="/cpretBorrower"
        {...routeProps}
      />
    )}
  />,
  <Route
    exact
    path={`/${rappelsKey}`}
    render={routeProps => (
      <TemplatesProvider>
        <RappelsList
          resource="cpretBorrower"
          basePath="/cpretBorrower"
          {...routeProps}
        />
      </TemplatesProvider>
    )}
  />,
  <Route
    exact
    path={`/${virementsKey}`}
    render={routeProps => (
      <VirementsList
        resource="cpretBorrower"
        basePath="/cpretBorrower"
        {...routeProps}
      />
    )}
  />,
];

// if (
//   process.env.REACT_APP_ENV !== 'stage' &&
//   process.env.REACT_APP_ENV !== 'prod'
// )
customRoutes.push(
  <Route
    exact
    path={`/${exportsComptablesKey}`}
    render={routeProps => (
      <ComptaList
        resource="cpretLedger"
        basePath="/cpretLedger"
        {...routeProps}
      />
    )}
  />,
);
