/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Button } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { get } from 'lodash';
import Color from 'color';

import { Guarantee } from '../../types/schema';
import FormSection from '../../components/baseElements/FormSection';
import FormRow from '../../components/baseElements/FormRow';
import { getDataBlob, GuaranteeDataBlobField } from './GuaranteeDataBlobField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      width: 'auto',
      '&:nth-child(odd)': {
        backgroundColor: Color(theme.palette.specials.cpretBlue)
          .alpha(0.1)
          .toString(),
      },
    },
    verticalLayout: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        marginBottom: theme.spacing(1),
      },
    },
    hoverBackgroundColor: {
      backgroundColor: theme.palette.action.hover,
    },
    oddColored: {
      '&:nth-child(odd)': {
        backgroundColor: Color(theme.palette.specials.cpretBlue)
          .alpha(0.1)
          .toString(),
      },
    },
    dialogContent: {
      '& fieldset': {
        width: 'auto',
      },
    },
  }),
);

export interface GuaranteeDetailsDialogProps {
  record: Guarantee;
}

export const GuaranteeDetailsDialog: React.FC<GuaranteeDetailsDialogProps> = ({
  record,
}) => {
  const [open, setOpen] = React.useState(false);
  const toggleOpen = React.useCallback(() => setOpen(!open), [open]);
  const classes = useStyles();
  const dataBlob = React.useMemo(() => getDataBlob(record), [record]);
  const operationDetailLength = get(
    dataBlob,
    'detail.operation.detail',
    [],
  ).length;
  const legacyGuarantyLoan = get(dataBlob, 'detail.pretsCautionnes', []);

  const operationDetailRows = React.useMemo(() => {
    const rows = [];

    for (let i = 0; i < operationDetailLength; i++) {
      rows.push(
        <TableRow className={classes.oddColored} key={`row${i}`}>
          <TableCell>
            <GuaranteeDataBlobField
              source={`detail.operation.detail[${i}].type`}
              record={record}
              dataType="raw"
            />
          </TableCell>
          <TableCell align="right">
            <GuaranteeDataBlobField
              source={`detail.operation.detail[${i}].montant`}
              record={record}
              dataType="rawCurrency"
              currency="EUR"
            />
          </TableCell>
        </TableRow>,
      );
    }

    return rows;
  }, [classes.oddColored, operationDetailLength, record]);

  return (
    <>
      <Button
        color="default"
        variant="contained"
        size="medium"
        label="Voir les détails"
        onClick={toggleOpen}
      />
      <Dialog fullWidth open={open} onClose={toggleOpen} maxWidth="lg">
        <DialogTitle>Détails</DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <FormSection title="Adresse du bien financé">
            <FormRow className={classes.row}>
              <GuaranteeDataBlobField
                label="Adresse"
                source="detail.adresseDuBien.adresse"
                record={record}
              />
              <GuaranteeDataBlobField
                label="Code postal"
                source="detail.adresseDuBien.codePostal"
                record={record}
              />
              <GuaranteeDataBlobField
                label="Ville"
                source="detail.adresseDuBien.ville"
                record={record}
              />
              <GuaranteeDataBlobField
                label="Résidence Principale"
                source="detail.adresseDuBien.residencePrincipale"
                record={record}
                dataType="boolean"
              />
            </FormRow>
          </FormSection>
          <FormSection title="Opération">
            <FormRow className={classes.row}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">Montant</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{operationDetailRows}</TableBody>
                </Table>
              </TableContainer>
              <div className={classes.verticalLayout}>
                <GuaranteeDataBlobField
                  label="Coût travaux"
                  source="detail.operation.coutTravaux"
                  record={record}
                  dataType="currency"
                  currency="EUR"
                />

                <GuaranteeDataBlobField
                  label="Part indivisée"
                  source="detail.operation.partIndivisee"
                  record={record}
                  dataType="currency"
                  currency="EUR"
                />
              </div>
            </FormRow>
          </FormSection>
          <FormSection title="Prêt cautionné">
            <FormRow className={classes.row}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Banque</TableCell>
                      <TableCell>Agence</TableCell>
                      <TableCell>Adresse</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {legacyGuarantyLoan.map((loan: any, i: number) => (
                      <>
                        <TableRow className={classes.hoverBackgroundColor} key={`loan${i}-0`}>
                          <TableCell>
                            <GuaranteeDataBlobField
                              source={`detail.pretsCautionnes[${i}].banque`}
                              record={record}
                              dataType="raw"
                            />
                          </TableCell>
                          <TableCell>
                            <GuaranteeDataBlobField
                              source={`detail.pretsCautionnes[${i}].agence`}
                              record={record}
                              dataType="raw"
                            />
                          </TableCell>
                          <TableCell>
                            <GuaranteeDataBlobField
                              source={`detail.pretsCautionnes[${i}].adresse`}
                              record={record}
                              dataType="raw"
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow key={`loan${i}-1`}>
                          <TableCell />
                          <TableCell colSpan={2}>
                            <Table size="small">
                              <TableBody>
                                {loan.prets.map((pret: any, j: number) => (
                                  <>
                                    <TableRow key={`pret${j}-0`}>
                                      <TableCell colSpan={2}>
                                        <GuaranteeDataBlobField
                                          source={`detail.pretsCautionnes[${i}].prets[${j}].label`}
                                          record={record}
                                          dataType="raw"
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <GuaranteeDataBlobField
                                          source={`detail.pretsCautionnes[${i}].prets[${j}].montant`}
                                          record={record}
                                          dataType="rawCurrency"
                                          currency="EUR"
                                        />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow key={`pret${j}-1`}>
                                      <TableCell />
                                      <TableCell>Paliers</TableCell>
                                      <TableCell>
                                        <Table size="small">
                                          <TableBody>
                                            {pret.paliers.map(
                                              (_: any, k: number) => (
                                                <TableRow key={`palier${k}`}>
                                                  <TableCell colSpan={2}>
                                                    <GuaranteeDataBlobField
                                                      source={`detail.pretsCautionnes[${i}].prets[${j}].paliers[${k}].mois`}
                                                      record={record}
                                                      dataType="raw"
                                                    />
                                                  </TableCell>
                                                  <TableCell>
                                                    <GuaranteeDataBlobField
                                                      source={`detail.pretsCautionnes[${i}].prets[${j}].paliers[${k}].montant`}
                                                      record={record}
                                                      dataType="rawCurrency"
                                                      currency="EUR"
                                                    />
                                                  </TableCell>
                                                </TableRow>
                                              ),
                                            )}
                                          </TableBody>
                                        </Table>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ))}
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </FormRow>
          </FormSection>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            size="medium"
            label="Fermer"
            onClick={toggleOpen}
            type="button"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
