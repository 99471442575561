import React from 'react';

import {
  EditorState,
  convertToRaw,
  ContentState,
  ContentBlock,
  DraftEntityMutability,
  DraftEntityType,
  RawDraftEntity,
  RichUtils,
  DraftEditorCommand,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../WysiwygDraftjs.css';
import './HtmlComponent/HtmlComponent.css';
import draftToHtml from 'draftjs-to-html';
import convert from 'react-attr-converter';
import { SimpleCms } from 'phicomas-client';

import {
  withStyles,
  Theme,
  createStyles,
  WithStyles,
} from '@material-ui/core/styles';

import AssetLinkButton from './HtmlComponent/Link/AssetLinkButton'; // eslint-disable-line import/no-cycle
import AssetImageButton from './HtmlComponent/Image/AssetImageButton'; // eslint-disable-line import/no-cycle
import AssetIframeButton from './HtmlComponent/Iframe/IframeButton'; // eslint-disable-line import/no-cycle

import RenderImageEntity from './HtmlComponent/Image/RenderImageEntity';
import RenderIframeEntity from './HtmlComponent/Iframe/RenderIframeEntity';

import linkStringifyer from './HtmlComponent/Link/stringifyer';
import imageStringifyer from './HtmlComponent/Image/stringifyer';
import iframeStringifyer from './HtmlComponent/Iframe/stringifyer';
import transformCssToReact from '../../../../lib/css-to-react';
import SelectedContextProvider from './HtmlComponent/selectedContext';

import htmlToDraft from '../../../../lib/html-to-draftjs';

const styles = (theme: Theme) =>
  createStyles({
    editor: {
      padding: theme.spacing(0.5, 1),
      border: '1px solid #e1e1e1',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.white,
      minHeight: 100,
      maxHeight: 600,
    },
  });

type HtmlComponentProps = {
  sourceValue?: SimpleCms.PageComponentHtmlProps;
  setSourceValue: (newValue: SimpleCms.PageComponentHtmlProps) => void;
} & WithStyles<typeof styles>;

type HtmlComponentState = {
  editorState: EditorState;
};

class HtmlComponent extends React.Component<
  HtmlComponentProps,
  HtmlComponentState
> {
  /** Transform string value (DB compatible) into DraftJS value */
  static valueToDraft(value: string): ContentState | undefined {
    /** Handle atomic entities with refined data */
    function customChunkRenderer(
      nodeName: string,
      node: HTMLElement,
    ): RawDraftEntity<{ [key: string]: any }> | undefined {
      if (nodeName === 'img') {
        const data: { [key: string]: any } = {};
        for (let i = 0; i < node.attributes.length; i += 1) {
          const attributeName = node.attributes[i].nodeName;
          data[convert(attributeName)] =
            node.getAttribute(attributeName) || attributeName;
        }
        if (data.style) {
          // Transform string CSS into React object CSS
          data.style = transformCssToReact(data.style);
        }
        return {
          type: 'IMAGE',
          mutability: 'IMMUTABLE',
          data,
        };
      }
      if (nodeName === 'iframe') {
        const data: { [key: string]: any } = {};
        for (let i = 0; i < node.attributes.length; i += 1) {
          const attributeName = node.attributes[i].nodeName;
          data[convert(attributeName)] =
            node.getAttribute(attributeName) || attributeName;
        }
        if (data.style) {
          // Transform string CSS into React object CSS
          data.style = transformCssToReact(data.style);
        }
        return {
          type: 'IFRAME',
          mutability: 'IMMUTABLE',
          data,
        };
      }
      return undefined;
    }

    const blocksFromHtml = htmlToDraft(value, customChunkRenderer);
    if (blocksFromHtml) {
      const { contentBlocks, entityMap } = blocksFromHtml;
      return ContentState.createFromBlockArray(contentBlocks, entityMap);
    }
    return undefined;
  }

  /** Transform DraftJS value into string value (DB compatible) */
  static draftToValue(content: ContentState): string {
    const hashConfig = undefined; // { trigger: '#', separator: ' ' }
    const directional = undefined; // Boolean, if directional is true text is aligned according to bidi algorithm.

    /** Handle atomic entities with special stringifiers */
    function customEntityTransform(
      entity: {
        type: DraftEntityType;
        mutalibity: DraftEntityMutability;
        data: { [k: string]: any };
      },
      text?: string,
    ) {
      if (entity.type === 'LINK') {
        return linkStringifyer(entity.data, text);
      }
      if (entity.type === 'IMAGE') {
        return imageStringifyer(entity.data);
      }
      if (entity.type === 'IFRAME') {
        return iframeStringifyer(entity.data);
      }
      return undefined;
    }

    let transformedValue: string;
    if (!content.hasText()) {
      transformedValue = '';
    } else {
      transformedValue = draftToHtml(
        convertToRaw(content),
        hashConfig,
        directional,
        customEntityTransform,
      );
    }
    return transformedValue;
  }

  constructor(props: HtmlComponentProps) {
    super(props);

    const contentState = HtmlComponent.valueToDraft(
      props.sourceValue?.content ?? '',
    );
    let editorState: EditorState;
    if (contentState) {
      editorState = EditorState.createWithContent(contentState);
    } else {
      editorState = EditorState.createEmpty();
    }

    this.state = {
      editorState,
    };

    this.handleEditorStateChange = this.handleEditorStateChange.bind(this);
    this.customBlockRenderer = this.customBlockRenderer.bind(this);
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.getEditorState = this.getEditorState.bind(this);
  }

  componentDidMount() {
    const { sourceValue } = this.props;
    const { editorState } = this.state;
    // Initialize data structure (useful for the SourceComponent)
    if (!sourceValue?.content) {
      this.onChange(
        HtmlComponent.draftToValue(editorState.getCurrentContent()),
      );
    }
  }

  shouldComponentUpdate(
    nextProps: HtmlComponentProps,
    nextState: HtmlComponentState,
  ) {
    // Do not render on props changes, only on state changes
    if (nextState !== this.state) {
      return true;
    }
    return false;
  }

  handleEditorStateChange(newEditorState: EditorState) {
    const { editorState } = this.state;

    // Always update the state (it contains everything, up to cursor positonning)
    this.setState({ editorState: newEditorState });

    // Check for actual change in content, to be sent to the page constructor
    const previousContent = editorState.getCurrentContent();
    const currentContent = newEditorState.getCurrentContent();
    if (currentContent !== previousContent) {
      this.onChange(HtmlComponent.draftToValue(currentContent));
    }
  }

  handleKeyCommand(command: DraftEditorCommand) {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      // Fix the non-editable-block delete issue - https://github.com/jpuri/react-draft-wysiwyg/issues/987
      this.handleEditorStateChange(newState);
      return 'handled';
    }

    return 'not-handled';
  }

  private onChange(htmlValue: string) {
    const { sourceValue, setSourceValue } = this.props;
    setSourceValue({
      ...sourceValue,
      content: htmlValue,
    });
  }

  getEditorState() {
    const { editorState } = this.state;
    return editorState;
  }

  /** Block renderer within the wysiwyg editor
   * eg: Here an atomic block with the entity type IMAGE will be rendered using the ImageAssetBlock component */
  customBlockRenderer(contentBlock: ContentBlock): any {
    // BECAUSE I CAN'T FIND A WAY TO OVERIDE IT PROPERLY
    // const [config, getEditorState] = rest as [any, () => EditorState];
    const editorState = this.getEditorState();

    const type = contentBlock.getType();
    if (type === 'atomic') {
      const entityKey = contentBlock.getEntityAt(0);
      if (entityKey) {
        const contentState = editorState.getCurrentContent();
        const entity = contentState.getEntity(entityKey);

        const entityType = entity.getType();
        if (entityType === 'IMAGE') {
          return {
            component: RenderImageEntity,
            editable: false,
            props: {
              getEditorState: this.getEditorState,
              onEditorStateChange: this.handleEditorStateChange,
            },
          };
        }
        if (entityType === 'IFRAME') {
          return {
            component: RenderIframeEntity,
            editable: false,
            props: {
              getEditorState: this.getEditorState,
              onEditorStateChange: this.handleEditorStateChange,
            },
          };
        }
      }
    }
    return undefined;
  }

  render() {
    const { classes } = this.props;
    const { editorState } = this.state;

    return (
      <SelectedContextProvider>
        <Editor
          toolbar={{
            // Default values can be found here https://jpuri.github.io/react-draft-wysiwyg/#/docs
            // Bellow are listed only the changes to defaults
            options: [
              'inline',
              'blockType',
              // 'fontSize',
              // 'fontFamily',
              'list',
              // 'textAlign',
              // 'colorPicker',
              // 'link',
              // 'embedded',
              // 'emoji',
              // 'image',
              'remove',
              'history',
            ],
            inline: {
              options: [
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'monospace',
                // 'superscript',
                // 'subscript',
              ],
              className: 'rdw-toolbar-inline',
            },
            blockType: {
              inDropdown: false,
              options: [
                'Normal',
                'H1',
                'H2',
                'H3',
                // 'H4',
                // 'H5',
                // 'H6',
                'Blockquote',
                // 'Code',
              ],
              className: 'rdw-toolbar-block-type',
            },
            list: {
              className: 'rdw-toolbar-list',
            },
            link: {
              className: 'rdw-toolbar-link',
            },
            remove: {
              className: 'rdw-toolbar-remove', // /!\ Doesn't apply to the wrapper ...
            },
            history: {
              className: 'rdw-toolbar-history',
            },
          }}
          toolbarCustomButtons={[
            <AssetLinkButton />,
            <AssetImageButton getEditorState={this.getEditorState} />,
            <AssetIframeButton getEditorState={this.getEditorState} />,
          ]}
          localization={{
            locale: 'fr',
          }}
          editorState={editorState}
          onEditorStateChange={this.handleEditorStateChange}
          editorClassName={classes.editor}
          customBlockRenderFunc={this.customBlockRenderer}
          handleKeyCommand={this.handleKeyCommand}
        />
      </SelectedContextProvider>
    );
  }
}

export default withStyles(styles)(HtmlComponent);
