import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from 'react';
import { AtomicBlockUtils, EditorState } from 'draft-js';
import _isEqual from 'lodash/isEqual';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { WebAsset as IframeIcon } from '@material-ui/icons';

import Option from '../Option/Option';

import { isValidUrl } from '../../../../../../lib/regexValidators';
import { SelectedContext } from '../selectedContext';
import { usePrevious } from '../../../../../../hooks/use-previous';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    fieldWrapper: {
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',

      margin: theme.spacing(-0.5),
      marginBottom: theme.spacing(1 - 0.5),
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    field: {
      flexGrow: 1,
      '& label': {
        fontSize: '1rem',
      },
      '& input': {
        fontSize: '1rem',
      },
    },
  }),
);

type IframeButtonProps = {
  onChange?: (s: any) => any;
  getEditorState: () => EditorState;
};

const IframeButton: React.FC<IframeButtonProps> = ({
  onChange,
  getEditorState,
}: IframeButtonProps) => {
  const classes = useStyles();
  const [selectedEntity, setSelectedEntity] = useContext(SelectedContext);

  const [src, setSrc] = useState('');
  const handleSrcChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSrc(event.target.value);
    },
    [setSrc],
  );

  const resetFields = useCallback(() => {
    setSrc('');
  }, []);

  const hasSelectedIframe = useMemo(() => selectedEntity?.type === 'iframe', [
    selectedEntity,
  ]);

  const hadSelectedIframe = usePrevious(hasSelectedIframe);
  /** Set selected image as current (edition) */
  useEffect(() => {
    if (selectedEntity && !hadSelectedIframe && hasSelectedIframe) {
      const editorStatee = getEditorState();
      const contentState = editorStatee.getCurrentContent();
      const entity = contentState.getEntity(selectedEntity.entityKey);
      const data = entity.getData();

      setSrc(data.src);
    }
  }, [getEditorState, hadSelectedIframe, hasSelectedIframe, selectedEntity]);

  const handleOk = useCallback(() => {
    if (onChange && src) {
      let entityData: {
        src: string;
      } = {
        src,
      };

      const editorState = getEditorState();

      // Do nothing if data hasn't changed
      // Retrieve old props otherwise
      if (selectedEntity && hasSelectedIframe) {
        const contentState = editorState.getCurrentContent();
        const entity = contentState.getEntity(selectedEntity.entityKey);
        const data = entity.getData();

        if (_isEqual(entityData, data)) {
          return;
        }

        const { src: oldSrc, ...otherData } = data;
        entityData = {
          ...otherData,
          ...entityData,
        };
      }

      // DO NOT merge or replace entity data as it doesnt rerender correctly, instead, trust the fact that it is selected and creating a new entity will replace the old one
      const entityKey = editorState
        .getCurrentContent()
        .createEntity('IFRAME', 'IMMUTABLE', entityData)
        .getLastCreatedEntityKey();
      const newEditorState = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        entityKey,
        ' ',
      );

      onChange(newEditorState);
    }
  }, [getEditorState, hasSelectedIframe, onChange, selectedEntity, src]);

  const handleOnClose = useCallback(() => {
    const editorState = getEditorState();
    if (hasSelectedIframe && onChange) {
      setSelectedEntity(null, editorState, onChange);
    }
  }, [getEditorState, hasSelectedIframe, onChange, setSelectedEntity]);

  const isValid = useMemo(() => isValidUrl(src), [src]);

  return (
    <Option
      className="rdw-toolbar-iframe"
      Icon={IframeIcon}
      resetFields={resetFields}
      isValid={isValid}
      onOk={handleOk}
      onClose={handleOnClose}
      doOpen={hasSelectedIframe}
    >
      <div className={classes.fieldWrapper}>
        <TextField
          value={src}
          onChange={handleSrcChange}
          size="small"
          label="Url"
          variant="filled"
          className={classes.field}
          required
        />
      </div>
    </Option>
  );
};

export default IframeButton;
