import * as React from 'react';
// import Typography from '@material-ui/core/Typography';
import {
  Link,
  TextField,
  TextFieldProps,
  linkToRecord,
  useRecordContext,
} from 'react-admin';

import { Borrower, Owner } from '../../types/schema';

export const OwnerLinkField: React.FC<TextFieldProps> = props => {
  const record = useRecordContext<Borrower>();

  // stop propagation on click
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.stopPropagation();
    },
    [],
  );

  const owner: Owner | undefined = record?.owner?.edges?.[0]?.node;
  const ownerPage = linkToRecord('/cpretOwner', owner?.id);

  return (
    <Link to={ownerPage} onClick={handleClick}>
      <TextField {...props} />
    </Link>
  );
};
