import { useCallback, useContext } from 'react';
import { DataProviderContext, useAuthProvider } from 'react-admin';
import _ from 'lodash';
import gql from 'graphql-tag';
import { ApolloClient } from '@apollo/client';
// import { print } from 'graphql';
import { Node } from 'phicomas-client';
import projectInfosBase from 'phicomas-client/src/projects/cpret/projectInfos';

import { CustomDataProvider } from '../dataProvider/type';
import { ConnectionInput } from '../types/schema';

// CpretHistoryFieldsBase
const fragCpretHistoryFieldsBase = gql`
  ${projectInfosBase.resourcesInfos.cpretHistory.fragments.base}
`;

const fragUpdate = gql`
  fragment HistoryUpdateFragment on NodeUpdate {
    id
    node {
      ...CpretHistoryFieldsBase
    }
    updateInfo {
      mutation
      connect {
        field
        id
      }
      disconnect {
        field
        id
      }
    }
  }
  ${fragCpretHistoryFieldsBase}
`;

const createHistory = gql`
  mutation History($data: HistoryInput!) {
    createHistory(data: $data) {
      ...HistoryUpdateFragment
    }
  }
  ${fragUpdate}
`;

export type HistoryEntry = {
  /** History message, see i18n/frenchMessages */
  message: string;
  /** Message arguments (polyglot style) */
  args: { [k: string]: any };
};

/**
 * Cpret History hook - async for now
 */
type HistoryCallback = <T = any>(
  entry: HistoryEntry,
  /** Resources to be connected to the history */
  record: Partial<Node>[],
) => ReturnType<ApolloClient<T>['mutate']>;

export const useCpretHistory = () => {
  const dataProvider = useContext(DataProviderContext) as CustomDataProvider;
  const authProvider = useAuthProvider();
  const client = dataProvider.getClient();
  const cb = useCallback<HistoryCallback>(
    ({ message, args }, records) => {
      const action = async () => {
        // create connections from records:
        const connectFields: { [k: string]: ConnectionInput } = {};
        records.forEach(r => {
          if (!r?.id || !r?.__typename) return;
          const field = _.lowerFirst(r.__typename);
          if (!field) return;
          connectFields[field] = {
            connect: [...(connectFields[field]?.connect || []), r.id],
          };
        });
        // take from useGetIndentity()
        const identity =
          authProvider &&
          typeof authProvider.getIdentity === 'function' &&
          (await authProvider.getIdentity());
        const variables = {
          data: {
            message,
            args: JSON.stringify({ ...args }),
            identity: (identity && (identity.sub || identity.id)) || 'N/A',
            ...connectFields,
          },
        };
        //   console.log(print(createHistory));
        //   console.log(variables);
        return client.mutate({
          mutation: createHistory,
          fetchPolicy: 'no-cache',
          variables,
        });
      };
      return action();
    },
    [client, authProvider],
  );
  return cb;
};
