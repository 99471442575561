import { TextField, TextFieldProps } from '@material-ui/core';
import React from 'react';
import { useField } from 'react-final-form';
import { FieldValidator } from 'final-form';

export type AgnosticDateFieldProps = TextFieldProps & {
  name: string;
  validate?: FieldValidator<string>;
};

export const AgnosticDateField: React.FC<AgnosticDateFieldProps> = ({
  name,
  validate,
  ...otherProps
}) => {
  const { input, meta } = useField(name, { validate });
  return (
    <TextField
      type="date"
      variant="filled"
      {...input}
      {...(meta.touched &&
        meta.invalid && {
          error: true,
          helperText: meta.error || meta.submitError,
        })}
      {...otherProps}
    />
  );
};
