export type ID = string;

export enum Status {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
}

export type Node = {
  __typename?: string;
  id: ID;
  createdAt?: string;
  updatedAt?: string;
  version: number;
  status?: Status;
} & {
  [k: string]: unknown;
};