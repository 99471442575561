import React from 'react';
import { ListProps } from 'react-admin';

import { BorrowerList } from '../resource/borrower/BorrowerList';
// import { LoanList } from '../resource/loan/LoanList';
import { OwnerList } from '../resource/owner/OwnerList';
// import GeneratedList from './GeneratedList';

export const CustomList: React.FC<ListProps> = ({
  resource,
  ...otherProps
}) => {
  switch (resource) {
    case 'cpretOwner':
      return <OwnerList {...otherProps} />;

    case 'cpretBorrower':
      return <BorrowerList {...otherProps} />;

    // case 'cpretLoan':
    //   return <LoanList {...otherProps} />;

    default:
      return null;
    // return <GeneratedList resource={resource} {...otherProps} />;
  }
};
