import React, { useContext } from 'react';
import { Level } from 'phicomas-client';

type EnvironmentContextType = [
  Level,
  React.Dispatch<React.SetStateAction<Level>>,
];

const EnvironmentContext = React.createContext<
  EnvironmentContextType | undefined
>(undefined);

export function useEnvironmentContext(): EnvironmentContextType {
  const environmentContext = useContext(EnvironmentContext);
  if (environmentContext === undefined)
    throw new Error('Expected context value to be set');
  return environmentContext;
}

export default EnvironmentContext;
