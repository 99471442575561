import {
  ApolloClient,
  // makeReference,
  NormalizedCacheObject,
  Reference,
} from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { TExistingRelay } from '@apollo/client/utilities/policies/pagination';
import {
  findKey as _findKey,
  compact as _compact,
  isEqual as _isEqual,
} from 'lodash';

import {
  GenericResourceKey,
  GenericResourcesInfos,
  resourceInfosHasConnections,
} from '../resources';
import { ID, NodeUpdate, UpdateType } from '../types';

/**
 * With subscription, updates are notified twice, so we ignore exact duplicates
 */
let lastUpdate: NodeUpdate;

async function addConnectionToNode(
  resourcesInfos: GenericResourcesInfos,
  /** The parent node */
  nodeInfos: { resource: GenericResourceKey; id: ID },
  /** The connection field and id that will be added */
  toBeConnectedInfos: { field: GenericResourceKey; toBeConnectedId: ID },
  client: ApolloClient<NormalizedCacheObject>,
): Promise<{ resource: string; id: ID } | undefined> {
  const { resource, id } = nodeInfos;
  const { field, toBeConnectedId } = toBeConnectedInfos;
  const resourceInfos = resourcesInfos[resource];
  if (!resourceInfosHasConnections(resourceInfos)) return undefined;
  const connectionInfos = resourceInfos.connections[field];
  if (!connectionInfos) return undefined;
  console.time('modify start');
  // https://www.apollographql.com/docs/react/caching/cache-interaction/#examples
  client.cache.modify({
    id: `${resourceInfos.type.name}:${id}`,
    fields: {
      [field](
        existing: TExistingRelay<Reference>,
        { canRead, readField, toReference },
      ) {
        console.timeEnd('modify start');
        console.time('modify end');
        if (!existing) return existing;
        const typename = resourcesInfos[connectionInfos.onResource].type.name;
        const incoming = toReference({
          __typename: typename,
          id: toBeConnectedId,
        });
        // it should already exist - otherwise just ignore
        if (!canRead(incoming)) return existing;
        if (
          existing.edges.some(
            ({ node }: any) => readField('id', node) === toBeConnectedId,
          )
        )
          return existing;
        // finally merge
        return {
          ...existing,
          edges: [
            ...existing.edges,
            {
              node: incoming,
              __typename: `${typename}Edge`,
            },
          ],
        };
      },
    },
  });
  console.timeEnd('modify end');
  return { resource, id };
}

async function removeConnectionFromNode(
  resourcesInfos: GenericResourcesInfos,
  /** The parent node */
  nodeInfos: { resource: GenericResourceKey; id: ID },
  /** The connection field and id that will be removed */
  connectedNodeInfos: { field: GenericResourceKey; toBeRemovedId: ID },
  client: ApolloClient<NormalizedCacheObject>,
): Promise<{ resource: string; id: ID } | undefined> {
  const { resource, id } = nodeInfos;
  const { field, toBeRemovedId } = connectedNodeInfos;
  const resourceInfos = resourcesInfos[resource];
  if (!resourceInfosHasConnections(resourceInfos)) return undefined;
  const connectionInfos = resourceInfos.connections[field];
  if (!connectionInfos) return undefined;

  client.cache.modify({
    id: `${resourceInfos.type.name}:${id}`,
    fields: {
      [field](existing: TExistingRelay<Reference>, { readField }) {
        if (!existing) return existing;
        // and filter
        return {
          ...existing,
          edges: existing.edges.filter(
            ({ node }: any) => readField('id', node) !== toBeRemovedId,
          ),
        };
      },
    },
  });

  return { resource, id };
}

export type CacheUpdateResult = {
  [k in GenericResourceKey]?: {
    [l in ID]?: true;
  };
};
/**
 * Manual Apollo cache update
 * @param update
 * @param client
 * @returns map of resources / id of resource (note: current resource list is always updated for free...)
 */
export async function updateStore(
  resourcesInfos: GenericResourcesInfos,
  update: NodeUpdate,
  client: ApolloClient<NormalizedCacheObject>,
): Promise<CacheUpdateResult | undefined> {
  if (_isEqual(update, lastUpdate)) return Promise.resolve(undefined);
  lastUpdate = update;

  const { node: updatedNode } = update;
  if (!updatedNode) {
    return Promise.resolve(undefined);
  }
  const resource = _findKey(
    resourcesInfos,
    rI => rI.type.name === updatedNode.__typename,
  );
  if (!resource) return {};

  const resourceInfos = resourcesInfos[resource];
  const { id: nodeId, updateInfo } = update;

  // Connect and disconnect to node
  const { connect, disconnect } = updateInfo;
  console.time('update connected');
  const connected = await connect.reduce(
    async (p, { field, id }) => {
      const prev = await p;
      const connectionInfos = resourceInfos.connections?.[field];
      if (!connectionInfos) {
        console.error(
          `No connectionInfos found for connected id [${id}] to field [${field}]`,
        );
        return [];
      }
      if (connectionInfos.isDirect) {
        return [];
      }
      // Ours
      const ours = await addConnectionToNode(
        resourcesInfos,
        { resource, id: nodeId },
        { field, toBeConnectedId: id },
        client,
      );
      // Theirs
      const theirs = await addConnectionToNode(
        resourcesInfos,
        { resource: connectionInfos.onResource, id },
        { field: connectionInfos.onField, toBeConnectedId: nodeId },
        client,
      );
      return [...prev, ..._compact([ours, theirs])];
    },
    Promise.resolve(
      [] as {
        resource: string;
        id: string;
      }[],
    ),
  );
  console.timeEnd('update connected');
  console.time('update disconnected');
  const disconnected = await disconnect.reduce(
    async (p, { field, id }) => {
      const prev = await p;
      const connectionInfos = resourceInfos.connections?.[field];
      if (!connectionInfos) {
        console.error(
          `No connectionInfos found for connected id [${id}] to field [${field}]`,
        );
        return [];
      }
      if (connectionInfos.isDirect) {
        return [];
      }
      // Ours
      const ours = await removeConnectionFromNode(
        resourcesInfos,
        { resource, id: nodeId },
        { field, toBeRemovedId: id },
        client,
      );
      // Theirs
      const theirs = await removeConnectionFromNode(
        resourcesInfos,
        { resource: connectionInfos.onResource, id },
        { field: connectionInfos.onField, toBeRemovedId: nodeId },
        client,
      );
      return [...prev, ..._compact([ours, theirs])];
    },
    Promise.resolve(
      [] as {
        resource: string;
        id: string;
      }[],
    ),
  );

  console.timeEnd('update disconnected');

  const result: CacheUpdateResult = { [resource]: { [nodeId]: true } };
  [...connected, ...disconnected].forEach(({ resource: res, id }) => {
    result[res] = { ...result[res], [id]: true };
  });

  if (
    updateInfo.mutation === UpdateType.DELETE ||
    updateInfo.mutation === UpdateType.CREATE
  ) {
    const {
      query: { allName: queryAllName },
    } = resourceInfos;

    console.time('update all');
    // access private data property (entityStore) to determine if we even should run modify
    const shouldUpateAll = (client.cache as InMemoryCache as any).data.has(
      queryAllName,
    );
    if (shouldUpateAll && updateInfo.mutation === UpdateType.CREATE) {
      client.cache.modify({
        // id: client.cache.identify(makeReference('ROOT_QUERY')), // not necessary as this is the default
        fields: {
          [queryAllName]: (
            existing: TExistingRelay<Reference>,
            { toReference, canRead, readField },
          ) => {
            if (!existing) return existing;
            const typename = resourcesInfos[resource].type.name;
            const incoming = toReference({
              __typename: typename,
              id: nodeId,
            });
            // it should already exist - otherwise just ignore
            if (!canRead(incoming)) return existing;
            if (
              existing.edges.some(
                ({ node }: any) => readField('id', node) === nodeId,
              )
            )
              return existing;
            // finally merge
            return {
              ...existing,
              edges: [
                ...existing.edges,
                {
                  node: incoming,
                  __typename: `${typename}Edge`,
                },
              ],
            };
          },
        },
      });

      result[resource] = { ...result[resource], [nodeId]: true };
    }

    if (shouldUpateAll && updateInfo.mutation === UpdateType.DELETE) {
      client.cache.modify({
        // id: client.cache.identify(makeReference('ROOT_QUERY')), // not necessary as this is the default
        fields: {
          [queryAllName]: (
            existing: TExistingRelay<Reference>,
            { readField },
          ) => {
            if (!existing) return existing;
            // and filter
            return {
              ...existing,
              edges: existing.edges.filter(
                ({ node }: any) => readField('id', node) === nodeId,
              ),
            };
          },
        },
      });

      result[resource] = { ...result[resource], [nodeId]: true };
    }
    console.timeEnd('update all');
  }

  return result;
}
