import React, { useCallback } from 'react';
import { Toolbar, Datagrid, ListToolbar, Pagination } from 'react-admin';
import { useTranslate, Record, ListContext, ListBase } from 'ra-core';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import generateAllResourceFields from '../../Fields/generateFields';
// Cycle is not an issue here
import GeneratedFilters from '../../GeneratedFilters'; // eslint-disable-line import/no-cycle
import ConnectionConnectButton from './ConnectionConnectButton';
import { ResourceKey } from '../../../project/projectInfos';

const useToolbarStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'sticky',
      bottom: 0,
    },
  }),
);

type ConnectionAddDialogToolbarProps = {
  onClose: () => void;
};
const ConnectionAddDialogToolbar: React.FC<ConnectionAddDialogToolbarProps> = ({
  onClose,
  ...props
}: ConnectionAddDialogToolbarProps) => {
  const translate = useTranslate();
  const classes = useToolbarStyles();

  return (
    <Toolbar {...props} className={classes.root}>
      <>
        <span />
        <Button onClick={onClose} color="primary">
          {translate('ra.action.close')}
        </Button>
      </>
    </Toolbar>
  );
};

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    loading: {
      textAlign: 'center',
      padding: theme.spacing(4, 1),
    },
    loadingSkeleton: {
      width: '50%',
      margin: theme.spacing(4, 'auto'),
    },
    dialogContent: {
      padding: '0',
      overflow: 'hidden',
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    contentScroller: {
      overflow: 'auto',
      padding: theme.spacing(1),
    },
    content: {
      overflow: 'hidden', // For scrolling sticky top headers are not at the very top
    },
    listToolbarWrapper: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    connectButton: {
      width: '1%',
    },
  }),
);

type ConnectionConnectDialogProps = {
  resource: ResourceKey;
  onConnect: (record: Record) => void;
  onClose: () => void;
  connected?: Array<Record['id']>;
};

const ConnectionConnectDialog: React.FC<ConnectionConnectDialogProps> = ({
  resource,
  onConnect,
  onClose,
  connected,
}: ConnectionConnectDialogProps) => {
  const classes = useStyles();
  const translate = useTranslate();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog fullWidth maxWidth="xl" open onClose={handleClose}>
      <DialogTitle>
        {translate(`action.connect`, {
          name: translate(`resources.${resource}.name`, 1),
        })}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <ListBase
          basePath=""
          resource={resource}
          filter={{ excludeIds: connected }}
        >
          <div className={classes.contentScroller}>
            <div className={classes.content}>
              <div className={classes.listToolbarWrapper}>
                <ListToolbar
                  filters={<GeneratedFilters resource={resource} />}
                />
                <GeneratedFilters resource={resource} context="button" />
              </div>
              <ListContext.Consumer>
                {({ loading }) =>
                  loading && (
                    <Skeleton
                      variant="rect"
                      className={classes.loadingSkeleton}
                    />
                  )
                }
              </ListContext.Consumer>
              <Datagrid>
                <ConnectionConnectButton
                  cellClassName={classes.connectButton}
                  onConnect={onConnect}
                  resource={resource}
                />
                {generateAllResourceFields(resource)}
              </Datagrid>
              <Pagination />
            </div>
          </div>
        </ListBase>
        <ConnectionAddDialogToolbar onClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
};

export default ConnectionConnectDialog;
