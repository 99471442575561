import React from 'react';

import { AgnosticAmountField } from '../../../../components/Fields/agnostic/AgnosticAmountField';
import { AgnosticTextField } from '../../../../components/Fields/agnostic/AgnosticTextField';
import { Ledger } from '../../../../types/schema';
import { AgnosticFormControl } from '../../../../components/Fields/agnostic/AgnosticFormControl';
import { getAccountBalance } from '../../../../lib/ledger';
import { LedgerAccount } from '../../../../dataProvider/type';

export interface LossesUserCreditValues {
  payee: string;
  amount: number;
}

export interface LossesUserCreditLedgerActionFieldsProps {
  ledgers: Ledger[];
}

export const LossesUserCreditLedgerActionFields: React.FC<
  LossesUserCreditLedgerActionFieldsProps
> = ({ ledgers }) => {
  const [, , balance] = React.useMemo(
    () => getAccountBalance(ledgers, LedgerAccount.USER_ACCOUNT),
    [ledgers],
  );

  const validateAmount = React.useCallback((value: number) => {
    if (!value) {
      return 'Vous devez saisir un montant';
    }

    if (value <= 0) {
      return 'Le montant doit être supérieur à 0';
    }

    return null;
  }, []);

  const validatePayee = React.useCallback((value: string) => {
    if (!value) {
      return 'Vous devez saisir un motif';
    }

    return null;
  }, []);

  return (
    <>
      <AgnosticFormControl fullWidth>
        <AgnosticTextField
          name="payee"
          label="Motif"
          required
          validate={validatePayee}
        />
      </AgnosticFormControl>
      <AgnosticFormControl fullWidth>
        <AgnosticAmountField
          name="amount"
          label="Montant (€) (ex: 12,34)"
          defaultValue={balance}
          required
          validate={validateAmount}
        />
      </AgnosticFormControl>
    </>
  );
};
