import React, { useContext } from 'react';

export type AppContextType = {
  loading: number;
};

const AppContext = React.createContext<AppContextType | undefined>(undefined);

export function useAppContext(): AppContextType {
  const appContext = useContext(AppContext);
  if (appContext === undefined)
    throw new Error('Expected context value to be set');
  return appContext;
}

export default AppContext;
