import { get } from 'lodash';
import React from 'react';
import { FunctionField } from 'react-admin';

import { Schedule, SchedulePayment } from '../../../../types/schema';

export interface PaymentProgressProps {
  // eslint-disable-next-line react/no-unused-prop-types
  label: string;
}

export const PaymentProgress: React.FC<PaymentProgressProps> = () => {
  const render = React.useCallback((record?: Schedule) => {
    const payments = get(record, ['payments'], []) as SchedulePayment[];

    const nextPaymentIndex = payments?.findIndex(p => !p.done);

    if (nextPaymentIndex === -1) {
      return '0/0';
    }

    return [nextPaymentIndex, payments.length].join('/');
  }, []) as any;

  return <FunctionField render={render} />;
};
