// taken straight from https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts

import { FieldPolicy } from '@apollo/client/cache';
import { Reference, StoreObject } from '@apollo/client/utilities';
import { addMonths } from 'date-fns';
import _ from 'lodash';
// import {
//   RelayFieldPolicy,
//   TExistingRelay,
//   TRelayEdge,
// } from '@apollo/client/utilities/policies/pagination';

import { Ledger } from '../projects/cpret/schema';

export function makeEmptyConnection(): TExistingRelay {
  return {
    edges: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      startCursor: '',
      endCursor: '',
    },
  };
}

type TRelayEdge = {
  node: Reference | StoreObject;
};

type TRelayPageInfo = {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string;
  endCursor: string;
};

type TExistingRelay = Readonly<{
  __typename?: string;
  edges: TRelayEdge[];
  pageInfo: TRelayPageInfo;
  digest?: any;
}>;

type TIncomingRelay = {
  edges?: TRelayEdge[];
  pageInfo?: TRelayPageInfo;
};

type RelayFieldPolicy = FieldPolicy<
  TExistingRelay | null,
  TIncomingRelay | null,
  TIncomingRelay | null
>;

export function relayStylePagination<TNode = any>(
  keyArgs: FieldPolicy<any>['keyArgs'] = false,
): RelayFieldPolicy {
  return {
    keyArgs,

    // do we really need read here ?
    /*
    read(existing, { canRead, readField }) {
      // if we don't return formatted object, pagination fails with @client fields !
      if (!existing) return existing;

      const edges: TRelayEdge[] = [];
      existing.edges.forEach(edge => {
        // Edges themselves could be Reference objects, so it's important
        // to use readField to access the edge.edge.node property.
        if (canRead(readField<TNode>('node', edge))) {
          edges.push(edge);
        }
      });

      const { startCursor, endCursor } = existing.pageInfo || {};

      return {
        // Some implementations return additional Connection fields, such
        // as existing.totalCount. These fields are saved by the merge
        // function, so the read function should also preserve them.
        ...existing,
        edges,
        pageInfo: {
          ...existing.pageInfo,
          // If existing.pageInfo.{start,end}Cursor are undefined or "", default
          // to firstEdgeCursor and/or lastEdgeCursor.
          startCursor,
          endCursor,
        },
      };
    },
    */

    merge(prev, incoming, { fieldName, readField, args }) {
      const existing = prev || makeEmptyConnection();

      if (!incoming) {
        return existing;
      }

      const { before, after } = args || {};
      const { pageInfo: prevInfo } = existing;
      if (
        !prevInfo?.hasNextPage ||
        (prevInfo?.startCursor && before !== prevInfo?.startCursor) ||
        (prevInfo?.endCursor && before && before !== prevInfo?.endCursor) || // workaround backend bug
        (prevInfo?.endCursor && !before && after !== prevInfo?.endCursor)
      ) {
        console.warn(`${fieldName}: Not my page, ignoring`);
        console.log(args, existing);
        return existing;
      }

      const existingIdSet = new Set(
        existing.edges.map(({ node }) => readField<TNode>('id', node)),
      );
      const newEdges = (incoming.edges ?? []).filter(
        ({ node }) => !existingIdSet.has(readField<TNode>('id', node)),
      );

      if (!newEdges.length && _.isEqual(existing.pageInfo, incoming.pageInfo))
        return existing;

      const next = {
        ...existing,
        ...incoming,
        edges: [...existing.edges, ...(newEdges ?? [])],
        pageInfo: incoming.pageInfo ?? existing.pageInfo,
      };

      return next;
    },
  };
}

// FILTER FIXME !
const now = addMonths(new Date(), -1);
const month = now.getMonth();
const start = new Date(now.getFullYear(), month, 1);
const stop = addMonths(start, 1);

export function relayLedgerPagination<TNode = any>(
  keyArgs: FieldPolicy<any>['keyArgs'] = false,
): RelayFieldPolicy {
  return {
    keyArgs,

    merge(prev, incoming, { fieldName, readField, args }) {
      const existing = prev || makeEmptyConnection();

      if (!incoming) {
        return existing;
      }

      const { before, after } = args || {};
      const { pageInfo: prevInfo } = existing;
      if (
        !prevInfo?.hasNextPage ||
        (prevInfo?.startCursor && before !== prevInfo?.startCursor) ||
        (prevInfo?.endCursor && before && before !== prevInfo?.endCursor) || // workaround backend bug
        (prevInfo?.endCursor && !before && after !== prevInfo?.endCursor)
      ) {
        console.warn(`${fieldName}: Not my page, ignoring`);
        console.log(args, existing);
        return existing;
      }

      const existingIdSet = new Set(
        existing.edges.map(({ node }) => readField<TNode>('id', node)),
      );
      const newEdges = (incoming.edges ?? [])
        .filter(({ node }) => {
          const l = node as Ledger;
          const created = new Date(l.createdAt);
          return created >= start && created < stop;
        })
        .filter(({ node }) => !existingIdSet.has(readField<TNode>('id', node)));

      if (!newEdges.length && _.isEqual(existing.pageInfo, incoming.pageInfo))
        return existing;

      const next = {
        ...existing,
        ...incoming,
        edges: [...existing.edges, ...(newEdges ?? [])],
        pageInfo: incoming.pageInfo ?? existing.pageInfo,
      };

      return next;
    },
  };
}
