import React from 'react';
import { EditProps, Record, SimpleForm } from 'react-admin';
import { upperFirst, toLower, intersectionBy, differenceBy, get } from 'lodash';

import { Borrower, LoanType, Schedule } from '../../types/schema';
import { BorrowerForm } from './BorrowerForm';
import { clearDataBeforeSubmit } from '../../dataProvider/utils';
import { Breadcrumb } from '../../components/Breadcrumb';
import { BorrowerHistory } from './BorrowerHistory';
import UploadToolbar from '../../components/Toolbars/AttachmentToolbar';
import { ASSET_USER_NAMESPACE } from '../../aws/cpret-config';
import { useCpretHistory } from '../../workflow/history';
import { EditWithAttachmentsAndLog } from '../../components/EditWithAttachmentsAndLog';

export interface BorrowerEditTitleProps {
  record?: Borrower;
}

export const BorrowerEditTitle: React.FC<BorrowerEditTitleProps> = ({
  record,
}) => {
  const person = record?.contact;
  const fragments = React.useMemo(
    () =>
      [
        {
          title: 'Demandeur',
          typename: 'Owner',
          id: get(record, 'owner.edges[0].node.id', null),
        },
        {
          title: `Bénéficiaire ${
            record?.loanType === LoanType.HONNEUR ? 'PH' : 'Autre'
          }: ${
            person &&
            [person?.givenName, person?.familyName]
              .filter(Boolean)
              .join(' ')
              .trim()
              .match(/(\p{L}+)|(-|\s)/gu)
              ?.map(s => upperFirst(toLower(s)))
              .join('')
          }`,
          typename: 'Borrower',
          id: record?.id,
        },
      ].filter(Boolean),
    [record, person],
  );

  return <Breadcrumb fragments={fragments} />;
};

export const BorrowerSimpleForm: React.FC<any> = props => {
  const log = useCpretHistory();
  const { record } = props;

  const onSaveSuccess = React.useCallback(
    ({ data }: { data: Partial<Borrower> }) => {
      const schedules = record.schedules || [];
      const formSchedules = data.schedules || [];
      const fieldToCompare: keyof Schedule = 'label';
      const intersection = intersectionBy<Schedule>(
        formSchedules,
        schedules,
        fieldToCompare,
      );

      const inserts = differenceBy<Schedule, Schedule>(
        formSchedules,
        intersection,
        fieldToCompare,
      );
      const removes = differenceBy<Schedule, Schedule>(
        schedules,
        intersection,
        fieldToCompare,
      );

      removes.forEach(d => {
        log(
          {
            message: 'cpret.schedule.deletion',
            args: { label: d.label },
          },
          [data],
        );
      });
      inserts.forEach(d => {
        log(
          {
            message: 'cpret.schedule.creation',
            args: { label: d.label },
          },
          [data],
        );
      });
    },
    [record, log],
  );

  return (
    <SimpleForm
      {...props}
      redirect={false}
      // warnWhenUnsavedChanges
      submitOnEnter={false}
      toolbar={
        <UploadToolbar
          field="borrower"
          namespace={ASSET_USER_NAMESPACE}
          kind="edit"
          onSaveSuccess={onSaveSuccess as any}
        />
      }
    >
      <BorrowerForm />
    </SimpleForm>
  );
};

export const BorrowerEdit: React.FC<EditProps> = props => {
  return (
    <EditWithAttachmentsAndLog
      {...props}
      title={<BorrowerEditTitle />}
      undoable={false}
      transform={(data: Record): Record => clearDataBeforeSubmit(data)}
      aside={<BorrowerHistory />}
    >
      <BorrowerSimpleForm />
    </EditWithAttachmentsAndLog>
  );
};
