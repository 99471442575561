import { NumberField, BooleanField, FieldProps } from 'react-admin';

import {
  getResourceInfos,
  ResourceInfos,
  ResourceKey,
} from '../../project/projectInfos';

import TextField from './TextField';
import DateField, { DateFieldProps } from './DateField';
import ConnectionField, { ConnectionFieldProps } from './ConnectionField';
import ListField, { ListFieldProps } from './ListField';

import { GQLField, GQLFieldType } from '../../types/gqlTypes';
import DateTimeField from './DateTimeField';

type BasicFieldComponentInfos = {
  Field: React.FC<FieldProps>;
  fieldProps?: Partial<FieldProps>;
};
type DateFieldComponentInfos = {
  Field: React.FC<DateFieldProps>;
  fieldProps?: Partial<DateFieldProps>;
};
type ConnectionFieldComponentInfos = {
  Field: React.FC<ConnectionFieldProps>;
  fieldProps: Partial<ConnectionFieldProps>;
};
type ListFieldComponentInfos = {
  Field: React.FC<ListFieldProps>;
  fieldProps: Partial<ListFieldProps>;
};

type FieldComponentInfos =
  | BasicFieldComponentInfos
  | DateFieldComponentInfos
  | ConnectionFieldComponentInfos
  | ListFieldComponentInfos
  | null;

function getScalarComponentInfos(typeName: string): FieldComponentInfos {
  switch (typeName) {
    case 'Int':
    case 'Float':
      return { Field: NumberField };
    case 'Boolean':
      return { Field: BooleanField };
    case 'AWSDate':
      return { Field: DateField };
    case 'AWSDateTime':
      return { Field: DateTimeField };
    case 'ID':
    case 'String':
    default:
      return { Field: TextField };
  }
}

function getObjectComponentInfos(
  type: GQLFieldType,
  {
    fieldName,
    resourceInfos,
  }: {
    fieldName: string;
    resourceInfos: ResourceInfos;
  },
): FieldComponentInfos {
  const { connections, objectTypes } = resourceInfos;
  const connection = connections?.[fieldName];
  if (connection) {
    try {
      const connectedResourceInfos = getResourceInfos(connection.onResource);
      // Double-check that the connectionType matches
      if (connectedResourceInfos.connectionType.name === type.name) {
        return {
          Field: ConnectionField,
          fieldProps: {
            connectionInfos: connection,
          },
        };
      }
      console.warn(
        `Field "${fieldName}" was found to be connected to resource "${connection.onResource}" but typenames do not match`,
      );
    } catch (error) {
      console.error(error);
    }
  }
  const objectType = objectTypes[type.name || ''];
  if (objectType) {
    return { Field: TextField };
  }
  throw new Error(
    `Could not find a connection on OBJECT field (found on field "${fieldName}")`,
  );
}

function getListComponentInfos(
  type: GQLFieldType,
  subComponentInfos: NonNullable<FieldComponentInfos>,
): FieldComponentInfos {
  return {
    Field: ListField,
    fieldProps: {
      Field: subComponentInfos.Field,
      fieldProps: subComponentInfos.fieldProps,
    },
  };
}

function getComponentInfosFromType(
  type: GQLFieldType,
  {
    fieldName,
    resourceInfos,
  }: {
    fieldName: string;
    resourceInfos: ResourceInfos;
  },
): FieldComponentInfos {
  const { kind, name, ofType } = type;
  switch (kind) {
    case 'NON_NULL': {
      if (!ofType) {
        throw new Error(
          `NON_NULL field type did not have an ofType value (found on field "${fieldName}")`,
        );
      }
      return getComponentInfosFromType(ofType, {
        fieldName,
        resourceInfos,
      });
    }
    case 'SCALAR': {
      if (!name) {
        throw new Error(
          `SCALAR field did not have a type name (found on field "${fieldName}")`,
        );
      }
      return getScalarComponentInfos(name);
    }
    case 'ENUM': {
      if (!name) {
        throw new Error(
          `ENUM field did not have a type name (found on field "${fieldName}")`,
        );
      }
      return { Field: TextField };
    }
    case 'LIST': {
      if (!ofType) {
        throw new Error(
          `LIST field did not have a type ofType (found on field "${fieldName}")`,
        );
      }
      const ofFieldComponentInfos = getComponentInfosFromType(ofType, {
        fieldName,
        resourceInfos,
      });
      if (ofFieldComponentInfos === null) {
        return null;
      }
      if (!ofFieldComponentInfos) {
        throw new Error(
          `Could not find subComponent of LIST field from it's ofType (found on field "${fieldName}")`,
        );
      }
      return getListComponentInfos(type, ofFieldComponentInfos);
    }
    case 'OBJECT': {
      if (name === 'TransactionState') {
        return null;
      }
      return getObjectComponentInfos(type, {
        fieldName,
        resourceInfos,
      });
    }
    default:
      throw new Error(
        `Field of kind "${kind}" is not yet handled (found on field "${fieldName}")`,
      );
  }
}

export default function generateFieldComponentInfos(
  resource: ResourceKey,
  field: GQLField,
): FieldComponentInfos {
  const { name, type } = field;
  const resourceInfos = getResourceInfos(resource);

  return getComponentInfosFromType(type, {
    fieldName: name,
    resourceInfos,
  });
}
