import React from 'react';
import { useNotify } from 'react-admin';
import { FORM_ERROR } from 'final-form';

import { WFAction } from '../../../workflow/guaranty-loans-enum';
import { AvenantValues } from './field/AvenantActionFields';
import { AnticipeValues } from './field/AnticipeActionFields';
import { Values } from './types';
import { GuarantyLoan } from '../../../types/schema';
import {
  ExecuteResult,
  useGuarantyLoanWorkflow,
} from '../../../workflow/guaranty-loans';

export function useOnSubmit(
  loan: GuarantyLoan,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  onSuccess: () => void,
) {
  const [workflow] = useGuarantyLoanWorkflow();
  const notify = useNotify();

  const commit = React.useCallback(
    async (actionType: WFAction, callback: () => Promise<ExecuteResult>) => {
      try {
        setLoading(true);
        const [errors] = await callback();
        setLoading(false);

        if (errors) {
          return errors;
        }
      } catch (error: any) {
        notify('cpret.action.error', 'error', {
          _: `Une erreur est survenue, l'action %{action} n'a pas été exécutée : %{error}`,
          action: actionType,
          error: error.message,
        });
        return { [FORM_ERROR]: `Une erreur est survenue: ${error.message}` };
      }
      notify('cpret.action.success', 'success', {
        _: `L'action a bien été exécutée: %{action}'`, // default translation - avoids console errors
        action: actionType,
      });
      onSuccess();
      return null;
    },
    [notify, onSuccess, setLoading],
  );

  const onSubmit = React.useCallback(
    async (values: Values) => {
      const { actionType, ...restValues } = values;
      switch (actionType) {
        case WFAction.AVENANT: {
          const { message, actionDate } = restValues as AvenantValues;
          return commit(actionType, () =>
            workflow({
              id: loan.id,
              wfAction: actionType,
              next: {
                message: message.replace(/\//g, '-'),
                actionDate,
              },
            }),
          );
        }
        case WFAction.ANTICIPE: {
          const { message, endDate } = restValues as AnticipeValues;
          return commit(actionType, () =>
            workflow({
              id: loan.id,
              wfAction: actionType,
              next: {
                message: message.replace(/\//g, '-'),
                endDate,
              },
            }),
          );
        }
        default:
          throw new Error(`Unsupported action type ${actionType}`);
      }
    },
    [commit, workflow, loan.id],
  );

  return onSubmit;
}
