import React from 'react';
import { useForm, useFormState } from 'react-final-form';

export const LoanPackagesSpy: React.FC = () => {
  const {
    values: {
      checkEloignement,
      checkEnseignement,
      checkEntretien,
      cptFraisLoyer = 0,
      cptFraisScolarite = 0,
      dependentChild = false,
    },
  } = useFormState();
  const form = useForm();

  React.useEffect(() => {
    form.batch(() => {
      if (cptFraisLoyer === 0 && checkEloignement) {
        form.change('checkEloignement', false);
      }

      if (cptFraisScolarite === 0 && checkEnseignement) {
        form.change('checkEnseignement', false);
      }

      if (!dependentChild) {
        form.change('checkEntretien', false);
      }
    });
  }, [
    form,
    cptFraisLoyer,
    cptFraisScolarite,
    dependentChild,
    checkEloignement,
    checkEnseignement,
    checkEntretien,
  ]);

  return null;
};
