// this code is in progress

import gql from 'graphql-tag';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { visit, print } from 'graphql';
import { Identifier } from 'react-admin';
import combineQuery from 'graphql-combine-query';
import { RenameFnWithIndex } from 'graphql-combine-query/dist/utils';

import projectInfos from '../project/projectInfos';
import { Borrower, Ledger } from '../types/schema';
import { LedgerAccount, LedgerDigest } from './type';

const { resourcesInfos } = projectInfos;

const makeEmptyLedgerDigest = (): LedgerDigest => ({
  [LedgerAccount.USER_ACCOUNT]: [0, 0, 0],
  [LedgerAccount.PRINCIPAL_ACCOUNT]: [0, 0, 0],
  [LedgerAccount.INTEREST_ACCOUNT]: [0, 0, 0],
  [LedgerAccount.BANK_ACCOUNT]: [0, 0, 0],
  [LedgerAccount.LOSSES_ACCOUNT]: [0, 0, 0],
  [LedgerAccount.ROUNDING_ACCOUNT]: [0, 0, 0],
});

// CpretLedgerFieldsBase
const fragCpretLedgerFieldsBase = gql`
  ${resourcesInfos.cpretLedger.fragments.base}
`;

export const fragNode = gql`
  fragment NodeFields on Node {
    __typename
    id
    createdAt
    updatedAt
    version
    status
  }
`;

const borrowerLedgers = gql`
  query ledgerPage($id: ID!, $after: ID) {
    brw: borrower(id: $id) {
      bid: id
      ledger(after: $after) @connection(key: "ledger") {
        edges {
          node {
            ...CpretLedgerFieldsBase
          }
        }
        pageInfo {
          hasPreviousPage
          startCursor
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${fragCpretLedgerFieldsBase}
`;

const makeCombined = (variables: any, rename: RenameFnWithIndex) =>
  combineQuery('ledgerPages').addN(borrowerLedgers, variables, rename, rename);

const customRename = (name: string, index: number) => `${name}${index}`;

export const fetchDigestPage = async ({
  client,
  ids,
  maxDate,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  ids: Identifier[];
  maxDate?: Date;
}) => {
  const { document, variables } = makeCombined(
    ids.map(id => ({
      id,
      after: null,
    })),
    customRename,
  );

  const digest: LedgerDigest[] = ids.map(() => makeEmptyLedgerDigest());
  // console.log(print(document), JSON.stringify(variables, null, 2));

  // let q: ObservableQuery<any, any>;
  const q = client.query({
    query: document,
    variables,
    fetchPolicy: 'no-cache',
  });

  const pageLoop = async (res: any): Promise<void> => {
    const { data } = res;
    // digest now
    Object.keys(data).forEach((_, i) => {
      const brwr = data[`brw${i}`] as (Borrower & { bid: string }) | undefined;
      const di = ids.findIndex(id => id === brwr?.bid);
      if (di === -1) throw new Error('We have a problem');
      const curDigest = digest[di];
      brwr?.ledger?.edges
        ?.map(({ node }) => node as Ledger)
        .filter(l => !maxDate || new Date(l.createdAt) <= maxDate)
        .forEach(l => {
          l.postings?.forEach(({ account, amount = 0 }) => {
            if (!account) return;
            const acc = String(account) as LedgerAccount;
            const d = curDigest[acc] || [0, 0, 0];
            d[0] += Math.max(0, amount);
            d[1] += Math.min(0, amount);
            d[2] = d[0] + d[1];
            curDigest[acc] = d;
          });
        });
    });
    // build next page vars
    const nextPages = Object.keys(data)
      .map((_, i) => {
        const { hasNextPage, endCursor } =
          data[`brw${i}`]?.ledger.pageInfo || {};
        if (hasNextPage) {
          return { id: data[`brw${i}`].bid, after: endCursor };
        }
        return false;
      })
      .filter(Boolean);
    if (nextPages.length) {
      const { document: nextDoc, variables: nextVars } = makeCombined(
        nextPages,
        customRename,
      );
      await new Promise(resolve =>
        requestAnimationFrame(() => {
          resolve(
            client
              .query({
                query: nextDoc,
                variables: nextVars,
                fetchPolicy: 'no-cache',
              })
              .then(pageLoop),
          );
        }),
      );
    }
  };
  // note we just load the cache, we don't return any data here
  // apollo will provide...
  await q.then(pageLoop);

  return digest;

  console.log(print(borrowerLedgers));
  const newQ = visit(borrowerLedgers, {
    FragmentDefinition(node) {
      console.log(node);
      visit(node, {
        enter(...a) {
          console.log(a);
        },
      });
      return null;
    },
  });
  console.log(print(newQ));
};

export const fetchDigests = async ({
  brwrs,
  client,
  maxDate,
}: {
  brwrs: Borrower[];
  client: ApolloClient<NormalizedCacheObject>;
  maxDate?: Date;
}) => {
  // console.log(`Getting needed borrowers for ${edges.length} owners.`);
  console.log(
    `Batch fetching ${brwrs.length} borrowers.${
      maxDate ? ` Max date: ${maxDate}.` : ''
    }`,
  );
  const input = brwrs.entries();
  const getChunk = (size = 16) =>
    [...new Array(size)]
      .map(() => input.next())
      .filter(n => !n.done)
      .map(n => n.value[1].id);
  const ddd = await Promise.all(
    // up to 8 x 16 lane fetch
    [...new Array(8)].map(
      () =>
        new Promise<any[]>(rsv => {
          return (async () => {
            const dd: any[] = [];
            let nextIds = getChunk();
            const myMap = (d: LedgerDigest, i: number) => [nextIds[i], d];
            while (nextIds.length) {
              dd.push(
                (
                  await fetchDigestPage({
                    client,
                    ids: nextIds,
                    maxDate,
                  })
                ).map(myMap),
              );
              nextIds = getChunk();
            }
            rsv(([] as any[]).concat(...dd));
          })();
        }),
    ),
  );
  return ([] as [id: string, d: LedgerDigest][]).concat(...ddd);
};
