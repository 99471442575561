import { get } from 'lodash';
import moment from 'moment';
import { Introspection, Node } from 'phicomas-client';
import {
  getResourceInfos,
  ResourceInfos,
  ResourceKey,
} from '../project/projectInfos';
import { isStringCohercible } from '../types/utils';
import { customFilterFieldTypes } from './cpretCalculatedFields';

type FilterFieldTypes = { [k: string]: Partial<Introspection.Field> };

function filterScalarFields(
  field: Partial<Introspection.Field>,
  rawData: Node[],
  filterValue: any,
): Node[] {
  let data = rawData;

  const { name: fieldName, type: { name: typeName = undefined } = {} } =
    field || {};
  if (!fieldName || !typeName) return data;

  switch (typeName) {
    case 'Int':
    case 'Float':
      data = data.filter(d => {
        const modifier = fieldName.slice(-3);
        if (modifier === '_lt' || modifier === '_gt') {
          const dataFieldValue = d[fieldName.slice(0, -3)];
          if (modifier === '_lt')
            return (
              isStringCohercible(dataFieldValue) &&
              +dataFieldValue < +filterValue
            );
          return (
            isStringCohercible(dataFieldValue) && +dataFieldValue > +filterValue
          );
        }
        const dataFieldValue = d[fieldName];
        return (
          (isStringCohercible(dataFieldValue) &&
            +filterValue === +dataFieldValue) ||
          (filterValue === 0 && !dataFieldValue) // Handles 0 as any falsy value (undefined mainly)
        );
      });
      break;
    case 'Boolean':
      data = data.filter(d => {
        const dataFieldValue = d[fieldName];
        return (
          typeof dataFieldValue === 'boolean' && filterValue === dataFieldValue
        );
      });
      break;
    case 'AWSDate':
    case 'AWSDateTime':
      data = data.filter(d => {
        const dataFieldValue = d[fieldName];
        return (
          !moment(filterValue).isValid() ||
          (isStringCohercible(dataFieldValue) &&
            moment(filterValue).isSame(dataFieldValue, 'day'))
        );
      });
      break;
    case 'ID':
    case 'String':
      data = data.filter(d => {
        const dataFieldValue = d[fieldName];
        return (
          typeof dataFieldValue === 'string' &&
          dataFieldValue !== '' &&
          new RegExp(filterValue, 'i').test(dataFieldValue)
        );
      });
      break;
    default:
      break;
  }
  return data;
}

function filterEnumFields(
  field: Partial<Introspection.Field>,
  data: Node[],
  filterValue: any,
): Node[] {
  const { name: fieldName } = field || {};
  if (!fieldName) return data;
  return data.filter(d => {
    const dataFieldValue = d[fieldName];
    const vv = Array.isArray(filterValue) ? filterValue : [filterValue];
    return (
      (typeof dataFieldValue === 'string' &&
        dataFieldValue !== '' &&
        vv.includes(dataFieldValue)) ||
      (Array.isArray(dataFieldValue) &&
        dataFieldValue.length > 0 &&
        dataFieldValue.some(fv => fv && vv.includes(fv)))
    );
  });
}

// This only filter one level of depth
function filterObjectFields(
  field: Partial<Introspection.Field>,
  data: Node[],
  filterValue: any,
): Node[] {
  const keys = Object.keys(filterValue);

  return data.filter(d =>
    // @ts-ignore
    keys.reduce((p, k) => {
      const key = [field.name, k].join('.');
      const dataFieldValue = get(d, key);

      if (typeof dataFieldValue === 'string') {
        return (
          p &&
          dataFieldValue !== '' &&
          new RegExp(filterValue[k], 'i').test(dataFieldValue)
        );
      }

      if (typeof dataFieldValue === 'boolean') {
        return p && filterValue[k] === dataFieldValue;
      }

      return false;
    }, true),
  );
}

function filterField(
  rawData: Node[],
  fieldInfos: Partial<Introspection.Field>,
  filterValue: any,
): Node[] {
  let data = rawData;
  const { name, type } = fieldInfos;
  const { ofType } = type as Introspection.SubType;
  switch (type?.kind) {
    case Introspection.Kind.NonNull:
      if (!ofType) {
        throw new Error(
          `NON_NULL field type did not have an ofType value (found on filter field "${name}")`,
        );
      }
      data = filterField(data, { ...fieldInfos, type: ofType }, filterValue);
      break;
    case Introspection.Kind.Scalar:
      data = filterScalarFields(fieldInfos, data, filterValue);
      break;
    case Introspection.Kind.Enum:
      data = filterEnumFields(fieldInfos, data, filterValue);
      break;
    case Introspection.Kind.Object:
      data = filterObjectFields(fieldInfos, data, filterValue);
      break;
    default:
      break;
  }
  return data;
}

function filterFields(
  resourceInfos: ResourceInfos,
  rawData: Node[],
  filters: Record<string | number, any>,
): Node[] {
  let data = rawData;

  const fieldTypes: FilterFieldTypes = {
    ...(resourceInfos.type.fields || []).reduce(
      (r, f) => ({
        ...r,
        [f.name]: f,
      }),
      {} as FilterFieldTypes,
    ),
    ...customFilterFieldTypes[resourceInfos.type.name],
  };

  Object.entries(filters).forEach(([fieldName, filterValue]) => {
    const field = fieldTypes[fieldName];
    data = filterField(data, field, filterValue);
  });
  return data;
}

type Filters = {
  excludeIds?: Array<Node['id']>;
} & Record<string | number, any>;
function filterNodes(
  resource: ResourceKey,
  rawData: Node[],
  rawFilters: Filters,
): Node[] {
  if (!rawData.length || !Object.keys(rawFilters).length) return rawData;

  let data = rawData;
  const { excludeIds, ...fieldFilters } = rawFilters;
  if (excludeIds) {
    data = data.filter(d => !excludeIds.includes(d.id));
  }

  const resourceInfos = getResourceInfos(resource);
  data = filterFields(resourceInfos, data, fieldFilters);

  return data;
}

export default filterNodes;
