import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Confirm,
  Datagrid,
  DataProviderProxy,
  Identifier,
  List,
  ListProps,
  ReferenceField,
  TextField,
  TopToolbar,
  useDataProvider,
  useListContext,
  useListController,
  useNotify,
  useUnselectAll,
} from 'react-admin';

import EmailIcon from '@material-ui/icons/Email';
import DownloadIcon from '@material-ui/icons/GetApp';
import { useTheme, Theme } from '@material-ui/core';
import Color from 'color';
import * as doNotZip from 'do-not-zip';
import { stringify } from 'csv-stringify/dist/esm/sync';
import { format } from 'date-fns';

import { BorrowerNameField } from '../../resource/borrower/BorrowerNameField';
import { CurrencyField } from '../../resource/loan/CurrencyFields';
import { Style } from '../../types/styles';
import { DefaultAddressField } from './DefaultAddressField';
import TemplatesContext from '../../context/templatesContext';
import { useReminder } from '../../workflow/export-docx';
import download from '../../lib/download';
import { centsToEur } from '../../lib/utils';
import { EnhancedBorrower, EnhancedOwner } from '../../types/schema-custom';
import PaginationWithLoader from '../layout/PaginationWithLoader';
import { useAppContext } from '../../context/AppContext';
import ProgressBar from '../layout/ProgressBar';
import { OwnerLinkField } from './OwnerLinkField';

export const key = 'rappels';

const rowStyle =
  (theme: Theme) =>
  (record: any, index: number): Style => {
    return {
      backgroundColor:
        index % 2
          ? 'transparent'
          : Color(theme.palette.specials.cpretBlue).alpha(0.1).toString(),
    };
  };

const exportFilename = (now = new Date()) =>
  `${format(now, 'yyyyMMdd-HHmmss')}-cpret-rappels.csv`;

const useRappel = (setProgress: (p: number) => void) => {
  const templates = useContext(TemplatesContext);
  const remind = useReminder();
  const notify = useNotify();

  const cb = useCallback(
    (selectedIds: Identifier[] | undefined) => {
      setProgress(0);
      const action = async () => {
        if (!selectedIds) return;

        if (!templates?.reminder) throw new Error('Templates error');

        const rr: File[] = [];
        notify('cpret.template.started', 'info', {
          smart_count: selectedIds.length,
        });
        await selectedIds.reduce(async (p, id, i) => {
          await p;
          try {
            const result = await remind(
              id,
              /** saveAsset */ true, // selectedIds.length === 1,
            );
            if (result) rr.push(result);
          } catch (e: any) {
            console.error(e);
            notify('cpret.template.error', 'error', {
              name: e.name,
              message: e.message,
            });
          }
          setProgress(i + 1);
        }, Promise.resolve());

        if (rr.length === 1) {
          download(rr[0], rr[0].name);
        } else {
          const files: doNotZip.File[] = await Promise.all(
            rr.map(async r => ({
              path: r.name,
              data: Buffer.from(await r.arrayBuffer()), // better way to get Uint8Array from File ?
            })),
          );
          const output = doNotZip.toBlob(files);

          notify('cpret.template.complete', 'success', {
            smart_count: files.length,
          });

          download(output, 'rappels.zip');
        }
        setProgress(-1);
      };
      return action();
    },
    [notify, remind, templates],
  );
  return cb;
};

const COLS = [
  'CP',
  'Nom',
  'Prénom',
  'Statut',
  'Nom',
  'Prénom',
  'Signature',
  'Rappel',
  'Adresse',
  'Code Postal',
  'Ville',
  'Montant',
  'Restant dû',
];

const brwrsToCsv = async (
  bb: EnhancedBorrower[],
  dataProvider: DataProviderProxy,
): Promise<string[][]> => {
  const input = bb.entries();
  const output: string[][] = [];

  const getChunk = (size = 16): EnhancedBorrower[] =>
    [...new Array(size)]
      .map(() => input.next())
      .filter(n => !n.done)
      .map(n => n.value[1]);
  let next = getChunk();
  while (next.length) {
    const { data: oo } = await dataProvider.getMany<EnhancedOwner>(
      'cpretOwner',
      {
        ids: next.map(b => b.owner.edges?.[0]?.node.id).filter(Boolean),
      },
    );
    next.forEach(b => {
      const oid = b.owner.edges?.[0]?.node.id;
      const o = oid ? oo.find(own => own.id === oid) : undefined;
      let {
        streetAddress: addr,
        postalCode: code,
        addressLevel2: city,
      } = b.contact || {};
      const isFallBack = !addr && !code && !city;
      if (isFallBack) {
        addr = o?.contact?.streetAddress;
        code = o?.contact?.postalCode;
        city = o?.contact?.addressLevel2;
      }
      output.push(
        [
          o?.sncfCP,
          o?.contact?.familyName,
          o?.contact?.givenName,
          o?.$$workStatus,
          b.contact?.familyName,
          b.contact?.givenName,
          b.$$loanLastSignature,
          b.$$rappel,
          addr,
          code,
          city,
          centsToEur(b.$$loanAmount).toFixed(2),
          centsToEur(b.$$restantDu).toFixed(2),
        ].map(s => s || ''),
      );
    });
    next = getChunk();
  }
  return output;
};

const ListActions = () => {
  const [open, setOpen] = useState(false);
  const dataProvider = useDataProvider();
  const lst = useListContext();
  const [progress, setProgress] = useState<number | null>(null);
  const generate = useRappel(setProgress);
  const { loading } = useAppContext();
  const { currentSort, filter, filterValues, total } = lst;

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = useCallback(() => {
    const action = async () => {
      setOpen(false);
      const { data } = await dataProvider.getList<EnhancedBorrower>(
        'cpretBorrower',
        {
          filter: filter ? { ...filterValues, ...filter } : filterValues,
          pagination: { page: 0, perPage: -1 },
          sort: currentSort,
        },
      );
      if (data.length !== total) throw new Error('Wrong data length !');
      generate(data.map(b => b.id));
    };
    return action();
  }, [dataProvider, generate, currentSort, filter, filterValues, total]);
  const handleExport = useCallback(() => {
    const action = async () => {
      const { data } = await dataProvider.getList<EnhancedBorrower>(
        'cpretBorrower',
        {
          filter: filter ? { ...filterValues, ...filter } : filterValues,
          pagination: { page: 0, perPage: -1 },
          sort: currentSort,
        },
      );
      if (data.length !== total) throw new Error('Wrong data length !');
      const csvData = await brwrsToCsv(data, dataProvider);
      const out = stringify(csvData, {
        header: true,
        delimiter: ';',
        columns: COLS,
      });
      download(Buffer.from(out, 'latin1'), exportFilename());
    };
    return action();
  }, [dataProvider, currentSort, filter, filterValues, total]);

  return (
    <>
      <ProgressBar progress={progress} totalNumber={total} />
      <TopToolbar>
        <Button onClick={handleClick} label="Générer tout" disabled={!!loading}>
          <EmailIcon />
        </Button>
        <Button onClick={handleExport} label="Exporter" disabled={!!loading}>
          <DownloadIcon />
        </Button>
      </TopToolbar>
      <Confirm
        isOpen={open}
        // loading={loading}
        title="Génération de tous les rappels"
        content={`Voulez-vous générer et exporter tous les courriers (${lst.total}) ?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const CustomBulk = ({ selectedIds }: { selectedIds?: string[] }) => {
  const [open, setOpen] = useState(false);
  const unselectAll = useUnselectAll();

  const [progress, setProgress] = useState<number | null>(null);
  const generate = useRappel(setProgress);

  const { loading } = useAppContext();

  useEffect(() => {
    return () => {
      unselectAll('cpretBorrower');
    };
  }, [unselectAll]);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = useCallback(() => {
    const action = async () => {
      setOpen(false);
      generate(selectedIds);
    };
    return action();
  }, [generate, selectedIds]);

  return (
    <>
      <ProgressBar progress={progress} totalNumber={selectedIds?.length} />
      <Button label="Générer" onClick={handleClick} disabled={!!loading}>
        <EmailIcon />
      </Button>
      <Confirm
        isOpen={open}
        // loading={loading}
        title="Génération rappels"
        content={`Voulez-vous générer et exporter ${
          (selectedIds || []).length
        } courrier(s) ?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export const BorrowerList: React.FC<ListProps> = props => {
  const theme = useTheme();
  const { staticContext, ...rest } = props;

  const lst = useListController(rest);
  // this hack prevents console error because useGetMainList returns total undefined instead of null - FIXME
  if (lst.total === undefined) return null;

  return (
    <List
      {...rest}
      exporter={false}
      filter={{ $$rappel: ['1er', '2e', '⚠️'] }}
      perPage={10}
      sort={{ field: '$$rappel', order: 'ASC' }}
      actions={<ListActions />}
      bulkActionButtons={<CustomBulk />}
      pagination={<PaginationWithLoader />}
    >
      <Datagrid rowClick="toggleSelection" rowStyle={rowStyle(theme)}>
        <OwnerLinkField label="CP" source="owner.edges[0].node.sncfCP" />
        <OwnerLinkField
          label="Nom"
          source="owner.edges[0].node.contact.familyName"
        />
        <OwnerLinkField
          label="Prénom"
          source="owner.edges[0].node.contact.givenName"
        />
        <ReferenceField
          label="Statut"
          source="owner.edges[0].node.id"
          reference="cpretOwner"
          link={false}
          sortable={false}
        >
          <TextField source="$$workStatus" />
        </ReferenceField>
        <BorrowerNameField label="Nom" source="contact.familyName" link />
        <BorrowerNameField label="Prénom" source="contact.givenName" link />
        <TextField label="Signature" source="$$loanLastSignature" />
        <TextField label="Rappel" source="$$rappel" />
        <DefaultAddressField label="Adresse" source="streetAddress" />
        <DefaultAddressField label="Code Postal" source="postalCode" />
        <DefaultAddressField label="Ville" source="addressLevel2" />
        <CurrencyField label="Montant" source="$$loanAmount" />
        <CurrencyField label="Restant dû" source="$$restantDu" />
      </Datagrid>
    </List>
  );
};
