import React from 'react';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

import { primaryColor } from '../../theme/custom/sncf/colors';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    progress: {
      // Theme is not yet loaded
      color: primaryColor,
    },
  }),
);

const LoadingPage: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress className={classes.progress} />
    </div>
  );
};

export default LoadingPage;
