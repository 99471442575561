export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
// Generated on 2023-01-31T15:16:17+01:00

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `AWSDate` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 Date](https://en.wikipedia.org/wiki/ISO_8601#Calendar_dates) string. In other words, this scalar type accepts date strings of the form `YYYY-MM-DD`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-05-01**" and "**-9999-01-01**" are both valid dates.  This scalar type can also accept an optional [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators). For example, "**1970-01-01**", "**1970-01-01Z**", "**1970-01-01-07:00**" and "**1970-01-01+05:30**" are all valid dates. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDate: any;
  /** The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: any;
};












export type ActionHistory = {
  __typename?: 'ActionHistory';
  wfAction?: Maybe<Scalars['String']>;
  prevStatus?: Maybe<Scalars['String']>;
  nextStatus?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['AWSDateTime']>;
  message?: Maybe<Scalars['String']>;
  /**   Extra arguments as JSON */
  args?: Maybe<Scalars['String']>;
};

export type ActionHistoryInput = {
  wfAction?: Maybe<Scalars['String']>;
  prevStatus?: Maybe<Scalars['String']>;
  nextStatus?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['AWSDateTime']>;
  message?: Maybe<Scalars['String']>;
  /**   Extra arguments as JSON */
  args?: Maybe<Scalars['String']>;
};

export type ActionSchedule = {
  __typename?: 'ActionSchedule';
  wfAction?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['AWSDate']>;
  /**   Extra arguments as JSON */
  args?: Maybe<Scalars['String']>;
  /**   Executed */
  done?: Maybe<Scalars['Boolean']>;
};

export type ActionScheduleInput = {
  wfAction?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['AWSDate']>;
  /**   Extra arguments as JSON */
  args?: Maybe<Scalars['String']>;
  /**   Executed */
  done?: Maybe<Scalars['Boolean']>;
};

export type Asset = Node & {
  __typename?: 'Asset';
  /**   Title */
  title?: Maybe<Scalars['String']>;
  /**   File name */
  filename?: Maybe<Scalars['String']>;
  /**   File size */
  size?: Maybe<Scalars['Int']>;
  /**   Mime type */
  mimeType?: Maybe<Scalars['String']>;
  /**   S3 Key (suffix only) */
  key?: Maybe<Scalars['String']>;
  /**   the list of Owners of this attachment */
  owner: OwnerConnection;
  /**   the list of Borrowers of this attachment */
  borrower: BorrowerConnection;
  /**   the list of Loans of this attachment */
  loan: LoanConnection;
  /**   the list of Guarantees of this attachment */
  guarantee: GuaranteeConnection;
  /**   the list of GuarantyLoans of this attachment */
  guarantyLoan: GuarantyLoanConnection;
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Status>;
  /**   Data version, used for transactions */
  version: Scalars['Int'];
};


export type AssetOwnerArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


export type AssetBorrowerArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


export type AssetLoanArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


export type AssetGuaranteeArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


export type AssetGuarantyLoanArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};

export type AssetConnection = Connection & {
  __typename?: 'AssetConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<AssetEdge>>;
};

export type AssetEdge = Edge & {
  __typename?: 'AssetEdge';
  node: Asset;
  /**   Always null in this implementation */
  cursor?: Maybe<Scalars['ID']>;
};

export type AssetInput = {
  /**   Title */
  title?: Maybe<Scalars['String']>;
  /**   File name */
  filename?: Maybe<Scalars['String']>;
  /**   File size */
  size?: Maybe<Scalars['Int']>;
  /**   Mime type */
  mimeType?: Maybe<Scalars['String']>;
  /**   S3 Key (suffix only) */
  key?: Maybe<Scalars['String']>;
  /**   Owner connections */
  owner?: Maybe<ConnectionInput>;
  /**   Borrower connections */
  borrower?: Maybe<ConnectionInput>;
  /**   Loan connections */
  loan?: Maybe<ConnectionInput>;
  /**   Guarantee connections */
  guarantee?: Maybe<ConnectionInput>;
  /**   GuarantyLoan connections */
  guarantyLoan?: Maybe<ConnectionInput>;
  status?: Maybe<Status>;
};

/**   Dossier Emprunteur */
export type Borrower = Node & {
  __typename?: 'Borrower';
  loanType?: Maybe<LoanType>;
  contact?: Maybe<Person>;
  loans: LoanConnection;
  attachments: AssetConnection;
  ledger: LedgerConnection;
  schedules?: Maybe<Array<Maybe<Schedule>>>;
  contentieux?: Maybe<Scalars['Boolean']>;
  surrendettement?: Maybe<Scalars['Boolean']>;
  ibanName?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  rum?: Maybe<Scalars['String']>;
  /**   Unique Mandate Reference */
  umr?: Maybe<Scalars['String']>;
  /**   Jour de prélèvement (1 ou 15) */
  pmtDay?: Maybe<Scalars['Int']>;
  /**   Commentaire */
  comment?: Maybe<Scalars['String']>;
  history: HistoryConnection;
  /**   Original ID */
  inpecId?: Maybe<Scalars['ID']>;
  /**   the list of Owners of this borrower */
  owner: OwnerConnection;
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Status>;
  /**   Data version, used for transactions */
  version: Scalars['Int'];
};


/**   Dossier Emprunteur */
export type BorrowerLoansArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


/**   Dossier Emprunteur */
export type BorrowerAttachmentsArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


/**   Dossier Emprunteur */
export type BorrowerLedgerArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


/**   Dossier Emprunteur */
export type BorrowerHistoryArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


/**   Dossier Emprunteur */
export type BorrowerOwnerArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};

export type BorrowerConnection = Connection & {
  __typename?: 'BorrowerConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<BorrowerEdge>>;
};

export type BorrowerEdge = Edge & {
  __typename?: 'BorrowerEdge';
  node: Borrower;
  /**   Always null in this implementation */
  cursor?: Maybe<Scalars['ID']>;
};

export type BorrowerInput = {
  loanType?: Maybe<LoanType>;
  contact?: Maybe<PersonInput>;
  /**   Loan connections */
  loans?: Maybe<ConnectionInput>;
  /**   Asset connections */
  attachments?: Maybe<ConnectionInput>;
  /**   Ledger connections */
  ledger?: Maybe<ConnectionInput>;
  schedules?: Maybe<Array<Maybe<ScheduleInput>>>;
  contentieux?: Maybe<Scalars['Boolean']>;
  surrendettement?: Maybe<Scalars['Boolean']>;
  ibanName?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  rum?: Maybe<Scalars['String']>;
  /**   Unique Mandate Reference */
  umr?: Maybe<Scalars['String']>;
  /**   Jour de prélèvement (1 ou 15) */
  pmtDay?: Maybe<Scalars['Int']>;
  /**   Commentaire */
  comment?: Maybe<Scalars['String']>;
  /**   History connections */
  history?: Maybe<ConnectionInput>;
  /**   Original ID */
  inpecId?: Maybe<Scalars['ID']>;
  /**   Owner connections */
  owner?: Maybe<ConnectionInput>;
  status?: Maybe<Status>;
};

export type Connection = {
  pageInfo: PageInfo;
  edges: Array<Maybe<Edge>>;
};

/**   keep connect + disconnect count <= 25 for single ddb call */
export type ConnectionInput = {
  connect?: Maybe<Array<Maybe<Scalars['ID']>>>;
  disconnect?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ConnectionUpdate = {
  __typename?: 'ConnectionUpdate';
  field: Scalars['String'];
  id: Scalars['ID'];
};

export type Edge = {
  node: Node;
  cursor?: Maybe<Scalars['ID']>;
};

/**   Cautionné */
export type Guarantee = Node & {
  __typename?: 'Guarantee';
  /**   Matricule d'origine */
  sncfCP?: Maybe<Scalars['ID']>;
  /**   Emprunteurs à date */
  contacts?: Maybe<Array<Maybe<Person>>>;
  /**   Co Emprunteur */
  coSncfCP?: Maybe<Scalars['String']>;
  coSncfContractBeginDate?: Maybe<Scalars['String']>;
  nonDenunciation?: Maybe<Scalars['Boolean']>;
  attachments: AssetConnection;
  /**   Prêts cautionnés */
  loans: GuarantyLoanConnection;
  /**   Date de signature */
  signature?: Maybe<Scalars['AWSDate']>;
  /**   Montant (centimes) */
  amount?: Maybe<Scalars['Int']>;
  /**   Historical data (react rendered) */
  dataBlob?: Maybe<Scalars['String']>;
  /**   Commentaire */
  comment?: Maybe<Scalars['String']>;
  history: HistoryConnection;
  /**   Original ID */
  inpecId?: Maybe<Scalars['ID']>;
  /**   Action history */
  actionHistory?: Maybe<Array<Maybe<ActionHistory>>>;
  /**   the list of Owners of this guarantee */
  owner: OwnerConnection;
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Status>;
  /**   Data version, used for transactions */
  version: Scalars['Int'];
};


/**   Cautionné */
export type GuaranteeAttachmentsArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


/**   Cautionné */
export type GuaranteeLoansArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


/**   Cautionné */
export type GuaranteeHistoryArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


/**   Cautionné */
export type GuaranteeOwnerArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};

export type GuaranteeConnection = Connection & {
  __typename?: 'GuaranteeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<GuaranteeEdge>>;
};

export type GuaranteeEdge = Edge & {
  __typename?: 'GuaranteeEdge';
  node: Guarantee;
  /**   Always null in this implementation */
  cursor?: Maybe<Scalars['ID']>;
};

export type GuaranteeInput = {
  /**   Matricule d'origine */
  sncfCP?: Maybe<Scalars['ID']>;
  /**   Emprunteurs à date */
  contacts?: Maybe<Array<Maybe<PersonInput>>>;
  /**   Co Emprunteur */
  coSncfCP?: Maybe<Scalars['String']>;
  coSncfContractBeginDate?: Maybe<Scalars['String']>;
  nonDenunciation?: Maybe<Scalars['Boolean']>;
  /**   Asset connections */
  attachments?: Maybe<ConnectionInput>;
  /**   GuarantyLoan connections */
  loans?: Maybe<ConnectionInput>;
  /**   Date de signature */
  signature?: Maybe<Scalars['AWSDate']>;
  /**   Montant (centimes) */
  amount?: Maybe<Scalars['Int']>;
  /**   Historical data (react rendered) */
  dataBlob?: Maybe<Scalars['String']>;
  /**   Commentaire */
  comment?: Maybe<Scalars['String']>;
  /**   History connections */
  history?: Maybe<ConnectionInput>;
  /**   Original ID */
  inpecId?: Maybe<Scalars['ID']>;
  /**   Action history */
  actionHistory?: Maybe<Array<Maybe<ActionHistoryInput>>>;
  /**   Owner connections */
  owner?: Maybe<ConnectionInput>;
  status?: Maybe<Status>;
};

/**   Prêt cautionné */
export type GuarantyLoan = Node & {
  __typename?: 'GuarantyLoan';
  label?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['AWSDate']>;
  startDate?: Maybe<Scalars['AWSDate']>;
  endDate?: Maybe<Scalars['AWSDate']>;
  /**   Montant (centimes) */
  amount?: Maybe<Scalars['Int']>;
  /**   Workflow status */
  wfStatus?: Maybe<Scalars['String']>;
  /**   Action history */
  actionHistory?: Maybe<Array<Maybe<ActionHistory>>>;
  /**   Action schedule */
  actionSchedule?: Maybe<Array<Maybe<ActionSchedule>>>;
  attachments: AssetConnection;
  /**   Commentaire */
  comment?: Maybe<Scalars['String']>;
  history: HistoryConnection;
  /**   Original ID */
  inpecId?: Maybe<Scalars['ID']>;
  /**   the list of Guarantees of this loan */
  guarantee: GuaranteeConnection;
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Status>;
  /**   Data version, used for transactions */
  version: Scalars['Int'];
};


/**   Prêt cautionné */
export type GuarantyLoanAttachmentsArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


/**   Prêt cautionné */
export type GuarantyLoanHistoryArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


/**   Prêt cautionné */
export type GuarantyLoanGuaranteeArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};

export type GuarantyLoanConnection = Connection & {
  __typename?: 'GuarantyLoanConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<GuarantyLoanEdge>>;
};

export type GuarantyLoanEdge = Edge & {
  __typename?: 'GuarantyLoanEdge';
  node: GuarantyLoan;
  /**   Always null in this implementation */
  cursor?: Maybe<Scalars['ID']>;
};

export type GuarantyLoanInput = {
  label?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['AWSDate']>;
  startDate?: Maybe<Scalars['AWSDate']>;
  endDate?: Maybe<Scalars['AWSDate']>;
  /**   Montant (centimes) */
  amount?: Maybe<Scalars['Int']>;
  /**   Workflow status */
  wfStatus?: Maybe<Scalars['String']>;
  /**   Action history */
  actionHistory?: Maybe<Array<Maybe<ActionHistoryInput>>>;
  /**   Action schedule */
  actionSchedule?: Maybe<Array<Maybe<ActionScheduleInput>>>;
  /**   Asset connections */
  attachments?: Maybe<ConnectionInput>;
  /**   Commentaire */
  comment?: Maybe<Scalars['String']>;
  /**   History connections */
  history?: Maybe<ConnectionInput>;
  /**   Original ID */
  inpecId?: Maybe<Scalars['ID']>;
  /**   Guarantee connections */
  guarantee?: Maybe<ConnectionInput>;
  status?: Maybe<Status>;
};

export type History = Node & {
  __typename?: 'History';
  /**   Action author */
  identity?: Maybe<Scalars['ID']>;
  /**   Message useTranslate / polyglot style */
  message?: Maybe<Scalars['String']>;
  /**   Second arguments as JSON */
  args?: Maybe<Scalars['String']>;
  /**   the list of Owners of this history */
  owner: OwnerConnection;
  /**   the list of Borrowers of this history */
  borrower: BorrowerConnection;
  /**   the list of Loans of this history */
  loan: LoanConnection;
  /**   the list of Guarantees of this history */
  guarantee: GuaranteeConnection;
  /**   the list of GuarantyLoans of this history */
  guarantyLoan: GuarantyLoanConnection;
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Status>;
  /**   Data version, used for transactions */
  version: Scalars['Int'];
};


export type HistoryOwnerArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


export type HistoryBorrowerArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


export type HistoryLoanArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


export type HistoryGuaranteeArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


export type HistoryGuarantyLoanArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};

export type HistoryConnection = Connection & {
  __typename?: 'HistoryConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<HistoryEdge>>;
};

export type HistoryEdge = Edge & {
  __typename?: 'HistoryEdge';
  node: History;
  /**   Always null in this implementation */
  cursor?: Maybe<Scalars['ID']>;
};

export type HistoryInput = {
  /**   Action author */
  identity?: Maybe<Scalars['ID']>;
  /**   Message useTranslate / polyglot style */
  message?: Maybe<Scalars['String']>;
  /**   Second arguments as JSON */
  args?: Maybe<Scalars['String']>;
  /**   Owner connections */
  owner?: Maybe<ConnectionInput>;
  /**   Borrower connections */
  borrower?: Maybe<ConnectionInput>;
  /**   Loan connections */
  loan?: Maybe<ConnectionInput>;
  /**   Guarantee connections */
  guarantee?: Maybe<ConnectionInput>;
  /**   GuarantyLoan connections */
  guarantyLoan?: Maybe<ConnectionInput>;
  status?: Maybe<Status>;
};

export enum HonorificPrefix {
  MR = 'MR',
  MME = 'MME'
}

export type Income = {
  __typename?: 'Income';
  type?: Maybe<IncomeType>;
  label?: Maybe<Scalars['String']>;
  /**   Montant, positif (revenu) ou négatif (dépense) (en centimes) */
  amount?: Maybe<Scalars['Int']>;
};

export type IncomeInput = {
  type?: Maybe<IncomeType>;
  label?: Maybe<Scalars['String']>;
  /**   Montant, positif (revenu) ou négatif (dépense) (en centimes) */
  amount?: Maybe<Scalars['Int']>;
};

export enum IncomeType {
  AGENT = 'AGENT',
  CONJOINT = 'CONJOINT',
  ENFANT = 'ENFANT',
  AUTRE = 'AUTRE'
}

/**   Livre des écritures */
export type Ledger = Node & {
  __typename?: 'Ledger';
  payee?: Maybe<Scalars['String']>;
  /**   Sum of amounts must equal to zero */
  postings?: Maybe<Array<Maybe<LedgerPosting>>>;
  hash?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
  /**   the list of Borrowers of this ledger */
  borrower: BorrowerConnection;
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Status>;
  /**   Data version, used for transactions */
  version: Scalars['Int'];
};


/**   Livre des écritures */
export type LedgerBorrowerArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};

export type LedgerConnection = Connection & {
  __typename?: 'LedgerConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<LedgerEdge>>;
};

export type LedgerEdge = Edge & {
  __typename?: 'LedgerEdge';
  node: Ledger;
  /**   Always null in this implementation */
  cursor?: Maybe<Scalars['ID']>;
};

export type LedgerInput = {
  payee?: Maybe<Scalars['String']>;
  /**   Sum of amounts must equal to zero */
  postings?: Maybe<Array<Maybe<LedgerPostingInput>>>;
  hash?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
  /**   Borrower connections */
  borrower?: Maybe<ConnectionInput>;
  status?: Maybe<Status>;
};

/**   Ledger Transaction Posting */
export type LedgerPosting = {
  __typename?: 'LedgerPosting';
  account?: Maybe<Scalars['String']>;
  /**   Montant, positif (débit) ou négatif (crédit) (en centimes) */
  amount?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
};

export type LedgerPostingInput = {
  account?: Maybe<Scalars['String']>;
  /**   Montant, positif (débit) ou négatif (crédit) (en centimes) */
  amount?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
};

/**   Prêt */
export type Loan = Node & {
  __typename?: 'Loan';
  attachments: AssetConnection;
  /**   Matricule d'origine */
  sncfCP?: Maybe<Scalars['ID']>;
  /**   Société d'origine */
  sncfCompany?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<Person>>>;
  /**   Revenus et Dépenses */
  income?: Maybe<Array<Maybe<Income>>>;
  /**
   *   Total des revenus (non calculé)
   * @deprecated Field no longer supported
   */
  totalIncome?: Maybe<Scalars['Int']>;
  /**   Nombre de parts */
  taxShares?: Maybe<Scalars['Float']>;
  ibanName?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  /**   Enfant à charge */
  dependentChild?: Maybe<Scalars['Boolean']>;
  /**   Année scolaire */
  schoolYear?: Maybe<Scalars['String']>;
  /**   Frais de loyer (centimes) */
  cptFraisLoyer?: Maybe<Scalars['Int']>;
  /**   APL Etudiant */
  cptAPL?: Maybe<Scalars['Int']>;
  /**   Frais de scolarité */
  cptFraisScolarite?: Maybe<Scalars['Int']>;
  /**   Montant Entretien (centimes) */
  amountEntretien?: Maybe<Scalars['Int']>;
  /**   Montant Eloignement (centimes) */
  amountEloignement?: Maybe<Scalars['Int']>;
  /**   Montant Eloignement (centimes) */
  amountEnseignement?: Maybe<Scalars['Int']>;
  /**   Forfait Entretien */
  checkEntretien?: Maybe<Scalars['Boolean']>;
  /**   Forfait Eloignement */
  checkEloignement?: Maybe<Scalars['Boolean']>;
  /**   Forfait Enseignement */
  checkEnseignement?: Maybe<Scalars['Boolean']>;
  /**   Montant du prêt (centimes) */
  amount?: Maybe<Scalars['Int']>;
  /**   Taux */
  rate?: Maybe<Scalars['Float']>;
  /**   Date de signature */
  signature?: Maybe<Scalars['AWSDate']>;
  /**   Historical data for Prêt Sociaux (react rendered) */
  dataBlob?: Maybe<Scalars['String']>;
  /**   Commentaire */
  comment?: Maybe<Scalars['String']>;
  history: HistoryConnection;
  /**   Workflow status */
  wfStatus?: Maybe<Scalars['String']>;
  /**   Action history */
  actionHistory?: Maybe<Array<Maybe<ActionHistory>>>;
  /**   Original ID */
  inpecId?: Maybe<Scalars['ID']>;
  /**   the list of Borrowers of this loan */
  borrower: BorrowerConnection;
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Status>;
  /**   Data version, used for transactions */
  version: Scalars['Int'];
};


/**   Prêt */
export type LoanAttachmentsArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


/**   Prêt */
export type LoanHistoryArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


/**   Prêt */
export type LoanBorrowerArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};

export type LoanConnection = Connection & {
  __typename?: 'LoanConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<LoanEdge>>;
};

export type LoanEdge = Edge & {
  __typename?: 'LoanEdge';
  node: Loan;
  /**   Always null in this implementation */
  cursor?: Maybe<Scalars['ID']>;
};

export type LoanInput = {
  /**   Asset connections */
  attachments?: Maybe<ConnectionInput>;
  /**   Matricule d'origine */
  sncfCP?: Maybe<Scalars['ID']>;
  /**   Société d'origine */
  sncfCompany?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<PersonInput>>>;
  /**   Revenus et Dépenses */
  income?: Maybe<Array<Maybe<IncomeInput>>>;
  /**   Total des revenus (non calculé) */
  totalIncome?: Maybe<Scalars['Int']>;
  /**   Nombre de parts */
  taxShares?: Maybe<Scalars['Float']>;
  ibanName?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  /**   Enfant à charge */
  dependentChild?: Maybe<Scalars['Boolean']>;
  /**   Année scolaire */
  schoolYear?: Maybe<Scalars['String']>;
  /**   Frais de loyer (centimes) */
  cptFraisLoyer?: Maybe<Scalars['Int']>;
  /**   APL Etudiant */
  cptAPL?: Maybe<Scalars['Int']>;
  /**   Frais de scolarité */
  cptFraisScolarite?: Maybe<Scalars['Int']>;
  /**   Montant Entretien (centimes) */
  amountEntretien?: Maybe<Scalars['Int']>;
  /**   Montant Eloignement (centimes) */
  amountEloignement?: Maybe<Scalars['Int']>;
  /**   Montant Eloignement (centimes) */
  amountEnseignement?: Maybe<Scalars['Int']>;
  /**   Forfait Entretien */
  checkEntretien?: Maybe<Scalars['Boolean']>;
  /**   Forfait Eloignement */
  checkEloignement?: Maybe<Scalars['Boolean']>;
  /**   Forfait Enseignement */
  checkEnseignement?: Maybe<Scalars['Boolean']>;
  /**   Montant du prêt (centimes) */
  amount?: Maybe<Scalars['Int']>;
  /**   Taux */
  rate?: Maybe<Scalars['Float']>;
  /**   Date de signature */
  signature?: Maybe<Scalars['AWSDate']>;
  /**   Historical data for Prêt Sociaux (react rendered) */
  dataBlob?: Maybe<Scalars['String']>;
  /**   Commentaire */
  comment?: Maybe<Scalars['String']>;
  /**   History connections */
  history?: Maybe<ConnectionInput>;
  /**   Workflow status */
  wfStatus?: Maybe<Scalars['String']>;
  /**   Action history */
  actionHistory?: Maybe<Array<Maybe<ActionHistoryInput>>>;
  /**   Original ID */
  inpecId?: Maybe<Scalars['ID']>;
  /**   Borrower connections */
  borrower?: Maybe<ConnectionInput>;
  status?: Maybe<Status>;
};

export enum LoanType {
  HONNEUR = 'HONNEUR',
  SOCIAL = 'SOCIAL',
  INSTALLATION = 'INSTALLATION',
  HABITATION = 'HABITATION'
}

export type Mutation = {
  __typename?: 'Mutation';
  createOwner?: Maybe<NodeUpdate>;
  updateOwner?: Maybe<NodeUpdate>;
  deleteOwner?: Maybe<NodeUpdate>;
  createBorrower?: Maybe<NodeUpdate>;
  updateBorrower?: Maybe<NodeUpdate>;
  deleteBorrower?: Maybe<NodeUpdate>;
  createLoan?: Maybe<NodeUpdate>;
  updateLoan?: Maybe<NodeUpdate>;
  deleteLoan?: Maybe<NodeUpdate>;
  createGuarantee?: Maybe<NodeUpdate>;
  updateGuarantee?: Maybe<NodeUpdate>;
  deleteGuarantee?: Maybe<NodeUpdate>;
  createGuarantyLoan?: Maybe<NodeUpdate>;
  updateGuarantyLoan?: Maybe<NodeUpdate>;
  deleteGuarantyLoan?: Maybe<NodeUpdate>;
  createAsset?: Maybe<NodeUpdate>;
  updateAsset?: Maybe<NodeUpdate>;
  deleteAsset?: Maybe<NodeUpdate>;
  createLedger?: Maybe<NodeUpdate>;
  updateLedger?: Maybe<NodeUpdate>;
  deleteLedger?: Maybe<NodeUpdate>;
  createHistory?: Maybe<NodeUpdate>;
  updateHistory?: Maybe<NodeUpdate>;
  deleteHistory?: Maybe<NodeUpdate>;
};


export type MutationCreateOwnerArgs = {
  data: OwnerInput;
};


export type MutationUpdateOwnerArgs = {
  data: OwnerInput;
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
};


export type MutationDeleteOwnerArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBorrowerArgs = {
  data: BorrowerInput;
};


export type MutationUpdateBorrowerArgs = {
  data: BorrowerInput;
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
};


export type MutationDeleteBorrowerArgs = {
  id: Scalars['ID'];
};


export type MutationCreateLoanArgs = {
  data: LoanInput;
};


export type MutationUpdateLoanArgs = {
  data: LoanInput;
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
};


export type MutationDeleteLoanArgs = {
  id: Scalars['ID'];
};


export type MutationCreateGuaranteeArgs = {
  data: GuaranteeInput;
};


export type MutationUpdateGuaranteeArgs = {
  data: GuaranteeInput;
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
};


export type MutationDeleteGuaranteeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateGuarantyLoanArgs = {
  data: GuarantyLoanInput;
};


export type MutationUpdateGuarantyLoanArgs = {
  data: GuarantyLoanInput;
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
};


export type MutationDeleteGuarantyLoanArgs = {
  id: Scalars['ID'];
};


export type MutationCreateAssetArgs = {
  data: AssetInput;
};


export type MutationUpdateAssetArgs = {
  data: AssetInput;
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
};


export type MutationDeleteAssetArgs = {
  id: Scalars['ID'];
};


export type MutationCreateLedgerArgs = {
  data: LedgerInput;
};


export type MutationUpdateLedgerArgs = {
  data: LedgerInput;
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
};


export type MutationDeleteLedgerArgs = {
  id: Scalars['ID'];
};


export type MutationCreateHistoryArgs = {
  data: HistoryInput;
};


export type MutationUpdateHistoryArgs = {
  data: HistoryInput;
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
};


export type MutationDeleteHistoryArgs = {
  id: Scalars['ID'];
};

/**   Any uniquely identifiable object by ID */
export type Node = {
  /**   The ID of the object */
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Status>;
  /**   Data version, used for transactions */
  version: Scalars['Int'];
};

export type NodeUpdate = {
  __typename?: 'NodeUpdate';
  id: Scalars['ID'];
  node: Node;
  updateInfo: NodeUpdateInfo;
};

export type NodeUpdateInfo = {
  __typename?: 'NodeUpdateInfo';
  mutation: UpdateType;
  connect: Array<Maybe<ConnectionUpdate>>;
  disconnect: Array<Maybe<ConnectionUpdate>>;
};

/**   Demandeur */
export type Owner = SncfInterface & Node & {
  __typename?: 'Owner';
  /**   Code CP */
  sncfCP?: Maybe<Scalars['ID']>;
  /**   SA */
  sncfCompany?: Maybe<Scalars['String']>;
  /**   Date d'embauche */
  sncfContractBeginDate?: Maybe<Scalars['AWSDate']>;
  /**   Date de cessation */
  sncfContractEndDate?: Maybe<Scalars['AWSDate']>;
  /**   Motif de cessation */
  sncfContractEndReason?: Maybe<Scalars['String']>;
  contact?: Maybe<Person>;
  agent?: Maybe<Person>;
  /**   Commentaire */
  comment?: Maybe<Scalars['String']>;
  history: HistoryConnection;
  attachments: AssetConnection;
  borrowers: BorrowerConnection;
  guarantees: GuaranteeConnection;
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Status>;
  /**   Data version, used for transactions */
  version: Scalars['Int'];
};


/**   Demandeur */
export type OwnerHistoryArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


/**   Demandeur */
export type OwnerAttachmentsArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


/**   Demandeur */
export type OwnerBorrowersArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


/**   Demandeur */
export type OwnerGuaranteesArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};

export type OwnerConnection = Connection & {
  __typename?: 'OwnerConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<OwnerEdge>>;
};

export type OwnerEdge = Edge & {
  __typename?: 'OwnerEdge';
  node: Owner;
  /**   Always null in this implementation */
  cursor?: Maybe<Scalars['ID']>;
};

export type OwnerInput = {
  /**   Code CP */
  sncfCP?: Maybe<Scalars['ID']>;
  /**   SA */
  sncfCompany?: Maybe<Scalars['String']>;
  /**   Date d'embauche */
  sncfContractBeginDate?: Maybe<Scalars['AWSDate']>;
  /**   Date de cessation */
  sncfContractEndDate?: Maybe<Scalars['AWSDate']>;
  /**   Motif de cessation */
  sncfContractEndReason?: Maybe<Scalars['String']>;
  contact?: Maybe<PersonInput>;
  agent?: Maybe<PersonInput>;
  /**   Commentaire */
  comment?: Maybe<Scalars['String']>;
  /**   History connections */
  history?: Maybe<ConnectionInput>;
  /**   Asset connections */
  attachments?: Maybe<ConnectionInput>;
  /**   Borrower connections */
  borrowers?: Maybe<ConnectionInput>;
  /**   Guarantee connections */
  guarantees?: Maybe<ConnectionInput>;
  status?: Maybe<Status>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /**   When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /**   When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /**   When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /**   When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

/**   Individu */
export type Person = PersonInterface & {
  __typename?: 'Person';
  /**   Civilité */
  honorificPrefix?: Maybe<HonorificPrefix>;
  /**   Prénom */
  givenName?: Maybe<Scalars['String']>;
  /**   Nom usuel */
  familyName?: Maybe<Scalars['String']>;
  /**   Nom patronymique */
  patronymicName?: Maybe<Scalars['String']>;
  /**   Date de naissance */
  bday?: Maybe<Scalars['AWSDate']>;
  /**   Addresse */
  streetAddress?: Maybe<Scalars['String']>;
  /**   Code postal */
  postalCode?: Maybe<Scalars['String']>;
  /**   Ville */
  addressLevel2?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /**   Téléphone */
  tel?: Maybe<Scalars['String']>;
};

export type PersonInput = {
  /**   Civilité */
  honorificPrefix?: Maybe<HonorificPrefix>;
  /**   Prénom */
  givenName?: Maybe<Scalars['String']>;
  /**   Nom usuel */
  familyName?: Maybe<Scalars['String']>;
  /**   Nom patronymique */
  patronymicName?: Maybe<Scalars['String']>;
  /**   Date de naissance */
  bday?: Maybe<Scalars['AWSDate']>;
  /**   Addresse */
  streetAddress?: Maybe<Scalars['String']>;
  /**   Code postal */
  postalCode?: Maybe<Scalars['String']>;
  /**   Ville */
  addressLevel2?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /**   Téléphone */
  tel?: Maybe<Scalars['String']>;
};

export type PersonInterface = {
  honorificPrefix?: Maybe<HonorificPrefix>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  patronymicName?: Maybe<Scalars['String']>;
  bday?: Maybe<Scalars['AWSDate']>;
  streetAddress?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  addressLevel2?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  allOwner: OwnerConnection;
  owner?: Maybe<Owner>;
  allBorrower: BorrowerConnection;
  borrower?: Maybe<Borrower>;
  allLoan: LoanConnection;
  loan?: Maybe<Loan>;
  allGuarantee: GuaranteeConnection;
  guarantee?: Maybe<Guarantee>;
  allGuarantyLoan: GuarantyLoanConnection;
  guarantyLoan?: Maybe<GuarantyLoan>;
  allAsset: AssetConnection;
  asset?: Maybe<Asset>;
  allLedger: LedgerConnection;
  ledger?: Maybe<Ledger>;
  allHistory: HistoryConnection;
  history?: Maybe<History>;
  /**   Returns optimistic data for nodes in transition */
  nodeNext?: Maybe<NodeUpdate>;
  /**   Introspection config */
  _schemaConfig?: Maybe<SchemaConfig>;
};


export type QueryAllOwnerArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


export type QueryOwnerArgs = {
  id: Scalars['ID'];
};


export type QueryAllBorrowerArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


export type QueryBorrowerArgs = {
  id: Scalars['ID'];
};


export type QueryAllLoanArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


export type QueryLoanArgs = {
  id: Scalars['ID'];
};


export type QueryAllGuaranteeArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


export type QueryGuaranteeArgs = {
  id: Scalars['ID'];
};


export type QueryAllGuarantyLoanArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


export type QueryGuarantyLoanArgs = {
  id: Scalars['ID'];
};


export type QueryAllAssetArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


export type QueryAssetArgs = {
  id: Scalars['ID'];
};


export type QueryAllLedgerArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


export type QueryLedgerArgs = {
  id: Scalars['ID'];
};


export type QueryAllHistoryArgs = {
  after?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<WhereInput>;
};


export type QueryHistoryArgs = {
  id: Scalars['ID'];
};


export type QueryNodeNextArgs = {
  id: Scalars['ID'];
};

export type SncfInterface = {
  sncfCP?: Maybe<Scalars['ID']>;
  sncfCompany?: Maybe<Scalars['String']>;
  sncfContractBeginDate?: Maybe<Scalars['AWSDate']>;
  sncfContractEndDate?: Maybe<Scalars['AWSDate']>;
  sncfContractEndReason?: Maybe<Scalars['String']>;
};

/**   Echéancier */
export type Schedule = {
  __typename?: 'Schedule';
  label?: Maybe<Scalars['String']>;
  /**   Motif */
  type?: Maybe<ScheduleType>;
  payments?: Maybe<Array<Maybe<SchedulePayment>>>;
  /**   Historical ID */
  inpecId?: Maybe<Scalars['String']>;
};

export type ScheduleInput = {
  label?: Maybe<Scalars['String']>;
  /**   Motif */
  type?: Maybe<ScheduleType>;
  payments?: Maybe<Array<Maybe<SchedulePaymentInput>>>;
  /**   Historical ID */
  inpecId?: Maybe<Scalars['String']>;
};

export type SchedulePayment = {
  __typename?: 'SchedulePayment';
  /**   Echéance */
  dueDate?: Maybe<Scalars['AWSDate']>;
  /**   Montant (centimes) */
  principalAmount?: Maybe<Scalars['Int']>;
  /**   Intérêts (centimes) */
  interestAmount?: Maybe<Scalars['Int']>;
  /**   Appelé */
  done?: Maybe<Scalars['Boolean']>;
};

export type SchedulePaymentInput = {
  /**   Echéance */
  dueDate?: Maybe<Scalars['AWSDate']>;
  /**   Montant (centimes) */
  principalAmount?: Maybe<Scalars['Int']>;
  /**   Intérêts (centimes) */
  interestAmount?: Maybe<Scalars['Int']>;
  /**   Appelé */
  done?: Maybe<Scalars['Boolean']>;
};

export enum ScheduleType {
  CAPITAL = 'CAPITAL',
  DEBT = 'DEBT'
}

export type SchemaConfig = {
  __typename?: 'SchemaConfig';
  connections?: Maybe<Array<Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>>>;
  refs?: Maybe<Array<Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>>>;
};

export enum Status {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED'
}

export type Subscription = {
  __typename?: 'Subscription';
  subscribeToNode?: Maybe<NodeUpdate>;
  subscribeToOwner?: Maybe<NodeUpdate>;
  subscribeToBorrower?: Maybe<NodeUpdate>;
  subscribeToLoan?: Maybe<NodeUpdate>;
  subscribeToGuarantee?: Maybe<NodeUpdate>;
  subscribeToGuarantyLoan?: Maybe<NodeUpdate>;
  subscribeToAsset?: Maybe<NodeUpdate>;
  subscribeToLedger?: Maybe<NodeUpdate>;
  subscribeToHistory?: Maybe<NodeUpdate>;
};


export type SubscriptionSubscribeToNodeArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type SubscriptionSubscribeToOwnerArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type SubscriptionSubscribeToBorrowerArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type SubscriptionSubscribeToLoanArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type SubscriptionSubscribeToGuaranteeArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type SubscriptionSubscribeToGuarantyLoanArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type SubscriptionSubscribeToAssetArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type SubscriptionSubscribeToLedgerArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type SubscriptionSubscribeToHistoryArgs = {
  id?: Maybe<Scalars['ID']>;
};

export enum UpdateType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

/**   Optional input filter */
export type WhereInput = {
  /**   Filter by status */
  status?: Maybe<Status>;
  /**   Find known connection with other id */
  id?: Maybe<Scalars['ID']>;
  /**   Find by ddbindex 0 (begins_with) */
  alt0?: Maybe<Scalars['String']>;
  /**   Find by ddbindex 1 (begins_with) */
  alt1?: Maybe<Scalars['String']>;
};
