import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import { GuarantyLoan } from '../../types/schema';
import { HistoryNode } from '../../history/HistoryNode';
import { HistoryPanel } from '../../history/HistoryPanel';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles<Theme>(theme => createStyles({}));

export interface GuarantyLoanHistoryProps {
  record?: GuarantyLoan;
}

export const GuarantyLoanHistory: React.FC<GuarantyLoanHistoryProps> = ({
  record,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classes = useStyles();

  if (!record) {
    return null;
  }

  return (
    <HistoryPanel resource="GuarantyLoan" id={record.id} pageSize={50}>
      {({ node }) => <HistoryNode key={node.id} node={node} />}
    </HistoryPanel>
  );
};
