import React from 'react';
import { Create, CreateProps, Record, SimpleForm } from 'react-admin';

import { clearDataBeforeSubmit } from '../dataProvider/utils';
import { getResourceCustomization, ResourceKey } from '../project/projectInfos';

import generateAllResourceInputs from './Inputs/generateInputs';
import CreateToolbar from './Toolbars/CreateToolbar';

type GeneratedCreateProps = CreateProps;

const GeneratedCreate: React.FC<GeneratedCreateProps> = (
  props: GeneratedCreateProps,
) => {
  const { resource } = props;

  if (!resource) return null;

  const custo = getResourceCustomization(resource as ResourceKey);

  return (
    <Create
      {...props}
      transform={(data: Record): Record => clearDataBeforeSubmit(data)}
    >
      <SimpleForm
        submitOnEnter={false}
        toolbar={<CreateToolbar />}
        initialValues={custo.defaultValues}
      >
        {generateAllResourceInputs(resource as ResourceKey, true)}
      </SimpleForm>
    </Create>
  );
};

export default GeneratedCreate;
