/* eslint-disable no-underscore-dangle */

import { Entity } from 'draft-js';

const getEntityId = node => {
  let entityId;
  if (node instanceof HTMLAnchorElement) {
    const entityConfig = {};
    if (node.dataset && node.dataset.mention !== undefined) {
      entityConfig.url = node.href;
      entityConfig.text = node.innerHTML;
      entityConfig.value = node.dataset.value;
      console.log('htmlToDraft getEntityId Mention', entityConfig);
      entityId = Entity.__create('MENTION', 'IMMUTABLE', entityConfig);
    } else {
      for (let i = 0; i < node.attributes.length; i += 1) {
        const attributeName = node.attributes[i].nodeName;
        entityConfig[attributeName] = node.getAttribute(attributeName);
      }

      entityConfig.url = node.getAttribute
        ? node.getAttribute('href') || node.href
        : node.href;
      delete entityConfig.href;
      entityConfig.title = node.innerHTML;
      entityConfig.targetOption = node.target;
      delete entityConfig.target;
      console.log('htmlToDraft getEntityId Link', entityConfig);
      entityId = Entity.__create('LINK', 'MUTABLE', entityConfig);
    }
  }
  return entityId;
};

export default getEntityId;
