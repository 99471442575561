import { useCallback, useContext } from 'react';
import { DataProviderContext } from 'react-admin';

import { HistoryEntry, useCpretHistory } from './history';
import { CustomDataProvider } from '../dataProvider/type';
import { Guarantee, GuarantyLoan } from '../types/schema';
import { useGuarantyLoanWorkflow } from './guaranty-loans';
import { WFState, WFAction as GuarantyWFAction } from './guaranty-loans-enum';
import { WFAction as GuaranteeWFAction } from './guarantee-enum';
import { clearDataBeforeSubmit } from '../dataProvider/utils';

const useLogAndRefresh = () => {
  const log = useCpretHistory();
  const dataProvider = useContext(DataProviderContext) as CustomDataProvider;

  const cb = useCallback(
    (entry: HistoryEntry, g: Guarantee, wfAction: GuaranteeWFAction) => {
      const action = async () => {
        const { id, actionHistory } = g;
        const { message, ...args } = entry.args;

        // update actionHistory first
        const nextHistory = (actionHistory || []).filter(
          a => a.wfAction !== wfAction,
        );
        nextHistory.push({
          wfAction,
          message,
          args: args && JSON.stringify(args),
          date: new Date().toISOString(),
        });
        const nextData = clearDataBeforeSubmit({
          ...g,
          actionHistory: nextHistory,
        } as Guarantee);
        await dataProvider.update('cpretGuarantee', {
          id,
          previousData: g,
          data: nextData,
        });

        // log
        const result = await log(entry, [{ __typename: 'Guarantee', id }]);

        // force guarantee refresh
        dataProvider.observeOne('cpretGuarantee', { id });

        return result;
      };
      return action();
    },
    [log, dataProvider],
  );

  return cb;
};

export type GuaranteeDissociation = (
  /** Gurarantee */
  guarantee: Guarantee,
  /** Log arguments - action dependent */
  logArgs?: HistoryEntry['args'],
) => Promise<void>;

export const useGuaranteeDissociation = () => {
  //   const [state, setState] = useState<LedgerActionState>({ loading: false });
  const logAndRefresh = useLogAndRefresh();
  const cb = useCallback<GuaranteeDissociation>(
    (guarantee, logArgs) => {
      const action = async () => {
        if (logArgs)
          logAndRefresh(
            { message: 'cpret.workflow.guarantee.dissociation', args: logArgs },
            guarantee,
            GuaranteeWFAction.DISSOCIATION,
          );
      };
      return action();
    },
    [logAndRefresh],
  );
  return cb;
};

export type GuaranteeTransfer = (
  /** Gurarantee */
  guarantee: Guarantee,
  /** Log arguments - action dependent */
  logArgs?: HistoryEntry['args'],
) => Promise<void>;

export const useGuaranteeTransfer = () => {
  const logAndRefresh = useLogAndRefresh();
  const cb = useCallback<GuaranteeTransfer>(
    (guarantee, logArgs) => {
      const action = async () => {
        if (logArgs)
          logAndRefresh(
            { message: 'cpret.workflow.guarantee.transfer', args: logArgs },
            guarantee,
            GuaranteeWFAction.TRANSFER,
          );
      };
      return action();
    },
    [logAndRefresh],
  );
  return cb;
};

export type GuaranteeCall = (
  /** Gurarantee */
  guarantee: Guarantee,
  /** Log arguments - action dependent */
  logArgs?: HistoryEntry['args'],
) => Promise<void>;
export const useGuaranteeCall = () => {
  const logAndRefresh = useLogAndRefresh();
  const [workflow] = useGuarantyLoanWorkflow();

  const cb = useCallback<GuaranteeCall>(
    (guarantee, logArgs) => {
      const action = async () => {
        const { loans } = guarantee;
        const ll =
          loans?.edges
            .filter(e => (e.node as GuarantyLoan).wfStatus === WFState.GESTION)
            .map(({ node }) => node as GuarantyLoan) || [];

        await ll.reduce(async (prev, l) => {
          await prev;
          await workflow({ id: l.id, wfAction: GuarantyWFAction.GARANTIE });
        }, Promise.resolve());

        if (logArgs)
          logAndRefresh(
            { message: 'cpret.workflow.guarantee.call', args: logArgs },
            guarantee,
            GuaranteeWFAction.CALL,
          );
      };
      return action();
    },
    [logAndRefresh, workflow],
  );
  return cb;
};

export type GuaranteeDenunciation = (
  /** Gurarantee */
  guarantee: Guarantee,
  confirm: boolean,
  /** Log arguments - action dependent */
  logArgs?: HistoryEntry['args'],
) => Promise<void>;
export const useGuaranteeDenunciation = () => {
  const logAndRefresh = useLogAndRefresh();
  const dataProvider = useContext(DataProviderContext) as CustomDataProvider;

  const cb = useCallback<GuaranteeDenunciation>(
    (guarantee, confirm, logArgs) => {
      const action = async () => {
        const { loans } = guarantee;
        const ll =
          loans?.edges
            .filter(({ node }) => {
              const g = node as GuarantyLoan;
              return (
                g.wfStatus === WFState.GESTION ||
                g.wfStatus === WFState.RECOUVREMENT
              );
            })
            .map(e => e.node as GuarantyLoan) || [];
        if (confirm && logArgs?.date) {
          await ll.reduce(async (prev, l) => {
            await prev;
            const nextData = clearDataBeforeSubmit({
              ...l,
              // Schedule action in the future. Simply add for now
              actionSchedule: [
                ...(l.actionSchedule || []),
                { wfAction: GuarantyWFAction.CLOTURE, date: logArgs.date },
              ],
            } as GuarantyLoan);
            await dataProvider.update('cpretGuarantyLoan', {
              id: l.id,
              previousData: l,
              data: nextData,
            });
          }, Promise.resolve());
        } else {
          // do something else ?
          // TODO - dataProvider.update('cpretGuarantee', );
          // next: clearDataBeforeSubmit({ ...guarantee, nonDenunciation: true });
          // prev: guarantee
        }
        if (logArgs)
          logAndRefresh(
            { message: 'cpret.workflow.guarantee.denunciation', args: logArgs },
            guarantee,
            GuaranteeWFAction.DENUNCIATION,
          );
      };
      return action();
    },
    [logAndRefresh, dataProvider],
  );
  return cb;
};
