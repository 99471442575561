import React, { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import { SimpleCms } from 'phicomas-client';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import AddButton from './AddButton'; // eslint-disable-line import/no-cycle
import RenderComponent from './RenderComponent'; // eslint-disable-line import/no-cycle
import { ResourceKey } from '../../../project/projectInfos';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    addButton: {
      '&:last-child': {
        transform: `translateY(${theme.spacing(0.5)}px)`,
      },
    },
    firstAddButton: {
      transform: `translateY(${-theme.spacing(0.5)}px)`,
    },
  }),
);

type ComponentsProps = {
  resource: ResourceKey;
  parent?: SimpleCms.PageComponentType;
  components: SimpleCms.PageComponents;
  setComponents: (newValue: SimpleCms.PageComponents) => void;
};

const Components: React.FC<ComponentsProps> = ({
  resource,
  parent,
  components,
  setComponents,
}: ComponentsProps) => {
  const classes = useStyles();

  /** Add a component at `position` */
  const handleAddComponent = useCallback(
    (position: number, componentType: SimpleCms.PageComponentType) => {
      const newComponents: SimpleCms.PageComponents = [...components];
      newComponents.splice(position, 0, {
        type: componentType,
        componentProps: {},
        key: uuid(),
      } as SimpleCms.PageComponentInfos);
      setComponents(newComponents);
    },
    [components, setComponents],
  );

  /** Update a component's props (from it's `position`) */
  const setComponentProps = useCallback(
    (
      position: number,
      newProps: SimpleCms.PageComponentInfos['componentProps'],
    ) => {
      const component = { ...components[position] };
      const newComponents: SimpleCms.PageComponents = [...components];
      newComponents.splice(position, 1, {
        ...component,
        componentProps: newProps,
      } as SimpleCms.PageComponentInfos);
      setComponents(newComponents);
    },
    [components, setComponents],
  );

  /** Remove a component at `position` */
  const handleRemoveComponent = useCallback(
    (position: number) => {
      const newComponents: SimpleCms.PageComponents = [...components];
      newComponents.splice(position, 1);
      setComponents(newComponents);
    },
    [components, setComponents],
  );

  return (
    <>
      <AddButton
        position={0}
        onAdd={handleAddComponent}
        className={clsx(classes.addButton, classes.firstAddButton)}
        parent={parent}
      />
      {components.map(({ type, componentProps, key }, index) => {
        let k = key;
        if (!k) {
          k = uuid();
          components[index].key = k;
        }
        return (
          <React.Fragment key={k}>
            <RenderComponent
              resource={resource}
              type={type}
              componentProps={componentProps}
              setComponentProps={setComponentProps}
              position={index}
              onRemove={handleRemoveComponent}
            />
            <AddButton
              position={index + 1}
              onAdd={handleAddComponent}
              className={classes.addButton}
              parent={parent}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default Components;
