import { useTranslate } from 'ra-core';
import { useCallback } from 'react';

import _upperFirst from 'lodash/upperFirst';
import _lowerCase from 'lodash/lowerCase';
import _startCase from 'lodash/startCase';

// eslint-disable-next-line import/prefer-default-export
export function useTranslateIf(): ReturnType<typeof useTranslate> {
  const translate = useTranslate();

  return useCallback(
    (key: string, options?: any): string => {
      const keySource = key.split('.').pop();
      const smartCount =
        typeof options === 'number' ? options : options?.smart_count ?? 1;
      let translation = translate(key, {
        _: '',
        ...options,
        smart_count: smartCount,
      });
      if (!translation) {
        console.warn(`Missing translation for key: "${key}"`);
        translation = _upperFirst(_lowerCase(_startCase(keySource)));
      }
      return translation;
    },
    [translate],
  );
}
