import AWS from 'aws-sdk';
import React, { useCallback, useMemo, useState } from 'react';
import {
  DeleteButton,
  Record,
  SaveButton,
  Toolbar as RaToolbar,
  ToolbarProps as RaToolbarProps,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useField, useForm } from 'react-final-form';
import clsx from 'clsx';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, WithWidthProps } from '@material-ui/core';
import ContentSave from '@material-ui/icons/Save';
import ContentSaved from '@material-ui/icons/Check';
import ContentError from '@material-ui/icons/Error';

import { UPLOAD_FIELD } from '../Fields/asset/AssetDropzone';

import { useTranslateIf } from '../../hooks/use-translate-if';
import projectInfos, {
  getResourceCustomization,
  ResourceKey,
} from '../../project/projectInfos';

import { useCreateAsset, useUpdateAsset } from '../../dataProvider/useAsset';
import ContainerForward from '../../lib/ContainerForward';
import { Asset } from '../../types/schema';
import { useCircularStyles } from '../layout/Loading';

const useToolbarStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      flex: '0 0 auto',
      position: 'relative',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    icon: {
      fontSize: 18,
    },
  }),
);

export type BaseToolbarProps = RaToolbarProps &
  WithWidthProps & {
    onCancel?: () => void;
    onStandaloneSuccess?: (response: { data: Record }) => void;
  } & {
    resource?: ResourceKey;
  };

type ToolbarProps = BaseToolbarProps & {
  kind: 'create' | 'edit';
};
const Toolbar: React.FC<ToolbarProps> = ({
  kind,
  onCancel,
  onStandaloneSuccess,
  ...props
}: ToolbarProps) => {
  const translate = useTranslateIf();
  const notify = useNotify();
  const classes = useToolbarStyles();

  const form = useForm();
  const fileField = useField(UPLOAD_FIELD);
  const file = useMemo(() => {
    const fileTmp = fileField.input.value;
    return fileTmp instanceof File ? fileTmp : undefined;
  }, [fileField.input.value]);
  const redirectTo = useRedirect();
  const createAsset = useCreateAsset('borrower');
  const updateAsset = useUpdateAsset();

  const { resource, record, basePath, redirect } = props;
  const saveClasses = useCircularStyles();

  const [uploadProgress, setUploadProgress] = useState<number | null>(null);

  const handleProgress = useCallback(
    (progress: AWS.S3.ManagedUpload.Progress) => {
      const { loaded, total } = progress;
      setUploadProgress((loaded / total) * 100);
    },
    [],
  );

  const handleSubmitUpload = useCallback(async () => {
    if (resource) {
      if (!file) {
        form.submit();
      } else if (kind === 'create') {
        const { values } = form.getState();
        setUploadProgress(0);
        try {
          const response = await createAsset({
            file,
            values,
            progressCallback: handleProgress,
            prefix: 'sncf/fixme',
          });
          setUploadProgress(100);
          if (onStandaloneSuccess) {
            onStandaloneSuccess(response);
          }
          if (basePath && redirect === 'edit') {
            redirectTo(`${basePath}/${response.data.id}`);
          }
        } catch (error) {
          setUploadProgress(-1);
          if (error instanceof Error) {
            notify(error.message, 'error');
          }
        }
      } else if (kind === 'edit') {
        if (record) {
          setUploadProgress(0);
          try {
            await updateAsset({
              file,
              asset: record as Asset,
              progressCallback: handleProgress,
              prefix: 'sncf/fixme',
            });
          } catch (error) {
            setUploadProgress(-1);
            if (error instanceof Error) {
              notify(error.message, 'error');
            }
          }
          setUploadProgress(100);
        }
        form.submit();
      }
    }
  }, [
    resource,
    file,
    kind,
    form,
    createAsset,
    handleProgress,
    onStandaloneSuccess,
    basePath,
    redirect,
    redirectTo,
    notify,
    record,
    updateAsset,
  ]);

  if (!resource) return null;

  const { assetResource } = projectInfos;

  const isAsset = resource === assetResource;

  const custo = getResourceCustomization(resource);

  return (
    <RaToolbar {...props} className={classes.root}>
      {isAsset ? (
        <>
          <Button
            onClick={handleSubmitUpload}
            color="primary"
            variant="contained"
            disabled={uploadProgress !== null}
            className={classes.button}
          >
            {uploadProgress === null && (
              <ContentSave className={clsx(classes.leftIcon, classes.icon)} />
            )}
            {uploadProgress !== null &&
              uploadProgress >= 0 &&
              uploadProgress < 100 && (
                <CircularProgress
                  size={18}
                  thickness={uploadProgress === 0 ? 2 : 4}
                  className={classes.leftIcon}
                  variant={
                    uploadProgress === 0 ? 'indeterminate' : 'determinate'
                  }
                  value={uploadProgress}
                />
              )}
            {uploadProgress === 100 && (
              <ContentSaved className={clsx(classes.leftIcon, classes.icon)} />
            )}
            {uploadProgress === -1 && (
              <ContentError className={clsx(classes.leftIcon, classes.icon)} />
            )}
            {file
              ? translate('action.upload_and_save')
              : translate('ra.action.save')}
          </Button>
        </>
      ) : (
        <SaveButton classes={saveClasses} />
      )}
      <ContainerForward>
        {onCancel && (
          <>
            <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
          </>
        )}
        {kind === 'edit' && custo.canCreateDelete && <DeleteButton />}
      </ContainerForward>
    </RaToolbar>
  );
};

export default Toolbar;
