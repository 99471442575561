const inlineTags = {
  code: 'CODE',
  del: 'STRIKETHROUGH',
  em: 'ITALIC',
  strong: 'BOLD',
  ins: 'UNDERLINE',
  sub: 'SUBSCRIPT',
  sup: 'SUPERSCRIPT',
};

export default function processInlineTag(tag, node, currentStyle) {
  const styleToCheck = inlineTags[tag];
  let inlineStyle;
  if (styleToCheck) {
    inlineStyle = currentStyle.add(styleToCheck).toOrderedSet();
  } else if (node instanceof HTMLElement) {
    inlineStyle = currentStyle;
    const htmlElement = node;
    inlineStyle = inlineStyle
      .withMutations(style => {
        const {
          color,
          backgroundColor,
          fontSize,
          fontFamily: fontFamilyRaw,
          fontWeight,
          textDecoration,
          fontStyle,
        } = htmlElement.style;
        const fontFamily = fontFamilyRaw.replace(/^"|"$/g, '');
        if (color) {
          style.add(`color-${color.replace(/ /g, '')}`);
        }
        if (backgroundColor) {
          style.add(`bgcolor-${backgroundColor.replace(/ /g, '')}`);
        }
        if (fontSize) {
          style.add(`fontsize-${fontSize.replace(/px$/g, '')}`);
        }
        if (fontFamily) {
          style.add(`fontfamily-${fontFamily}`);
        }
        if (fontWeight === 'bold') {
          style.add(inlineTags.strong);
        }
        if (textDecoration === 'underline') {
          style.add(inlineTags.ins);
        }
        if (fontStyle === 'italic') {
          style.add(inlineTags.em);
        }
      })
      .toOrderedSet();
  }
  return inlineStyle;
}
