import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Theme,
  Typography,
  DialogActions,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import DownloadIcon from '@material-ui/icons/GetApp';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { addMonths, format } from 'date-fns';

import { OccurenceField } from './OccurenceField';
import { IndexedSchedule } from '../types';
import { ScheduleType } from '../../../../types/schema';
import { AgnosticSelectField } from '../../../../components/Fields/agnostic/AgnosticSelectField';
import { AgnosticTextField } from '../../../../components/Fields/agnostic/AgnosticTextField';
import { useExportSchedule } from '../../../../workflow/exports-schedule';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      padding: 0,
      height: '100%',
      overflow: 'hidden',
    },
    labelContainer: {
      flex: 3,
    },
    typeContainer: {
      flex: 1,
    },
    type: {
      marginLeft: theme.spacing(1),
    },
    topContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      '& > *': {
        flexGrow: 1,
        flexBasis: '0%',
        padding: theme.spacing(1),
      },
    },
    tableContainer: {
      height: '80vh',
      paddingBottom: '150px',
    },
    titleRoot: {
      margin: 0,
      padding: theme.spacing(2),
    },
    exportButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
      color: theme.palette.grey[600],
    },
  }),
);

type EditScheduleDialogProps = {
  onCommit: (schedule: IndexedSchedule) => void;
  onDelete: () => void;
  onRollback: () => void;
  schedule: IndexedSchedule;
};

export const EditScheduleDialog: React.FC<EditScheduleDialogProps> = ({
  onCommit,
  onDelete,
  onRollback,
  schedule,
}) => {
  const classes = useStyles();
  const exportSchedule = useExportSchedule(schedule);

  return (
    <Form
      onSubmit={() => {}}
      initialValues={schedule}
      mutators={{ ...arrayMutators }}
    >
      {({ values, pristine }) => (
        <Dialog fullWidth open onClose={() => onRollback()} maxWidth="lg">
          <DialogTitle disableTypography className={classes.titleRoot}>
            <Typography variant="h6">Edition d'un échéancier</Typography>
            <Button
              size="small"
              className={classes.exportButton}
              label="Exporter"
              onClick={exportSchedule}
            >
              <DownloadIcon />
            </Button>
          </DialogTitle>

          <FieldArray name="payments">
            {({ fields }) => {
              return (
                <>
                  <DialogContent classes={{ root: classes.dialogContent }}>
                    <div className={classes.topContainer}>
                      <div className={classes.labelContainer}>
                        <AgnosticTextField
                          name="label"
                          fullWidth
                          label="Motif"
                        />
                      </div>
                      <div className={classes.typeContainer}>
                        <AgnosticSelectField
                          label="Type"
                          name="type"
                          options={[
                            { value: ScheduleType.CAPITAL, label: 'Primaire' },
                            { value: ScheduleType.DEBT, label: 'Secondaire' },
                          ]}
                          fullWidth
                        />
                      </div>
                    </div>
                    <TableContainer
                      component={Paper}
                      className={classes.tableContainer}
                    >
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Date d'échéance</TableCell>
                            <TableCell>Exécuté</TableCell>
                            <TableCell>Montant échéance</TableCell>
                            <TableCell>Capital remboursé</TableCell>
                            <TableCell>Intérêt remboursé</TableCell>
                            <TableCell>Capital restant</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fields.map((name, index) => (
                            <OccurenceField
                              source={name}
                              index={index}
                              key={name}
                              onDelete={() => fields.remove(index)}
                              defaultEditable={fields.value[index].isNewRecord}
                            />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="secondary"
                      size="medium"
                      variant="contained"
                      label="Supprimer"
                      onClick={onDelete}
                    >
                      <DeleteIcon />
                    </Button>
                    <Button
                      color="default"
                      variant="contained"
                      size="medium"
                      label="Ajouter un paiement"
                      onClick={() => {
                        const lastPayment =
                          fields.value[fields.value.length - 1];
                        const dueDate = format(
                          lastPayment
                            ? addMonths(new Date(lastPayment.dueDate), 1)
                            : new Date(),
                          'yyyy-MM-dd',
                        );

                        fields.push({
                          dueDate,
                          done: false,
                          principalAmount: 0,
                          interestAmount: 0,
                          isNewRecord: true,
                        });
                      }}
                    >
                      <AddIcon />
                    </Button>
                    <Button
                      color="default"
                      variant="contained"
                      size="medium"
                      label="Annuler"
                      onClick={onRollback}
                      type="button"
                    />
                    <Button
                      disabled={pristine}
                      color="primary"
                      size="medium"
                      variant="contained"
                      label="Valider"
                      onClick={() => onCommit(values)}
                    >
                      <DoneIcon />
                    </Button>
                  </DialogActions>
                </>
              );
            }}
          </FieldArray>
        </Dialog>
      )}
    </Form>
  );
};
