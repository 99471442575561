import React, { useCallback } from 'react';
import { AppBarProps, HideOnScroll } from 'react-admin';
import { toggleSidebar, useTranslate } from 'ra-core';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { Level } from 'phicomas-client';

import {
  AppBar as MuiAppBar,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

import CustomUserMenu from './CustomUserMenu';
import { useKeyPressed } from '../../hooks/use-key-pressed';
import { useEnvironmentContext } from '../../context/environmentContext';
import { availableEnvironments } from '../../project/projectsEnvironments';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    toolbar: {
      paddingRight: 24,
      background: `repeating-linear-gradient(
        130deg,
        transparent -10px,
        transparent 40px,
        rgba(0, 0, 0, 0.2) 60px,
        rgba(0, 0, 0, 0.2) 160px,
        transparent 180px,
        transparent 230px
      )`,
    },
    toolbarEnvironmentDev: {
      backgroundColor: theme.palette.success.main,
    },
    toolbarEnvironmentStage: {
      backgroundColor: theme.palette.specials.cpretBlue,
    },
    toolbarEnvironmentProd: {
      backgroundColor: theme.palette.specials.cpretDarkBlue,
    },
    menuButton: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    title: {
      flex: '1 1 auto',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    environmentSelect: {
      flex: '0 1 auto',
      minWidth: 0,
      width: 200,
      borderRadius: 0,
      marginRight: theme.spacing(2),
      backgroundColor: 'rgba(255, 255, 255, 0.75)',
      alignSelf: 'stretch',
      fontWeight: theme.typography.fontWeightBold as any,
    },
    environmentDev: {
      color: theme.palette.success.main,
    },
    environmentStage: {
      color: theme.palette.warning.main,
    },
    environmentProd: {
      color: theme.palette.error.main,
    },
    environmentSelectSelect: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    environmentSelectHiddenItem: {
      opacity: 0.5,

      '&:not($environmentSelectHiddenItemShow)': {
        display: 'none',
      },
    },
    environmentSelectHiddenItemShow: {},
    userMenu: {
      flex: '0 1 auto',
      [theme.breakpoints.down('xs')]: {
        flex: '0 0 auto',
      },
    },
  }),
);

const CustomAppBar: React.FC<AppBarProps> = (props: AppBarProps) => {
  const {
    children,
    classes: classesOverride,
    className,
    color = 'secondary',
    logout,
    open,
    title,
    userMenu,
    ...rest
  } = props;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
  const translate = useTranslate();

  const [environment, setEnvironment] = useEnvironmentContext();

  const handleChangeEnvironment = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
    ) => {
      setEnvironment(event.target.value as Level);
    },
    [setEnvironment],
  );

  const ctrlPressed = useKeyPressed('Control');

  return (
    <HideOnScroll>
      <MuiAppBar className={className} color={color} {...rest}>
        <Toolbar
          disableGutters
          variant={isXSmall ? 'regular' : 'dense'}
          className={clsx(classes.toolbar, {
            [classes.toolbarEnvironmentDev]: environment === Level.DEV,
            [classes.toolbarEnvironmentStage]: environment === Level.STAGE,
            [classes.toolbarEnvironmentProd]: environment === Level.PROD,
          })}
        >
          <Tooltip
            title={translate(
              open ? 'ra.action.close_menu' : 'ra.action.open_menu',
              {
                _: 'Open/Close menu',
              },
            )}
            enterDelay={500}
          >
            <IconButton
              color="inherit"
              onClick={(): void => {
                dispatch(toggleSidebar());
              }}
              className={clsx(classes.menuButton)}
            >
              <MenuIcon
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
          </Tooltip>
          {(availableEnvironments.length > 1 || !environment) && (
            <Select
              className={clsx(classes.environmentSelect, {
                [classes.environmentDev]: environment === Level.DEV,
                [classes.environmentStage]: environment === Level.STAGE,
                [classes.environmentProd]: environment === Level.PROD,
              })}
              value={environment}
              onChange={handleChangeEnvironment}
              variant="outlined"
              color="primary"
              classes={{ select: classes.environmentSelectSelect }}
            >
              {availableEnvironments.map(env => (
                <MenuItem
                  key={env.value}
                  value={env.value}
                  className={clsx({
                    [classes.environmentSelectHiddenItem]: env.hidden,
                    [classes.environmentSelectHiddenItemShow]:
                      (env.hidden && ctrlPressed) || environment === env.value,
                  })}
                >
                  {env.value}
                </MenuItem>
              ))}
            </Select>
          )}
          {React.Children.count(children) === 0 ? (
            <Typography
              variant="h6"
              color="inherit"
              className={classes.title}
              id="react-admin-title"
            />
          ) : (
            children
          )}
          <CustomUserMenu className={classes.userMenu} logout={logout} />
        </Toolbar>
      </MuiAppBar>
    </HideOnScroll>
  );
};

export default CustomAppBar;
