export function isNonConnectedObject(
  data: unknown,
): data is Record<string, any> {
  return (
    typeof data === 'object' &&
    !!data &&
    !Array.isArray(data) &&
    !('edges' in data) &&
    !('connect' in data) &&
    !('disconnect' in data)
  );
}
