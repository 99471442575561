import React from 'react';

export type ImageElProps = {
  src?: string;
  [k: string]: any;
};

/** Simply render an image given it's props */
const ImageEl: React.FC<ImageElProps> = (props: ImageElProps) => {
  const { src } = props;
  if (!src) return null;

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...props} />;
};

export default ImageEl;
