import React from 'react';
import { useFormState } from 'react-final-form';

import { useLoanMaxPackages } from './useLoanMaxPackages';

export interface LoanPackages {
  estrangement: number;
  teaching: number;
  maintenance: number;
  invalid: boolean;
}

export function useLoanPackages(): LoanPackages {
  const {
    values: { cptFraisLoyer = 0, cptAPL = 0, cptFraisScolarite = 0 },
  } = useFormState();
  const packages = useLoanMaxPackages();

  const estrangement = React.useMemo(
    () =>
      packages ? Math.min(cptFraisLoyer - cptAPL, packages.estrangement) : 0,
    [packages, cptFraisLoyer, cptAPL],
  );
  const teaching = React.useMemo(
    () => (packages ? Math.min(cptFraisScolarite, packages.teaching) : 0),
    [packages, cptFraisScolarite],
  );
  const maintenance = React.useMemo(
    () => packages?.maintenance || 0,
    [packages],
  );

  return { estrangement, teaching, maintenance, invalid: !packages };
}
