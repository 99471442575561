import React from 'react';
import { Filter, FilterProps } from 'react-admin';

import {
  getResourceCustomization,
  getResourceInfos,
  ResourceKey,
} from '../project/projectInfos';
// Cycle is not an issue here
import generateInput from './Inputs/generateInput'; // eslint-disable-line import/no-cycle

type GeneratedFiltersProps = Omit<FilterProps, 'children'> & {
  resource: ResourceKey;
};
const GeneratedFilters: React.FC<GeneratedFiltersProps> = ({
  resource,
  ...filterProps
}: GeneratedFiltersProps) => {
  const { type } = getResourceInfos(resource);
  const { fields } = type;

  if (!fields) return null;

  const resourceCusto = getResourceCustomization(resource);

  const { fields: filterFields } = resourceCusto.list?.filter ?? {};
  if (!filterFields) return null;

  return (
    <Filter {...filterProps}>
      {filterFields.map(({ name, alwaysOn }) => {
        const field = fields?.find(({ name: n }) => n && n === name);
        if (!field) {
          // Field described in custo not found in resourceType
          // => Error in project's custo
          throw Error(
            `field [${name}] defined in resource [${resource}] customization filter have not been found`,
          );
        }
        return generateInput(resource, field, {
          asFilter: true,
          resettable: true,
          alwaysOn,
        });
      })}
    </Filter>
  );
};

export default GeneratedFilters;
