import _omit from 'lodash/omit';

const omittedPropnames = [
  'addLabel',
  'allowEmpty',
  'basePath',
  'cellClassName',
  'className',
  'emptyText',
  'formClassName',
  'headerClassName',
  'label',
  'linkType',
  'link',
  'locale',
  'record',
  'resource',
  'sortable',
  'sortBy',
  'source',
  'textAlign',
  'translateChoice',
] as const;

type OmittedProps = typeof omittedPropnames[number];

export default function sanitizeRestProps<Props extends { [k: string]: any }>(
  props: Props,
): Omit<Props, OmittedProps> {
  return _omit(props, omittedPropnames);
}
