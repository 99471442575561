import React from 'react';
import { useTranslate } from 'ra-core';
// import YAML from 'yaml';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Card, CardHeader, CardContent } from '@material-ui/core';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

import { History } from '../types/schema';
import { centsToEur, shortId } from '../lib/utils';
import { currencyOptions as options } from '../lib/ledger';
import { LedgerAccount } from '../dataProvider/type';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      '& + &': {
        marginTop: theme.spacing(1),
      },
    },
  }),
);

export interface HistoryNodeProps {
  node: History;
}

export const getAccountLabel = (number: any) => {
  switch (number) {
    case LedgerAccount.USER_ACCOUNT:
      return 'AGENT';
    case LedgerAccount.PRINCIPAL_ACCOUNT:
      return 'PRÊT';
    case LedgerAccount.INTEREST_ACCOUNT:
      return 'INTERÊTS';
    case LedgerAccount.ROUNDING_ACCOUNT:
      return 'ARRONDIS';
    case LedgerAccount.LOSSES_ACCOUNT:
      return 'CRÉANCES';
    case LedgerAccount.BANK_ACCOUNT:
      return 'BANQUE';
    default:
      return 'Pas de compte trouvé';
  }
};

export const HistoryNode: React.FC<HistoryNodeProps> = ({ node }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const date = format(new Date(node.createdAt), 'EEE d MMM yyyy - HH:mm', {
    locale: fr,
  });
  const args: { [k: string]: any } = React.useMemo(() => {
    if (!node.args) return {};
    try {
      const res = JSON.parse(node.args);
      // res.identity = node.identity; // we could add it to arguments in the future
      Object.keys(res).forEach(k => {
        if (['amount', 'pPmt', 'iPmt'].includes(k)) {
          // special currency args
          res[k] = centsToEur(res[k] || 0).toLocaleString(undefined, options);
        }
        if (k === 'loanId' && res[k]) {
          res[k] = shortId(res[k]);
        }
        if ((k === 'sourceAccount' || k === 'targetAccount') && res[k]) {
          res[k] = getAccountLabel(res[k]);
        }
        if (k === 'date') {
          res[k] = format(new Date(res[k]), 'dd.MM.yyyy');
        }
        if (typeof res[k] === 'boolean') {
          res[k] = res[k] ? 'OUI' : 'NON';
        }
      });
      return res;
    } catch (e) {
      return {};
    }
  }, [node]);

  const msg = node.message && translate(node.message, args);

  return (
    <Card className={classes.root}>
      <CardHeader title={date} subheader={node.identity || 'Anonymous'} />
      <CardContent>
        <Typography component="div">
          {msg && msg?.split(/\//).map(s => <div key={s}>{s}</div>)}
        </Typography>
        {/* node.args && <pre>{YAML.stringify(args)}</pre> */}
      </CardContent>
    </Card>
  );
};
