import AWS from 'aws-sdk';
import { ManagedUpload } from 'aws-sdk/clients/s3';
import { Level } from 'phicomas-client';

import projectInfos from '../project/projectInfos';

import { awsCreds, level } from '../auth/authProvider';
import { Asset } from '../types/schema';

const encodeRFC5987ValueChars = (str: string): string =>
  encodeURIComponent(str)
    // Note that although RFC3986 reserves "!", RFC5987 does not, so we do not need to escape it
    .replace(/['()]/g, escape) // i.e., %27 %28 %29
    .replace(/\*/g, '%2A')
    // The following are not required for percent-encoding per RFC5987, so we can allow for a little better readability over the wire: |`^
    .replace(/%(?:7C|60|5E)/g, unescape);

export const getS3DownloadUrl = (
  key: string,
  {
    inline = false,
    asset,
  }: { inline?: boolean; asset?: Pick<Asset, 'filename'> } = {},
): Promise<string> => {
  const { s3: s3Raw } = projectInfos.awsExports;
  const s3 = s3Raw?.[level];
  if (!s3) {
    throw Error(`No S3 config detected in project`);
  }
  const S3 = new AWS.S3({
    apiVersion: s3.apiVersion,
    region: s3.region,
    credentials: awsCreds,
  });
  const params: AWS.S3.Types.GetObjectRequest = {
    Bucket: s3.bucket,
    Key: key,
  };
  if (inline) {
    params.ResponseContentDisposition = 'inline';
  } else if (asset?.filename) {
    params.ResponseContentDisposition = `attachment; filename*=UTF-8''${encodeRFC5987ValueChars(
      asset.filename,
    )}`;
  }

  return new Promise(resolve => {
    S3.getSignedUrl('getObject', params, (error, res) => {
      resolve(res);
    });
  });
};

/**
 *
 * @param key full key (namespace prefix **must** be provided)
 * @param options
 * @returns
 */
export const downloadFromS3 = (
  key: string,
): Promise<AWS.S3.GetObjectOutput> => {
  const { s3: s3Raw } = projectInfos.awsExports;
  const s3 = s3Raw?.[level];
  if (!s3) {
    throw Error(`No S3 config detected in project`);
  }
  const S3 = new AWS.S3({
    apiVersion: s3.apiVersion,
    region: s3.region,
    credentials: awsCreds,
  });
  const params: AWS.S3.Types.GetObjectRequest = {
    Bucket: s3.bucket,
    Key: key,
  };

  const rq = S3.getObject(params);

  return rq.promise().then(r => {
    const { data, error } = r.$response;
    if (error) throw error;
    if (!data) throw new Error('Missing data');
    return data;
  });
};

/**
 * Upload a file to S3 and get it's key back
 * @param envOption Determines s3 configuration (bucket, region)
 * @param file the actual file
 * @param asset asset resource
 */
export const uploadToS3 = async (
  { environmentLevel }: { environmentLevel: Level },
  file: File,
  asset: Partial<Asset>,
  options: {
    progressCallback?: (progress: AWS.S3.ManagedUpload.Progress) => void;
    needToAbort?: boolean;
  } = {},
): Promise<{ key: string; upload: Promise<ManagedUpload.SendData> }> => {
  const {
    awsExports: { s3: s3Raw },
  } = projectInfos;
  const s3 = s3Raw?.[environmentLevel];
  if (!s3) {
    throw Error(`No S3 config detected in project`);
  }
  const { progressCallback, needToAbort = false } = options;

  const S3 = new AWS.S3({
    apiVersion: s3.apiVersion,
    region: s3.region,
    credentials: awsCreds,
  });

  const lastModified: File['lastModified'] =
    file.lastModified || (file as any).lastModifedDate;
  const mtime = lastModified && new Date(lastModified).toISOString();

  const metadata: { [k: string]: string } = {};
  if (mtime) metadata.mtime = mtime;

  const { key, mimeType, filename } = asset;
  if (!key || !mimeType || !filename)
    throw new Error('Missing asset information');
  const upload = S3.upload(
    {
      Bucket: s3.bucket,
      Key: key,
      // StorageClass: 'REDUCED_REDUNDANCY', // keep by default for now
      Body: file,
      CacheControl: 'max-age=86400',
      ContentType: mimeType,
      ContentDisposition: `attachment; filename*=UTF-8''${encodeRFC5987ValueChars(
        filename,
      )}`,
      Metadata: metadata,
      ACL: 'bucket-owner-full-control',
    },
    { partSize: 5 * 1024 * 1024, queueSize: 4 },
  );

  if (progressCallback) upload.on('httpUploadProgress', progressCallback);
  if (needToAbort) upload.abort.bind(upload);

  return { key, upload: upload.promise() };
};
