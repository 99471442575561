import { get } from 'lodash';
import React from 'react';
import { FunctionField } from 'react-admin';

import { Schedule, SchedulePayment } from '../../../../types/schema';

export interface PaymentStatusProps {
  // eslint-disable-next-line react/no-unused-prop-types
  label: string;
}

export const PaymentStatus: React.FC<PaymentStatusProps> = () => {
  const render = React.useCallback((record?: Schedule) => {
    const payments = get(record, ['payments'], null) as
      | SchedulePayment[]
      | null;

    const lastPayment = payments ? payments[payments.length - 1] : null;
    const firstPayment = payments ? payments[0] : null;

    if (!lastPayment || !firstPayment) {
      return '';
    }

    const now = new Date();
    if (
      new Date(firstPayment.dueDate) < now &&
      now < new Date(lastPayment.dueDate)
    ) {
      return 'En cours';
    }

    if (now > new Date(lastPayment.dueDate)) {
      return 'Terminé';
    }

    return 'En attente';
  }, []) as any;

  return <FunctionField render={render} />;
};
