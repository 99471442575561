import React from 'react';

import { findMaxPackage } from './loan-packages';
import { useFamilyQuotient } from './useFamilyQuotient';

export function useLoanMaxPackages() {
  const familyQuotient = useFamilyQuotient();
  const packages = React.useMemo(
    () => findMaxPackage(familyQuotient),
    [familyQuotient],
  );

  return packages;
}
