import AWS from 'aws-sdk';
import { Level } from 'phicomas-client';
import {
  DataProviderContext,
  DataProviderProxy,
  Record,
  useDataProvider,
} from 'ra-core';
import { useCallback, useContext } from 'react';

import { uploadToS3 } from '../aws/s3-utils';
import { useEnvironmentContext } from '../context/environmentContext';
import { ThenArg } from '../types/utils';
import { clearDataBeforeSubmit } from './utils';

import { ResourceKey } from '../project/projectInfos';
import { Asset, Scalars } from '../types/schema';
import { CustomDataProvider } from './type';

async function uploadAndUpdate({
  environmentLevel,
  file,
  asset,
  progressCallback,
  dataProvider,
  prefix,
  assetResource = 'cpretAsset',
}: {
  asset: Partial<Asset> & { id: Scalars['ID'] };
  assetResource: ResourceKey;
  file: File;
  dataProvider: DataProviderProxy;
  environmentLevel: Level;
  progressCallback?: (progress: AWS.S3.ManagedUpload.Progress) => void;
  prefix: string;
}) {
  let uploadResult: ThenArg<ReturnType<typeof uploadToS3>>;
  // if the asset key is not set, we generate it from the asset id
  const newAsset: Partial<Asset> & { id: Scalars['ID'] } = {
    mimeType: file.type,
    filename: file.name,
    ...asset,
    key: asset.key || `${prefix}/${asset.id.slice(0, 3)}/${asset.id}`,
    size: asset.size || file.size, // also update size but not filename or mimeType for now
  };
  try {
    uploadResult = await uploadToS3({ environmentLevel }, file, newAsset, {
      progressCallback,
    });
    await uploadResult.upload;
  } catch (e: any) {
    // dataProvider.delete<Asset>(resource, {
    //   id: asset.id,
    //   previousData: asset,
    // });
    throw new Error(
      `Une erreur s'est produite lors de l'envoi de votre fichier, merci de contacter un administrateur. "${e}"`,
    );
  }

  return dataProvider.update<Asset>(assetResource, {
    id: asset.id,
    previousData: asset,
    data: clearDataBeforeSubmit(newAsset),
  });
}

export function useCreateAsset(
  field: string,
  assetResource: ResourceKey = 'cpretAsset',
) {
  const dataProvider = useDataProvider();
  const dp = useContext(DataProviderContext) as CustomDataProvider;
  const [environmentLevel] = useEnvironmentContext(); // Really ?

  const createAsset = useCallback(
    async ({
      file,
      values,
      progressCallback,
      connectedOther,
      prefix,
    }: {
      file: File;
      values?: {
        filename?: string;
        size?: number;
        type?: string;
      };
      progressCallback?: (progress: AWS.S3.ManagedUpload.Progress) => void;
      connectedOther?: Partial<Record>;
      prefix: string;
    }): Promise<{ data: Record }> => {
      const filename = values?.filename || file.name;
      const newAsset: Partial<Asset> = {
        filename,
        title: filename,
        size: values?.size || file.size,
        mimeType: values?.type || file.type,
      };
      if (connectedOther)
        (newAsset as any)[field] = {
          edges: [{ node: connectedOther } as any /* Edge */],
        };

      const assetCreateResult = await dataProvider.create<Asset>(
        assetResource,
        {
          data: newAsset,
        },
      );
      const asset = assetCreateResult.data;

      const assetUpdateResult = await uploadAndUpdate({
        assetResource,
        environmentLevel,
        file,
        asset,
        progressCallback,
        dataProvider,
        prefix,
      });

      // manual refresh
      if (connectedOther?.__typename && connectedOther?.id)
        dp.observeOne(`cpret${connectedOther.__typename}` as ResourceKey, {
          id: connectedOther.id,
        });

      return assetUpdateResult;
    },
    [dataProvider, dp, environmentLevel, field, assetResource],
  );

  return createAsset;
}

export function useUpdateAsset() {
  const dataProvider = useDataProvider();
  const [environmentLevel] = useEnvironmentContext();

  const updateAsset = useCallback(
    async ({
      file,
      asset,
      progressCallback,
      prefix, // FIXME - Read from existing ?
      assetResource = 'cpretAsset',
    }: {
      file: File;
      asset: Asset;
      prefix: string;
      progressCallback?: (progress: AWS.S3.ManagedUpload.Progress) => void;
      assetResource?: ResourceKey;
    }): Promise<{ data: Record }> => {
      const assetUpdateResult = await uploadAndUpdate({
        environmentLevel,
        file,
        asset,
        progressCallback,
        dataProvider,
        prefix,
        assetResource,
      });

      return assetUpdateResult;
    },
    [dataProvider, environmentLevel],
  );

  return updateAsset;
}
