import React, { useCallback, useState } from 'react';
import { InputProps, Record } from 'ra-core';

import { IconButton, Tooltip } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';

// Cycle is not an issue here
import ConnectionConnectDialog from './ConnectionConnectDialog'; // eslint-disable-line import/no-cycle
import { useTranslateIf } from '../../../hooks/use-translate-if';
import { ResourceKey } from '../../../project/projectInfos';

type ConnectionConnectDialogButtonProps = InputProps & {
  resource: ResourceKey;
  onConnect: (record: Record) => void;
  connected?: Array<Record['ID']>;
};

const ConnectionConnectDialogButton: React.FC<
  ConnectionConnectDialogButtonProps
> = ({
  resource,
  onConnect,
  connected,
  className,
}: ConnectionConnectDialogButtonProps) => {
  const translate = useTranslateIf();

  const [showDialog, setShowDialog] = useState(false);
  const handleOpen = useCallback(
    event => {
      event.preventDefault();
      setShowDialog(true);
    },
    [setShowDialog],
  );
  const handleClose = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  return (
    <>
      <Tooltip title={translate(`action.connect`, { name: '' })}>
        <IconButton onClick={handleOpen} className={className}>
          <LinkIcon />
        </IconButton>
      </Tooltip>
      {showDialog && (
        <ConnectionConnectDialog
          resource={resource}
          onConnect={onConnect}
          onClose={handleClose}
          connected={connected}
        />
      )}
    </>
  );
};

export default ConnectionConnectDialogButton;
