import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  InputAdornment,
  CircularProgress,
  setRef,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import AddIcon from '@material-ui/icons/Add';
import React, { useMemo, useCallback } from 'react';
import {
  TextInput,
  SelectInput,
  DateInput,
  ReferenceArrayField,
  TextInputProps,
  SelectInputProps,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Record, useDataProvider } from 'ra-core';
import { pick } from 'lodash';
import clsx from 'clsx';
import { Status } from 'phicomas-client';

import { useForm, useFormState } from 'react-final-form';
import { useCanAccess } from '../../hooks/use-can-access';
import { Assets } from '../../components/Fields/asset/Assets';
import { Owner, LoanType } from '../../types/schema';
import FormRow from '../../components/baseElements/FormRow';
import FormSection from '../../components/baseElements/FormSection';
import { BorrowerDatagrid } from '../borrower/BorrowerDatagrid';
import TooltipRGPD from '../../components/Tooltip/TooltipRGPD';
import { SncfCompanyField } from './SncfCompanyField';
import { ASSET_USER_NAMESPACE } from '../../aws/cpret-config';
// import { useApimanAutoFill } from '../../hooks/use-apiman-auto-fill';
import { GuaranteeDatagrid } from '../guarantee/GuaranteeDatagrid';

interface CreateRelatedBorrowerButtonProps {
  record?: Owner;
}

const CreateRelatedBorrowerButton: React.FC<
  CreateRelatedBorrowerButtonProps
> = ({ record }) => {
  const form = useForm();
  return (
    <Button
      component={Link}
      disabled={!form.getState().pristine}
      color="default"
      size="medium"
      variant="contained"
      to={{
        pathname: '/cpretBorrower/create',
        search: `?source=${JSON.stringify({
          loanType: LoanType.HONNEUR,
          owner: {
            edges: [{ node: pick(record, ['id', 'sncfCP', 'contact']) }],
          },
        })}`,
      }}
    >
      <AddIcon />
      <span>Ajouter un enfant / PH</span>
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      visibility: 'hidden',
    },
    root: {
      display: 'flex',
      flexFlow: 'row wrap',
      margin: theme.spacing(-1),
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    leftSide: {
      flex: '1 0 0%',

      '& $formSection': {
        display: 'flex',
        flexFlow: 'column nowrap',

        '& $field': {
          width: '100%',
        },
      },
    },
    rightSide: {
      flex: '3 0 0%',

      '& $formSection': {
        display: 'flex',
        flexFlow: 'row wrap',

        '& $field': {
          flexGrow: 1,
          flexBasis: '26%', // More that 1/4
        },
      },
    },
    formSection: {},
    field: {},
    diffHRField: {
      color: theme.palette.warning.main,
      fontWeight: theme.typography.fontWeightBold as number,
    },
    tooltipComment: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      padding: theme.spacing(4),
      boxShadow: theme.shadows[4],
    },
    hiddentooltip: {
      display: 'none',
    },
    createBorrowerButton: {
      marginTop: theme.spacing(2),
    },
  }),
);

const lockInputProps = {
  endAdornment: (
    <InputAdornment position="end">
      <LockIcon />
    </InputAdornment>
  ),
};

export interface OwnerFormProps {
  kind?: 'create' | 'edit';
  record?: Owner;
  resource?: string;
}

const inputOrNull = (input: any) => input || null;

const hrDiffMap = [
  'honorificPrefix',
  'familyName',
  'patronymicName',
  'givenName',
  'streetAddress',
  'postalCode',
  'addressLevel2',
] as const;

const TextInputWithRef = React.forwardRef<unknown, TextInputProps>(
  (props, ref) => <TextInput {...props} inputRef={ref} />,
);

const SelectInputWithRef = React.forwardRef<unknown, SelectInputProps>(
  (props, ref) => (
    <SelectInput
      {...props}
      SelectProps={{
        // I don't know why this works... look here https://github.com/mui/material-ui/blob/v4.12.4/packages/material-ui/src/Select/SelectInput.js#L76
        inputRef: value => setRef(ref, value && value.node),
      }}
    />
  ),
);

export const OwnerForm: React.FC<OwnerFormProps> = props => {
  const { record, resource, kind = 'edit' } = props;
  const { isAdmin } = useCanAccess();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const borrowerIds = record?.borrowers?.edges?.map(e => e.node.id) || [];
  const guaranteeIds = record?.guarantees?.edges?.map(e => e.node.id) || [];

  const form = useForm();
  const { values: formValues } = useFormState();

  // const { loading: apimanLoading, error: sncfCPError } = useApimanAutoFill({
  //   form,
  //   skip: kind !== 'create',
  //   sncfCP: formValues.sncfCP,
  // });
  const apimanLoading = false;
  const sncfCPError = null;

  React.useEffect(() => {
    if (kind !== 'create') {
      return;
    }
    // this is use to allow sncfCPError to be updated before the validator run
    form.setConfig('validateOnBlur', true);
  }, [form, kind]);

  React.useEffect(() => {
    if (kind !== 'create') {
      return;
    }
    form.blur('sncfCP');
  }, [sncfCPError, kind, form]);

  const validateSncfCP = React.useCallback(
    async value => {
      const { data } = await dataProvider.getList<Owner>('cpretOwner', {
        filter: { sncfCP: value },
        pagination: { page: 1, perPage: 1 },
        sort: {
          field: 'id',
          order: 'ASC',
        },
      });

      if (data.length > 0) {
        if (data[0].sncfCP === value) {
          return `L'immatriculation existe déjà`;
        }
      }

      if (sncfCPError) {
        return `L'immatriculation est invalide`;
      }

      if (!value) {
        return `L'immatriculation est obligatoire`;
      }

      return undefined;
    },
    [sncfCPError, dataProvider],
  );

  const isNotAdminOnEditView = React.useMemo(
    () => !isAdmin && kind === 'edit',
    [isAdmin, kind],
  );

  const hrDiff = useMemo(
    () =>
      hrDiffMap.reduce<{ [k in typeof hrDiffMap[number]]: boolean }>(
        (acc, hrDiffField) => {
          acc[hrDiffField] =
            !!record?.agent?.[hrDiffField] &&
            (formValues?.contact?.[hrDiffField] ?? '') !==
              (record?.agent?.[hrDiffField] ?? '');
          return acc;
        },
        {} as any,
      ),
    [formValues, record],
  );

  const handleReplaceHRField = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const { field } = event.currentTarget.dataset;
      if (field) {
        form.change(
          `contact.${field}`,
          record?.agent?.[field as typeof hrDiffMap[number]] ?? '',
        );
      }
    },
    [form, record?.agent],
  );

  if (!resource) return null;

  return (
    <div className={classes.root}>
      <section className={classes.leftSide}>
        <FormSection>
          <FormRow>
            <TooltipRGPD>
              <TextInput
                label="Commentaires"
                source="comment"
                multiline
                rows={10}
                rowsMax={10}
                fullWidth
              />
            </TooltipRGPD>
          </FormRow>
          {kind === 'edit' && (
            <FormRow>
              <Assets
                namespace={ASSET_USER_NAMESPACE}
                label="Pièces jointes"
                record={record}
                resource={resource}
                source="attachments"
                fullWidth
              />
            </FormRow>
          )}
        </FormSection>
      </section>
      <section className={classes.rightSide}>
        <FormSection title="Informations Personnelles">
          <FormRow>
            <TextInput
              source="sncfCP"
              label="Immatriculation"
              disabled={isNotAdminOnEditView}
              InputProps={
                isNotAdminOnEditView
                  ? lockInputProps
                  : {
                      style: { textTransform: 'uppercase' },
                      endAdornment: apimanLoading ? (
                        <InputAdornment position="end">
                          <CircularProgress size={20} />
                        </InputAdornment>
                      ) : null,
                    }
              }
              parse={(v?: string) => (v ? v.toUpperCase() : null)}
              validate={kind === 'create' ? validateSncfCP : undefined}
            />
            <Tooltip
              placement="left"
              classes={{
                tooltip: clsx(classes.tooltipComment, {
                  [classes.hiddentooltip]: !hrDiff.honorificPrefix,
                }),
              }}
              arrow
              interactive
              title={
                <>
                  <Typography variant="body2">
                    Valeur HR: {record?.agent?.honorificPrefix}
                  </Typography>
                  <Button
                    variant="contained"
                    data-field="honorificPrefix"
                    onClick={handleReplaceHRField}
                  >
                    Remplacer
                  </Button>
                </>
              }
            >
              <SelectInputWithRef
                source="contact.honorificPrefix"
                options={{
                  /* Passing label directly generates a type error in console... */
                  label: (
                    <span>
                      Civilité{' '}
                      {hrDiff.honorificPrefix && (
                        <span className={classes.diffHRField}>!</span>
                      )}
                    </span>
                  ),
                }}
                // label={false}
                choices={[
                  { id: 'MME', name: 'Madame' },
                  { id: 'MR', name: 'Monsieur' },
                ]}
                allowEmpty
              />
            </Tooltip>
            <Tooltip
              placement="left"
              classes={{
                tooltip: clsx(classes.tooltipComment, {
                  [classes.hiddentooltip]: !hrDiff.familyName,
                }),
              }}
              arrow
              interactive
              title={
                <>
                  <Typography variant="body2">
                    Valeur HR: {record?.agent?.familyName}
                  </Typography>
                  <Button
                    variant="contained"
                    data-field="familyName"
                    onClick={handleReplaceHRField}
                  >
                    Remplacer
                  </Button>
                </>
              }
              disableHoverListener
            >
              <TextInputWithRef
                source="contact.familyName"
                options={{
                  label: (
                    <span>
                      Nom usuel{' '}
                      {hrDiff.familyName && (
                        <span className={classes.diffHRField}>!</span>
                      )}
                    </span>
                  ),
                }}
              />
            </Tooltip>
            <Tooltip
              placement="left"
              classes={{
                tooltip: clsx(classes.tooltipComment, {
                  [classes.hiddentooltip]: !hrDiff.patronymicName,
                }),
              }}
              arrow
              interactive
              title={
                <>
                  <Typography variant="body2">
                    Valeur HR: {record?.agent?.patronymicName}
                  </Typography>
                  <Button
                    variant="contained"
                    data-field="patronymicName"
                    onClick={handleReplaceHRField}
                  >
                    Remplacer
                  </Button>
                </>
              }
              disableHoverListener
            >
              <TextInputWithRef
                source="contact.patronymicName"
                options={{
                  label: (
                    <span>
                      Nom patronymique{' '}
                      {hrDiff.patronymicName && (
                        <span className={classes.diffHRField}>!</span>
                      )}
                    </span>
                  ),
                }}
              />
            </Tooltip>
            <Tooltip
              placement="left"
              classes={{
                tooltip: clsx(classes.tooltipComment, {
                  [classes.hiddentooltip]: !hrDiff.givenName,
                }),
              }}
              arrow
              interactive
              title={
                <>
                  <Typography variant="body2">
                    Valeur HR: {record?.agent?.givenName}
                  </Typography>
                  <Button
                    variant="contained"
                    data-field="givenName"
                    onClick={handleReplaceHRField}
                  >
                    Remplacer
                  </Button>
                </>
              }
              disableHoverListener
            >
              <TextInputWithRef
                source="contact.givenName"
                options={{
                  label: (
                    <span>
                      Prénom{' '}
                      {hrDiff.givenName && (
                        <span className={classes.diffHRField}>!</span>
                      )}
                    </span>
                  ),
                }}
              />
            </Tooltip>
          </FormRow>
          <FormRow>
            <TextInput source="contact.email" label="Email" />
            <TextInput source="contact.tel" label="Téléphone" />
            <DateInput
              source="contact.bday"
              label="Date de naissance"
              parse={inputOrNull}
              format={inputOrNull}
            />
          </FormRow>
          <FormRow>
            <Tooltip
              placement="left"
              classes={{
                tooltip: clsx(classes.tooltipComment, {
                  [classes.hiddentooltip]: !hrDiff.streetAddress,
                }),
              }}
              arrow
              interactive
              title={
                <>
                  <Typography variant="body2">
                    Valeur HR: {record?.agent?.streetAddress}
                  </Typography>
                  <Button
                    variant="contained"
                    data-field="streetAddress"
                    onClick={handleReplaceHRField}
                  >
                    Remplacer
                  </Button>
                </>
              }
              disableHoverListener
            >
              <TextInputWithRef
                source="contact.streetAddress"
                options={{
                  label: (
                    <span>
                      Adresse{' '}
                      {hrDiff.streetAddress && (
                        <span className={classes.diffHRField}>!</span>
                      )}
                    </span>
                  ),
                }}
                multiline
                rowsMax={2}
              />
            </Tooltip>
            <Tooltip
              placement="left"
              classes={{
                tooltip: clsx(classes.tooltipComment, {
                  [classes.hiddentooltip]: !hrDiff.postalCode,
                }),
              }}
              arrow
              interactive
              title={
                <>
                  <Typography variant="body2">
                    Valeur HR: {record?.agent?.postalCode}
                  </Typography>
                  <Button
                    variant="contained"
                    data-field="postalCode"
                    onClick={handleReplaceHRField}
                  >
                    Remplacer
                  </Button>
                </>
              }
              disableHoverListener
            >
              <TextInputWithRef
                source="contact.postalCode"
                options={{
                  label: (
                    <span>
                      CP{' '}
                      {hrDiff.postalCode && (
                        <span className={classes.diffHRField}>!</span>
                      )}
                    </span>
                  ),
                }}
              />
            </Tooltip>
            <Tooltip
              placement="left"
              classes={{
                tooltip: clsx(classes.tooltipComment, {
                  [classes.hiddentooltip]: !hrDiff.addressLevel2,
                }),
              }}
              arrow
              interactive
              title={
                <>
                  <Typography variant="body2">
                    Valeur HR: {record?.agent?.addressLevel2}
                  </Typography>
                  <Button
                    variant="contained"
                    data-field="addressLevel2"
                    onClick={handleReplaceHRField}
                  >
                    Remplacer
                  </Button>
                </>
              }
              disableHoverListener
            >
              <TextInputWithRef
                source="contact.addressLevel2"
                options={{
                  label: (
                    <span>
                      Ville{' '}
                      {hrDiff.addressLevel2 && (
                        <span className={classes.diffHRField}>!</span>
                      )}
                    </span>
                  ),
                }}
              />
            </Tooltip>
          </FormRow>
        </FormSection>

        <FormSection
          title="Informations Professionnelles"
          className={kind === 'create' ? classes.hidden : undefined}
        >
          <FormRow>
            {kind === 'edit' && (
              <DateInput
                source="sncfContractBeginDate"
                label="Date de d'embauche"
                parse={inputOrNull}
                format={inputOrNull}
                disabled={isNotAdminOnEditView}
                InputProps={isNotAdminOnEditView ? lockInputProps : {}}
              />
            )}
            {kind === 'edit' ? (
              <SncfCompanyField source="sncfCompany" label="Société" addLabel />
            ) : (
              <TextInput
                source="sncfCompany"
                label="Société"
                disabled
                InputProps={lockInputProps}
              />
            )}
            {kind === 'edit' && (
              <DateInput
                source="sncfContractEndDate"
                label="Date de cessation"
                parse={inputOrNull}
                format={inputOrNull}
                disabled={isNotAdminOnEditView}
                InputProps={isNotAdminOnEditView ? lockInputProps : {}}
              />
            )}
            {kind === 'edit' && (
              <TextInput
                source="sncfContractEndReason"
                label="Motif de cessation"
                disabled={isNotAdminOnEditView}
                InputProps={isNotAdminOnEditView ? lockInputProps : {}}
              />
            )}
          </FormRow>
        </FormSection>

        {kind === 'edit' && (
          <ReferenceArrayField
            reference="cpretBorrower"
            source="borrowerIds"
            filter={{ status: Status.PUBLISHED }}
            addLabel={false}
            record={{ ...record, borrowerIds } as unknown as Record}
            fullWidth
          >
            <FormSection title="Les Prêts">
              <FormRow>
                <BorrowerDatagrid />
              </FormRow>
              {false /* disabled since july 2024 */ && (
                <FormRow>
                  <>
                    <div className={classes.createBorrowerButton}>
                      <CreateRelatedBorrowerButton record={record} />
                    </div>
                  </>
                </FormRow>
              )}
            </FormSection>
          </ReferenceArrayField>
        )}
        {kind === 'edit' && !!guaranteeIds.length && (
          <ReferenceArrayField
            reference="cpretGuarantee"
            source="guaranteeIds"
            filter={{ status: Status.PUBLISHED }}
            addLabel={false}
            record={{ ...record, guaranteeIds } as unknown as Record}
            fullWidth
          >
            <FormSection title="Les Cautions">
              <FormRow>
                <GuaranteeDatagrid />
              </FormRow>
            </FormSection>
          </ReferenceArrayField>
        )}
      </section>
    </div>
  );
};
