import React from 'react';
import { InputProps } from 'ra-core';
import { Introspection } from 'phicomas-client';
import { sanitizeInputRestProps } from 'react-admin';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { FieldTitle } from 'ra-core/esm/util/FieldTitle';

import { ResourceKey } from '../../project/projectInfos';
// eslint-disable-next-line import/no-cycle
import generateInput from './generateInput'; // Cycle import dependency is needed for this one
import { useTranslateIf } from '../../hooks/use-translate-if';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexFlow: 'row wrap',
      margin: -theme.spacing(1),

      '& > *': {
        margin: theme.spacing(1),
      },
    },
    label: {
      marginBottom: theme.spacing(2),
      position: 'relative',
      transform: 'none',
    },
  }),
);

export type ObjectInputProps = InputProps & {
  objectType?: Introspection.Type;
};
const ObjectInput: React.FC<ObjectInputProps> = ({
  objectType,
  fullWidth,
  ...restProps
}: ObjectInputProps) => {
  const classes = useStyles();
  const { resource, source, label, className } = restProps;
  const translate = useTranslateIf();

  if (!source || !resource || !objectType?.fields) return null;

  return (
    <FormControl
      {...sanitizeInputRestProps(restProps)}
      fullWidth={fullWidth}
      margin="dense"
      className={className}
    >
      <InputLabel htmlFor={source} shrink className={classes.label}>
        <FieldTitle
          source={source}
          resource={resource}
          label={label || translate(`resources.${resource}.fields.${source}`)}
        />
      </InputLabel>
      <div className={classes.wrapper}>
        {objectType.fields.map(field => {
          return generateInput(resource as ResourceKey, field, {
            ...restProps,
            ofObjectSource: source,
            label: translate(
              `resources.${resource}.fields.${source}.${field.name}`,
            ),
          });
        })}
      </div>
      <FormHelperText>&#8203;</FormHelperText>
    </FormControl>
  );
};

export default ObjectInput;
