import { FormControl, FormHelperText, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import { InputProps, useNotify, useTranslate, Record } from 'ra-core';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { useForm, Field, FieldRenderProps, useField } from 'react-final-form';
import FilePreviewer from '../../../lib/FilePreviewer';
import {
  ASSET_MAIL_NAMESPACE,
  ASSET_USER_NAMESPACE,
} from '../../../aws/cpret-config';
import { AgnosticSelectField } from '../agnostic/AgnosticSelectField';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
    },
    dropPreviewWrapper: {
      display: 'flex',
      flexFlow: 'row wrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
      margin: -theme.spacing(2),

      '& > *': {
        margin: theme.spacing(2),
      },
    },
    dropZone: {
      flex: '1 1 600px',

      backgroundColor: theme.palette.background.default,
      cursor: 'pointer',
      padding: theme.spacing(4),
      textAlign: 'center',
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    dropzoneLabel: {
      margin: 0,
    },

    preview: {
      flex: '0 1 auto',
      boxShadow: theme.shadows[4],
      marginBottom: theme.spacing(1),
    },
  }),
);

/** This is a file holder field: used in CreateToolbar,
 *  and then filtered out in the withFileupload dataProvider HOC */
export const UPLOAD_FIELD = '#UPLOAD_FIELD#';

export type AssetDropzoneProps = Omit<InputProps, 'source'> & {
  record?: Record;
  displayNamespaceSelectOnMailFile?: boolean;
};
export const AssetDropzone: React.FC<AssetDropzoneProps> = (
  props: AssetDropzoneProps,
) => {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const {
    className,
    record,
    resource,
    displayNamespaceSelectOnMailFile = false,
  } = props;
  const form = useForm();
  const fileField = useField(UPLOAD_FIELD);
  const filenameField = useField('filename');

  const file: File | undefined = fileField.input.value || undefined;

  /*
  const uploadFileValidator = useCallback(
    value => {
      if (typeof value !== 'undefined' || (resource && record?.key)) {
        return undefined;
      }
      return translate('error.required_file');
    },
    [record, resource, translate],
  );
  */

  const handleFile = useCallback(
    (droppedFile: File) => {
      form.change(UPLOAD_FIELD, droppedFile);
      const previousFile = file;
      if (
        !filenameField.input.value ||
        previousFile?.name === filenameField.input.value // Name was not manually changed, so update it
      ) {
        form.change('filename', droppedFile.name);
      }
      form.change('size', droppedFile.size);
      form.change('type', droppedFile.type);
    },
    [form, file, filenameField.input.value],
  );

  const handleDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      // event: DropEvent,
    ): void => {
      if (acceptedFiles.length + fileRejections.length > 1) {
        notify('error.one_file_only', 'warning');
      } else {
        if (fileRejections.length) {
          // handleFile(fileRejections[0]); // ??
          // notify('warning.changed_format', 'info');
        }
        if (acceptedFiles.length) {
          handleFile(acceptedFiles[0]);
        }
      }
    },
    [handleFile, notify],
  );

  const fileValidator = useCallback<
    <T extends File>(file: T) => FileError | FileError[] | null
  >(
    f => {
      if (!f.name) return null;
      const ext = f.name.split('.').slice(-1)[0].toLocaleLowerCase();
      // TODO - check f.type too ?
      if (['exe', 'bat', 'vbs', 'vbe', 'msi', 'cmd', 'js'].includes(ext)) {
        notify('error.bad_format', 'warning');
        return {
          code: 'bad-format',
          message: 'Bad format',
        };
      }
      return null;
    },
    [notify],
  );

  const mimeType = file?.type || record?.mimeType || 'unknown';

  // Do not limit if no record.type, but widen limitation with file.type if any limitation
  const { getRootProps, getInputProps } = useDropzone({
    // accept: acceptType,
    multiple: false,
    onDrop: handleDrop,
    validator: fileValidator,
  });

  const namespaceOptions = React.useMemo(
    () => [
      { label: 'Courrier', value: ASSET_MAIL_NAMESPACE },
      { label: 'Pièce jointe', value: ASSET_USER_NAMESPACE },
    ],
    [],
  );

  const ext = file?.name.split('.').slice(-1)[0].toLocaleLowerCase();
  const mightBePostmail = ext && ['doc', 'docx', 'rtf', 'pdf'].includes(ext); // FIXME - Always true ?

  return (
    <Field
      name={UPLOAD_FIELD}
      // validate={uploadFileValidator}
      className={className}
    >
      {({ meta }: FieldRenderProps<boolean>) => (
        <FormControl
          fullWidth
          error={(meta.touched || meta.submitFailed) && meta.invalid}
          className={classes.root}
        >
          <div className={classes.dropPreviewWrapper}>
            {file && mightBePostmail && displayNamespaceSelectOnMailFile && (
              <AgnosticSelectField
                fullWidth
                defaultValue={
                  mightBePostmail ? ASSET_MAIL_NAMESPACE : ASSET_USER_NAMESPACE
                }
                options={namespaceOptions}
                label="Destination"
                name="namespace"
                required
              />
            )}

            <div className={classes.dropZone} {...getRootProps()}>
              {file && (
                <FilePreviewer
                  mimeType={mimeType}
                  file={file}
                  s3FileInfos={{
                    resource,
                    key: record?.key,
                    filename: record?.filename,
                  }}
                  className={classes.preview}
                  downloadOnClick
                />
              )}
              {/* Fix onClick when used within a ClickAwayListener, triggering it */}
              <input {...getInputProps()} onClick={undefined} />
              <Typography className={classes.dropzoneLabel}>
                {translate('ra.input.file.upload_single')}
              </Typography>
            </div>
          </div>
          {(meta.touched || meta.submitFailed) && (
            <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );
};
