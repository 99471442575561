import * as React from 'react';
import {
  InputHelperText,
  ResettableTextField,
  sanitizeInputRestProps,
} from 'react-admin';
import { useInput, FieldTitle, InputProps as InputPropsType } from 'ra-core';
import clsx from 'clsx';

import { TextFieldProps } from '@material-ui/core/TextField';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    resizable: {
      resize: 'vertical',
    },
  }),
);

export type TextInputProps = InputPropsType<TextFieldProps> &
  Omit<TextFieldProps, 'label' | 'helperText'>;

/**
 * An Input component for a string
 *
 * @example
 * <TextInput source="first_name" />
 *
 * You can customize the `type` props (which defaults to "text").
 * Note that, due to a React bug, you should use `<NumberField>` instead of using type="number".
 * @example
 * <TextInput source="email" type="email" />
 * <NumberInput source="nb_views" />
 *
 * The object passed as `options` props is passed to the <ResettableTextField> component
 */
const TextInput: React.FC<TextInputProps> = ({
  label,
  format,
  helperText,
  onBlur,
  onFocus,
  onChange,
  options = {},
  parse,
  resource,
  source,
  validate,
  ...rest
}: TextInputProps) => {
  const classes = useStyles();

  const {
    id,
    input,
    isRequired,
    meta: { error, submitError, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'text',
    validate,
    ...rest,
  });

  const InputProps = {
    ...rest.InputProps,
    inputProps: {
      ...rest.InputProps?.inputProps,
      className: clsx(rest.InputProps?.inputProps?.className, {
        [classes.resizable]: rest.multiline,
      }),
    },
  };

  return (
    <ResettableTextField
      id={id}
      {...input}
      label={
        label !== '' &&
        label !== false && (
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        )
      }
      error={!!(touched && (error || submitError))}
      helperText={
        <InputHelperText
          touched={!!touched}
          error={error || submitError}
          helperText={helperText}
        />
      }
      {...options}
      {...sanitizeInputRestProps(rest)}
      InputProps={InputProps}
    />
  );
};

export default TextInput;
