import React, { useCallback, useMemo, useState } from 'react';
import {
  ReferenceArrayField,
  Datagrid,
  sanitizeInputRestProps,
} from 'react-admin';
import { FieldTitle, InputProps, Record } from 'ra-core';
import { useField, useForm } from 'react-final-form';

import { Node, Connection, Introspection } from 'phicomas-client';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';

import generateAllResourceFields from '../Fields/generateFields';
// Cycle needed because a connection edition lives within a record edition
import ConnectionEditDialog from './ConnectionInput/ConnectionEditDialog'; // eslint-disable-line import/no-cycle
// Cycle needed because a connection edition lives within a record edition
import ConnectionCreateDialogButton from './ConnectionInput/ConnectionCreateDialogButton'; // eslint-disable-line import/no-cycle
import ConnectionDisconnectButton from './ConnectionInput/ConnectionDisconnectButton';
// Cycle is not an issue here
import ConnectionConnectDialogButton from './ConnectionInput/ConnectionConnectDialogButton'; // eslint-disable-line import/no-cycle

import FilePreviewerField, {
  useCellStyles,
} from '../Fields/FilePreviewerField';

import projectInfos, {
  ResourceInfos,
  ResourceKey,
} from '../../project/projectInfos';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    label: {
      marginBottom: theme.spacing(2),
      position: 'relative',
      transform: 'none',
    },
    buttons: {
      display: 'inline-block',
      position: 'absolute',
      bottom: '50%',
      transform: 'translate(0, 50%)',
      margin: theme.spacing(0, 1),
    },
  }),
);

export type ConnectionInputProps = InputProps & {
  record?: Record;
  connectionInfos?: NonNullable<ResourceInfos['connections']>[ResourceKey];
};
const ConnectionInput: React.FC<ConnectionInputProps> = ({
  connectionInfos,
  ...raProps
}: ConnectionInputProps) => {
  const classes = useStyles();
  const { record, className, validate, ...restProps } = raProps;
  const { resource, source } = restProps;
  const {
    onResource,
    isLeft = true,
    isDirect = false,
    type = Introspection.OneManyEnum.MANY,
  } = connectionInfos ?? {};

  const cellClasses = useCellStyles();

  const form = useForm();
  const defaultValue = useMemo(
    () => (isDirect ? null : { edges: [] }),
    [isDirect],
  );
  const field = useField<Pick<Connection, 'edges'> | Node | null>(source, {
    defaultValue,
    initialValue: defaultValue,
  });

  function isConnection(
    connectionOrNode: Pick<Connection, 'edges'> | Node | null,
  ): connectionOrNode is Pick<Connection, 'edges'> {
    return (
      typeof (connectionOrNode as Pick<Connection, 'edges'>) === 'object' &&
      connectionOrNode !== null &&
      typeof connectionOrNode.edges !== 'undefined'
    );
  }

  const handleCreatedConnection = useCallback(
    (createdRecord: Record) => {
      if (source) {
        const prevValue = field.input.value;
        let newValue;
        if (isConnection(prevValue)) {
          newValue = {
            ...prevValue,
            edges:
              type === Introspection.OneManyEnum.MANY
                ? [...prevValue.edges, { node: createdRecord }]
                : [{ node: createdRecord }],
          };
        } else {
          newValue = createdRecord;
        }
        form.change(source, newValue);
      }
    },
    [source, field.input.value, form, type],
  );

  const handleEditConnections = useCallback(
    (addedRecord: Record) => {
      if (source) {
        const prevValue = field.input.value;
        let newValue;
        if (isConnection(prevValue)) {
          newValue = {
            ...prevValue,
            edges:
              type === Introspection.OneManyEnum.MANY
                ? [...prevValue.edges, { node: addedRecord }]
                : [{ node: addedRecord }],
          };
        } else {
          newValue = addedRecord;
        }
        form.change(source, newValue);
      }
    },
    [source, field.input.value, form, type],
  );

  const handleDeleteConnection = useCallback(
    (deletedRecord: Record) => {
      if (source) {
        const prevValue = field.input.value;
        let newValue;
        if (isConnection(prevValue)) {
          newValue = {
            ...prevValue,
            edges: prevValue.edges.filter(
              ({ node }) => node.id !== deletedRecord.id,
            ),
          };
        } else {
          newValue = null;
        }
        form.change(source, newValue);
      }
    },
    [source, form, field],
  );

  const [editNode, setEditNode] = useState<Node | null>(null);
  const handleCloseEditDialog = useCallback(() => {
    setEditNode(null);
  }, [setEditNode]);

  const handleRowClick = useCallback(
    (id, basePath, node) => {
      setEditNode(node);
      return ''; // Need to return a string: https://marmelab.com/react-admin/List.html#rowclick
    },
    [setEditNode],
  );

  const recordConnectionValue = field.input.value;
  const ids = useMemo(() => {
    if (isConnection(recordConnectionValue)) {
      return recordConnectionValue?.edges.map(({ node: { id } }) => id) ?? [];
    }
    return recordConnectionValue === null ? [] : [recordConnectionValue.id];
  }, [recordConnectionValue]);

  if (!source || !onResource) return null;

  const { assetResource } = projectInfos;
  const isAsset = assetResource === onResource;

  return (
    <FormControl
      {...sanitizeInputRestProps(raProps)}
      fullWidth
      margin="dense"
      className={className}
    >
      <InputLabel htmlFor={source} shrink className={classes.label}>
        <FieldTitle source={source} resource={resource} />
        <div className={classes.buttons}>
          {(isLeft || isDirect) && (
            <ConnectionCreateDialogButton
              {...raProps}
              resource={onResource}
              onCreate={handleCreatedConnection}
            />
          )}
          <ConnectionConnectDialogButton
            {...raProps}
            resource={onResource}
            onConnect={handleEditConnections}
            connected={ids}
          />
        </div>
      </InputLabel>
      <ConnectionEditDialog
        {...raProps}
        resource={onResource}
        record={editNode}
        onClose={handleCloseEditDialog}
      />
      {ids.length > 0 && (
        <ReferenceArrayField
          {...restProps}
          basePath={restProps.basePath ?? ''} // Prop is required, but missing on dialog's connectionInput
          record={{ ...(record ?? { id: '' }), [source]: ids }}
          reference={onResource}
          fullWidth
        >
          <Datagrid rowClick={handleRowClick}>
            <ConnectionDisconnectButton
              cellClassName={cellClasses.noGrowCell}
              onDisconnect={handleDeleteConnection}
            />
            {isAsset && (
              <FilePreviewerField cellClassName={cellClasses.noGrowCell} />
            )}
            {generateAllResourceFields(onResource)}
          </Datagrid>
        </ReferenceArrayField>
      )}
      <FormHelperText>&#8203;</FormHelperText>
    </FormControl>
  );
};

export default ConnectionInput;
