import { TextField, TextFieldProps } from '@material-ui/core';
import React from 'react';
import { useField } from 'react-final-form';
import { FieldValidator } from 'final-form';

import { eurToCents, centsToEur } from '../../../lib/utils';

export type AgnosticAmountFieldProps = TextFieldProps & {
  name: string;
  validate?: FieldValidator<number>;
  defaultValue?: number;
  /** Credit value (stored as negative) */
  isCredit?: boolean;
};

export const AgnosticAmountField: React.FC<AgnosticAmountFieldProps> = ({
  defaultValue,
  name,
  validate,
  isCredit = false,
  ...otherProps
}) => {
  const sign = isCredit ? -1 : 1;
  const parse = React.useCallback(
    value => value && eurToCents(sign * parseFloat(value)),
    [sign],
  );
  const format = React.useCallback(
    value => (value ? centsToEur(sign * value) : ''),
    [sign],
  );
  const { input, meta } = useField(name, {
    defaultValue,
    format,
    parse,
    validate,
  });

  return (
    <TextField
      {...otherProps}
      {...input}
      type="number"
      variant="filled"
      {...(meta.touched &&
        meta.invalid && {
          error: true,
          helperText: meta.error || meta.submitError,
        })}
    />
  );
};
