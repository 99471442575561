import React, { useMemo } from 'react';
import { Datagrid, NumberField, TextField } from 'react-admin';
import { useTheme, Theme } from '@material-ui/core';
import Color from 'color';

import { Style } from '../../types/styles';
import { BorrowerNameField } from './BorrowerNameField';
import { useExportGPG } from '../../workflow/exports-gpg';
import { useExportNPF } from '../../workflow/exports-npf';
import { CurrencyField } from "../loan/CurrencyFields";

const rowStyle =
  (theme: Theme) =>
  (record: any, index: number): Style => {
    return {
      backgroundColor:
        index % 2
          ? 'transparent'
          : Color(theme.palette.specials.cpretBlue).alpha(0.1).toString(),
    };
  };

type BrwrDataGridProps = {
  mainList?: boolean;
};

export const BorrowerDatagrid: React.FC<BrwrDataGridProps> = ({
  mainList = false,
}) => {
  const theme = useTheme();
  const exportGPG = useExportGPG();
  const exportNPF = useExportNPF();
  const dbg = useMemo(
    () => ({
      gpg: exportGPG,
      npf: exportNPF,
    }),
    [exportGPG, exportNPF],
  );

  if (process.env.NODE_ENV !== 'production') window.CPRET_DEBUG = dbg;

  return (
    <Datagrid rowClick="edit" rowStyle={rowStyle(theme)}>
      <BorrowerNameField label="Prénom" source="contact.givenName" />
      <BorrowerNameField label="Nom" source="contact.familyName" />
      <TextField label="Type" source="loanType" />
      <NumberField label="Nb" source="$$loanCount" />
      {mainList && <TextField label="Rappel" source="$$rappel" />}
      {mainList && <NumberField label="GPG" source="$$paymentAmount" />}
      {mainList && (
        <NumberField label="GPG⚠️" source="$$paymentScheduleAlert" />
      )}
      {mainList && <NumberField label="Echéances⚠️" source="$$scheduleAlert" />}
      {mainList && <CurrencyField label="Creditor" source="$$userCreditor" />}
    </Datagrid>
  );
};
