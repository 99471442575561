import React from 'react';
import { Datagrid, DateField, TextField } from 'react-admin';
import { useTheme, Theme } from '@material-ui/core';
import Color from 'color';

import { Style } from '../../types/styles';
// import { GuaranteeContactField } from './GuaranteeContactField';
import { CurrencyField } from '../loan/CurrencyFields';

const rowStyle =
  (theme: Theme) =>
  (record: any, index: number): Style => {
    return {
      backgroundColor:
        index % 2
          ? 'transparent'
          : Color(theme.palette.specials.cpretBlue).alpha(0.1).toString(),
    };
  };

export const GuaranteeDatagrid: React.FC = () => {
  const theme = useTheme();

  return (
    <Datagrid rowClick="edit" rowStyle={rowStyle(theme)}>
      {/* <GuaranteeContactField source="givenName" label="Prénom Co-emprunteur" /> */}
      <TextField source="contacts[1].givenName" label="Prénom Co-emprunteur" />
      {/* <GuaranteeContactField source="familyName" label="Nom Co-emprunteur" /> */}
      <TextField source="contacts[1].familyName" label="Nom Co-emprunteur" />
      <DateField source="signature" label="Date de signature" />
      <TextField source="$$loanCount" label="Nb (prêts cautionnés)" />
      <CurrencyField source="amount" label="Montant" />
    </Datagrid>
  );
};
