import {
  TextField as BaseTextField,
  TextFieldProps as BaseTextFieldProps,
} from '@material-ui/core';
import React from 'react';
import { useField } from 'react-final-form';
import { FieldValidator } from 'final-form';

export type AgnosticTextFieldProps = BaseTextFieldProps & {
  name: string;
  defaultValue?: string;
  validate?: FieldValidator<string>;
};

export const AgnosticTextField: React.FC<AgnosticTextFieldProps> = ({
  name,
  defaultValue,
  validate,
  ...otherProps
}) => {
  const { input, meta } = useField(name, { defaultValue, validate });

  return (
    <BaseTextField
      type="text"
      variant="filled"
      {...otherProps}
      {...input}
      {...(meta.touched &&
        meta.invalid && {
          error: true,
          helperText: meta.error || meta.submitError,
        })}
    />
  );
};
