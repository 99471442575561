import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Form /* , useFormState */ } from 'react-final-form';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CheckIcon from '@material-ui/icons/Check';
import { Button, useDataProvider, useRecordContext } from 'react-admin';

import { ActionTypeField } from './ActionTypeField';
import { WFAction as GuaranteeActionType } from '../../../workflow/guarantee-enum';
import { DissociationGuaranteeActionFields } from './fields/DissociationGuaranteeActionFields';
import { TransferGuaranteeActionFields } from './fields/TransferGuaranteeActionFields';
import { CallGuaranteeActionFields } from './fields/CallGuaranteeActionFields';
import { DenunciationGuaranteeActionFields } from './fields/DenunciationGuaranteeActionFields';
import { AgnosticFormControl } from '../../../components/Fields/agnostic/AgnosticFormControl';
import { useOnSubmit } from './useOnSubmit';
import Loading from '../../../components/layout/Loading';
import { Guarantee, GuarantyLoan, Owner } from '../../../types/schema';
import { WFState } from '../../../workflow/guaranty-loans-enum';

type GuaranteeActionDialogProps = {
  disabled?: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      height: 'fit-content',
      '& h1, & >div>div>div:last-child': {
        display: 'none',
      },
      '& svg': {
        color: 'rgb(255, 0, 0)',
      },
      marginTop: '-9px',
      marginBottom: '-10px',
      '& div > div': {
        margin: '0px',
      },
      '& .MuiCircularProgress-root': {
        width: '16px !important',
        height: '16px !important',
      },
    },
    checkIcon: {
      marginBottom: '-6px',
    },
    button: {
      height: '40px',
    },
    error: {
      color: 'red', // improve...
    },
  }),
);

export const GuaranteeActionDialog: React.FC<GuaranteeActionDialogProps> = ({
  disabled,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  // const { values: parentValues } = useFormState();
  const guarantee: Guarantee = useRecordContext();

  const { coSncfCP, loans, contacts } = guarantee; // parentValues as Guarantee;

  const loansEdges = loans?.edges || [];
  const cloturableLoans = loansEdges
    .filter(({ node }) => {
      const g = node as GuarantyLoan;
      return (
        g.wfStatus === WFState.GESTION || g.wfStatus === WFState.RECOUVREMENT
      );
    })
    .map(e => e.node as GuarantyLoan);

  const nonCloturableLoans = loansEdges
    .filter(({ node }) => {
      const g = node as GuarantyLoan;
      return g.wfStatus === WFState.GARANTIE || g.wfStatus === WFState.PAIEMENT;
    })
    .map(e => e.node as GuarantyLoan);

  const hasGestion = loansEdges.filter(
    e => (e.node as GuarantyLoan).wfStatus === WFState.GESTION,
  ).length;

  const hasNonClosed = loansEdges.filter(
    e => (e.node as GuarantyLoan).wfStatus !== WFState.CLOTURE,
  ).length;

  const toggleOpen = React.useCallback(() => {
    setOpen(!open);
    setLoading(false);
  }, [open]);
  const onSubmit = useOnSubmit(guarantee, toggleOpen);

  const dataProvider = useDataProvider();

  const validateActionType = React.useCallback(
    (value?: GuaranteeActionType) => {
      if (!value) {
        return "Vous devez sélectionner un type d'action";
      }
      if (value === GuaranteeActionType.DISSOCIATION) {
        if (contacts?.length !== 2)
          return 'Action impossible. Pas de co-emprunteur sur cette caution.';
      }
      if (value === GuaranteeActionType.TRANSFER) {
        if (!coSncfCP)
          return 'Le transfert est impossible, aucun agent co-emprunteur sur cette caution.';

        return dataProvider
          .getList<Owner>('cpretOwner', {
            filter: { sncfCP: coSncfCP },
            pagination: { page: 1, perPage: 1 },
            sort: {
              field: 'createdAt',
              order: 'ASC',
            },
          })
          .then(({ data: oData }) => {
            if (oData.length === 0)
              return `Le transfert est impossible, veuillez créer le co-emprunteur d'abord (${coSncfCP} introuvable dans la base C'Prêt)`;
            return null;
          });
      }
      if (value === GuaranteeActionType.CALL) {
        if (!hasGestion)
          return 'Action impossible. Aucun prêts à passer en garantie.';
      }
      if (value === GuaranteeActionType.DENUNCIATION) {
        if (nonCloturableLoans.length > 0)
          return 'Dénonciation impossible, certains prêts ne peuvent pas être cloturés.';
        if (cloturableLoans.length === 0)
          return 'Dénonciation impossible, aucun prêts à cloturer.';
      }
      return null;
    },
    [
      cloturableLoans.length,
      coSncfCP,
      contacts?.length,
      dataProvider,
      hasGestion,
      nonCloturableLoans.length,
    ],
  );

  const initialValues = React.useMemo(
    () => ({
      actionType: null,
    }),
    [],
  );

  return (
    <>
      {!!hasNonClosed && (
        <Button
          color="default"
          variant="contained"
          size="medium"
          label="Lancer une action"
          onClick={toggleOpen}
          disabled={disabled}
        >
          <PlayArrowIcon />
        </Button>
      )}
      <Dialog fullWidth open={open} onClose={toggleOpen} maxWidth="lg">
        <DialogTitle>Lancer une action</DialogTitle>
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ values, submitting, submitError, invalid, form }) => (
            <>
              <DialogContent>
                {submitError && (
                  <div className={classes.error}>{submitError}</div>
                )}
                <AgnosticFormControl fullWidth>
                  <ActionTypeField validate={validateActionType} />
                </AgnosticFormControl>

                {values.actionType === GuaranteeActionType.DISSOCIATION && (
                  <DissociationGuaranteeActionFields />
                )}
                {values.actionType === GuaranteeActionType.TRANSFER && (
                  <TransferGuaranteeActionFields />
                )}
                {values.actionType === GuaranteeActionType.CALL && (
                  <CallGuaranteeActionFields />
                )}
                {values.actionType === GuaranteeActionType.DENUNCIATION && (
                  <DenunciationGuaranteeActionFields />
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  color="default"
                  variant="contained"
                  size="medium"
                  label="Annuler"
                  onClick={toggleOpen}
                  type="button"
                />
                <Button
                  disabled={submitting || invalid}
                  color="primary"
                  variant="contained"
                  size="medium"
                  label={submitting ? 'Exécution en cours...' : 'Valider'}
                  onClick={() => {
                    setLoading(true);
                    form.submit();
                  }}
                  type="submit"
                  className={classes.button}
                >
                  <span>
                    {!loading && <CheckIcon className={classes.checkIcon} />}
                    {loading && (
                      <div className={classes.loader}>
                        <Loading />
                      </div>
                    )}
                  </span>
                </Button>
              </DialogActions>
            </>
          )}
        </Form>
      </Dialog>
    </>
  );
};
