import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { Button } from 'react-admin';

import { centsToEur } from '../../lib/utils';
import {
  currencyOptions as options,
  PostingWithLedger,
} from '../../lib/ledger';

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      padding: 0,
    },
    credit: {
      color: 'red',
    },
    debit: {
      color: 'green',
    },
  }),
);

type PostingsDialogProps = {
  locales: string[] | string;
  postings: PostingWithLedger[];
  onClose: () => void;
  title: string;
};

export const PostingsDialog: React.FC<PostingsDialogProps> = ({
  locales,
  postings,
  onClose,
  title,
}) => {
  const classes = useStyles();

  const rows = React.useMemo(() => {
    let balance = 0;
    return postings
      .sort(
        (a, b) =>
          new Date(a.ledger.createdAt).getTime() -
          new Date(b.ledger.createdAt).getTime(),
      )
      .map(posting => {
        balance += posting.amount || 0;
        return {
          id: posting.ledger.id,
          date: posting.ledger.createdAt,
          label: posting.ledger.payee,
          amount: posting.amount || 0,
          balance,
          other: posting.otherAccounts,
        };
      })
      .reverse();
  }, [postings]);

  return (
    <>
      <Dialog fullWidth open onClose={onClose} maxWidth="lg">
        <DialogTitle>{title}</DialogTitle>
        <DialogTitle>Liste des mouvements</DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Libellé</TableCell>
                  <TableCell align="right">Débit</TableCell>
                  <TableCell align="right">Crédit</TableCell>
                  <TableCell align="right">Solde</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <TableRow hover key={row.id}>
                    <TableCell component="th" scope="row">
                      {format(new Date(row.date), 'dd/MM/yyyy')}
                    </TableCell>
                    <TableCell>{row.label}</TableCell>
                    <TableCell
                      align="right"
                      className={classes.debit}
                      title={row.amount > 0 ? row.other : undefined}
                    >
                      {row.amount > 0 &&
                        centsToEur(row.amount).toLocaleString(locales, options)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.credit}
                      title={row.amount <= 0 ? row.other : undefined}
                    >
                      {row.amount <= 0 &&
                        centsToEur(-row.amount).toLocaleString(
                          locales,
                          options,
                        )}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={
                        row.balance < 0 ? classes.credit : classes.debit
                      }
                    >
                      {centsToEur(Math.abs(row.balance)).toLocaleString(
                        locales,
                        options,
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            size="medium"
            label="Fermer"
            onClick={onClose}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
