import React from 'react';
import { DataProviderContext } from 'react-admin';
import gql from 'graphql-tag';

import { getResourceInfos } from '../../project/projectInfos';
import { CustomDataProvider } from '../../dataProvider/type';
import { Ledger, Borrower } from '../../types/schema';

// CpretLedgerFieldsBase
const fragCpretLedgerFieldsBase = gql`
  ${getResourceInfos('cpretLedger').fragments.base}
`;

const PageInfoFragment = gql`
  fragment PageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;

const query = gql`
  query getLedger($id: ID!, $after: ID, $first: Int) {
    borrower(id: $id) {
      # id # not requiring id prevents apollo from caching this query, yay!
      ledger(after: $after, first: $first) {
        edges {
          node {
            ...CpretLedgerFieldsBase
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragment}
  ${fragCpretLedgerFieldsBase}
`;

export function useLedger(id?: string, cacheId?: number): [Ledger[], boolean] {
  const dataProvider = React.useContext(
    DataProviderContext,
  ) as CustomDataProvider;
  const client = dataProvider.getClient();

  const [ledgers, setLedgers] = React.useState<Ledger[]>([]);
  const [loading, setLoading] = React.useState(false);
  const semaphore = React.useRef<number | undefined>();

  const fetchLedger = React.useCallback(
    async (after?: string, pageSize = 100) => {
      const { data } = await client.query({
        query,
        variables: { id, after, first: pageSize },
        fetchPolicy: 'no-cache',
      });

      const { ledger } = data.borrower as Borrower;
      const edges = ledger?.edges?.map(edge => edge.node as Ledger) || [];
      return { edges, pageInfo: ledger.pageInfo };
    },
    [client, id],
  );

  const fetchPage = React.useCallback(
    (after?: string) => {
      const action = async () => {
        setLoading(true);
        const ts = Date.now();
        semaphore.current = ts;
        const { edges, pageInfo } = await fetchLedger(after);
        if (semaphore.current !== ts) return; // someone else has taken over
        setLedgers(prev => [...prev, ...edges]);
        setLoading(false);
        if (pageInfo.hasNextPage) {
          requestAnimationFrame(() => {
            fetchPage(pageInfo.endCursor);
          });
        }
      };
      return action();
    },
    [fetchLedger],
  );

  React.useEffect(() => {
    if (id) {
      setLedgers([]);
      fetchPage();
    }
  }, [fetchPage, id, cacheId]);

  return [ledgers, loading];
}
