import React from 'react';

import { AgnosticTextField } from '../../../../components/Fields/agnostic/AgnosticTextField';
import { AgnosticFormControl } from '../../../../components/Fields/agnostic/AgnosticFormControl';
import { AgnosticAmountField } from '../../../../components/Fields/agnostic/AgnosticAmountField';

export interface CallValues {
  reason: string;
  incident: string;
  amount: number;
}

export const CallGuaranteeActionFields: React.FC = () => {
  const validateReason = React.useCallback((value: string) => {
    if (!value) {
      return 'Vous devez saisir un motif';
    }

    return null;
  }, []);

  const validateIncident = React.useCallback((value: string) => {
    if (!value) {
      return 'Vous devez saisir une indication';
    }

    return null;
  }, []);

  const validateAmount = React.useCallback((value: number) => {
    if (!value) {
      return 'Vous devez saisir un montant';
    }

    if (value <= 0) {
      return 'Le montant doit être supérieur à 0';
    }

    return null;
  }, []);

  return (
    <>
      <AgnosticFormControl fullWidth>
        <AgnosticTextField
          name="reason"
          label="Motif"
          required
          validate={validateReason}
        />
      </AgnosticFormControl>
      <AgnosticFormControl fullWidth>
        <AgnosticTextField
          name="incident"
          label="Indications de règlement"
          required
          validate={validateIncident}
        />
      </AgnosticFormControl>
      <AgnosticFormControl fullWidth>
        <AgnosticAmountField
          name="amount"
          label="Montant (€) (ex: 12,34)"
          required
          validate={validateAmount}
        />
      </AgnosticFormControl>
    </>
  );
};
