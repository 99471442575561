import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { useRecordContext } from 'react-admin';

import { GuarantyLoan } from '../../types/schema';
import { shortId } from '../../lib/utils';

type GuarantyLoanIdFieldProps = {
  label?: string; // eslint-disable-line react/no-unused-prop-types
};

export const GuarantyLoanIdField: React.FC<GuarantyLoanIdFieldProps> = () => {
  const record: GuarantyLoan = useRecordContext();
  const { id } = record;
  const sId = React.useMemo(() => shortId(id), [id]);
  return (
    <Typography component="span" variant="body2">
      {sId}
    </Typography>
  );
};
