import React from 'react';

import {
  getResourceCustomization,
  getResourceInfos,
  ResourceKey,
} from '../../project/projectInfos';
import generateFieldComponentInfos from './generateField';

export default function generateAllResourceFields(
  resource: ResourceKey,
): React.ReactNode {
  const {
    type: { fields },
  } = getResourceInfos(resource);

  if (!fields) {
    return null;
  }

  const resourceCusto = getResourceCustomization(resource);

  if (resourceCusto.list?.fields) {
    return resourceCusto.list.fields?.map(({ name }) => {
      const fieldType = fields?.find(({ name: n }) => n && n === name);
      if (!fieldType) {
        // Field described in custo not found in resourceType
        // => Error in project's custo
        console.info('Available fields:', fields);
        throw new Error(`Field "${name}" not found`);
      }
      const fieldComponentInfos = generateFieldComponentInfos(
        resource,
        fieldType,
      );
      if (fieldComponentInfos) {
        const { Field, fieldProps } = fieldComponentInfos;
        return <Field key={name} source={name} {...fieldProps} />;
      }
      // Field specificities not handled
      return <span>Error: {name}</span>;
    });
  }

  return fields.map(field => {
    const fieldComponentInfos = generateFieldComponentInfos(resource, field);
    if (fieldComponentInfos) {
      const { Field, fieldProps } = fieldComponentInfos;
      return <Field key={field.name} source={field.name} {...fieldProps} />;
    }
    return null;
  });
}
