import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { Button, useNotify } from 'react-admin';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { addMonths, format } from 'date-fns';

import {
  ScheduleType,
  Schedule,
  SchedulePayment,
  Ledger,
} from '../../../../types/schema';
import { AgnosticSelectField } from '../../../../components/Fields/agnostic/AgnosticSelectField';
import { AgnosticAmountField } from '../../../../components/Fields/agnostic/AgnosticAmountField';
import { AgnosticTextField } from '../../../../components/Fields/agnostic/AgnosticTextField';
import { AgnosticFormControl } from '../../../../components/Fields/agnostic/AgnosticFormControl';
import { AgnosticDateField } from '../../../../components/Fields/agnostic/AgnosticDateField';
import { ConsistencySpy } from './ConsistencySpy';
import { ScheduleTypeSpy } from './ScheduleTypeSpy';
import { FormValues } from './types';

type CreateScheduleDialogProps = {
  ledgers: Ledger[];
  initialValues: Partial<FormValues>;
  onCommit: (schedule: Schedule) => void;
  onRollback: () => void;
};

export const CreateScheduleDialog: React.FC<CreateScheduleDialogProps> = ({
  ledgers,
  initialValues,
  onCommit,
  onRollback,
}) => {
  const notify = useNotify();
  const typeOptions = React.useMemo(
    () => [
      { value: ScheduleType.CAPITAL, label: 'Echéancier principal' },
      { value: ScheduleType.DEBT, label: 'Echéancier secondaire' },
    ],
    [],
  );
  const reconciledInitialValues = React.useMemo(
    () => ({
      type: ScheduleType.CAPITAL,
      ...initialValues,
    }),
    [initialValues],
  );

  const onValidate = React.useCallback((values: FormValues): any => {
    const { amount, monthlyAmount, size } = values;
    if (!amount || !monthlyAmount || !size) return FORM_ERROR;
    return undefined;
  }, []);

  const onSubmit = React.useCallback(
    (values: FormValues) => {
      const payments: SchedulePayment[] = [];
      let startDate = new Date(values.startDate);
      const monthlyAmount =
        values.monthlyAmount || Math.round(values.amount / values.size);
      const extraLastMonthAmount =
        values.amount - values.monthlyAmount * values.size;

      for (let i = 0; i < values.size; i++) {
        payments.push({
          dueDate: format(startDate, 'yyyy-MM-dd'),
          principalAmount:
            i < values.size - 1
              ? monthlyAmount
              : monthlyAmount + extraLastMonthAmount,
          interestAmount: 0,
          done: false,
        });

        startDate = addMonths(startDate, 1);
      }

      const schedule: Schedule = {
        payments,
        type: values.type,
      };

      onCommit(schedule);
      notify(`L'échéancier a été ajouté à la liste`, 'success');
    },
    [onCommit, notify],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={reconciledInitialValues}
      mutators={{ ...arrayMutators }}
      validate={onValidate}
    >
      {({ submitting, invalid, form }) => (
        <Dialog fullWidth open onClose={() => onRollback()} maxWidth="lg">
          <ConsistencySpy />
          <ScheduleTypeSpy ledgers={ledgers} />
          <DialogTitle>Création d'un échéancier</DialogTitle>

          <DialogContent>
            <AgnosticFormControl fullWidth>
              <AgnosticSelectField
                name="type"
                options={typeOptions}
                label="Type d'échancier"
              />
            </AgnosticFormControl>
            <AgnosticFormControl fullWidth>
              <AgnosticAmountField
                name="amount"
                label="Montant du / des prêts"
                isCredit
                required
              />
            </AgnosticFormControl>
            <AgnosticFormControl fullWidth>
              <AgnosticDateField
                name="startDate"
                label="Date de début de réglement"
              />
            </AgnosticFormControl>
            <AgnosticFormControl fullWidth>
              <AgnosticTextField name="size" label="Nb de mois" required />
            </AgnosticFormControl>
            <AgnosticFormControl fullWidth>
              <AgnosticAmountField
                name="monthlyAmount"
                label="Montant de l'échéance"
                isCredit
                required
              />
            </AgnosticFormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              variant="contained"
              size="medium"
              label="Annuler"
              onClick={onRollback}
              type="button"
            />
            <Button
              disabled={submitting || invalid}
              color="primary"
              variant="contained"
              size="medium"
              label={submitting ? 'Ajout en cours...' : 'Valider'}
              onClick={() => form.submit()}
              type="submit"
            >
              <CheckIcon />
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  );
};
