import React, { useCallback } from 'react';
import { SelectInput, SelectInputProps } from 'react-admin';
import { useFormState } from 'react-final-form';

export const ActionInput = (props: SelectInputProps) => {
  const formState = useFormState();
  const handleActionChange = useCallback(() => {
    // remove potential workflow errors
    if (formState.submitErrors?.endDate) {
      delete formState.submitErrors.endDate;
    }
  }, [formState]);
  return <SelectInput {...props} onChange={handleActionChange} />;
};
