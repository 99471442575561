declare global {
  interface Navigator {
    msSaveOrOpenBlob: any;
  }
}

export default (
  payload: any,
  /** should include extension */ filename: string,
  /** may include charset */ type = 'text/csv;charset=utf-8',
) => {
  const b = payload instanceof Blob ? payload : new Blob([payload], { type });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge - why not
    window.navigator.msSaveOrOpenBlob(b, filename);
  } else {
    const urlDownload = window.URL.createObjectURL(b);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = urlDownload;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(urlDownload);
    }, 200);
  }
};
