import React, { useCallback } from 'react';
import {
  Button,
  Datagrid,
  List,
  ListProps,
  TextField,
  TopToolbar,
} from 'react-admin';
import { addMonths, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { stringify } from 'csv-stringify/dist/esm/sync';

import DownloadIcon from '@material-ui/icons/GetApp';
import { useTheme, Theme } from '@material-ui/core';
import Color from 'color';

import { Style } from '../../types/styles';
import {
  ledgerFilename,
  pfeFilename,
  useExportLedgerCSV,
  useExportLedgerPFE,
} from '../../workflow/exports-ledger';
import download from '../../lib/download';
import DateField from '../Fields/DateField';
import PaginationWithLoader from '../layout/PaginationWithLoader';
import { useAppContext } from '../../context/AppContext';

export const key = 'exportsComptables';

const rowStyle =
  (theme: Theme) =>
  (record: any, index: number): Style => {
    return {
      backgroundColor:
        index % 2
          ? 'transparent'
          : Color(theme.palette.specials.cpretBlue).alpha(0.1).toString(),
    };
  };

const ListActions = ({ onExport, onPFE }: { onExport: any; onPFE: any }) => {
  const now = addMonths(new Date(), -1);
  const { loading } = useAppContext();

  return (
    <TopToolbar>
      {/* FIXME - disable button until loaded */}
      <Button
        onClick={onExport}
        label={`Export ${format(now, 'MMM', { locale: fr })}`}
        disabled={!!loading}
      >
        <DownloadIcon />
      </Button>
      <Button
        onClick={onPFE}
        label={`PFE ${format(now, 'MMM', { locale: fr })}`}
        disabled={!!loading}
      >
        <DownloadIcon />
      </Button>
    </TopToolbar>
  );
};

export const LedgerList: React.FC<ListProps> = props => {
  const theme = useTheme();
  const exportLedger = useExportLedgerCSV();
  const exportPFE = useExportLedgerPFE();
  // const dataProvider = React.useContext(
  //   DataProviderContext,
  // ) as CustomDataProvider;
  // const client = dataProvider.getClient();

  const { staticContext, ...rest } = props;

  /*
  const [ready, setReady] = useState(false);
  useEffect(() => {
    return () => {};
    // FIXME - We need some sort of dataProvider.clearStore()
    client
      .clearStore() // resetStore will refetch, but not clearStore (?)
      .then(() => {
        // dataProvider.getListAll('cpretLedger');
        setReady(true);
      });
    return () => {
      setReady(false);
      client.clearStore();
    };
  }, [client, dataProvider]);
  */

  const handleExport = useCallback(() => {
    const now = addMonths(new Date(), -1);
    exportLedger(/* now.getMonth(), now */).then(data => {
      const out = stringify(data, {
        header: true,
        delimiter: ';',
        columns: [
          'id',
          'createdAt',
          'owner',
          'payee',
          'account',
          'debit',
          'credit',
        ],
        cast: {
          number: v => v.toString().split('.').join(','), // not needed here
        },
      });
      download(Buffer.from(out, 'latin1'), ledgerFilename(now));
      // console.log(data);
    });
  }, [exportLedger]);

  const handlePFE = useCallback(() => {
    const now = addMonths(new Date(), -1); // hard coded in apollo pagination for now
    exportPFE(now).then(data => {
      download(data, pfeFilename(now), 'text/plain;charset=utf-8');
    });
  }, [exportPFE]);

  // if (!ready) return <div>Offline only</div>;

  return (
    <List
      {...rest}
      exporter={false}
      perPage={10}
      actions={<ListActions onExport={handleExport} onPFE={handlePFE} />}
      bulkActionButtons={false}
      pagination={<PaginationWithLoader />}
    >
      <Datagrid rowStyle={rowStyle(theme)}>
        <TextField label="ID" source="id" />
        <DateField label="Date" source="createdAt" />
        <TextField label="Motif" source="payee" />
      </Datagrid>
    </List>
  );
};
