import React, { useCallback } from 'react';
import { FieldProps } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import classnames from 'clsx';
import { createStyles, Link, Theme } from '@material-ui/core';
import { useField } from 'react-final-form';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

import { getS3DownloadUrl } from '../../aws/s3-utils';
import sanitizeRestProps from './sanitizeRestProps';
import { Asset } from '../../types/schema';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: { display: 'flex', alignItems: 'center' },
      remove: { color: '#ff1744', cursor: 'pointer' },
      button: { textAlign: 'left', marginLeft: theme.spacing(1) },
    }),
  { name: 'CustomFileField' },
);

interface Props extends FieldProps {
  title?: string;
  target?: string;
  onRemove?(): void | Promise<void>;
  showCreatedAt?: boolean;
}

const FileField: React.FC<Props> = (props: Props) => {
  const {
    className,
    emptyText,
    source,
    title,
    target,
    resource,
    onRemove,
    showCreatedAt = false,
    ...rest
  } = props;
  const {
    input: { value: record },
  } = useField(source as string);
  const classes = useStyles(props);
  const createdAt = React.useMemo(
    () => format(new Date(record.createdAt), 'dd MMMM yyyy', { locale: fr }),
    [record.createdAt],
  );

  const handleClickOpen = useCallback(async () => {
    if (!record) return;

    let link: string | undefined;
    if (record.rawFile) {
      link = URL.createObjectURL(record.rawFile);
    } else if (record.key) {
      const asset = record as Asset;
      link = asset.key && (await getS3DownloadUrl(asset.key));
    } else {
      return;
    }
    if (link) window.open(link, target);
  }, [record, target]);

  return (
    <div
      className={classnames(classes.root, className)}
      {...sanitizeRestProps(rest)}
    >
      {onRemove && (
        <RemoveCircleIcon className={classes.remove} onClick={onRemove} />
      )}
      {showCreatedAt && <span>{createdAt}</span>}
      <Link
        component="button"
        type="button"
        variant="body2"
        className={classes.button}
        onClick={handleClickOpen}
      >
        {record?.title || title || 'Unknown'}
      </Link>
    </div>
  );
};

export default FileField;
