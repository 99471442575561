import {
  ApolloClient,
  ApolloQueryResult,
  NormalizedCacheObject,
  ObservableQuery,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { OperationVariables } from 'react-apollo';
import _memoize from 'lodash/memoize';

import { FRAGMENT_FULL_SUFFIX, QueryAllNodes } from 'phicomas-client';

import { getResourceInfos, ResourceKey } from '../project/projectInfos';
import { AppContextType } from '../context/AppContext';

export type ObservableTuple = [
  ObservableQuery<any, OperationVariables>,
  Promise<ApolloQueryResult<QueryAllNodes>>,
  Promise<ApolloQueryResult<QueryAllNodes>>,
];

// CpretOwnerFieldsBase
const fragCpretOwnerFieldsBase = gql`
  ${getResourceInfos('cpretOwner').fragments.base}
`;

// CpretBorrowerFieldsBase
const fragCpretBorrowerFieldsBase = gql`
  ${getResourceInfos('cpretBorrower').fragments.base}
`;

// CpretGuaranteeFieldsBase
const fragCpretGuaranteeFieldsBase = gql`
  ${getResourceInfos('cpretGuarantee').fragments.base}
`;

// CpretLedgerFieldsBase
// const fragCpretLedgerFieldsBase = gql`
//   ${getResourceInfos('cpretLedger').fragments.base}
// `;

// CpretLoanFieldsBase
const fragCpretLoanFieldsBase = gql`
  ${getResourceInfos('cpretLoan').fragments.base}
`;

// CpretGuarantyLoanFieldsBase
const fragCpretGuarantyLoanFieldsBase = gql`
  ${getResourceInfos('cpretGuarantyLoan').fragments.base}
`;

const pageInfoFields = gql`
  fragment PageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;

const fragAllBorrowerFields = gql`
  fragment BorrowerFields on Borrower {
    ...CpretBorrowerFieldsBase
    loans @connection(key: "loans") {
      edges {
        node {
          ...CpretLoanFieldsBase
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
    owner @connection(key: "owner") {
      edges {
        node {
          ...CpretOwnerFieldsBase
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFields}
  ${fragCpretBorrowerFieldsBase}
  ${fragCpretLoanFieldsBase}
  ${fragCpretOwnerFieldsBase}
`;

const fragOwnerFields = gql`
  fragment OwnerFields on Owner {
    ...CpretOwnerFieldsBase
    borrowers @connection(key: "borrowers") {
      edges {
        node {
          ...BorrowerFields
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
    guarantees @connection(key: "guarantees") {
      edges {
        node {
          ...CpretGuaranteeFieldsBase
          loans @connection(key: "loans") {
            edges {
              node {
                ...CpretGuarantyLoanFieldsBase
              }
            }
            pageInfo {
              ...PageInfo
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${fragCpretOwnerFieldsBase}
  ${fragAllBorrowerFields}
  ${fragCpretGuaranteeFieldsBase}
  ${fragCpretLoanFieldsBase}
  ${fragCpretGuarantyLoanFieldsBase}
  ${pageInfoFields}
`;

const getAllOwner = () => {
  return gql`
    query allOwner($last: Int, $before: ID) {
      allOwner(last: $last, before: $before) @connection(key: "allOwner") {
        edges {
          node {
            ...OwnerFields
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
    ${pageInfoFields}
    ${fragOwnerFields}
  `;
};

const getAllBorrower = () => {
  return gql`
    query allBorrower($last: Int, $before: ID) {
      allBorrower(last: $last, before: $before)
        @connection(key: "allBorrower") {
        edges {
          node {
            ...BorrowerFields
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
    ${pageInfoFields}
    ${fragAllBorrowerFields}
  `;
};

const getQuery = (resource: ResourceKey) => {
  // manual override for cpretOwner (includes two level loans)
  if (resource === 'cpretOwner') return getAllOwner();
  if (resource === 'cpretBorrower') return getAllBorrower();

  const resourceInfos = getResourceInfos(resource);

  const {
    query: { allName: queryAllName },
    fragments: { name: fragmentName, full: fragmentFull },
  } = resourceInfos;

  return gql`
    query($last: Int, $before: ID) {
      ${queryAllName}(
        last: $last
        before: $before
      ) @connection(key: "${queryAllName}") {
        edges {
          node {
            ...${fragmentName}${FRAGMENT_FULL_SUFFIX}
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
    ${fragmentFull}
  `;
};

const getObservableQuery = (
  query: DocumentNode,
  client: ApolloClient<NormalizedCacheObject>,
  queryName = 'allList',
  setLoading: React.Dispatch<React.SetStateAction<AppContextType>>,
): ObservableTuple => {
  setLoading(({ loading, ...rest }) => ({ ...rest, loading: loading + 1 }));
  const list = client.watchQuery<QueryAllNodes>({
    query,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only', // ? https://github.com/apollographql/apollo-client/issues/6760
    // notifyOnNetworkStatusChange: true,
    variables: {
      last: 1000, // List size cannot exceed 1000
    },
    partialRefetch: false,
    // returnPartialData: false,
  });

  // subscribe now to actually start the query - no longer needed with apollo 3 ?
  list.subscribe({
    next: (/* ...args */) => {
      // console.log(args);
    },
    error: e => {
      // FIXME - Notify ?
      console.error(e);
    },
  });
  const init = list.result().catch(
    e => {
      // some errors will throw here.
      console.error(e);
      throw e;
    },
  );

  const full: typeof init = new Promise((resolve, reject) => {
    let pageLoop: (p: ApolloQueryResult<QueryAllNodes>) => void;

    const getMore = (cursor: string): void => {
      list
        .fetchMore({
          variables: {
            before: cursor,
          },
          // updateQuery has moved to cache typePolicies (see apolloClient.ts in vendor/phicomas-client)
        })
        .then(pageLoop)
        .catch(reject);
    };

    pageLoop = async (res: ApolloQueryResult<QueryAllNodes>): Promise<void> => {
      const { data } = res;
      const {
        pageInfo: { hasNextPage, endCursor },
      } = data[queryName];
      if (hasNextPage) {
        // double raf or no update (!!?)
        requestAnimationFrame(() =>
          requestAnimationFrame(() => {
            if (!endCursor) {
              setLoading(({ loading, ...rest }) => ({
                ...rest,
                loading: loading - 1,
              }));
              resolve(res);
            } else {
              getMore(endCursor);
            }
          }),
        );
      } else {
        setLoading(({ loading, ...rest }) => ({
          ...rest,
          loading: loading - 1,
        }));
        resolve(res);
      }
    };

    init.then(pageLoop);
  });

  full.catch(e => {
    // others here
    console.warn('Pageing error - not much I can do ?') // FIXME - Notify?
    console.error(e);
    throw e;
  })

  return [list, init, full];
};

// Observables needs to be memoized as there is one observable that MUST
// be reused around the app
// /!\ Key to memoization is the first TWO parameters
// /*\ so the observable changes for each resource AND environment level
const getObservable = _memoize(
  (
    resource: ResourceKey,
    environmentLevel: string,
    apolloClient: ApolloClient<NormalizedCacheObject>,
    setLoading = () => {},
  ) => {
    const resourceInfos = getResourceInfos(resource);

    const {
      query: { allName: queryAllName },
    } = resourceInfos;

    const query = getQuery(resource);
    return getObservableQuery(query, apolloClient, queryAllName, setLoading);
  },
  // Memoization key definition
  (...args) => JSON.stringify([args[0], args[1]]),
);

export default getObservable;
