import { get } from 'lodash';
import React from 'react';
import { FunctionField } from 'react-admin';

import { Schedule, SchedulePayment } from '../../../../types/schema';
import { centsToEur } from '../../../../lib/utils';
import { currencyOptions } from '../../../../lib/ledger';

export interface PaymentNextMonthlyAmountProps {
  locales?: string[] | string;
  // eslint-disable-next-line react/no-unused-prop-types
  label: string;
}

export const PaymentNextMonthlyAmount: React.FC<
  PaymentNextMonthlyAmountProps
> = ({ locales }) => {
  const render = React.useCallback(
    (record?: Schedule) => {
      const payments = get(record, ['payments'], null) as
        | SchedulePayment[]
        | null;

      const nextPayment = payments?.find(p => !p.done);

      const nextAmount =
        (nextPayment?.principalAmount || 0) +
        (nextPayment?.interestAmount || 0);

      return centsToEur(Math.abs(nextAmount)).toLocaleString(
        locales,
        currencyOptions,
      );
    },
    [locales],
  ) as any;

  return <FunctionField render={render} />;
};
