import { Record } from 'ra-core';
import _reduce from 'lodash/reduce';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';

import {
  resourceInfosHasConnections,
  ID,
  Node,
  NodeInput,
  isConnectionObject,
  isNonConnectedObject,
  Introspection,
  nodeCommonFields,
} from 'phicomas-client';
import { ResourceInfos } from '../project/projectInfos';
import { isRecord, isRecordArray } from '../types/record';

export function clearTypenames<T>(data: T): T {
  if (Array.isArray(data)) {
    return data.reduce((acc, d) => {
      const item = clearTypenames(d);
      if (item) {
        acc.push(item);
      }
      return acc;
    }, []);
  }
  if (typeof data === 'object' && data !== null) {
    return (Object.entries(data) as Array<[keyof T, T[keyof T]]>).reduce(
      (acc, [key, value]) => {
        if (key !== '__typename') {
          acc[key] = clearTypenames(value);
        }
        return acc;
      },
      {} as T,
    );
  }
  return data;
}

export function clearEmptyObjects<T extends Record>(data: T): T {
  return (Object.entries(data) as Array<[keyof T, T[keyof T]]>).reduce(
    (acc, [key, value]) => {
      if (isNonConnectedObject(value)) {
        if (Object.values(value).every(v => v === null)) {
          acc[key] = null as any;
        } else {
          acc[key] = clearEmptyObjects(value);
        }
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {} as T,
  );
}

/** Clear a RA record before submition */
export function clearDataBeforeSubmit<T extends Record>(data: T): T {
  let clearedData = data;
  clearedData = clearTypenames(clearedData);
  clearedData = clearEmptyObjects(clearedData);
  return clearedData;
}

/** Transform a Node to a NodeInput + handle connection values */
export function transformCreatedData(
  data: NodeInput,
  resourceInfos: ResourceInfos,
): NodeInput {
  const dataInput: NodeInput = data;
  if (resourceInfosHasConnections(resourceInfos)) {
    Object.keys(resourceInfos.connections).forEach(connectionField => {
      const connectionInfos = resourceInfos.connections[connectionField];
      const cData = data[connectionField];

      if (connectionInfos.isDirect) {
        if (isRecord(cData)) {
          dataInput[connectionField] = cData.id;
        } else if (isRecordArray(cData)) {
          dataInput[connectionField] = cData.map(node => node.id);
        }
      } else if (isConnectionObject(cData)) {
        const cIds = cData.edges.map(({ node: { id: cId } }) => cId);
        dataInput[connectionField] =
          cIds.length > 0 ? { connect: cIds } : undefined;
      } else if (cData) {
        throw new Error(
          `Data was found different from the expected connection object/reference on field [${connectionField}]`,
        );
      }
    });
  }
  return dataInput;
}

function getRequiredFields(resourceInfos: ResourceInfos): string[] {
  return (
    resourceInfos.type.fields?.reduce((acc, field) => {
      if (
        field.type.kind === Introspection.Kind.NonNull &&
        !nodeCommonFields.includes(field.name)
      ) {
        acc.push(field.name);
      }
      return acc;
    }, [] as string[]) ?? []
  );
}

/** Extract key/values from data that are different than previousData + handle connection values */
export function transformUpdatedData(
  data: Node,
  previousDataRaw: Node,
  resourceInfos: ResourceInfos,
): NodeInput {
  const previousData = clearDataBeforeSubmit(previousDataRaw); // data is cleared within RA Create/Edit components' `transform` prop before submit
  const connectionFields = Object.keys(resourceInfos.connections ?? {});

  const requiredFields = getRequiredFields(resourceInfos);
  const requiredUnchangedFields: NodeInput<Node> = {};

  // Filter out unchanged data and connections (which are handled bellow)
  const filteredDataInput = _reduce<Node, NodeInput>(
    data,
    (acc, value, field) => {
      if (connectionFields && connectionFields.includes(field)) {
        return acc;
      }

      const prevValue = previousData[field as keyof Node];
      if (
        typeof value !== 'undefined' &&
        !_isEqual(value, prevValue) &&
        !(_isNil(value) && _isNil(prevValue))
      ) {
        acc[field] = value;
      } else if (requiredFields.includes(field)) {
        // Required field should never be filtered out
        requiredUnchangedFields[field] = value;
      }
      return acc;
    },
    {},
  );

  // Handle conections
  let connectionsDataInput: NodeInput = {};
  if (resourceInfosHasConnections(resourceInfos)) {
    connectionsDataInput = Object.keys(
      resourceInfos.connections,
    ).reduce<NodeInput>((connectionsAcc, connectionField) => {
      const connectionInfos = resourceInfos.connections[connectionField];
      const cData = data[connectionField];
      const pCData = previousData[connectionField];

      if (connectionInfos.isDirect) {
        if (!cData) {
          connectionsAcc[connectionField] = null;
        } else if (isRecord(cData)) {
          connectionsAcc[connectionField] = cData.id;
        } else if (isRecordArray(cData)) {
          connectionsAcc[connectionField] = cData.map(node => node.id);
        }
      } else {
        let cIds: ID[] = [];
        let pCIds: ID[] = [];

        if (isConnectionObject(cData)) {
          cIds = cData.edges.map(({ node: { id: cId } }) => cId);
        } else if (cData) {
          throw new Error(
            `Data was found different from the expected connection object on key [${connectionField}]`,
          );
        }
        if (isConnectionObject(pCData)) {
          pCIds = pCData.edges.map(({ node: { id: cId } }) => cId);
        } else if (pCData) {
          throw new Error(
            `Previous data was found different from the expected connection object on key [${connectionField}]`,
          );
        }

        if (cIds.length > 0 || pCIds.length > 0) {
          let removedCIds: ID[] = [];
          let addedCIds: ID[] = cIds;

          if (pCIds.length > 0) {
            removedCIds = pCIds.filter(cId => !cIds.includes(cId));
            addedCIds = cIds.filter(cId => !pCIds.includes(cId));
          }

          if (removedCIds.length > 0 || addedCIds.length > 0) {
            connectionsAcc[connectionField] = {
              connect: addedCIds,
              disconnect: removedCIds,
            };
          }
        }
      }

      return connectionsAcc;
    }, {});
  }

  let nodeInput: NodeInput = {
    ...filteredDataInput,
    ...connectionsDataInput,
  };

  if (Object.keys(nodeInput).length > 0) {
    // If any value to send, add the required but unchanged fields
    nodeInput = {
      ...nodeInput,
      ...requiredUnchangedFields,
    };
  }

  return nodeInput;
}
