import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ClassesOverride } from 'ra-ui-materialui';

import { Typography } from '@material-ui/core';
import { ClassName } from '../../types/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: `calc(100% + ${theme.spacing(0.5) * 2}px);`,
      display: 'flex',
      flexFlow: 'row wrap',
      margin: theme.spacing(-0.5),
    },
    content: {
      width: `calc(100% + ${theme.spacing(0.5) * 2}px);`,
      display: 'flex',
      flexFlow: 'row wrap',
      margin: theme.spacing(-0.5),
      alignItems: 'start',
      '& > *': {
        flexGrow: 1,
        flexBasis: '0%',
        margin: theme.spacing(0.5),
      },
    },
    title: {
      display: 'block',
      color: theme.palette.specials.sncfDarkGrey,
      fontWeight: 'bold',
      margin: [theme.spacing(0.5), theme.spacing(1)].join(' '),
    },
  }),
);

export type FormRowProps = {
  children: React.ReactNode;
  className?: ClassName;
  title?: string;
  classes?: ClassesOverride<typeof useStyles>;
};
const FormRow: React.FC<FormRowProps> = ({
  children,
  className = '',
  title,
  classes: classesOverride,
  ...props
}: FormRowProps) => {
  const classes = useStyles();
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });
  return (
    <div className={clsx(classes.root, className, classesOverride?.root)}>
      {title && (
        <Typography component="span" className={classes.title}>
          {title}
        </Typography>
      )}
      <div className={clsx(classes.content, classesOverride?.content)}>
        {childrenWithProps}
      </div>
    </div>
  );
};

export default FormRow;
