import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Button, useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import { get, flattenDeep, upperFirst } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';

import { Borrower, Guarantee, Loan, GuarantyLoan } from '../../../types/schema';
import TemplatesContext from '../../../context/templatesContext';
import { AgnosticFormControl } from '../../../components/Fields/agnostic/AgnosticFormControl';
import { AgnosticSelectField } from '../../../components/Fields/agnostic/AgnosticSelectField';
import { useMakeDocx } from '../../../workflow/export-docx';
import { ResourceKey } from '../../../project/projectInfos';
import Loading from '../../../components/layout/Loading';

export interface CreatePostmailDialogProps {
  record: Borrower | Guarantee | Loan | GuarantyLoan;
  templatePrefix: string;
  disabled?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      height: 'fit-content',
      '& h1, & >div>div>div:last-child': {
        display: 'none',
      },
      '& svg': {
        color: 'rgb(255, 0, 0)',
      },
      marginTop: '-9px',
      marginBottom: '-10px',
      '& div > div': {
        margin: '0px',
      },
      '& .MuiCircularProgress-root': {
        width: '16px !important',
        height: '16px !important',
      },
    },
    checkIcon: {
      marginBottom: '-6px',
    },
    button: {
      height: '40px',
    },
  }),
);

export const CreatePostmailDialog: React.FC<CreatePostmailDialogProps> = ({
  record,
  templatePrefix,
  disabled,
}) => {
  const notify = useNotify();
  const classes = useStyles();
  const makeDocx = useMakeDocx(
    `cpret${upperFirst(templatePrefix)}` as ResourceKey,
  );

  const templates = React.useContext(TemplatesContext);
  const keys = React.useMemo(() => {
    const visit = (obj: Record<string, unknown>): string[] =>
      flattenDeep(
        Object.keys(obj).map(key => {
          if (typeof obj[key] === 'object') {
            return visit(obj[key] as Record<string, unknown>).map(k =>
              [key, k].join('.'),
            );
          }
          return key;
        }),
      );
    return templates
      ? visit(templates).filter(k => k.startsWith(templatePrefix))
      : [];
  }, [templates, templatePrefix]);

  const options = React.useMemo(
    () =>
      keys.map(key => ({ value: key, label: get(templates, key) as string })),
    [keys, templates],
  );
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const toggleOpen = React.useCallback(() => {
    setOpen(!open);
    setLoading(false);
  }, [open]);
  const onSubmit = React.useCallback(
    async ({ template }) => {
      setLoading(true);
      try {
        await makeDocx(record.id, template);
        notify('cpret.mail.success', 'success', {
          _: `Le courrier a bien été créé`, // default translation - avoids console errors
        });
        toggleOpen();
      } catch (error) {
        notify('cpret.mail.error', 'error', {
          _: `Une erreur est survenue, le courrier n'a pas été créé : %{error}`,
          error: (error as Error).message,
        });
      }
    },
    [makeDocx, notify, record.id, toggleOpen],
  );
  const initialValues = React.useMemo(() => ({}), []);
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        size="medium"
        label="Nouveau courrier"
        onClick={toggleOpen}
        disabled={disabled}
      >
        <AddIcon />
      </Button>
      <Dialog fullWidth open={open} onClose={toggleOpen} maxWidth="lg">
        <DialogTitle>Nouveau courrier</DialogTitle>
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ submitting, invalid, form }) => (
            <>
              <DialogContent>
                <AgnosticFormControl fullWidth>
                  <AgnosticSelectField
                    options={options}
                    name="template"
                    label="Modèle"
                  />
                </AgnosticFormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  color="default"
                  variant="contained"
                  size="medium"
                  label="Annuler"
                  onClick={toggleOpen}
                  type="button"
                />
                <Button
                  disabled={submitting || invalid}
                  color="primary"
                  variant="contained"
                  size="medium"
                  label={submitting ? 'Création en cours...' : 'Valider'}
                  onClick={() => form.submit()}
                  type="submit"
                  className={classes.button}
                >
                  <span>
                    {!loading && <CheckIcon className={classes.checkIcon} />}
                    {loading && (
                      <div className={classes.loader}>
                        <Loading />
                      </div>
                    )}
                  </span>
                </Button>
              </DialogActions>
            </>
          )}
        </Form>
      </Dialog>
    </>
  );
};
