import PeopleIcon from '@material-ui/icons/People';

import { Customization } from '../types/customization';

const customization: Customization = {
  i18nProjectName: "C'Prêt",
  resources: [
    {
      typename: 'Owner',
      i18nName: 'Liste Demandeurs',
      mainFields: 'sncfCP',
      icon: PeopleIcon,
      // canCreateDelete: false, // disables cpreOwner/create route
    },
    {
      typename: 'Asset',
      i18nName: 'Pièce jointe',
      mainFields: 'id',
      canList: false,
    },
    {
      typename: 'Borrower',
      i18nName: 'Bénéficiaire',
      mainFields: 'label',
      canList: false,
    },
    {
      typename: 'Guarantee',
      i18nName: 'Cautionné',
      mainFields: 'id',
      canList: false,
    },
    {
      typename: 'GuarantyLoan',
      i18nName: 'Prêt cautionné',
      mainFields: 'id',
      canList: false,
    },
    {
      typename: 'Loan',
      i18nName: 'Prêt',
      mainFields: 'id',
      canList: false,
    },
    {
      typename: 'Ledger',
      i18nName: 'Livre des écritures',
      mainFields: 'id',
      canList: false,
    },
    {
      typename: 'History',
      i18nName: 'Historique',
      mainFields: 'id',
      canList: false,
    },
  ],
  canAccess: ['VIEWER', 'EDITOR', 'ADMIN'],
};

export default customization;
