import { OmitExtends, PickExtends } from './utils';
import { Connection, ConnectionInput } from './connections';
import { Node } from './common';

// TODO: Replace once it's possible
export const nodeCommonFields = [
  '__typename',
  'id',
  'createdAt',
  'updatedAt',
  'version',
  'status',
];

export type SimpleNodeInput<N extends Node = Node> = Partial<
  OmitExtends<N, Connection>
>;
export type ConnectionNodeInput<N extends Node = Node> = Partial<
  Record<keyof PickExtends<N, Connection>, ConnectionInput>
>;
export type NodeInput<N extends Node = Node> = SimpleNodeInput<N> &
  ConnectionNodeInput<N>;

// export type TransactionalNode = {
//   transaction?: Transaction;
// };

// export enum TransactionState {
//   BEGIN = 'BEGIN',
//   COMMIT = 'COMMIT',
//   ROLLBACK = 'ROLLBACK',
// }

// export type Transaction = {
//   begin?: string;
//   commit?: string;
//   rollback?: string;
//   state?: keyof typeof TransactionState;
//   message?: string; // only on rollback
// };

/* **********
 * Assets
 */
export interface Asset extends Node {
  /** File name */
  filename?: string;
  /** File size */
  size?: number;
  /** Mime type */
  type?: string;
  /** S3 Key (suffix only) */
  key?: string;
}
