import React from 'react';
import { format } from 'date-fns';
import { useFormState, useForm } from 'react-final-form';

import { AgnosticTextField } from '../../../../components/Fields/agnostic/AgnosticTextField';
import { AgnosticAmountField } from '../../../../components/Fields/agnostic/AgnosticAmountField';
import { AgnosticSelectField } from '../../../../components/Fields/agnostic/AgnosticSelectField';
import { AgnosticFormControl } from '../../../../components/Fields/agnostic/AgnosticFormControl';
import { Loan } from '../../../../types/schema';
import { centsToEur, shortId } from '../../../../lib/utils';

export interface DisbursementValues {
  amount: number;
  loanId: string;
  payee: string;
}

interface LoanSelectFieldProps {
  locales?: string | string[];
  loans: Loan[];
}

function formatLoan(loan: Loan, locales?: string | string[]) {
  return `${shortId(loan.id)} - ${format(
    new Date(loan.signature),
    'dd/MM/yyyy',
  )} - ${centsToEur(loan.amount).toLocaleString(locales, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'EUR',
  })}`;
}

const LoanSelectField: React.FC<LoanSelectFieldProps> = ({
  locales,
  loans,
}) => {
  const validate = React.useCallback((value: string) => {
    if (!value) {
      return 'Vous devez saisir un prêt';
    }

    return null;
  }, []);
  const options = React.useMemo(
    () =>
      loans.map(loan => ({
        label: formatLoan(loan, locales),
        value: loan.id,
      })),
    [loans, locales],
  );

  if (options.length === 0) {
    return null;
  }

  return (
    <AgnosticSelectField
      options={options}
      label="Prêt"
      name="loanId"
      validate={validate}
    />
  );
};

export interface DisbursementLedgerActionFieldsProps {
  loans: LoanSelectFieldProps['loans'];
  locales?: string | string[];
}

export const DisbursementLedgerActionFields: React.FC<
  DisbursementLedgerActionFieldsProps
> = ({ loans, locales }) => {
  const {
    values: { loanId },
  } = useFormState();
  const form = useForm();
  React.useEffect(() => {
    const targetLoan = loans.find(l => l.id === loanId);
    if (targetLoan) {
      form.change('amount', targetLoan.amount);
    }
  }, [form, loanId, loans]);

  const validateAmount = React.useCallback((value: number) => {
    if (!value) {
      return 'Vous devez saisir un montant';
    }

    if (value <= 0) {
      return 'Le montant doit être supérieur à 0';
    }

    return null;
  }, []);

  const validatePayee = React.useCallback((value: string) => {
    if (!value) {
      return 'Vous devez saisir un motif';
    }

    return null;
  }, []);

  return (
    <>
      <AgnosticFormControl fullWidth>
        <AgnosticTextField
          name="payee"
          defaultValue="Paiement prêt"
          label="Motif"
          required
          validate={validatePayee}
        />
      </AgnosticFormControl>
      <AgnosticFormControl fullWidth>
        <LoanSelectField loans={loans} locales={locales} />
      </AgnosticFormControl>
      <AgnosticFormControl fullWidth>
        <AgnosticAmountField
          name="amount"
          label="Montant (€) (ex: 12,34)"
          required
          validate={validateAmount}
        />
      </AgnosticFormControl>
    </>
  );
};
