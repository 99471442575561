import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Confirm,
  Datagrid,
  List,
  ListProps,
  ReferenceField,
  TextField,
  TopToolbar,
  useDataProvider,
  useListController,
  useUnselectAll,
} from 'react-admin';

import EuroIcon from '@material-ui/icons/Euro';
import { useTheme, Theme } from '@material-ui/core';
import Color from 'color';
import * as doNotZip from 'do-not-zip';

import { BorrowerNameField } from '../../resource/borrower/BorrowerNameField';
import { LoanIdField } from '../../resource/loan/LoanIdField';
import { CurrencyField } from '../../resource/loan/CurrencyFields';
import { EnhancedBorrower } from '../../types/schema-custom';
import { Style } from '../../types/styles';
import {
  fcrFilename,
  reportFilename,
  useExportFCR,
} from '../../workflow/exports-fcr';
import download from '../../lib/download';

export const key = 'virements';

const rowStyle =
  (theme: Theme) =>
  (record: any, index: number): Style => {
    return {
      backgroundColor:
        index % 2
          ? 'transparent'
          : Color(theme.palette.specials.cpretBlue).alpha(0.1).toString(),
    };
  };

const ListActions = () => <TopToolbar />;

const CustomBulk = ({ selectedIds }: { selectedIds?: string[] }) => {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);

  const exportFCR = useExportFCR();
  const dataProvider = useDataProvider();
  const unselectAll = useUnselectAll('cpretBorrower');

  const handleClick = useCallback(() => {
    if (!selectedIds) return;
    dataProvider
      .getMany<EnhancedBorrower>('cpretBorrower', { ids: selectedIds })
      .then(({ data }) => {
        setCount(
          data.reduce((r, b) => r + (b.$$creditedLoans?.length || 0), 0),
        );
        setOpen(true);
      });
  }, [dataProvider, selectedIds]);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = useCallback(() => {
    const action = async () => {
      setOpen(false);
      if (selectedIds) {
        unselectAll();
        const [ordres, report] = await exportFCR(selectedIds, true);

        const now = new Date();
        const files: doNotZip.File[] = [
          { path: fcrFilename(now), data: new TextEncoder().encode(ordres) },
        ];
        if (report) {
          files.push({
            path: reportFilename(now),
            data: new TextEncoder().encode(report),
          });
        }
        const output = doNotZip.toBlob(files);
        download(output, 'virements.zip');

        console.log('done');
      }
    };
    return action();
  }, [exportFCR, selectedIds, unselectAll]);

  return (
    <>
      <Button label="Export" onClick={handleClick}>
        <EuroIcon />
      </Button>
      <Confirm
        isOpen={open}
        // loading={loading}
        title="Export Virement"
        content={
          <div>
            Export pour {selectedIds?.length} bénéficiaire(s).
            <br />
            {count
              ? `Cette opération passera ${count} prêt(s) en GESTION.`
              : null}
            {count ? <br /> : null}
            <br />
            Voulez-vous procéder ?
          </div>
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};
export const BorrowerList: React.FC<ListProps> = props => {
  const theme = useTheme();
  const unselectAll = useUnselectAll('cpretBorrower');

  const { staticContext, ...rest } = props;
  const lst = useListController(rest);

  useEffect(() => {
    return () => {
      unselectAll();
    };
  }, [unselectAll]);

  // this hack prevents console error because useGetMainList returns total undefined instead of null - FIXME
  if (lst.total === undefined) return null;

  return (
    <List
      {...rest}
      exporter={false}
      filter={{ $$userCreditor_lt: 0 }}
      perPage={10}
      actions={<ListActions />}
      bulkActionButtons={<CustomBulk />}
    >
      <Datagrid rowClick="toggleSelection" rowStyle={rowStyle(theme)}>
        <CurrencyField label="FCR" source="$$userCreditor" />
        <BorrowerNameField label="Prénom" source="contact.givenName" />
        <BorrowerNameField label="Nom" source="contact.familyName" />
        <TextField label="Type" source="loanType" />
        <ReferenceField
          source="$$creditedLoans[0]"
          reference="cpretLoan"
          label="Prêt"
          sortable={false}
        >
          <LoanIdField />
        </ReferenceField>
        <ReferenceField
          source="$$creditedLoans[0]"
          reference="cpretLoan"
          label="BIC"
          link={false}
          sortable={false}
        >
          <TextField label="BIC" source="bic" />
        </ReferenceField>
        <ReferenceField
          source="$$creditedLoans[0]"
          reference="cpretLoan"
          label="IBAN"
          link={false}
          sortable={false}
        >
          <TextField label="IBAN" source="iban" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
