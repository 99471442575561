import React from 'react';
import { LoginComponent, useLogin } from 'react-admin';
import { ThemeProvider, Button } from '@material-ui/core';

const LoginPage: LoginComponent = props => {
  // eslint-disable-next-line react/prop-types
  const { theme } = props;
  const login = useLogin();

  return (
    <ThemeProvider theme={theme ?? {}}>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={login}
          variant="outlined"
          color="primary"
          style={{
            margin: 2,
          }}
        >
          Login
        </Button>
      </div>

      {/* <Notification /> */}
    </ThemeProvider>
  );
};

export default LoginPage;
