import React, { useState, useEffect } from 'react';
import {
  Create,
  CreateProps,
  Record,
  useDataProvider,
  SimpleForm,
  SimpleFormProps,
} from 'react-admin';

import { Loan, LoanType, Owner } from '../../types/schema';
import { LoanForm } from './LoanForm';
import { clearDataBeforeSubmit } from '../../dataProvider/utils';
import { Breadcrumb } from '../../components/Breadcrumb';
import { useLoanWorkflowCreate } from '../../workflow/loans';

export interface LoanCreateTitleProps {
  record?: Loan;
}

export const LoanCreateTitle: React.FC<LoanCreateTitleProps> = ({ record }) => {
  const id = record?.id;
  const brwr = record?.borrower.edges[0]?.node;
  const borrowerId = brwr?.id; // passed from URL
  const year = record?.signature
    ? new Date(record?.signature).getFullYear()
    : new Date().getFullYear();

  const dataProvider = useDataProvider();

  const [ownerId, setOwnerId] = useState<Owner['id']>();
  useEffect(() => {
    if (borrowerId) {
      dataProvider
        .getOne('cpretBorrower', { id: borrowerId })
        .then(({ data: borrower }) => {
          setOwnerId(borrower?.owner.edges[0]?.node.id);
        });
    }
  }, [borrowerId, dataProvider]);

  const fragments = React.useMemo(
    () => [
      {
        title: 'Demandeur',
        typename: 'Owner',
        id: ownerId,
      },
      {
        title: `Bénéficiaire ${
          brwr?.loanType === LoanType.HONNEUR ? 'PH' : 'Autre'
        }`,
        typename: 'Borrower',
        id: borrowerId,
      },
      {
        title: `${year /* id && shortId(id) */}`,
        typename: 'Loan',
        id,
      },
    ],
    [ownerId, borrowerId, id, brwr, year],
  );

  return <Breadcrumb fragments={fragments} />;
};

const Form: React.FC<Partial<SimpleFormProps>> = props => {
  const [loanSave, { loading: saving }] = useLoanWorkflowCreate();

  return (
    <SimpleForm
      {...props}
      redirect="edit"
      // warnWhenUnsavedChanges
      submitOnEnter={false}
      save={loanSave}
      saving={saving}
    >
      <LoanForm kind="create" />
    </SimpleForm>
  );
};

export const LoanCreate: React.FC<CreateProps> = props => {
  return (
    <Create
      {...props}
      title={<LoanCreateTitle />}
      transform={(data: Record): Record => clearDataBeforeSubmit(data)}
    >
      <Form />
    </Create>
  );
};
