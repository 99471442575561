import { GenericEnumsInfos, GenericResourcesInfos } from '../../resources';
import { ResourceKey, EnumKey } from './projectKeys';
import { ProjectInfos, Customization } from '../../project';

import resourcesInfosJson from './resourcesInfos.json';
import enumsInfosJson from './enumsInfos.json';

import customizationJson from './customization.json';

import awsExports from './apolloClient/aws-exports';

type ResourcesInfos = GenericResourcesInfos<ResourceKey>;
const resourcesInfos = resourcesInfosJson as ResourcesInfos;
type EnumsInfos = GenericEnumsInfos<EnumKey>;
const enumsInfos = enumsInfosJson as EnumsInfos;
const customization = customizationJson as Customization<ResourceKey>;

const projectInfos: ProjectInfos<ResourceKey, EnumKey> = {
  name: customization.projectName,
  assetResource: customization.assetResource,
  resourcesInfos,
  enumsInfos,
  awsExports,
};

export default projectInfos;
