import React, { useCallback, useState } from 'react';
import { ListToolbar, Pagination } from 'react-admin';
import {
  useTranslate,
  ListContext,
  ListBase,
  Record,
  FilterPayload,
} from 'ra-core';
import { v4 as uuid } from 'uuid';

import { ID } from 'phicomas-client';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Backdrop,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import GeneratedFilters from '../../GeneratedFilters'; // eslint-disable-line import/no-cycle
import AssetPickerGrid from './AssetPickerGrid';
import AssetCreateButton from '../AssetCreateButton'; // eslint-disable-line import/no-cycle
import { ResourceKey } from '../../../project/projectInfos';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    loading: {
      textAlign: 'center',
      padding: theme.spacing(4, 1),
    },
    loadingSkeleton: {
      width: '50%',
      margin: theme.spacing(4, 'auto'),
    },
    dialogContent: {
      padding: '0',
      overflow: 'hidden',
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    buttonWrapper: {
      padding: theme.spacing(1),
    },
    contentScroller: {
      overflow: 'auto',
      padding: theme.spacing(1),
    },
    content: {
      overflow: 'hidden', // For scrolling sticky top headers are not at the very top
    },
    listToolbarWrapper: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    connectButton: {
      width: '1%',
    },
    confirmingBackdrop: {
      zIndex: theme.zIndex.modal + 1,
    },
  }),
);

type AssetPickerDialogProps = {
  resource: ResourceKey;
  onSelect: (ids: ID[]) => Promise<void>;
  onClose: () => void;
  connected?: ID[];
  multiple?: boolean;
  filter?: FilterPayload;
};

const AssetPickerDialog: React.FC<AssetPickerDialogProps> = ({
  resource,
  onSelect: onConnect,
  onClose,
  connected,
  multiple,
  filter,
}: AssetPickerDialogProps) => {
  const classes = useStyles();
  const translate = useTranslate();

  const [selected, setSelected] = useState(connected ?? []);

  const [versionKey, setVersionKey] = useState(uuid());
  const updateVersionKey = useCallback(() => {
    setVersionKey(uuid());
  }, []);

  const handleSelect = useCallback(
    (id: ID) => {
      setSelected(multiple ? [...selected, id] : [id]);
    },
    [multiple, selected],
  );

  const handleDeselect = useCallback(
    (id: ID) => {
      setSelected(selected.filter(sId => sId !== id));
    },
    [selected],
  );

  const handleImageCreated = useCallback(
    (data: Record) => {
      updateVersionKey();
      handleSelect(`${data.id}`);
    },
    [handleSelect, updateVersionKey],
  );

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const [confirming, setConfirming] = useState(false);
  const handleConfirm = useCallback(async () => {
    setConfirming(true);
    await onConnect(selected);
    setConfirming(false);
    onClose();
  }, [selected, onClose, onConnect]);

  return (
    <Dialog fullWidth maxWidth="xl" open onClose={handleCancel}>
      <DialogTitle>
        {multiple
          ? translate(`action.selectMany`, {
              name: translate(`resources.${resource}.name`, 2),
            })
          : translate(`action.selectOne`, {
              name: translate(`resources.${resource}.name`, 1),
            })}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <div className={classes.buttonWrapper}>
          <AssetCreateButton
            resource={resource}
            onCreate={handleImageCreated}
            filter={filter}
          />
        </div>
        <ListBase
          basePath=""
          resource={resource}
          key={versionKey}
          filter={filter}
        >
          <div className={classes.contentScroller}>
            <div className={classes.content}>
              <div className={classes.listToolbarWrapper}>
                <ListToolbar
                  filters={<GeneratedFilters resource={resource} />}
                />
                <GeneratedFilters resource={resource} context="button" />
              </div>
              <ListContext.Consumer>
                {({ loading }) =>
                  loading && (
                    <Skeleton
                      variant="rect"
                      className={classes.loadingSkeleton}
                    />
                  )
                }
              </ListContext.Consumer>
              <AssetPickerGrid
                onSelect={handleSelect}
                onDeselect={handleDeselect}
                selected={selected}
              />
              <Pagination />
            </div>
          </div>
        </ListBase>
        <Backdrop
          className={classes.confirmingBackdrop}
          open={confirming}
          invisible
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="default" disabled={confirming}>
          {translate('ra.action.cancel')}
        </Button>
        <Button onClick={handleConfirm} color="primary" disabled={confirming}>
          {translate('ra.action.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssetPickerDialog;
