import * as React from 'react';
import get from 'lodash/get';
import { TextField, InputAdornment } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

import { Guarantee } from '../../types/schema';
import { centsToEur } from '../../lib/utils';

type GuaranteeDataBlobProps = {
  label?: string; // eslint-disable-line react/no-unused-prop-types
  record?: Guarantee;
  source: string;
  dataType?: 'currency' | 'percent' | 'boolean' | 'raw' | 'rawCurrency';
  currency?: string;
  locales?: string | string[];
};

export interface GuaranteeDataBlob {
  synthese: {
    projet: { montantCautionne: number; chargeRemboursement: number };
    endettement: {
      totalRevenus: number;
      revenuResiduelDisponible: number;
      chargeEmprunt: number;
      tauxEndettement: number;
      tauxEndettementMax: number;
    };
  };
  detail: {
    adresseDuBien: {
      adresse: string;
      residencePrincipale: boolean;
      codePostal: string;
      ville: string;
    };
    operation: { detail: Array<{ montant: number; type: string }> };
    pretsCautionnes: [
      {
        banque: string;
        prets: [
          {
            label: string;
            montant: number;
            paliers: Array<{ mois: number; montant: number }>;
          },
        ];
      },
    ];
  };
}

export function getDataBlob(guarantee?: Guarantee) {
  const dataBlob: GuaranteeDataBlob = guarantee?.dataBlob
    ? JSON.parse(guarantee.dataBlob)
    : {};

  return dataBlob;
}

export const GuaranteeDataBlobField: React.FC<
  GuaranteeDataBlobProps
> = props => {
  const { source, record, label, dataType, currency, locales } = props;
  const dataBlob = React.useMemo(() => getDataBlob(record), [record]);

  const value = get(dataBlob, source);
  const reconciledValue = React.useMemo(() => {
    if (value === undefined) {
      return 'N/A';
    }

    switch (dataType) {
      case 'currency':
      case 'rawCurrency':
        return centsToEur(value).toLocaleString(locales, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          style: 'currency',
          currency,
        } as any);

      case 'boolean':
        return value ? 'Oui' : 'Non';

      case 'percent':
        return `${value} %`;

      default:
        return value;
    }
  }, [value, dataType, locales, currency]);

  if (dataType === 'raw' || dataType === 'rawCurrency') {
    return reconciledValue;
  }

  return (
    <TextField
      variant="filled"
      label={label}
      value={reconciledValue}
      disabled
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <LockIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
