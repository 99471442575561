import { centsToEur, eurToCents } from '../../lib/utils';

interface LoanMaxPackageRule {
  min: number;
  max: number;
  packages: {
    maintenance: number;
    estrangement: number;
    teaching: number;
  };
}

function createRule(
  min: number,
  max: number,
  packages: LoanMaxPackageRule['packages'],
) {
  return {
    min: eurToCents(min),
    max: eurToCents(max),
    packages: {
      maintenance: eurToCents(packages.maintenance),
      estrangement: eurToCents(packages.estrangement),
      teaching: eurToCents(packages.teaching),
    },
  };
}

const rules: LoanMaxPackageRule[] = [
  createRule(0, 6347, { maintenance: 607, estrangement: 528, teaching: 915 }),
  createRule(6348, 7324, {
    maintenance: 577,
    estrangement: 521,
    teaching: 877,
  }),
  createRule(7325, 8296, {
    maintenance: 547,
    estrangement: 514,
    teaching: 839,
  }),
  createRule(8297, 9269, {
    maintenance: 517,
    estrangement: 507,
    teaching: 801,
  }),
  createRule(9270, 10246, {
    maintenance: 487,
    estrangement: 500,
    teaching: 765,
  }),
  createRule(10247, 11221, {
    maintenance: 457,
    estrangement: 493,
    teaching: 725,
  }),
  createRule(11222, 12193, {
    maintenance: 427,
    estrangement: 486,
    teaching: 687,
  }),
  createRule(12194, 13170, {
    maintenance: 397,
    estrangement: 479,
    teaching: 649,
  }),
  createRule(13171, 14138, {
    maintenance: 367,
    estrangement: 472,
    teaching: 611,
  }),
  createRule(14139, 15113, {
    maintenance: 337,
    estrangement: 465,
    teaching: 573,
  }),
  createRule(15114, 16085, {
    maintenance: 307,
    estrangement: 458,
    teaching: 535,
  }),
];

export function findMaxPackage(value: number) {
  // Round to nearest EUR
  const roundedValue = eurToCents(Math.round(centsToEur(value)));
  const match = rules.find(
    rule => rule.min <= roundedValue && roundedValue <= rule.max,
  );
  return match?.packages;
}
