import {
  Language as HtmlIcon,
  Code as CodeIcon,
  Image as ImageIcon,
  BurstMode as CarouselIcon,
  MoreVert as AccordionIcon,
  Assistant as PushIcon,
} from '@material-ui/icons';
import SvgIcon from '@material-ui/core/SvgIcon';
import { SimpleCms } from 'phicomas-client';

import YamlComponent from './Components/YamlComponent';
import HtmlComponent from './Components/HtmlComponent'; // eslint-disable-line import/no-cycle
import HtmlSourceComponent from './Components/HtmlSourceComponent';
import ImageComponent from './Components/ImageComponent'; // eslint-disable-line import/no-cycle
import CarouselComponent from './Components/CarouselComponent'; // eslint-disable-line import/no-cycle
import AccordionComponent from './Components/AccordionComponent'; // eslint-disable-line import/no-cycle
import PushComponent from './Components/PushComponent'; // eslint-disable-line import/no-cycle

import { XOR } from '../../../types/utils';

type PageComponent =
  | typeof YamlComponent
  | typeof HtmlComponent
  | typeof ImageComponent
  | typeof AccordionComponent
  | typeof PushComponent;

type PageComponentMapInfos = {
  Component: PageComponent;
  SourceComponent?: typeof YamlComponent;
  title: string;
  Icon: typeof SvgIcon;
  /* For components that can have children components */
} & XOR<
  { forbiddenSubTypes?: SimpleCms.PageComponentType[] },
  { availableSubTypes?: SimpleCms.PageComponentType[] }
>;

const pageComponentMapping = new Map<
  SimpleCms.PageComponentType,
  PageComponentMapInfos
>([
  [
    SimpleCms.PageComponentType.YAML,
    {
      Component: YamlComponent,
      title: 'Yaml',
      Icon: CodeIcon,
    },
  ],
  [
    SimpleCms.PageComponentType.HTML,
    {
      Component: HtmlComponent,
      SourceComponent: HtmlSourceComponent,
      title: 'Html',
      Icon: HtmlIcon,
    },
  ],
  [
    SimpleCms.PageComponentType.IMAGE,
    {
      Component: ImageComponent,
      title: 'Image',
      Icon: ImageIcon,
    },
  ],
  [
    SimpleCms.PageComponentType.CAROUSEL,
    {
      Component: CarouselComponent,
      title: 'Carousel',
      Icon: CarouselIcon,
      availableSubTypes: [SimpleCms.PageComponentType.IMAGE],
    },
  ],
  [
    SimpleCms.PageComponentType.ACCORDION,
    {
      Component: AccordionComponent,
      title: 'Accordéon',
      Icon: AccordionIcon,
      forbiddenSubTypes: [SimpleCms.PageComponentType.ACCORDION],
    },
  ],
  [
    SimpleCms.PageComponentType.PUSH,
    {
      Component: PushComponent,
      title: 'Push',
      Icon: PushIcon,
      forbiddenSubTypes: [
        SimpleCms.PageComponentType.ACCORDION,
        SimpleCms.PageComponentType.PUSH,
      ],
    },
  ],
]);

export default pageComponentMapping;
