import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { SvgIconComponent } from '@material-ui/icons';

import { ClassName } from '../../../../../../types/styles';

export type CommonOptionProps = {
  className?: ClassName;
  Icon: SvgIconComponent;
  disabled?: boolean;
};

export const useOptionStyles = makeStyles<Theme>(() =>
  createStyles({
    disabled: {
      opacity: 0.7,
      pointerEvents: 'none',
    },
  }),
);
