import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, StyleRules } from '@material-ui/core/styles';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslate } from 'ra-core';

// can be used to override savebutton
// ref : https://github.com/marmelab/react-admin/blob/v3.16.6/packages/ra-ui-materialui/src/button/SaveButton.tsx#L160
export const circularOverrides: StyleRules = {
  // material-ui indeterminate animation override - using mui v5
  leftIcon: {
    animation: '$circular-rotate 1.4s linear infinite',
  },
  icon: {
    // because leftIcon is used for both circular and left icon
    animation: 'none',
  },
  '@keyframes circular-rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
};

export const useCircularStyles = makeStyles(circularOverrides);

const useStyles = makeStyles(
  theme => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        height: '100%',
      },
      [theme.breakpoints.down('lg')]: {
        height: '100vh',
        marginTop: '-3em',
      },
    },
    icon: {
      width: '9em',
      height: '9em',
    },
    message: {
      textAlign: 'center',
      fontFamily: 'Roboto, sans-serif',
      opacity: 0.5,
      margin: '0 1em',
    },
  }),
  { name: 'RaLoading' },
);

const Loading: React.FC<any> = props => {
  const {
    className,
    loadingPrimary = 'ra.page.loading',
    loadingSecondary = 'ra.message.loading',
  } = props;
  const classes = useStyles(props);
  const circular = useCircularStyles(props);
  const translate = useTranslate();
  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.message}>
        <CircularProgress
          className={clsx(classes.icon, circular.leftIcon)}
          color="primary"
        />
        <h1>{translate(loadingPrimary)}</h1>
        <div>{translate(loadingSecondary)}.</div>
      </div>
    </div>
  );
};

Loading.propTypes = {
  //   classes: PropTypes.object,
  className: PropTypes.string,
  loadingPrimary: PropTypes.string,
  loadingSecondary: PropTypes.string,
};

Loading.defaultProps = {
  loadingPrimary: 'ra.page.loading',
  loadingSecondary: 'ra.message.loading',
};

export default Loading;
