import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop,
  createStyles,
  LinearProgress,
  makeStyles,
  Theme,
} from '@material-ui/core';

const useActionsStyles = makeStyles<Theme>(theme =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: theme.palette.text.contrastText,
    },
    linearProgress: {
      width: '20vw',
      boxShadow: theme.shadows[4],
    },
    documentProgress: {
      fontSize: 'medium',
    },
  }),
);

const ProgressBar: React.FC<any> = props => {
  const { progress, totalNumber } = props;
  const classes = useActionsStyles();
  return (
    <Backdrop
      className={classes.backdrop}
      open={progress !== null && progress !== -1}
    >
      {progress !== null && progress !== -1 && (
        <div>
          <div className={classes.documentProgress}>
            {progress}/{totalNumber} documents générés
          </div>
          <LinearProgress
            variant={
              progress / totalNumber !== 1 ? 'determinate' : 'indeterminate'
            }
            value={(progress / totalNumber) * 100}
            className={classes.linearProgress}
          />
        </div>
      )}
    </Backdrop>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
};

export default ProgressBar;
