export enum Level {
  DEV = 'DEV',
  STAGE = 'STAGE',
  PROD = 'PROD',
}

export enum Instance {
  FRONT = 'FRONT',
  BACK = 'BACK',
}

export type Environment = {
  level: Level;
  instance: Instance;
};
