import React from 'react';

import { AgnosticTextField } from '../../../../components/Fields/agnostic/AgnosticTextField';
import { AgnosticDateField } from '../../../../components/Fields/agnostic/AgnosticDateField';
import { AgnosticFormControl } from '../../../../components/Fields/agnostic/AgnosticFormControl';

export interface AvenantValues {
  message: string;
  actionDate: string;
}

export const AvenantActionFields: React.FC = () => {

  const validateDate = React.useCallback((value: string) => {
    if (!value) {
      return 'Vous devez saisir une date';
    }

    return null;
  }, []);

  const validateMessage = React.useCallback((value: string) => {
    if (!value) {
      return 'Vous devez saisir un motif';
    }

    return null;
  }, []);

  return (
    <>
      <AgnosticFormControl fullWidth>
        <AgnosticTextField
          name="message"
          label="Motif"
          required
          validate={validateMessage}
        />
      </AgnosticFormControl>
      <AgnosticFormControl fullWidth>
        <AgnosticDateField
          name="actionDate"
          label="Date"
          required
          validate={validateDate}
        />
      </AgnosticFormControl>
    </>
  );
};
