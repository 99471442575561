import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Button } from 'react-admin';

import { Guarantee } from '../../types/schema';
import FormSection from '../../components/baseElements/FormSection';
import FormRow from '../../components/baseElements/FormRow';
import { GuaranteeDataBlobField } from './GuaranteeDataBlobField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    dialogContent: {
      '& fieldset': {
        width: 'auto',
      },
    },
  }),
);

export interface GuaranteeSummaryDialogProps {
  record: Guarantee;
}

export const GuaranteeSummaryDialog: React.FC<GuaranteeSummaryDialogProps> = ({
  record,
}) => {
  const [open, setOpen] = React.useState(false);
  const toggleOpen = React.useCallback(() => setOpen(!open), [open]);
  const classes = useStyles();

  return (
    <>
      <Button
        color="default"
        variant="contained"
        size="medium"
        label="Voir la synthèse"
        onClick={toggleOpen}
      />
      <Dialog fullWidth open={open} onClose={toggleOpen} maxWidth="lg">
        <DialogTitle>Synthèse</DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <FormSection title="Projet">
            <FormRow className={classes.row}>
              <GuaranteeDataBlobField
                label="Coût Total"
                source="synthese.projet.coutTotal"
                record={record}
                dataType="currency"
                currency="EUR"
              />
              <GuaranteeDataBlobField
                label="Montant à cautionner"
                source="synthese.projet.montantCautionne"
                record={record}
                dataType="currency"
                currency="EUR"
              />
              <GuaranteeDataBlobField
                label="Date de signature"
                source="synthese.projet.dateSignature"
                record={record}
              />
            </FormRow>
            <FormRow className={classes.row}>
              <GuaranteeDataBlobField
                label="Charge de remboursement"
                source="synthese.projet.chargeRemboursement"
                record={record}
                dataType="currency"
                currency="EUR"
              />
              <GuaranteeDataBlobField
                label="Total apport personnel"
                source="synthese.projet.totalApportPersonnel"
                record={record}
                dataType="currency"
                currency="EUR"
              />
              <GuaranteeDataBlobField
                label="Apport nécessaire"
                source="synthese.projet.apportNecessaire"
                record={record}
                dataType="currency"
                currency="EUR"
              />
            </FormRow>
            <FormRow className={classes.row}>
              <GuaranteeDataBlobField
                label="Apport versé"
                source="synthese.projet.apportVerse"
                record={record}
                dataType="currency"
                currency="EUR"
              />
              <GuaranteeDataBlobField
                label="Apport justifié"
                source="synthese.projet.apportJustifie"
                record={record}
                dataType="currency"
                currency="EUR"
              />
            </FormRow>
          </FormSection>
          <FormSection title="Endettement">
            <FormRow className={classes.row}>
              <GuaranteeDataBlobField
                label="Total des revenus"
                source="synthese.endettement.totalRevenus"
                record={record}
                dataType="currency"
                currency="EUR"
              />
              <GuaranteeDataBlobField
                label="Total des charges"
                source="synthese.endettement.totalCharges"
                record={record}
                dataType="currency"
                currency="EUR"
              />
              <GuaranteeDataBlobField
                label="Revenu résiduel disponible"
                source="synthese.endettement.revenuResiduelDisponible"
                record={record}
                dataType="currency"
                currency="EUR"
              />
            </FormRow>
            <FormRow className={classes.row}>
              <GuaranteeDataBlobField
                label="Charge d'emprunt"
                source="synthese.endettement.chargeEmprunt"
                record={record}
                dataType="percent"
              />
              <GuaranteeDataBlobField
                label="Taux d'endettement"
                source="synthese.endettement.tauxEndettement"
                record={record}
                dataType="percent"
              />
              <GuaranteeDataBlobField
                label="Taux d'endettement maximum "
                source="synthese.endettement.tauxEndettementMax"
                record={record}
                dataType="percent"
              />
            </FormRow>
          </FormSection>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            size="medium"
            label="Fermer"
            onClick={toggleOpen}
            type="button"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
