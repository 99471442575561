import React, { useCallback, useState } from 'react';
import { useDataProvider, useNotify, InputProps, Record } from 'ra-core';

import { IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';

// Cycle needed because a connection edition lives within a record edition
import ConnectionCreateDialog from './ConnectionCreateDialog'; // eslint-disable-line import/no-cycle

import { useTranslateIf } from '../../../hooks/use-translate-if';
import { ResourceKey } from '../../../project/projectInfos';

type ConnectionCreateDialogButtonProps = InputProps & {
  resource: ResourceKey;
  onCreate: (record: Record) => void;
};

const ConnectionCreateDialogButton: React.FC<
  ConnectionCreateDialogButtonProps
> = ({ resource, onCreate, className }: ConnectionCreateDialogButtonProps) => {
  const translate = useTranslateIf();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [showDialog, setShowDialog] = useState(false);
  const handleOpen = useCallback(
    event => {
      event.preventDefault();
      setShowDialog(true);
    },
    [setShowDialog],
  );
  const handleClose = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  const onSuccess = useCallback(
    (response: { data: Record }) => {
      onCreate(response.data);
      handleClose();
      notify('ra.notification.created', 'info');
    },
    [handleClose, notify, onCreate],
  );

  const handleSave = useCallback(
    values => {
      dataProvider
        .create(resource, {
          data: values,
        })
        .then(response => {
          onSuccess(response);
        });
    },
    [dataProvider, resource, onSuccess],
  );

  return (
    <>
      <Tooltip title={translate(`ra.action.create`)}>
        <IconButton onClick={handleOpen} className={className}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      {showDialog && (
        <ConnectionCreateDialog
          resource={resource}
          onSave={handleSave}
          onStandaloneSuccess={onSuccess}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default ConnectionCreateDialogButton;
