/* eslint-disable class-methods-use-this */
/* eslint-disable no-alert */
import React, { useCallback, useState } from 'react';
import { ContentBlock, EditorState, SelectionState } from 'draft-js';

type SelectedValue = {
  type: string;
  entityKey: string;
  contentBlock: ContentBlock;
};
type Selected = SelectedValue | null;

type SetSelected = (
  newSelected: Selected,
  editorState: EditorState,
  onEditorStateChange: (editorState: EditorState) => void,
) => void;

const DEFAULT_SELECTED: Selected = null;

export const SelectedContext = React.createContext<
  [selected: Selected, setSelected: SetSelected]
>([DEFAULT_SELECTED, () => {}]);

const SelectedContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selected, setSelectedRaw] = useState<Selected>(DEFAULT_SELECTED);
  const setSelected = useCallback<SetSelected>(
    (
      newSelected: Selected,
      editorState: EditorState,
      onEditorStateChange: (editorState: EditorState) => void,
    ) => {
      if (newSelected !== null) {
        // Select image

        const blockKey = newSelected.contentBlock.getKey();
        const contentState = editorState.getCurrentContent();
        const beforeBlock = contentState.getBlockBefore(blockKey);
        const afterBlock = contentState.getBlockAfter(blockKey);

        if (beforeBlock && afterBlock) {
          const selection = new SelectionState({
            anchorKey: beforeBlock.getKey(),
            anchorOffset: beforeBlock.getLength(),
            focusKey: afterBlock.getKey(),
            focusOffset: 0,
            hasFocus: true,
            isBackward: false,
          });
          const newEditorState = EditorState.forceSelection(
            editorState,
            selection,
          );

          onEditorStateChange(newEditorState);
        }
      } else {
        // Unselect image

        const currentSelection = editorState.getSelection();
        const anchorKey = currentSelection.getAnchorKey();
        const anchorOffset = currentSelection.getAnchorOffset();
        const focusKey = currentSelection.getFocusKey();
        const focusOffset = currentSelection.getFocusOffset();

        // Check if user did not move selection himself (do not override his decision, doesn't work if click on end of previous line because the selection range is the same as the selected image
        if (anchorKey !== focusKey || anchorOffset !== focusOffset) {
          const selection = new SelectionState({
            anchorKey: focusKey,
            anchorOffset: 0,
            focusKey,
            focusOffset: 0,
            hasFocus: true,
            isBackward: false,
          });
          const newEditorState = EditorState.forceSelection(
            editorState,
            selection,
          );
          onEditorStateChange(newEditorState);
        }
      }
      setSelectedRaw(newSelected);
    },
    [],
  );

  return (
    <SelectedContext.Provider value={[selected, setSelected]}>
      {children}
    </SelectedContext.Provider>
  );
};

export default SelectedContextProvider;
