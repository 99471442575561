import Typography from '@material-ui/core/Typography';
import React from 'react';
import { get } from 'lodash';
import {
  NumberFieldProps,
  useRecordContext,
  NumberInput,
  NumberInputProps,
  useInput,
} from 'react-admin';
import { InputAdornment, TextField } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { useField, useFormState } from 'react-final-form';
import { getIn } from 'final-form';

import { centsToEur, eurToCents } from '../../lib/utils';
import { Loan } from '../../types/schema';
import sanitizeRestProps from '../../components/Fields/sanitizeRestProps';
import { currencyOptions } from '../../lib/ledger';
import { useFamilyQuotient } from './useFamilyQuotient';
import { useLoanPackages } from './useLoanPackages';

export interface CurrencyFieldProps extends Omit<NumberFieldProps, 'options'> {
  currency?: string;
  source?: string;
  value?: number;
}

export const CurrencyField: React.FC<CurrencyFieldProps> = props => {
  const {
    currency = 'EUR',
    className,
    source,
    locales,
    value,
    ...rest
  } = props;
  const record = useRecordContext(props);
  const reconciledValue = centsToEur(source ? get(record, source) || 0 : value);
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...(currency && { style: 'currency', currency }),
  };
  return (
    <Typography
      variant="body2"
      component="span"
      className={className}
      {...sanitizeRestProps(rest)}
    >
      {reconciledValue.toLocaleString(locales, options)}
    </Typography>
  );
};

export interface CurrencyInputProps extends Omit<NumberInputProps, 'source'> {
  source?: NumberInputProps['source'];
}

export const CurrencyInput: React.FC<CurrencyInputProps> = ({
  disabled,
  source = 'none',
  label,
  locales,
  value,
  ...otherProps
}) => {
  const { values } = useFormState();
  const { id, input, meta } = useInput({ source, ...otherProps });

  const resolvedValue = React.useMemo(() => {
    if (value !== undefined) {
      return value;
    }

    if (source) {
      return getIn(values, source);
    }

    return null;
  }, [source, value, values]);

  if (meta.active && !disabled && source) {
    return (
      <NumberInput
        format={centsToEur}
        parse={eurToCents}
        source={source}
        label={label}
        autoFocus
        {...otherProps}
      />
    );
  }

  return (
    <TextField
      id={id}
      {...otherProps}
      {...input}
      label={label}
      value={centsToEur(resolvedValue).toLocaleString(locales, currencyOptions)}
      // we add a fake helperText to get the same layout as NumberInput
      helperText={
        otherProps.helperText || (
          <span dangerouslySetInnerHTML={{ __html: '&#8203;' }} />
        )
      }
      variant="filled"
      margin="dense"
      disabled={disabled}
      style={{ height: '100%' }}
      InputProps={{
        endAdornment: disabled ? (
          <InputAdornment position="end">
            <LockIcon />
          </InputAdornment>
        ) : undefined,
      }}
    />
  );
};

// https://marmelab.com/react-admin/doc/3.19/Inputs.html#linking-two-inputs
export const QuotientFamilial: React.FC<any> = ({ locales }) => {
  const value = useFamilyQuotient();
  const tooHigh = value > eurToCents(16085);

  return (
    <CurrencyInput
      source="familyQuotient"
      label="Quotient familial"
      value={value}
      disabled
      locales={locales}
      error={tooHigh}
      helperText={tooHigh ? 'ATTENTION QUOTIENT FAMILIAL TROP ÉLEVÉ' : null}
    />
  );
};

export const MaxAmount: React.FC<any> = ({ locales }) => {
  const {
    values: { checkEloignement, checkEnseignement, checkEntretien },
  } = useFormState();
  const packages = useLoanPackages();
  const maxAmount = React.useMemo(
    () =>
      (checkEloignement ? packages.estrangement : 0) +
      (checkEntretien ? packages.maintenance : 0) +
      (checkEnseignement ? packages.teaching : 0),
    [packages, checkEloignement, checkEntretien, checkEnseignement],
  );

  const {
    input: { onChange, value },
  } = useField('amount');
  React.useEffect(() => {
    if (value !== maxAmount) {
      onChange(maxAmount);
    }
  }, [maxAmount, onChange, value]);

  return (
    <CurrencyInput
      label="Montant calculé du prêt"
      value={maxAmount}
      disabled
      locales={locales}
    />
  );
};

export const TotalIncome: React.FC<any> = ({ locales }) => {
  const { values } = useFormState();
  const { income = [] } = values as Loan;

  const totalIncome = React.useMemo(
    () => (income || []).reduce((p, v) => p + (v.amount || 0), 0),
    [income],
  );

  return (
    <CurrencyInput
      label="Total des revenus foyer"
      value={totalIncome}
      disabled
      locales={locales}
    />
  );
};
