import * as React from 'react';
import {
  ArrayInput,
  SimpleFormIterator,
  // DateField,
  // TextField,
  DateTimeInput,
  useRecordContext,
  TextInput,
  FormDataConsumer,
  SelectInput,
  required,
} from 'react-admin';
import { format /* , formatISO */ } from 'date-fns';

import {
  ActionHistory as ActionHistoryType,
  Guarantee,
  GuarantyLoan,
  Loan,
} from '../../types/schema';
import { WFAction as LoanAction } from '../../workflow/loans';
import {
  WFAction as GuarantyAction,
  ACTION_LABEL as GuarantyLabels,
} from '../../workflow/guaranty-loans';
import {
  WFAction as GuaranteeAction,
  ACTION_LABEL as GuaranteeLabels,
} from '../../workflow/guarantee-enum';

type ActionHistoryProps = {
  type: 'Loan' | 'GuarantyLoan' | 'Guarantee';
};

type NodeType<T> = T extends 'Loan'
  ? Loan
  : T extends 'GuarantyLoan'
  ? GuarantyLoan
  : T extends 'Guarantee'
  ? Guarantee
  : never;

type ActionEnum<T> = T extends 'Loan'
  ? typeof LoanAction
  : T extends 'GuarantyLoan'
  ? typeof GuarantyAction
  : T extends 'Guarantee'
  ? typeof GuaranteeAction
  : never;

type ActionType<T> = T extends 'Loan'
  ? LoanAction
  : T extends 'GuarantyLoan'
  ? GuarantyAction
  : T extends 'Guarantee'
  ? GuaranteeAction
  : never;

type VisibleProps = [/** message */ boolean, /** others */ boolean];

const notVisible: VisibleProps = [false, false];

const VisibleGuarantyAction: { [k in GuarantyAction]: VisibleProps } = {
  [GuarantyAction.ANTICIPE]: [true, true], // message + date (see logArgs)
  [GuarantyAction.AVENANT]: [true, true], // message + date (see logArgs)
  [GuarantyAction.CLOTURE]: [false, false],
  [GuarantyAction.GARANTIE]: [false, false],
  [GuarantyAction.GESTION]: [false, false],
  [GuarantyAction.PAIEMENT]: [false, false],
  [GuarantyAction.RECOUVREMENT]: [false, false],
};

const VisibleGuaranteeAction: { [k in GuaranteeAction]: VisibleProps } = {
  [GuaranteeAction.CALL]: [true, true], // message + incident + amount
  [GuaranteeAction.DENUNCIATION]: [true, true], // message + confirmation + reason + denunciationDate
  [GuaranteeAction.DISSOCIATION]: [true, true], // message + date
  [GuaranteeAction.TRANSFER]: [true, false], // message
};

// taken loosely from generateInput.tsx - improve ?
const formatInputOrNull = (input: any) =>
  input ? format(new Date(input), "yyyy-MM-dd'T'HH:mm") : null;

const parseInputOrNull = (input: any) =>
  input ? new Date(input).toISOString() : null;

// const isLoan = (t: string, record: any): record is Loan => t === 'Loan';
// const isGuarantyLoan = (t: string, record: any): record is GuarantyLoan =>
//   t === 'GuarantyLoan';

export const ActionHistory: React.FC<ActionHistoryProps> = props => {
  const { type } = props;

  const record: NodeType<typeof type> = useRecordContext();

  const showProps = React.useCallback(
    (a: ActionType<typeof type> | undefined): VisibleProps => {
      if (!a || type === 'Loan') return notVisible;
      if (type === 'GuarantyLoan')
        return VisibleGuarantyAction[a as ActionType<typeof type>];
      return VisibleGuaranteeAction[a as ActionType<typeof type>];
    },
    [type],
  );

  const [labels, actions] = React.useMemo(() => {
    let memoLabels: { [k: string]: string } | undefined;
    let memoActions: ActionEnum<typeof type>;

    if (type === 'Loan') {
      memoActions = LoanAction;
    } else if (type === 'GuarantyLoan') {
      memoActions = GuarantyAction;
      memoLabels = GuarantyLabels;
    } else {
      memoActions = GuaranteeAction;
      memoLabels = GuaranteeLabels;
    }
    return [memoLabels, memoActions];
  }, [type]);

  const actionChoices = React.useMemo(() => {
    return Object.keys(actions).map(a => ({
      id: a,
      name: labels ? labels[a] : a,
    }));
  }, [labels, actions]);

  const validate = React.useCallback(
    (values: (ActionHistoryType | undefined)[] | null) => {
      if (!values) return null;
      const valuesActions = values.map(v => v?.wfAction);
      const counts = valuesActions
        .filter((a): a is string => !!a)
        .reduce(
          (p, a) => ({ ...p, [a]: (p[a] || 0) + 1 }),
          {} as { [k: string]: number },
        );
      const duplicate = Object.keys(counts).find(a => counts[a] > 1);
      if (duplicate)
        return `Les actions doivent être uniques: ${labels?.[duplicate]}`;
      // if (valuesActions.filter(a => !a).length)
      //   return "L'action ne peut être vide";
      return null;
    },
    [labels],
  );

  return (
    <ArrayInput
      source="actionHistory"
      record={record}
      label=""
      validate={validate}
    >
      <SimpleFormIterator /* disableRemove disableAdd */>
        <SelectInput
          source="wfAction"
          label="Action"
          choices={actionChoices}
          validate={required()}
        />

        <DateTimeInput
          source="date"
          label="Date"
          parse={parseInputOrNull}
          format={formatInputOrNull}
          validate={required()}
        />
        <FormDataConsumer>
          {({ getSource, scopedFormData }) => {
            const source = getSource?.('message');
            if (source && showProps(scopedFormData?.wfAction)[0]) {
              return <TextInput source={source} label="Motif" />;
            }
            return null;
            // return (
            //   <TextField
            //     source="wfAction"
            //     record={scopedFormData}
            //     label="Action"
            //   />
            // );
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ getSource, scopedFormData }) => {
            const source = getSource?.('args');
            if (source && showProps(scopedFormData?.wfAction)[1]) {
              return <TextInput source={source} label="Props (JSON)" />;
            }
            return null;
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};
