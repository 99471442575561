import React from 'react';

import {
  AgnosticSelectField,
  AgnosticSelectFieldProps,
} from '../../../components/Fields/agnostic/AgnosticSelectField';

import { WFAction } from '../../../workflow/guaranty-loans-enum';

export type ActionTypeFieldProps = Omit<
  AgnosticSelectFieldProps,
  'options' | 'name'
>;


const options = [
  { label: 'Avenant', value: WFAction.AVENANT },
  { label: 'Remboursement anticipé', value: WFAction.ANTICIPE },
];

export function formatActionType(actionType: WFAction) {
  return options.find(o => o.value === actionType)?.label;
}

export const ActionTypeField: React.FC<ActionTypeFieldProps> = props => {
  return (
    <AgnosticSelectField
      {...props}
      options={options}
      name="actionType"
      label="Type d'action"
    />
  );
};
