import React from 'react';
import { Edit, EditProps, Record, SimpleForm } from 'react-admin';

import { clearDataBeforeSubmit } from '../dataProvider/utils';
import { ResourceKey } from '../project/projectInfos';

import generateAllResourceInputs from './Inputs/generateInputs';
import EditToolbar from './Toolbars/EditToolbar';

type GeneratedEditProps = EditProps;

const GeneratedEdit: React.FC<GeneratedEditProps> = (
  props: GeneratedEditProps,
) => {
  const { resource } = props;

  if (!resource) return null;

  return (
    <Edit
      {...props}
      transform={(data: Record): Record => clearDataBeforeSubmit(data)}
    >
      <SimpleForm submitOnEnter={false} toolbar={<EditToolbar />}>
        {generateAllResourceInputs(resource as ResourceKey)}
      </SimpleForm>
    </Edit>
  );
};

export default GeneratedEdit;
