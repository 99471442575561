import React from 'react';
import ReactDOMServer from 'react-dom/server';

// import ImageEl, { ImageElProps } from '../../../../../baseElements/ImageEl';

type LinkElProps = {
  title?: string;
  url?: string;
  targetOption?: string;
  [k: string]: string | undefined;
};

export default function linkStringifyer(propsRaw: LinkElProps, text?: string) {
  const {
    title,
    url: href,
    targetOption: target = '_self',
    ...rest
  } = propsRaw;
  const otherProps = Object.keys(rest).reduce(
    (acc: { [k: string]: string }, key) => {
      const value = rest[key];
      if (!value) {
        acc[key] = key;
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {},
  );
  const props = { href, target, ...otherProps };

  const link = <a {...props}>{text ?? title ?? 'link'}</a>;
  return ReactDOMServer.renderToStaticMarkup(link);
}
