import { useMemo } from 'react';
import { usePermissions } from 'ra-core';

export enum Roles {
  AUTH = 'AUTH',
  ADMIN = 'ADMIN',
  HT_VIEWER = 'HT_VIEWER',
  HT_EDITOR = 'HT_EDITOR',
  MINO_VIEWER = 'MINO_VIEWER',
  MINO_EDITOR = 'MINO_EDITOR',
  VIEWER = 'VIEWER',
  EDITOR = 'EDITOR',
  SUPER_USER = 'SUPER_USER',
}

// eslint-disable-next-line import/prefer-default-export
export function useIsAdmin(): boolean {
  const { permissions } = usePermissions();

  return (
    permissions?.includes(Roles.ADMIN) ||
    (process.env.NODE_ENV === 'development' &&
      permissions?.includes('Cognito_RESAAdminAuth_Role'))
  );
}

const useHasRole = (role: Roles): boolean => {
  const { loading, permissions } = usePermissions();

  const isRole = useMemo((): boolean => {
    if (!loading && permissions) {
      return permissions.includes(role);
    }
    return false;
  }, [role, loading, permissions]);
  return isRole;
};

export const useCanAccess = () => {
  const { loading } = usePermissions();

  const isSuper = useHasRole(Roles.SUPER_USER);
  const isAdmin = useIsAdmin();
  const isViewer = useHasRole(Roles.VIEWER);
  const isEditor = useHasRole(Roles.EDITOR);

  return {
    loading,
    canRead: isViewer || isEditor || isAdmin || isSuper,
    canWrite: isEditor || isAdmin || isSuper,
    isAdmin,
    isSuper,
  };
};
