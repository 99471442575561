import React from 'react';
import { Create, CreateProps, Record, SimpleForm } from 'react-admin';

import { OwnerForm } from './OwnerForm';
import { clearDataBeforeSubmit } from '../../dataProvider/utils';
import { Breadcrumb } from '../../components/Breadcrumb';

const OwnerTitle: React.FC = () => {
  const fragments = React.useMemo(
    () =>
      [
        {
          title: `Création d'un demandeur`,
          typename: 'Owner',
        },
      ].filter(Boolean),
    [],
  );

  return <Breadcrumb fragments={fragments} />;
};

export const OwnerCreate: React.FC<CreateProps> = props => {
  return (
    <Create
      {...props}
      title={<OwnerTitle />}
      transform={(data: Record): Record => clearDataBeforeSubmit(data)}
    >
      <SimpleForm redirect="edit" submitOnEnter={false}>
        <OwnerForm kind="create" />
      </SimpleForm>
    </Create>
  );
};
