import React, { useCallback } from 'react';
import { Record } from 'ra-core';
import { FieldProps } from 'react-admin';
import { Tooltip, IconButton } from '@material-ui/core';
import Link from '@material-ui/icons/Link';
import { useTranslateIf } from '../../../hooks/use-translate-if';
import { ResourceKey } from '../../../project/projectInfos';

type ConnectionConnectButtonProps = FieldProps & {
  resource: ResourceKey;
  onConnect?: (record: Record) => void;
};

const ConnectionConnectButton: React.FC<ConnectionConnectButtonProps> = ({
  resource,
  record,
  onConnect,
  className,
}: ConnectionConnectButtonProps) => {
  const translate = useTranslateIf();
  const handleConnect = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      if (record && onConnect) {
        onConnect(record);
      }
    },
    [record, onConnect],
  );

  if (!record || !resource) return null;

  return (
    <>
      <Tooltip
        title={translate(`action.connect`, {
          name: translate(`resources.${resource}.name`, 1),
        })}
        className={className}
      >
        <IconButton onClick={handleConnect}>
          <Link />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ConnectionConnectButton;
