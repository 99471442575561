import gql from 'graphql-tag';

export const NodeFragment = gql`
  fragment NodeFields on Node {
    __typename
    id
    createdAt
    updatedAt
    version
    status
  }
`;

export const NodeUpdateInfoFields = gql`
  fragment NodeUpdateInfoFields on NodeUpdateInfo {
    __typename
    mutation
    connect {
      field
      id
    }
    disconnect {
      field
      id
    }
  }
`;

export const TransactionField = gql`
  fragment TransactionField on Request {
    transaction {
      state
      begin
      commit
      rollback
      message
    }
  }
`;
