const maskHashMap: Record<string, string> = {};

export const ANON_STREET = 'SUPPRIMÉ';

const hashPseudonymize = async (value?: string) => {
  if (!value) return value;
  const key = value.trim().toLocaleLowerCase();
  if (maskHashMap[key]) return maskHashMap[key];
  const encoder = new TextEncoder();
  const data = encoder.encode(value.trim().toLocaleLowerCase());
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  maskHashMap[key] = hashHex.substring(0, 8);
  return maskHashMap[key];
};

export const maskPhone = (phone: string) => {
  return phone.replace(/\d/g, 'X');
};

export const maskName = async (name?: string) =>
  name && `ANON${await hashPseudonymize(name)}`;

export const maskEmail = async (email: string) => {
  const emailParts = email.split('@');
  return emailParts.length === 2
    ? `${await maskName(emailParts[0])}@${emailParts[1]}`
    : maskName(email);
};
