import React from 'react';

import Toolbar, { BaseToolbarProps } from './Toolbar';

type CreateToolbarProps = BaseToolbarProps;
const CreateToolbar: React.FC<CreateToolbarProps> = (
  props: CreateToolbarProps,
) => {
  return <Toolbar {...props} kind="create" />;
};

export default CreateToolbar;
