import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Form /* , useFormState */ } from 'react-final-form';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CheckIcon from '@material-ui/icons/Check';
import { Button, useRecordContext, useRedirect } from 'react-admin';

import { ActionTypeField } from './ActionTypeField';
import { AgnosticFormControl } from '../../../components/Fields/agnostic/AgnosticFormControl';
import { useOnSubmit } from './useOnSubmit';
import Loading from '../../../components/layout/Loading';
import { GuarantyLoan } from '../../../types/schema';
import { WFAction, WFState } from '../../../workflow/guaranty-loans-enum';
import { AvenantActionFields } from './field/AvenantActionFields';
import { AnticipeActionFields } from './field/AnticipeActionFields';

type GuarantyLoanActionDialogProps = {
  disabled?: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      height: 'fit-content',
      '& h1, & >div>div>div:last-child': {
        display: 'none',
      },
      '& svg': {
        color: 'rgb(255, 0, 0)',
      },
      marginTop: '-9px',
      marginBottom: '-10px',
      '& div > div': {
        margin: '0px',
      },
      '& .MuiCircularProgress-root': {
        width: '16px !important',
        height: '16px !important',
      },
    },
    checkIcon: {
      marginBottom: '-6px',
    },
    button: {
      height: '40px',
    },
    error: {
      color: 'red', // improve...
    },
  }),
);

export const GuarantyLoanActionDialog: React.FC<
  GuarantyLoanActionDialogProps
> = ({ disabled }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  // const { values: parentValues } = useFormState();
  const loan = useRecordContext<GuarantyLoan>();
  const redirectTo = useRedirect();

  const hasAction = loan.wfStatus === WFState.GESTION;

  const toggleOpen = React.useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleSuccess = React.useCallback(() => {
    toggleOpen();
    if (loan.guarantee.edges.length)
      // same behaviour as save
      redirectTo('edit', '/cpretGuarantee', loan.guarantee.edges[0]?.node.id);
  }, [loan.guarantee.edges, redirectTo, toggleOpen]);

  const onSubmit = useOnSubmit(loan, setLoading, handleSuccess);

  const validateActionType = React.useCallback((value?: WFAction) => {
    if (!value) {
      return "Vous devez sélectionner un type d'action";
    }
    if (value === WFAction.AVENANT) {
      // always possible here
    }
    if (value === WFAction.ANTICIPE) {
      // validation  is done on submit
    }
    return null;
  }, []);

  const initialValues = React.useMemo(
    () => ({
      actionType: null,
    }),
    [],
  );

  return (
    <>
      {hasAction && (
        <Button
          color="default"
          variant="contained"
          size="medium"
          label="Lancer une action"
          onClick={toggleOpen}
          disabled={disabled}
        >
          <PlayArrowIcon />
        </Button>
      )}
      <Dialog fullWidth open={open} onClose={toggleOpen} maxWidth="lg">
        <DialogTitle>Lancer une action</DialogTitle>
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ values, submitting, form, submitError }) => (
            <>
              {submitError && (
                <div className={classes.error}>{submitError}</div>
              )}
              <DialogContent>
                <AgnosticFormControl fullWidth>
                  <ActionTypeField validate={validateActionType} />
                </AgnosticFormControl>
                {values.actionType === WFAction.AVENANT && (
                  <AvenantActionFields />
                )}
                {values.actionType === WFAction.ANTICIPE && (
                  <AnticipeActionFields />
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  color="default"
                  variant="contained"
                  size="medium"
                  label="Annuler"
                  onClick={toggleOpen}
                  type="button"
                />
                <Button
                  disabled={submitting}
                  color="primary"
                  variant="contained"
                  size="medium"
                  label={submitting ? 'Exécution en cours...' : 'Valider'}
                  onClick={() => {
                    form.submit();
                  }}
                  type="submit"
                  className={classes.button}
                >
                  <span>
                    {!loading && <CheckIcon className={classes.checkIcon} />}
                    {loading && (
                      <div className={classes.loader}>
                        <Loading />
                      </div>
                    )}
                  </span>
                </Button>
              </DialogActions>
            </>
          )}
        </Form>
      </Dialog>
    </>
  );
};
