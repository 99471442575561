import { FormControl, FormHelperText, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { InputProps, useNotify, useTranslate, Record } from 'ra-core';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { useForm, Field, FieldRenderProps, useField } from 'react-final-form';
import FilePreviewer from '../../lib/FilePreviewer';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
    },
    dropPreviewWrapper: {
      display: 'flex',
      flexFlow: 'row wrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
      margin: -theme.spacing(2),

      '& > *': {
        margin: theme.spacing(2),
      },
    },
    dropZone: {
      flex: '1 1 600px',

      backgroundColor: theme.palette.background.default,
      cursor: 'pointer',
      padding: theme.spacing(4),
      textAlign: 'center',
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    dropzoneLabel: {
      margin: 0,
    },

    preview: {
      flex: '0 1 auto',
      boxShadow: theme.shadows[4],
      marginBottom: theme.spacing(1),
    },
  }),
);

/** This is a file holder field: used in CreateToolbar,
 *  and then filtered out in the withFileupload dataProvider HOC */
export const UPLOAD_FIELD = '#UPLOAD_FIELD#';

type AssetUploadInputProps = Omit<InputProps, 'source'> & {
  record?: Record;
};
const AssetUploadInput: React.FC<AssetUploadInputProps> = (
  props: AssetUploadInputProps,
) => {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const { className, record, resource } = props;
  const form = useForm();
  const fileField = useField(UPLOAD_FIELD);
  const filenameField = useField('filename');

  const file: File | undefined = fileField.input.value || undefined;

  /*
  const uploadFileValidator = useCallback(
    value => {
      if (typeof value !== 'undefined' || (resource && record?.key)) {
        return undefined;
      }
      return translate('error.required_file');
    },
    [record, resource, translate],
  );
  */

  const handleFile = useCallback(
    (droppedFile: File) => {
      form.change(UPLOAD_FIELD, droppedFile);
      const previousFile = file;
      if (
        !filenameField.input.value ||
        previousFile?.name === filenameField.input.value // Name was not manually changed, so update it
      ) {
        form.change('filename', droppedFile.name);
      }
      form.change('size', droppedFile.size);
      form.change('type', droppedFile.type);
    },
    [form, file, filenameField.input.value],
  );

  const handleDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      // event: DropEvent,
    ): void => {
      if (acceptedFiles.length + fileRejections.length > 1) {
        notify('error.one_file_only', 'warning');
      } else {
        if (fileRejections.length) {
          // handleFile(fileRejections[0]); // ??
          // notify('warning.changed_format', 'info');
        }
        if (acceptedFiles.length) {
          handleFile(acceptedFiles[0]);
        }
      }
    },
    [handleFile, notify],
  );

  const mimeType = file?.type || record?.mimeType;

  const { getRootProps, getInputProps } = useDropzone({
    // accept: acceptType,
    multiple: false,
    onDrop: handleDrop,
  });

  // TODO - Add <Labeled /> ?
  throw new Error(
    'This component is deprecated and should not be used anymore',
  );

  return (
    <Field
      name={UPLOAD_FIELD}
      // validate={uploadFileValidator}
      className={className}
    >
      {({ meta }: FieldRenderProps<boolean>) => (
        <FormControl
          fullWidth
          error={(meta.touched || meta.submitFailed) && !!meta.error}
          className={classes.root}
        >
          <div className={classes.dropPreviewWrapper}>
            <div className={classes.dropZone} {...getRootProps()}>
              {mimeType && (
                <FilePreviewer
                  mimeType={mimeType}
                  file={file}
                  s3FileInfos={{
                    resource,
                    key: record?.key,
                    filename: record?.filename,
                  }}
                  className={classes.preview}
                  downloadOnClick
                />
              )}
              {/* Fix onClick when used within a ClickAwayListener, triggering it */}
              <input {...getInputProps()} onClick={undefined} />
              <Typography className={classes.dropzoneLabel}>
                {translate('ra.input.file.upload_single')}
              </Typography>
            </div>
          </div>
          {(meta.touched || meta.submitFailed) && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );
};

export default AssetUploadInput;
