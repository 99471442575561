import React from 'react';
import { XOR } from '../types/utils';

type SingleCondition = {
  condition: boolean;
  wrapper: (children: JSX.Element) => JSX.Element;
};

type MultiConditions = {
  conditions: {
    [k: string]: boolean | undefined;
  };
  wrappers: {
    [k: string]: ((children: JSX.Element) => JSX.Element) | undefined;
  };
};

function isSingleCondition(
  props: SingleCondition | MultiConditions,
): props is SingleCondition {
  return typeof (props as SingleCondition).condition !== 'undefined';
}

type ConditionnalWrapperProps = {
  children: JSX.Element;
} & XOR<SingleCondition, MultiConditions>;

const ConditionnalWrapper: React.FC<ConditionnalWrapperProps> = (
  props: ConditionnalWrapperProps,
) => {
  if (isSingleCondition(props)) {
    const { condition, wrapper, children } = props;
    return condition ? wrapper(children) : children;
  }

  const { conditions, wrappers, children } = props;
  const foundWrapper =
    wrappers[Object.keys(wrappers).find(key => conditions[key]) ?? ''];
  return typeof foundWrapper !== 'undefined'
    ? foundWrapper(children)
    : children;
};

export default ConditionnalWrapper;
