/**
 * Export NPF
 *
 * Lundis, à partir de 21h30, sur 24h
 * agents_CPRET_AAAA-MM-JJ-HH-MM.csv
 * le fichier n'est pas zippé
 *
 * -> Worker à 21h le lundi
 */

import { useCallback, useContext } from 'react';
import { DataProviderContext } from 'react-admin';
// import { addMonths } from 'date-fns';
import _ from 'lodash';
import { diff } from 'deep-object-diff';

import { Owner, Person } from '../types/schema';
import { clearDataBeforeSubmit } from '../dataProvider/utils';
import { EnhancedOwner } from '../types/schema-custom';
import {
  borrowersToNPF,
  honorific,
  NPFAddress,
  NPFCessation,
  NPFSociete,
} from './lib/npf';
import { useCpretHistory } from './history';

const FORCED_END_DATE = '2022-01-01';
const FORCED_END_REASON = 'Reprise de données';

export const useExportNPF = () => {
  const dataProvider = useContext(DataProviderContext);
  const cb = useCallback((): Promise<{ [k: string]: string }[]> => {
    const action = async (): Promise<{ [k: string]: string }[]> => {
      // const now = new Date();
      const { data: list } = await dataProvider.getList<EnhancedOwner>(
        'cpretOwner',
        {
          filter: {},
          pagination: { page: 0, perPage: -1 },
          sort: { field: 'sncfCP', order: 'ASC' },
        },
      );
      const npf = list
        // only active loans or active guarantees
        .filter((r: EnhancedOwner) => r.$$cpretActive);
      // with missing endDate or now < endDate + 3 monhts
      // disabled for now (2022-07-04)
      // .filter(
      //   (r: EnhancedOwner) =>
      //     !r.sncfContractEndDate ||
      //     addMonths(new Date(r.sncfContractEndDate), 3) > now,
      // );
      console.log(npf.length);
      const data = borrowersToNPF(npf);
      return data;
    };
    return action();
  }, [dataProvider]);
  return cb;
};

/**
 * Import NPF
 *
 * Lundis, à partir de 22h00 sur 24h
 * CPRET.AGT.N.DIF.H.MMJJ.HHmm.zip
 * le fichier n'est pas zippé
 *
 * -> worker à 23h le mardi ou mercredi matin ?
 */

export const useImportNPF = () => {
  const dataProvider = useContext(DataProviderContext);
  const log = useCpretHistory();
  const cb = useCallback(
    (
      [npfAddresses, npfCessations, npfSocietes]: [
        NPFAddress[],
        NPFCessation[],
        NPFSociete[],
      ],
      commit: boolean,
    ) => {
      const action = async (): Promise<
        [Set<Partial<Owner>>, Map<string, Partial<Owner>>]
      > => {
        const owners = new Map<string, EnhancedOwner[]>();
        const { data: oo } = await dataProvider.getList<EnhancedOwner>(
          'cpretOwner',
          {
            filter: {},
            pagination: { page: 0, perPage: -1 },
            sort: { field: 'sncfCP', order: 'ASC' },
          },
        );
        // index
        oo.forEach(o => {
          const { sncfCP } = o;
          if (!sncfCP) return;
          const prev = owners.get(sncfCP) || [];
          owners.set(sncfCP, [...prev, o]);
        });
        const npfUpdates = new Map<string, Partial<Owner>>();

        npfAddresses.forEach(a => {
          const {
            immat: cp,
            bisTer,
            communeAdresse,
            complementAdresse,
            cpAdresse,
            natureAdresse,
            numeroAdresse,
          } = a;
          if (
            !cp ||
            !(
              bisTer ||
              communeAdresse ||
              complementAdresse ||
              cpAdresse ||
              natureAdresse ||
              numeroAdresse
            )
          )
            return;
          const streetAddress =
            `${numeroAdresse}${bisTer} ${natureAdresse}\n${complementAdresse}`.trim();
          const postalCode = cpAdresse.trim();
          const addressLevel2 = communeAdresse.trim();
          const prev: Partial<Owner> = npfUpdates.get(cp) || {};
          const nextAgent: { [k: string]: string } = {
            streetAddress,
            postalCode,
            addressLevel2,
          };
          // cleanup
          Object.keys(nextAgent).forEach(k => {
            if (!nextAgent[k]) delete nextAgent[k];
          });
          npfUpdates.set(cp, {
            ...prev,
            agent: { ...prev.agent, ...nextAgent },
          });
        });

        npfCessations.forEach(c => {
          const { immat: cp, codeCess, dateCess, motifCess } = c;
          if (!cp || !(codeCess || dateCess || motifCess)) return;
          const prev: Partial<Owner> = npfUpdates.get(cp) || {};
          const next = { ...prev };
          if (dateCess) next.sncfContractEndDate = dateCess;
          if (codeCess)
            next.sncfContractEndReason = `${motifCess} (${codeCess})`;
          npfUpdates.set(cp, next);
        });

        // deduced cessations from empty etat civil
        const npfWarnings = new Map<string, Partial<Owner>>();
        npfSocietes.forEach(s => {
          const { immat: cp, civilite, nomUsuel, prenom, societeCourante } = s;
          if (!cp || civilite || nomUsuel || prenom || societeCourante) return; // will be handled in the next segment
          const prev: Partial<Owner> = npfUpdates.get(cp) || {};
          if (prev.sncfContractEndDate) return;
          const next: Partial<Owner> = {
            /* ...prev */
          };
          next.sncfContractEndDate = FORCED_END_DATE;
          next.sncfContractEndReason = FORCED_END_REASON;
          npfWarnings.set(cp, next);
        });

        // societes et état civil
        npfSocietes.forEach(s => {
          const { immat: cp, civilite, nomUsuel, prenom, societeCourante } = s;
          if (!cp || !(civilite || nomUsuel || prenom || societeCourante))
            return;
          const prev: Partial<Owner> = npfUpdates.get(cp) || {};
          const nextAgent: { [k: string]: string | undefined } = {
            ...prev.agent,
            honorificPrefix: civilite.trim() && honorific(civilite.trim()),
            givenName: prenom.trim(),
            familyName: nomUsuel.trim(),
          };
          // cleanup
          Object.keys(nextAgent).forEach(k => {
            if (!nextAgent[k]) delete nextAgent[k];
          });
          const next = { ...prev, agent: nextAgent };
          if (societeCourante.trim()) next.sncfCompany = societeCourante.trim();
          npfUpdates.set(cp, next);
        });

        const npfUpdated = new Set<Partial<Owner>>();
        await [...npfUpdates.keys()].reduce(async (p1, updateCP) => {
          await p1;
          const update = npfUpdates.get(updateCP);
          await owners.get(updateCP)?.reduce(async (p2, o) => {
            await p2;
            const nextAgent: Partial<Person> = { ...update?.agent };
            const nextContact: Partial<Person> = {};
            Object.keys(nextAgent).forEach(s => {
              const k = s as keyof Person;
              if (
                o.contact?.[k] &&
                nextAgent[k] &&
                o.contact[k] === o.agent?.[k] &&
                o.contact[k] !== nextAgent[k]
              ) {
                // contact and agent are identical: sync udpate !
                nextContact[k] = nextAgent[k];
              }
            });
            // Fix for not overwritting existing sncfContractEndDate
            if (
              update?.sncfContractEndDate === FORCED_END_DATE &&
              update?.sncfContractEndReason === FORCED_END_REASON &&
              o.sncfContractEndDate
            ) {
              delete update.sncfContractEndDate;
              delete update.sncfContractEndReason;
            }
            const nextData = {
              ...o,
              ...update,
              agent: { ...o.agent, ...nextAgent },
              contact: { ...o.contact, ...nextContact },
            };
            // dedupe
            if (_.isEqual(o, nextData)) {
              console.log(`No change for ${o.sncfCP}, ignoring`);
              return;
            }
            console.log(`${commit ? 'Committing' : 'Will update'} ${o.sncfCP}`);
            npfUpdated.add({
              id: o.id,
              sncfCP: o.sncfCP,
              ...diff(o, nextData),
            });
            // if (Object.keys(nextContact).length) console.log(nextContact);
            if (!commit) return;
            await dataProvider.update('cpretOwner', {
              id: o.id,
              previousData: o,
              data: clearDataBeforeSubmit(nextData),
            });
            log(
              {
                message: 'cpret.worker.npf.import',
                args: update || {},
              },
              [o],
            );
          }, Promise.resolve());
        }, Promise.resolve());
        return [npfUpdated, npfWarnings];
      };
      return action();
    },
    [dataProvider, log],
  );
  return cb;
};
