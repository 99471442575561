import React, { useMemo, useState } from 'react';
import { Admin as ReactAdmin, AuthProvider, Resource } from 'react-admin';
import _intersection from 'lodash/intersection';

import { Level } from 'phicomas-client';

import { getSncfTheme } from '../theme/custom/sncf';

import EnvironmentContext from '../context/environmentContext';
import AppContext, { AppContextType } from '../context/AppContext';
import { defaultEnvironment } from '../project/projectsEnvironments';

import dataSaga from '../dataProvider/saga';
import getAuthProvider, { awsCreds } from '../auth/authProvider';
import { getDataProvider } from '../dataProvider/dataProvider';
import { CustomDataProvider } from '../dataProvider/type';
import i18nProvider from '../i18n';

import LoadingPage from './layout/LoadingPage';
import CustomLayout from './layout/CustomLayout';
import LoginPage from './LoginPage';

import projectInfos, {
  getResourceCustomization,
} from '../project/projectInfos';
import { EnhancedAccessRoles } from '../types/customization';
import { CustomList } from './CustomList';
import { CustomEdit } from './CustomEdit';
import { customRoutes } from '../routes/customRoutes';
import { CustomCreate } from './CustomCreate';

const muiTheme = getSncfTheme();

const resources = projectInfos.customization.resourcesOrder;

function checkAccess(
  accessRoles: EnhancedAccessRoles,
  userRoles: string[] = [],
): boolean {
  if (typeof accessRoles === 'boolean') return accessRoles;
  return _intersection(userRoles, accessRoles).length > 0;
}

const Admin: React.FC = () => {
  const environmentState = useState<Level>(defaultEnvironment);
  const [environmentLevel] = environmentState;
  const [appState, setAppState] = useState<AppContextType>({ loading: 0 });

  const [dataProvider, authProvider, customSaga] = useMemo<
    [CustomDataProvider, AuthProvider, any]
  >(() => {
    const ap = getAuthProvider();
    const dp = getDataProvider(
      environmentLevel,
      { awsCredentials: awsCreds },
      setAppState,
    ); // ap.getCreds() ?
    const sg = [dataSaga(dp)];
    // initialize fetch loop for owners
    dp.getListAll('cpretOwner'); // not really needed as also started from withObserver.ts now
    return [dp, ap, sg];
  }, [environmentLevel]);

  return (
    <EnvironmentContext.Provider value={environmentState}>
      <AppContext.Provider value={appState}>
        <ReactAdmin
          key={`react-admin environment ${environmentLevel}`} // So it resets on environment change
          title="CPrêt"
          dataProvider={dataProvider}
          customSagas={customSaga}
          i18nProvider={i18nProvider}
          authProvider={authProvider}
          theme={muiTheme}
          layout={CustomLayout}
          loginPage={LoginPage}
          loading={LoadingPage}
          disableTelemetry
          customRoutes={customRoutes}
        >
          {(permissions: string[]): React.ReactNode => {
            const isSuperAdmin = permissions.includes(
              'Cognito_RESAAdminAuth_Role',
            );
            const Resources = resources.reduce<React.ReactNode[]>(
              (acc, resource) => {
                const {
                  awsExports: {
                    appSync: { [environmentLevel]: appSync },
                  },
                  customization: { canAccess },
                } = projectInfos;
                if (
                  appSync && // No appSync config for this environment => Hide it's resources
                  (isSuperAdmin || checkAccess(canAccess, permissions))
                ) {
                  const customization = getResourceCustomization(resource);
                  const { canList, canEdit, canCreateDelete, icon } =
                    customization;

                  acc.push(
                    <Resource
                      key={resource}
                      name={resource}
                      icon={icon}
                      // intent={
                      //   checkAccess(canList, permissions)
                      //     ? 'route'
                      //     : 'registration'
                      // }
                      list={
                        isSuperAdmin || checkAccess(canList, permissions)
                          ? CustomList
                          : undefined
                      }
                      edit={
                        isSuperAdmin || checkAccess(canEdit, permissions)
                          ? CustomEdit
                          : undefined
                      }
                      create={
                        isSuperAdmin ||
                        checkAccess(canCreateDelete, permissions)
                          ? CustomCreate
                          : undefined
                      }
                    />,
                  );
                }
                return acc;
              },
              [],
            );

            if (Resources.length === 0) {
              Resources.push(<Resource name="login" />);
            }

            return Resources;
          }}
        </ReactAdmin>
      </AppContext.Provider>
    </EnvironmentContext.Provider>
  );
};

export default Admin;
