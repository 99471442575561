import React from 'react';

import { AgnosticSelectField } from '../../../../components/Fields/agnostic/AgnosticSelectField';
import { AgnosticTextField } from '../../../../components/Fields/agnostic/AgnosticTextField';
import { AgnosticDateField } from '../../../../components/Fields/agnostic/AgnosticDateField';
import { AgnosticFormControl } from '../../../../components/Fields/agnostic/AgnosticFormControl';

export interface DenunciationValues {
  confirmation: boolean;
  reason: string;
  denunciationDate: string;
}

export const DenunciationGuaranteeActionFields: React.FC = () => {
  const validateDenunciationDate = React.useCallback((value: string) => {
    if (!value) {
      return 'Vous devez saisir une date de dénonciation';
    }

    return null;
  }, []);

  const validateReason = React.useCallback((value: string) => {
    if (!value) {
      return 'Vous devez saisir un motif';
    }

    return null;
  }, []);

  const validateConfirmation = React.useCallback((value?: boolean) => {
    if (value === undefined) {
      return 'Vous devez choisir une valeur';
    }

    return null;
  }, []);

  const options = React.useMemo(
    () => [
      { label: 'OUI', value: true },
      { label: 'NON', value: false },
    ],
    [],
  );

  return (
    <>
      <AgnosticFormControl fullWidth>
        <AgnosticSelectField
          name="confirmation"
          label="Confirmation"
          options={options}
          required
          validate={validateConfirmation}
        />
      </AgnosticFormControl>
      <AgnosticFormControl fullWidth>
        <AgnosticTextField
          name="reason"
          label="Motif"
          required
          validate={validateReason}
        />
      </AgnosticFormControl>
      <AgnosticFormControl fullWidth>
        <AgnosticDateField
          name="denunciationDate"
          label="Date de dénonciation"
          required
          validate={validateDenunciationDate}
        />
      </AgnosticFormControl>
    </>
  );
};
