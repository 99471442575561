import { Provider } from '../../../aws';
import { AwsExports } from '../../../aws/aws-exports';
import { Instance, Level } from '../../../types';

const awsExports: AwsExports = {
  cognito: {
    // back is usually overriden by the back...
    [Instance.BACK]: {
      provider: Provider.Google,
      // provider: Provider.SNCF,
      // loginEnv: {
      //   [Level.DEV]: 'us-east-1:cpret-dev',
      //   [Level.STAGE]: 'eu-west-1:cpret-stage',
      //   [Level.PROD]: 'eu-west-1:cpret-prod',
      // },
    },
  },
  appSync: {
    [Level.DEV]: {
      region: 'us-east-1',
      graphqlEndpoint:
        'https://bsu2pvizbrf2ravg4rk7x3tyli.appsync-api.us-east-1.amazonaws.com/graphql',
    },
    [Level.STAGE]: {
      region: 'eu-west-1',
      graphqlEndpoint:
        'https://xn3ftpvafvegbdew3g6na3bffa.appsync-api.eu-west-1.amazonaws.com/graphql',
    },
    [Level.PROD]: {
      region: 'eu-west-1',
      graphqlEndpoint:
        'https://d4bdtqltm5f2zgizl6cboltflu.appsync-api.eu-west-1.amazonaws.com/graphql',
    },
  },
  s3: {
    [Level.DEV]: {
      region: 'eu-west-1',
      apiVersion: '2006-03-01',
      bucket: 'assets.content.phileog.com',
      prefix: 'sncf/cpret',
    },
    [Level.STAGE]: {
      region: 'eu-west-3',
      apiVersion: '2006-03-01',
      bucket: 'rec-userdata.cpret.sncf.fr',
      prefix: 'stage/cpret',
    },
    [Level.PROD]: {
      region: 'eu-west-3',
      apiVersion: '2006-03-01',
      bucket: 'userdata.cpret.sncf.fr',
      prefix: 'cpret',
    },
  },
};

export default awsExports;
