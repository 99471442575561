export interface SchemaConfig {
  connections: Array<ConnectionTuple>;
}

export enum OneManyEnum {
  ONE = 'one',
  MANY = 'many',
}

type ConnectionTuple = [leftField: FieldTuple, rightField: FieldTuple];
/** Is UNIQUE within all connection tuples */
type FieldTuple = [
  typeName: string,
  fieldName: string,
  connectionType?: OneManyEnum,
];
