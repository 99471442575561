import { get } from 'lodash';
import React from 'react';
import { FunctionField } from 'react-admin';

import { Schedule } from '../../../../types/schema';

export interface ReasonProps {
  // eslint-disable-next-line react/no-unused-prop-types
  label: string;
}
export const Reason: React.FC<ReasonProps> = () => {
  const render = React.useCallback((record?: Schedule) => {
    const label = get(record, ['label'], null) as string | null;

    return <>{label || ''}</>;
  }, []) as any;

  return <FunctionField render={render} />;
};
