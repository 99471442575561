import React from 'react';
import { DataProviderContext } from 'react-admin';
import gql from 'graphql-tag';
import _ from 'lodash';

import { getResourceInfos } from '../project/projectInfos';
import { CustomDataProvider } from '../dataProvider/type';
import { AssetEdge, AssetConnection } from '../types/schema';

// CpretLedgerFieldsBase
const fragCpretAssetFieldsBase = gql`
  ${getResourceInfos('cpretAsset').fragments.base}
`;

const PageInfoFragment = gql`
  fragment PageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;

export function useResourceAttachments<
  T extends { attachments: AssetConnection },
>(
  resource: string,
  id?: string,
  cacheId?: number,
): [Partial<AssetConnection>, boolean] {
  const dataProvider = React.useContext(
    DataProviderContext,
  ) as CustomDataProvider;
  const client = dataProvider.getClient();

  const rsrc = _.camelCase(resource);
  const query = React.useMemo(
    () => gql`
  query getAttachment($id: ID!, $after: ID, $first: Int) {
    ${rsrc}(id: $id) {
      rid: id # not requiring id prevents apollo from caching this query, yay!
      attachments(after: $after, first: $first) {
        edges {
          node {
            ...CpretAssetFieldsBase
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragment}
  ${fragCpretAssetFieldsBase}
`,
    [rsrc],
  );

  const [attachments, setAttachments] = React.useState<
    Partial<AssetConnection>
  >({ edges: [] });
  const [loading, setLoading] = React.useState(false);

  const semaphore = React.useRef<number | undefined>();

  const fetchAttachments = React.useCallback(
    async (rid: string, after?: string, pageSize = 100) => {
      const { data } = await client.query({
        query,
        variables: { id: rid, after, first: pageSize },
        fetchPolicy: 'no-cache',
      });

      const { attachments: res } = data[rsrc] as T;
      const edges = res?.edges?.map(edge => edge as AssetEdge) || [];
      return { edges, pageInfo: res.pageInfo };
    },
    [client, query, rsrc],
  );

  const fetchPage = React.useCallback(
    (rid: string, after?: string) => {
      const action = async () => {
        setLoading(true);
        const ts = Date.now();
        semaphore.current = ts;
        const { edges, pageInfo } = await fetchAttachments(rid, after);
        if (semaphore.current !== ts) return; // someone else has taken over
        setAttachments(prev => ({
          ...prev,
          edges: [...(prev.edges || []), ...edges],
        }));
        setLoading(false);
        if (pageInfo.hasNextPage) {
          requestAnimationFrame(() => {
            fetchPage(rid, pageInfo.endCursor);
          });
        }
      };
      return action();
    },
    [fetchAttachments],
  );

  React.useEffect(() => {
    if (id) {
      setAttachments({ edges: [] });
      fetchPage(id);
    }
  }, [fetchPage, id, cacheId]);

  return [attachments, loading];
}
