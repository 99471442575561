import React, { useCallback } from 'react';
import { useListContext, TextField } from 'react-admin';
import clsx from 'clsx';

import { ID } from 'phicomas-client';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import { Asset } from '../../../types/schema';
import FilePreviewerField from '../../Fields/FilePreviewerField';
import { ResourceKey } from '../../../project/projectInfos';

const CARD_HEIGHT = 200;

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    grid: {
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fill, minmax(${
        // v dialog width            - v dialog padding     - v scrollbar
        //                                                          v 5 cards max per line
        (theme.breakpoints.values.xl - theme.spacing(1) * 2 - 15) / 5 -
        theme.spacing(1) * 4 // 4 gaps max per line (5 cards)
      }px, auto))`,
      gap: theme.spacing(1),
      justifyItems: 'center',
    },
    card: {
      padding: theme.spacing(1),
      width: '100%',
      height: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
    selected: {
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0, 255, 0, 0.1)',
      },

      '& $preview': {
        transform: 'scale(0.8)',
      },
    },
    preview: {},
  }),
);

type AssetPickerGridProps = {
  onSelect: (id: ID) => void;
  onDeselect: (id: ID) => void;
  selected?: ID[];
};

const AssetPickerGrid: React.FC<AssetPickerGridProps> = ({
  onSelect,
  onDeselect,
  selected,
}: AssetPickerGridProps) => {
  const classes = useStyles();
  const { ids, data, resource } = useListContext();

  const handleClick = useCallback(
    (
      event:
        | React.MouseEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLDivElement>,
    ) => {
      const { id } = event.currentTarget.dataset;
      if (id) {
        if (selected?.includes(id)) {
          onDeselect(id);
        } else {
          onSelect(id);
        }
      }
    },
    [onDeselect, onSelect, selected],
  );

  return (
    <div className={classes.grid}>
      {(ids as ID[]).map(id => (
        <div
          key={`connect ${id}`}
          data-id={id}
          onClick={handleClick}
          onKeyPress={handleClick}
          role="button"
          tabIndex={-1}
          className={clsx(classes.card, {
            [classes.selected]: selected?.includes(id),
          })}
        >
          <FilePreviewerField
            resource={resource as ResourceKey}
            record={data[id] as Asset}
            height={CARD_HEIGHT}
            fit
            className={classes.preview}
          />
          <TextField record={data[id]} source="filename" />
        </div>
      ))}
    </div>
  );
};

export default AssetPickerGrid;
