import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

// import { AgnosticSelectField } from '../../../../components/Fields/agnostic/AgnosticSelectField';
import { AgnosticTextField } from '../../../../components/Fields/agnostic/AgnosticTextField';
import { AgnosticDateField } from '../../../../components/Fields/agnostic/AgnosticDateField';
import { AgnosticFormControl } from '../../../../components/Fields/agnostic/AgnosticFormControl';

export interface AnticipeValues {
  message: string;
  endDate: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    reason: {
      // width: 200,
    },
  }),
);

export const AnticipeActionFields: React.FC = () => {
  const classes = useStyles();

  const validateDate = React.useCallback((value: string) => {
    if (!value) {
      return 'Vous devez saisir une date de fin de remoursement';
    }

    return null;
  }, []);

  const validateMessage = React.useCallback((value: string) => {
    if (!value) {
      return 'Vous devez saisir un motif';
    }

    return null;
  }, []);

  /*
  const options = React.useMemo(
    () => [
      { label: 'Total', value: 'Total' },
      { label: 'Partiel', value: 'Partiel' },
    ],
    [],
  );
  */

  return (
    <>
      <AgnosticFormControl fullWidth>
        <AgnosticTextField
          name="message"
          label="Motif"
          // options={options}
          required
          validate={validateMessage}
          className={classes.reason}
        />
      </AgnosticFormControl>
      <AgnosticFormControl fullWidth>
        <AgnosticDateField
          name="endDate"
          label="Date fin remboursement"
          required
          validate={validateDate}
        />
      </AgnosticFormControl>
    </>
  );
};
