import React, { useCallback } from 'react';
import {
  // ResourceContextProvider,
  Button,
  Datagrid,
  DataProviderProxy,
  List,
  ListProps,
  NumberField,
  SortPayload,
  TextField,
  TopToolbar,
  useDataProvider,
  useListController,
  useNotify,
} from 'react-admin';
import Table from 'cli-table3';

import DownloadIcon from '@material-ui/icons/GetApp';
import { useTheme, Theme } from '@material-ui/core';
import Color from 'color';
import { stringify } from 'csv-stringify/dist/esm/sync';
import { format } from 'date-fns';

import { BorrowerNameField } from '../../resource/borrower/BorrowerNameField';
import { Style } from '../../types/styles';
import { CurrencyField } from '../../resource/loan/CurrencyFields';
import {
  gpgFilename,
  receiptHeader,
  statsFilename,
  useExportGPG,
} from '../../workflow/exports-gpg';
import { centsToEur, csvAmount } from '../../lib/utils';
import { currencyOptions as options } from '../../lib/ledger';
import download from '../../lib/download';
import { EnhancedBorrower, EnhancedOwner } from '../../types/schema-custom';
import PaginationWithLoader from '../layout/PaginationWithLoader';
import { useAppContext } from '../../context/AppContext';
import { OwnerLinkField } from './OwnerLinkField';

export const key = 'fluxGPG';

const rowStyle =
  (theme: Theme) =>
  (record: any, index: number): Style => {
    return {
      backgroundColor:
        index % 2
          ? 'transparent'
          : Color(theme.palette.specials.cpretBlue).alpha(0.1).toString(),
    };
  };

const COLS = [
  'CP',
  'Nom',
  'Prénom',
  'Nom',
  'Prénom',
  'RUM',
  'Date de prélevement',
  'Type',
  'Nb',
  'GPG',
  '!',
];

const exportFilename = (now = new Date()) =>
  `${format(now, 'yyyyMMdd-HHmmss')}-cpret-GPG.csv`;

const ListActions = ({
  onExport,
  onReceipt,
  onCsvExport,
}: {
  onExport: any;
  onReceipt: any;
  onCsvExport: any;
}) => {
  const { loading } = useAppContext();
  return (
    <TopToolbar>
      {/* FIXME - disable button until loaded */}
      <Button onClick={onExport} label="XML" disabled={!!loading}>
        <DownloadIcon />
      </Button>
      <Button onClick={onReceipt} label="Rapport" disabled={!!loading}>
        <DownloadIcon />
      </Button>
      <Button onClick={onCsvExport} label="Exporter" disabled={!!loading}>
        <DownloadIcon />
      </Button>
    </TopToolbar>
  );
};

const brwrsToCsv = async (
  bb: EnhancedBorrower[],
  dataProvider: DataProviderProxy,
): Promise<string[][]> => {
  const input = bb.entries();
  const output: string[][] = [];

  const getChunk = (size = 16): EnhancedBorrower[] =>
    [...new Array(size)]
      .map(() => input.next())
      .filter(n => !n.done)
      .map(n => n.value[1]);
  let next = getChunk();
  while (next.length) {
    const { data: oo } = await dataProvider.getMany<EnhancedOwner>(
      'cpretOwner',
      {
        ids: next.map(b => b.owner.edges?.[0]?.node.id).filter(Boolean),
      },
    );
    next.forEach(b => {
      const oid = b.owner.edges?.[0]?.node.id;
      const o = oid ? oo.find(own => own.id === oid) : undefined;
      output.push(
        [
          o?.sncfCP,
          o?.contact?.familyName,
          o?.contact?.givenName,
          b.contact?.familyName,
          b.contact?.givenName,
          b.umr,
          String(b.pmtDay),
          b.loanType,
          String(b.$$loanCount),
          csvAmount(b.$$paymentAmount),
          String(b.$$paymentScheduleAlert),
        ].map(s => s || ''),
      );
    });
    next = getChunk();
  }
  return output;
};

const filter = { $$paymentAmount_lt: 0 };
const sort: SortPayload = { field: '$$paymentAmount', order: 'ASC' };

export const BorrowerList: React.FC<ListProps> = props => {
  const theme = useTheme();
  const exportGPG = useExportGPG();
  const notify = useNotify();

  const { staticContext, ...rest } = props;
  if (!rest.resource) throw new Error('Mandatory resource');

  const handleExport = useCallback(() => {
    exportGPG(false)
      .then(([xml]) => {
        const now = new Date();
        download(xml, gpgFilename(now), 'application/xml;charset=utf-8');
      })
      .catch(e => {
        notify('cpret.export.gpg.error', 'error', {
          status: e.status,
          message: e.message,
        });
      });
  }, [exportGPG, notify]);

  const handleReceipt = useCallback(() => {
    exportGPG(false)
      .then(([, stats]) => {
        const now = new Date();

        const table = new Table({
          head: ['DATE', 'PRÉLÈVEMENTS', 'TOTAL'],
          style: { border: [], head: [] },
          colWidths: [25, 25, 25],
        });
        table.push(
          [
            stats[1].dueDate,
            stats[1].count,
            centsToEur(-stats[1].sum).toLocaleString('fr', options),
          ],
          [
            stats[15].dueDate,
            stats[15].count,
            centsToEur(-stats[15].sum).toLocaleString('fr', options),
          ],
          [
            'TOTAL',
            stats[1].count + stats[15].count,
            centsToEur(-(stats[1].sum + stats[15].sum)).toLocaleString(
              'fr',
              options,
            ),
          ],
        );
        download(
          `${receiptHeader}${table.toString()}`,
          statsFilename(now),
          'text/plain;charset=utf-8',
        );
      })
      .catch(e => {
        notify('cpret.export.gpg.error', 'error', {
          status: e.status,
          message: e.message,
        });
      });
  }, [exportGPG, notify]);

  const dataProvider = useDataProvider();
  const lst = useListController(rest);

  const handleCsvExport = useCallback(() => {
    const action = async () => {
      const { data } = await dataProvider.getList<EnhancedBorrower>(
        'cpretBorrower',
        {
          filter,
          pagination: { page: 0, perPage: -1 },
          sort,
        },
      );
      const csvData = await brwrsToCsv(data, dataProvider);
      const out = stringify(csvData, {
        header: true,
        delimiter: ';',
        columns: COLS,
      });
      download(Buffer.from(out, 'latin1'), exportFilename());
    };
    return action();
  }, [dataProvider]);

  // this hack prevents console error because useGetMainList returns total undefined instead of null - FIXME
  if (lst.total === undefined) return null;

  return (
    <List
      {...rest}
      filter={filter}
      perPage={10}
      sort={sort}
      actions={
        <ListActions
          onExport={handleExport}
          onReceipt={handleReceipt}
          onCsvExport={handleCsvExport}
        />
      }
      exporter={false}
      bulkActionButtons={false}
      pagination={<PaginationWithLoader />}
    >
      <Datagrid rowStyle={rowStyle(theme)}>
        <OwnerLinkField label="CP" source="owner.edges[0].node.sncfCP" />
        <OwnerLinkField
          label="Nom"
          source="owner.edges[0].node.contact.familyName"
        />
        <OwnerLinkField
          label="Prénom"
          source="owner.edges[0].node.contact.givenName"
        />
        <BorrowerNameField label="Nom" source="contact.familyName" link />
        <BorrowerNameField label="Prénom" source="contact.givenName" link />
        <TextField label="RUM" source="umr" />
        <NumberField label="Date de prélevement" source="pmtDay" />

        <TextField label="Type" source="loanType" />
        <NumberField label="Nb" source="$$loanCount" />
        <CurrencyField label="GPG" source="$$paymentAmount" />
        <NumberField label="⚠️" source="$$paymentScheduleAlert" />
      </Datagrid>
    </List>
  );
};
