import React from 'react';
import { CreateProps } from 'react-admin';

import { OwnerCreate } from '../resource/owner/OwnerCreate';
import { BorrowerCreate } from '../resource/borrower/BorrowerCreate';
import { LoanCreate } from '../resource/loan/LoanCreate';
import GeneratedCreate from './GeneratedCreate';

export const CustomCreate: React.FC<CreateProps> = ({
  resource,
  ...otherProps
}) => {
  switch (resource) {
    case 'cpretOwner':
      return <OwnerCreate resource={resource} {...otherProps} />;

    case 'cpretBorrower':
      return <BorrowerCreate resource={resource} {...otherProps} />;

    case 'cpretLoan':
      return <LoanCreate resource={resource} {...otherProps} />;

    default:
      return <GeneratedCreate resource={resource} {...otherProps} />;
  }
};
