import React from 'react';
import { FieldProps } from 'react-admin';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import moment from 'moment';
import _get from 'lodash/get';

import sanitizeRestProps from './sanitizeRestProps';

import config from '../../config';

const useStyles = makeStyles<Theme>(
  () =>
    createStyles({
      root: { display: 'inline-block' },
    }),
  { name: 'DateField' },
);

export type DateFieldProps = FieldProps;
const DateField: React.FC<DateFieldProps> = (props: DateFieldProps) => {
  const { record, source, label, emptyText, className, ...rest } = props;
  const classes = useStyles(props);

  if (!record || !source) return null;

  const value = _get(record, source);
  return (
    <div className={clsx(classes.root, className)} {...sanitizeRestProps(rest)}>
      <Typography>
        {moment(value, config.dates.awsDateFormat).format(
          config.dates.displayDateFormat,
        )}
      </Typography>
    </div>
  );
};

export default DateField;
