import React from 'react';
import { useFormState } from 'react-final-form';

import { AgnosticTextField } from '../../../../components/Fields/agnostic/AgnosticTextField';
import { AgnosticAmountField } from '../../../../components/Fields/agnostic/AgnosticAmountField';
import { AgnosticDateField } from '../../../../components/Fields/agnostic/AgnosticDateField';
import { AgnosticFormControl } from '../../../../components/Fields/agnostic/AgnosticFormControl';
import {
  PaymentChoice,
  PaymentChoiceSelectField,
} from './RepaymentLedgerActionFields';

export interface DelayedScheduleRepaymentValues {
  bankName: string;
  paymentChoice: PaymentChoice;
  amount: number;
  checkNumber: string;
  paymentDate: string;
}

export const DelayedScheduleRepaymentByCheckLedgerActionFields: React.FC =
  () => {
    const { values } = useFormState();
    const { paymentChoice } = values as DelayedScheduleRepaymentValues;

    const validateAmount = React.useCallback((value: number) => {
      if (!value) {
        return 'Vous devez saisir un montant';
      }

      if (value <= 0) {
        return 'Le montant doit être supérieur à 0';
      }

      return null;
    }, []);

    const validateCheckNumber = React.useCallback((value: string) => {
      if (!value) {
        return 'Vous devez saisir un numéro de chèque';
      }

      return null;
    }, []);

    const validatePaymentDate = React.useCallback((value: string) => {
      if (!value) {
        return 'Vous devez saisir une date de réglement';
      }

      return null;
    }, []);

    const validateBankName = React.useCallback((value: string) => {
      if (!value) {
        return 'Vous devez saisir un nom de banque';
      }

      return null;
    }, []);

    const validatePayment = React.useCallback((value: string) => {
      if (!value) {
        return 'Vous devez saisir un mode de réglement';
      }
      return null;
    }, []);

    return (
      <>
        <AgnosticFormControl fullWidth>
          <PaymentChoiceSelectField validate={validatePayment} />
        </AgnosticFormControl>
        <AgnosticFormControl fullWidth>
          <AgnosticAmountField
            name="amount"
            label="Montant (€) (ex: 12,34)"
            required
            validate={validateAmount}
          />
        </AgnosticFormControl>
        {paymentChoice === PaymentChoice.CHECK && (
          <AgnosticFormControl fullWidth>
            <AgnosticTextField
              name="checkNumber"
              label="Numéro de chèque"
              required
              validate={validateCheckNumber}
            />
          </AgnosticFormControl>
        )}
        <AgnosticFormControl fullWidth>
          <AgnosticDateField
            name="paymentDate"
            label="Date du réglement"
            required
            validate={validatePaymentDate}
          />
        </AgnosticFormControl>
        <AgnosticFormControl fullWidth>
          <AgnosticTextField
            name="bankName"
            label="Nom de la banque"
            required
            validate={validateBankName}
          />
        </AgnosticFormControl>
      </>
    );
  };
