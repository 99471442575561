import React from 'react';
import { TableRow, TableCell, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import { format } from 'date-fns';
import { getIn } from 'final-form';
import { useFormState } from 'react-final-form';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { centsToEur } from '../../../../lib/utils';
import { AgnosticAmountField } from '../../../../components/Fields/agnostic/AgnosticAmountField';
import { AgnosticDateField } from '../../../../components/Fields/agnostic/AgnosticDateField';
import { IndexedSchedule } from '../types';

const useStyles = makeStyles(() =>
  createStyles({
    actions: {
      width: '130px',
    },
  }),
);

export function creditToCurrency(value: number) {
  return centsToEur(value && -value).toLocaleString('fr', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'EUR',
  });
}

export interface OccurenceFieldProps {
  defaultEditable?: boolean;
  source: string;
  index: number;
  onDelete(): void;
}

export const OccurenceField: React.FC<OccurenceFieldProps> = ({
  defaultEditable = false,
  onDelete,
  source,
  index,
}) => {
  const classes = useStyles();
  const [editable, setEditable] = React.useState(defaultEditable);
  const { values } = useFormState();
  const { payments = [] } = values as {
    payments: IndexedSchedule['payments'];
  };
  const payment = payments[index];
  if (payment !== getIn(values, source))
    throw new Error('Source and index mismatch!'); // failsafe

  const pTotal = payments.reduce((t, p) => t + (p.principalAmount || 0), 0);
  const pRemain = payments
    .slice(0, index + 1)
    .reduce((r, p) => r - (p.principalAmount || 0), pTotal);
  const readonly = React.useMemo(() => payment.done, [payment]);
  const toggleEditor = React.useCallback(() => {
    if (readonly) {
      return;
    }

    setEditable(!editable);
  }, [editable, readonly]);

  return (
    <TableRow>
      {!editable && (
        <TableCell>{format(new Date(payment.dueDate), 'dd-MM-yyyy')}</TableCell>
      )}
      {editable && (
        <TableCell>
          <AgnosticDateField name={`${source}.dueDate`} />
        </TableCell>
      )}
      <TableCell>
        {payment.done && <DoneIcon fontSize="small" />}
        {!payment.done && <ClearIcon fontSize="small" />}
      </TableCell>
      <TableCell>
        {creditToCurrency(
          (payment?.principalAmount || 0) + (payment?.interestAmount || 0),
        )}
      </TableCell>
      {!editable && (
        <TableCell>{creditToCurrency(payment.principalAmount || 0)}</TableCell>
      )}
      {editable && (
        <TableCell>
          <AgnosticAmountField name={`${source}.principalAmount`} isCredit />
        </TableCell>
      )}
      {!editable && (
        <TableCell>{creditToCurrency(payment.interestAmount || 0)}</TableCell>
      )}
      {editable && (
        <TableCell>
          <AgnosticAmountField name={`${source}.interestAmount`} isCredit />
        </TableCell>
      )}
      <TableCell>{creditToCurrency(pRemain)}</TableCell>
      <TableCell className={classes.actions}>
        {!readonly && (
          <IconButton
            aria-label="edit"
            onClick={toggleEditor}
            color={editable ? 'primary' : 'default'}
          >
            <CreateIcon fontSize="small" />
          </IconButton>
        )}
        {!readonly && (
          <IconButton aria-label="delete" onClick={onDelete} color="secondary">
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};
