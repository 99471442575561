import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import {
  FRAGMENT_BASE_SUFFIX,
  FRAGMENT_FULL_SUFFIX,
  GenericResourceInfos,
} from '../resources/resourcesInfos';
import { isConnectionInputObject } from './connections';

import { ID, Node } from './common';
import { NodeInput } from './nodes';

export type NodeUpdate<N extends Node = Node> = {
  id: ID;
  node: N | null;
  updateInfo: NodeUpdateInfo;
};

export type NodeUpdateInfo = {
  mutation: UpdateType;
  connect: ConnectionUpdate[];
  disconnect: ConnectionUpdate[];
};

type ConnectionUpdate = {
  __typename?: string;
  field: string;
  id: ID;
};

export enum UpdateType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export type CreateNodeVariables<N extends Node = Node> = {
  data: NodeInput<N>;
};

export type UpdateNodeVariables<N extends Node = Node> = {
  id: ID;
  version?: number;
  data: NodeInput<N>;
};

export type DeleteNodeVariables = {
  id: ID;
};

export type MutationCreateNode<N extends Node = Node> = {
  [k: string]: NodeUpdate<N>;
};

export type MutationUpdateNode<N extends Node = Node> = {
  [k: string]: NodeUpdate<N>;
};

export type MutationDeleteNode<N extends Node = Node> = {
  [k: string]: NodeUpdate<N>;
};

export type MutationNode<N extends Node = Node> =
  | MutationCreateNode<N>
  | MutationUpdateNode<N>
  | MutationDeleteNode<N>;

/**
 * Get the response fragment of any mutation (CrUD)
 * @param resourceInfos
 */
export function getNodeUpdateFragment(
  resourceInfos: GenericResourceInfos,
  full = false,
): DocumentNode {
  const {
    fragments: { name: fragmentName, [full ? 'full' : 'base']: fragment },
  } = resourceInfos;

  return gql`
    fragment NodeUpdateFragment on NodeUpdate {
      __typename
      id
      node {
        ...${fragmentName}${full ? FRAGMENT_FULL_SUFFIX : FRAGMENT_BASE_SUFFIX}
      }
      updateInfo {
        __typename
        mutation
        connect {
          field
          id
        }
        disconnect {
          field
          id
        }
      }
    }
    ${fragment}
  `;
}

export function nodeInputToNodeUpdateInfo<N extends Node = Node>(
  data: NodeInput<N>,
): Pick<NodeUpdateInfo, 'connect' | 'disconnect'> {
  return Object.keys(data).reduce<
    Pick<NodeUpdateInfo, 'connect' | 'disconnect'>
  >(
    (acc, connectionDataKey) => {
      const connectionData = (data as any)[connectionDataKey];
      if (isConnectionInputObject(connectionData)) {
        connectionData.connect?.forEach(connId => {
          acc.connect.push({
            __typename: 'ConnectionUpdate',
            field: connectionDataKey,
            id: connId,
          });
        });
        connectionData.disconnect?.forEach(connId => {
          acc.disconnect.push({
            __typename: 'ConnectionUpdate',
            field: connectionDataKey,
            id: connId,
          });
        });
      }
      return acc;
    },
    { connect: [], disconnect: [] },
  );
}
