import React, { useCallback, useContext, useMemo } from 'react';
import { ContentBlock, ContentState, EditorState } from 'draft-js';
import clsx from 'clsx';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import { SelectedContext } from '../selectedContext';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    unselectable: {
      userSelect: 'none',
    },
    selected: {
      display: 'inline-flex',
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
        opacity: 0.2,
      },
    },
  }),
);

type RenderIframeEntityProps = {
  block: ContentBlock;
  contentState: ContentState;
  blockProps: {
    getEditorState: () => EditorState;
    onEditorStateChange: (editorState: EditorState) => void;
    [k: string]: any;
  }; // Additionnal props given at customBlockRenderer's execution call
  // For props within the entity, look for `data` below
};

/** Used to render asset images within the wysiwyg editor */
const RenderIframeEntity: React.FC<RenderIframeEntityProps> = ({
  block: contentBlock,
  contentState,
  blockProps: { getEditorState, onEditorStateChange },
}: RenderIframeEntityProps) => {
  const classes = useStyles();
  const [selected, setSelected] = useContext(SelectedContext);

  const entityKey = contentBlock.getEntityAt(0);
  const { src, ...restProps } = useMemo(() => {
    const entity = contentState.getEntity(entityKey);
    const data = entity.getData();
    return data; // Props within the entity
  }, [contentState, entityKey]);

  const handleClick = useCallback(() => {
    setSelected(
      { type: 'iframe', entityKey, contentBlock },
      getEditorState(),
      onEditorStateChange,
    );
  }, [
    contentBlock,
    getEditorState,
    entityKey,
    onEditorStateChange,
    setSelected,
  ]);

  return (
    <div
      className={clsx({
        [classes.selected]: selected?.entityKey === entityKey,
      })}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={-1}
    >
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe src={src} {...restProps} className={classes.unselectable} />
    </div>
  );
};

export default RenderIframeEntity;
