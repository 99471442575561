import React from 'react';
import { Datagrid, DateField, TextField, NumberField } from 'react-admin';
import { useTheme, Theme } from '@material-ui/core';
import Color from 'color';

import { Borrower, LoanType } from '../../types/schema';
import { CurrencyField } from './CurrencyFields';
import { Style } from '../../types/styles';
import { LoanIdField } from './LoanIdField';

const rowStyle =
  (theme: Theme) =>
  (record: any, index: number): Style => {
    return {
      backgroundColor:
        index % 2
          ? 'transparent'
          : Color(theme.palette.specials.cpretBlue).alpha(0.1).toString(),
    };
  };

export const LoanDatagrid: React.FC<{ type?: Borrower['loanType'] }> = ({
  type = LoanType.HONNEUR,
}) => {
  const theme = useTheme();
  const isHonneur = type === LoanType.HONNEUR;
  return (
    <Datagrid rowClick="edit" rowStyle={rowStyle(theme)}>
      <LoanIdField label="ID" />
      <TextField label="Statut" source="wfStatus" />
      <DateField label="Date de signature" source="signature" />
      {isHonneur && (
        <TextField label="Année de rappel 1" source="$$reminderYear1" />
      )}
      {isHonneur && (
        <TextField label="Année de rappel 2" source="$$reminderYear2" />
      )}
      {!isHonneur && <NumberField label="Taux" source="rate" />}
      <CurrencyField source="amount" />
    </Datagrid>
  );
};
