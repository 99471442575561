/**
 * Ledger Actions
 * https://github.com/phileog/cpret-archi/issues/4
 *
 * Triggered from Borrower Edit - but only needs borrower id
 *
 */

import { CreateResult, Record, DataProviderContext } from 'react-admin';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Borrower, BorrowerEdge, LedgerPosting } from '../types/schema';
import { shortId } from '../lib/utils';
import { useLoanWorkflow, WFAction } from './loans';
import { HistoryEntry, useCpretHistory } from './history';
import { CustomDataProvider, LedgerAccount } from '../dataProvider/type';
import { ID } from '../vendor/phicomas-client';

type LedgerActionState = { loading: boolean };

type LedgerActionInput = {
  payee: string;
  postings: LedgerPosting[];
};

type LedgerAction = (
  /** Borrower Id */
  id: string,
  entry: LedgerActionInput,
  logArgs?: HistoryEntry['args'],
) => Promise<CreateResult<Record> | undefined>;

const useLogAndRefresh = () => {
  const log = useCpretHistory();
  const dataProvider = useContext(DataProviderContext) as CustomDataProvider;

  const cb = useCallback(
    (entry: HistoryEntry, id: ID) => {
      const action = async () => {
        const result = await log(entry, [{ __typename: 'Borrower', id }]);
        // force borrower refresh + remove digest cache
        dataProvider.digestEvict(id);
        dataProvider.observeOne('cpretBorrower', { id });
        return result;
      };
      return action();
    },
    [log, dataProvider],
  );

  return cb;
};

/**
 * Hook Ledger Action
 * Action 9 - Evenement exceptionnel
 * @returns
 */
export const useLedgerAction = (): [LedgerAction, LedgerActionState] => {
  const [state, setState] = useState<LedgerActionState>({ loading: false });
  const dataProvider = useContext(DataProviderContext) as CustomDataProvider;
  const logAndRefresh = useLogAndRefresh();
  const mountRef = useRef(false);
  useEffect(() => {
    mountRef.current = true;
    return () => {
      mountRef.current = false;
    };
  }, []);
  const cb = useCallback<LedgerAction>(
    (id, entry, logArgs) => {
      setState({ loading: true });
      return dataProvider
        .getOne<Borrower>('cpretBorrower', { id })
        .then(async ({ data: brwr }) => {
          if (!brwr) throw new Error(`Ledger: Cannot find borrower: ${id}`);
          // failsafe
          const checkZero = entry.postings.reduce(
            (r, p) => r + (p.amount || 0),
            0,
          );
          if (checkZero !== 0)
            throw new Error('Leger: Sum of amounts must be zero !');
          const nextData = await dataProvider.create('cpretLedger', {
            data: {
              ...entry,
              borrower: { edges: [{ node: brwr } as BorrowerEdge] },
            },
          });
          if (logArgs)
            logAndRefresh(
              { message: 'cpret.ledger.generic', args: logArgs },
              id,
            );
          // we could be unmounted by now
          if (mountRef.current) setState({ loading: false });
          return nextData;
        })
        .catch((e: any) => {
          if (mountRef.current) setState({ loading: false });
          throw e;
        });
    },
    [dataProvider, logAndRefresh],
  );
  return [cb, state];
};

type LedgerSimplePostingInput = {
  /** Label */
  payee: string;
  /** Montant en centimes (positive for debit, negative for credit) */
  amount: number;
};

export type LedgerSimplePosting = (
  /** Borrower ID */
  id: string,
  entry: LedgerSimplePostingInput,
  /** Log arguments - action dependent */
  logArgs?: HistoryEntry['args'],
) => Promise<(CreateResult<Record> | undefined)[]>;

type LedgerRepaymentPostingInput = {
  /** Label or labels (principal, user) */
  payee: string | string[];
  /** Montant en centimes (negative for credit) */
  principalAmount: number;
  /** Montant en centimes (negative for credit) */
  interestAmount: number;
};

export type LedgerRepaymentPosting = (
  /** Borrower ID */
  id: string,
  entry: LedgerRepaymentPostingInput,
  logEntry?: HistoryEntry['args'],
) => Promise<(CreateResult<Record> | undefined)[]>;

/**
 * Action  3 - Retour chèque impayé
 * Action  4 - Enregistrement prélèvement bancaire rejeté
 * Action 10 - Déchéance du terme (pass src = PRINCIPAL_ACCOUNT)
 */
export const useLedgerUserDebit = (
  src = LedgerAccount.BANK_ACCOUNT,
): [LedgerSimplePosting, LedgerActionState] => {
  const [ledgerAction, state] = useLedgerAction();
  const logAndRefresh = useLogAndRefresh();
  const cb = useCallback<LedgerSimplePosting>(
    (
      id: string,
      { amount, payee = 'Prélèvement rejeté' }: LedgerSimplePostingInput,
      logArgs,
    ) => {
      if (!(amount > 0))
        throw new Error('Amount must be greater than zero (debit)');
      const action: LedgerActionInput = {
        payee,
        postings: [
          { account: LedgerAccount.USER_ACCOUNT, amount },
          { account: src, amount: -amount },
        ],
      };
      return ledgerAction(id, action).then(nextData => {
        if (logArgs)
          logAndRefresh(
            { message: 'cpret.ledger.userdebit', args: logArgs },
            id,
          );
        return [nextData];
      });
    },
    [src, ledgerAction, logAndRefresh],
  );
  return [cb, state];
};

/**
 * Action  5 - Remboursement échéance de retard par chèque
 * Action  6 - Enregistrement trop perçu par virement
 * Action 11 - Abandon de créance (irrécouvrable) - pass dst = LOSSES_ACCOUNT
 */
export const useLedgerUserCredit = (
  dst = LedgerAccount.BANK_ACCOUNT,
): [LedgerSimplePosting, LedgerActionState] => {
  const [ledgerAction, state] = useLedgerAction();
  const logAndRefresh = useLogAndRefresh();
  const cb = useCallback<LedgerSimplePosting>(
    (
      id: string,
      { amount, payee = 'Remboursement' }: LedgerSimplePostingInput,
      logArgs,
    ) => {
      if (!(amount < 0))
        throw new Error('Amount must be less than zero (credit)');
      const action: LedgerActionInput = {
        payee,
        postings: [
          { account: LedgerAccount.USER_ACCOUNT, amount },
          { account: dst, amount: -amount },
        ],
      };
      return ledgerAction(id, action).then(nextData => {
        if (logArgs)
          logAndRefresh(
            { message: 'cpret.ledger.usercredit', args: logArgs },
            id,
          );
        return [nextData];
      });
    },
    [dst, ledgerAction, logAndRefresh],
  );
  return [cb, state];
};

type LedgerDisbursementPosting = (
  /** Borrower ID */
  brwrId: string,
  /** amount must be negative for disbursement (user credit) */
  entry: LedgerSimplePostingInput,
  /** Loans IDs */
  loanId?: string,
  logArgs?: HistoryEntry['args'],
) => Promise<(CreateResult<Record> | undefined)[]>;

/**
 * Action 1 - Versement des fonds
 *
 * Two stage process :
 * - Stage 1 : Credit user and Debit Principal
 * - Stage 2 : Debit user and Credit Bank
 *
 * @param stage Disbursement stage (1 or 2)
 */
export const useLedgerDisbursement = (
  stage: 1 | 2 = 1,
): [LedgerDisbursementPosting, LedgerActionState] => {
  const [state, setState] = useState<LedgerActionState>({ loading: false });
  const [ledgerCredit] = useLedgerUserCredit(LedgerAccount.PRINCIPAL_ACCOUNT);
  const [ledgerDebit] = useLedgerUserDebit();
  const [loanWorkflow] = useLoanWorkflow();
  const logAndRefresh = useLogAndRefresh();
  const ledgerAction = stage === 1 ? ledgerCredit : ledgerDebit;
  const cb = useCallback<LedgerDisbursementPosting>(
    async (brwrId, { payee, amount, ...entry }, loanId, logArgs) => {
      setState({ loading: true });
      if (stage === 1 && !(amount < 0))
        throw new Error(
          'Stage 1 disbursement amount must be negative (credit)',
        );
      if (stage === 2 && !(amount > 0))
        throw new Error('Stage 2 disbursement amount must be positive (debit)');
      const posting: LedgerSimplePostingInput = {
        ...entry,
        amount,
        payee: loanId ? `${payee} [#${shortId(loanId)}]` : payee,
      };
      const nextData = await ledgerAction(brwrId, posting).catch((e: any) => {
        setState({ loading: false });
        throw e;
      });
      if (loanId && stage === 2) {
        const [execErrors] = await loanWorkflow({
          loanId,
          wfAction: WFAction.GESTION,
        });
        if (execErrors) {
          setState({ loading: false });
          console.warn(execErrors);
          throw new Error('Loan Workflow Error');
        }
      }
      if (logArgs)
        logAndRefresh(
          { message: 'cpret.ledger.disbursement', args: { ...logArgs, stage } },
          brwrId,
        );
      setState({ loading: false });
      return nextData;
    },
    [ledgerAction, loanWorkflow, logAndRefresh, stage],
  );
  return [cb, state];
};

/**
 * Action 2 - Remboursement anticipé
 * Repayment worker (exportGPG)
 */
export const useLedgerRepayment = (): [
  LedgerRepaymentPosting,
  LedgerActionState,
] => {
  const [state, setState] = useState<LedgerActionState>({ loading: false });
  const [legerAction] = useLedgerAction();
  const [ledgerCreditAction] = useLedgerUserCredit();
  const logAndRefresh = useLogAndRefresh();
  const mountRef = useRef(false);
  useEffect(() => {
    mountRef.current = true;
    return () => {
      mountRef.current = false;
    };
  }, []);
  const cb = useCallback<LedgerRepaymentPosting>(
    (id, { payee, principalAmount, interestAmount }, logArgs) => {
      const action = async () => {
        setState({ loading: true });
        if (!(principalAmount < 0))
          throw new Error('Principal amount must be negative (credit)');
        const nextData1 = await legerAction(id, {
          payee: Array.isArray(payee) ? payee[0] : payee,
          postings: [
            {
              account: LedgerAccount.PRINCIPAL_ACCOUNT,
              amount: principalAmount,
            },
            ...(interestAmount
              ? [
                  {
                    account: LedgerAccount.INTEREST_ACCOUNT,
                    amount: interestAmount,
                  },
                ]
              : []),
            {
              account: LedgerAccount.USER_ACCOUNT,
              amount: -(principalAmount + interestAmount),
            },
          ],
        }).catch((e: any) => {
          if (mountRef) setState({ loading: false });
          throw e;
        });
        const nextData2 = await ledgerCreditAction(id, {
          amount: principalAmount + interestAmount,
          payee: Array.isArray(payee) ? payee[1] : payee,
        }).catch((e: any) => {
          if (mountRef) setState({ loading: false });
          throw e;
        });
        if (logArgs)
          logAndRefresh(
            { message: 'cpret.ledger.repayment', args: logArgs },
            id,
          );
        // we could be unmounted by now
        if (mountRef.current) setState({ loading: false });
        return [nextData1, ...nextData2];
      };
      return action();
    },
    [legerAction, ledgerCreditAction, logAndRefresh],
  );
  return [cb, state];
};

/**
 * Action 7 - Régularisation arrondis fin de prêt
 * Amount may be negative
 */
export const useLedgerPrincipalRounding = (): [
  LedgerSimplePosting,
  LedgerActionState,
] => {
  const [ledgerAction, state] = useLedgerAction();
  const logAndRefresh = useLogAndRefresh();
  const cb = useCallback<LedgerSimplePosting>(
    (
      id: string,
      {
        amount,
        payee = 'Régularisation arrondis fin de prêt',
      }: LedgerSimplePostingInput,
      logArgs,
    ) => {
      // amount may be negative here !
      const action: LedgerActionInput = {
        payee,
        postings: [
          { account: LedgerAccount.ROUNDING_ACCOUNT, amount },
          { account: LedgerAccount.PRINCIPAL_ACCOUNT, amount: -amount },
        ],
      };
      return ledgerAction(id, action).then(nextData => {
        if (logArgs)
          logAndRefresh(
            { message: 'cpret.ledger.rounding', args: logArgs },
            id,
          );
        return [nextData];
      });
    },
    [ledgerAction, logAndRefresh],
  );
  return [cb, state];
};

/**
 * Action 8 - Régularisation arrondis compte agent
 * Amount may be negative
 */
export const useLedgerUserRounding = (): [
  LedgerSimplePosting,
  LedgerActionState,
] => {
  const [ledgerAction, state] = useLedgerAction();
  const logAndRefresh = useLogAndRefresh();
  const cb = useCallback<LedgerSimplePosting>(
    (
      id: string,
      {
        amount,
        payee = 'Régularisation arrondis compte agent prêt soldé',
      }: LedgerSimplePostingInput,
      logArgs,
    ) => {
      // amount may be negative here !
      const action: LedgerActionInput = {
        payee,
        postings: [
          { account: LedgerAccount.ROUNDING_ACCOUNT, amount },
          { account: LedgerAccount.USER_ACCOUNT, amount: -amount },
        ],
      };
      return ledgerAction(id, action).then(nextData => {
        if (logArgs)
          logAndRefresh(
            { message: 'cpret.ledger.rounding', args: logArgs },
            id,
          );
        return [nextData];
      });
    },
    [ledgerAction, logAndRefresh],
  );
  return [cb, state];
};
