import React from 'react';
import { Button } from 'react-admin';
import UndoIcon from '@material-ui/icons/Undo';
import { useForm } from 'react-final-form';

export const ResetButton: React.FC = () => {
  const form = useForm();
  const reset = React.useCallback(() => {
    form.setConfig('keepDirtyOnReinitialize', false);
    form.reset();
    form.setConfig('keepDirtyOnReinitialize', true);
  }, [form]);

  return (
    <Button
      disabled={form.getState().pristine}
      color="default"
      size="medium"
      variant="contained"
      label="Annuler"
      onClick={reset}
    >
      <UndoIcon />
    </Button>
  );
};
