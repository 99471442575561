import React from 'react';
import {
  DataProviderContext,
  EditContextProvider,
  EditProps,
  EditView,
  OnSuccess,
  Record,
  TransformData,
  useEditController,
} from 'react-admin';
import { ApolloClient } from '@apollo/client';

import { AssetConnection, Connection, Node, Asset } from '../types/schema';
import { useResourceAttachments } from '../lib/use-resource-attachements';
import { useDisconnectAttachments } from '../hooks/use-disconnect-attachments';
import { useCpretHistory } from '../workflow/history';

interface RecordWithAttachments extends Record {
  attachments: Partial<AssetConnection>;
}

const formatResource = (resource: string) => {
  const r = resource.replace('cpret', '');
  return `${r[0].toLowerCase()}${r.slice(1)}`;
};

function connectionToNodes<T extends Node>(connection: Partial<Connection>) {
  return (connection.edges || []).map(e => e.node as T);
}

export const EditWithAttachmentsAndLog: React.FC<EditProps> = props => {
  const disconnect = useDisconnectAttachments(
    formatResource(props.resource as string),
    props.id as string,
  );

  const dataProvider = React.useContext(DataProviderContext);
  const client = dataProvider.getClient() as ApolloClient<any>;
  const [nextAttachments, setNextAttachments] =
    React.useState<Partial<AssetConnection> | null>(null);
  const log = useCpretHistory();

  const { transform, onSuccess } = props;
  const transformProxy = React.useCallback<TransformData>(
    (record: RecordWithAttachments) => {
      const { attachments, ...restRecord } = record;
      setNextAttachments(attachments);

      if (transform) {
        return transform(restRecord);
      }
      return restRecord;
    },
    [transform, setNextAttachments],
  );

  const successProxy = React.useCallback<OnSuccess>(
    (response: { data: Record }) => {
      log(
        {
          message: 'cpret.mutation.updated',
          args: {},
        },
        [response.data as Node],
      );
      onSuccess?.(response);
    },
    [log, onSuccess],
  );

  const controllerProps = useEditController({
    ...props,
    transform: transformProxy,
    onSuccess: successProxy,
  });
  const { record } = controllerProps;

  const [attachments] = useResourceAttachments(
    formatResource(props.resource as string),
    record?.id as string,
    record?.$$cacheId as any,
  );

  const reconciledRecord = React.useMemo(
    () => record && { ...record, attachments },
    [record, attachments],
  );

  React.useEffect(() => {
    if (!nextAttachments) {
      return;
    }
    disconnect(
      connectionToNodes<Asset>(attachments),
      connectionToNodes<Asset>(nextAttachments),
    ).then(() => {
      setNextAttachments(null);
      dataProvider.observeOne(props.resource, { id: record?.id });
    });
  }, [
    attachments,
    client,
    dataProvider,
    disconnect,
    nextAttachments,
    props.resource,
    record,
    setNextAttachments,
  ]);

  const reconciledControllerProps = React.useMemo(
    () => ({
      ...controllerProps,
      record: reconciledRecord as Record,
    }),
    [controllerProps, reconciledRecord],
  );

  return (
    <EditContextProvider value={reconciledControllerProps}>
      <EditView {...(props as any)} {...reconciledControllerProps} />
    </EditContextProvider>
  );
};
