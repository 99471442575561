import { format } from 'date-fns';
import { get } from 'lodash';
import React from 'react';
import { FunctionField } from 'react-admin';

import { Schedule, SchedulePayment } from '../../../../types/schema';

export interface FirstPaymentDueDateProps {
  // eslint-disable-next-line react/no-unused-prop-types
  label: string;
}
export const FirstPaymentDueDate: React.FC<FirstPaymentDueDateProps> = () => {
  const render = React.useCallback((record?: Schedule) => {
    const firstPayment = get(
      record,
      ['payments', 0],
      null,
    ) as SchedulePayment | null;

    if (!firstPayment) {
      return '';
    }

    return format(new Date(firstPayment.dueDate), 'MM/yyyy');
  }, []) as any;

  return <FunctionField render={render} />;
};
