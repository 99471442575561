import React, { useCallback } from 'react';
import clsx from 'clsx';

import { CommonOptionProps, useOptionStyles } from './common';

export type SimpleOptionProps = CommonOptionProps & {
  onClick: () => void;
};

const SimpleOption: React.FC<SimpleOptionProps> = ({
  className,
  Icon,
  onClick,
  disabled,
}: SimpleOptionProps) => {
  const optionClasses = useOptionStyles();

  const handleClick = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <div
      className={clsx('rdw-option-wrapper', className, {
        [optionClasses.disabled]: disabled,
      })}
      tabIndex={-1}
      role="button"
      onKeyPress={handleClick}
      onClick={handleClick}
    >
      <Icon fontSize="small" />
    </div>
  );
};

export default SimpleOption;
