import {
  DataProvider,
  Record,
  CrudGetListAction,
  CrudGetManyAction,
  CrudGetOneAction,
  GetListResult,
  GetOneResult,
  GetManyResult,
  GetOneParams,
  Identifier,
} from 'react-admin';
import { EventChannel } from 'redux-saga';
import { ApolloClient } from '@apollo/client';
import { NormalizedCacheObject } from '@apollo/client/cache';
import { ResourceKey } from '../project/projectInfos';
import { ObservableTuple } from './observable';

export enum LedgerAccount {
  PRINCIPAL_ACCOUNT = '274380',
  USER_ACCOUNT = '467422',
  BANK_ACCOUNT = '512000',
  LOSSES_ACCOUNT = '678800',
  ROUNDING_ACCOUNT = '758000',
  INTEREST_ACCOUNT = '762800',
}

export type LedgerDigest = { [k in LedgerAccount]: number[] };

export type CustomDataProvider = DataProvider & {
  /** Get Observable wrapper */
  getObservable: (resource: ResourceKey) => ObservableTuple;
  getListAll: <RecordType extends Record = Record>(
    resource: ResourceKey,
  ) => Promise<GetListResult<RecordType>>;
  getObserver?: (
    resource: ResourceKey,
    params: CrudGetListAction | CrudGetOneAction | CrudGetManyAction,
  ) => EventChannel<GetListResult | GetOneResult | GetManyResult>;
  getClient: () => ApolloClient<NormalizedCacheObject>;
  /** Cache management */
  observeOne: (resource: ResourceKey, data: GetOneParams) => void;
  /** Evict from digest cache */
  digestEvict: (id: Identifier) => void;
};
