import React from 'react';
import { FieldProps, useTranslate } from 'react-admin';
import clsx from 'clsx';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import _get from 'lodash/get';

import sanitizeRestProps from './sanitizeRestProps';
import { useChipFieldStyle } from './chipField';

const MAX_CHIPS = 4;

const useStyles = makeStyles<Theme>(
  () =>
    createStyles({
      root: {
        display: 'inline-block',
      },
      chip: {
        margin: 4,
        cursor: 'inherit',
        maxWidth: 300,
      },
    }),
  { name: 'ConnectionField' },
);

export type ListFieldProps = FieldProps & {
  Field?: React.FC<FieldProps>;
  fieldProps?: Partial<FieldProps>;
};
const ListField: React.FC<ListFieldProps> = ({
  Field,
  fieldProps,
  ...restProps
}: ListFieldProps) => {
  const classes = useStyles(restProps);
  const chipClasses = useChipFieldStyle();
  const translate = useTranslate();

  const { record, source, className, resource } = restProps;

  if (!record || !source || !Field) return null;

  const sourceValue: Array<any> = _get(record, source);

  if (!sourceValue) return null;

  return (
    <div
      className={clsx(classes.root, className)}
      {...sanitizeRestProps(restProps)}
    >
      {sourceValue.length > MAX_CHIPS ? (
        <Chip
          label={`${sourceValue.length} ${translate(
            `resources.${resource}.fields.${source}`,
          )}`}
          className={chipClasses.chip}
        />
      ) : (
        sourceValue.map((value, index) => {
          return (
            <Chip
              // eslint-disable-next-line react/no-array-index-key
              key={`${record.id}-list-${index}`}
              label={
                <Field
                  {...restProps}
                  record={record}
                  source={`${source}[${index}]`}
                  {...fieldProps}
                />
              }
              className={chipClasses.chip}
            />
          );
        })
      )}
    </div>
  );
};

export default ListField;
