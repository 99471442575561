import React from 'react';
import { Datagrid, DateField, TextField } from 'react-admin';
import { useTheme, Theme } from '@material-ui/core';
import Color from 'color';

import { Style } from '../../types/styles';
import { GuarantyLoanIdField } from './GuarantyLoanIdField';
import { CurrencyField } from '../loan/CurrencyFields';

const rowStyle =
  (theme: Theme) =>
  (record: any, index: number): Style => {
    return {
      backgroundColor:
        index % 2
          ? 'transparent'
          : Color(theme.palette.specials.cpretBlue).alpha(0.1).toString(),
    };
  };

export const GuarantyLoanDatagrid: React.FC<any> = props => {
  const theme = useTheme();

  return (
    <Datagrid rowClick="edit" rowStyle={rowStyle(theme)} {...props}>
      <GuarantyLoanIdField label="ID" />
      <TextField source="wfStatus" label="Statut" />
      <CurrencyField source="amount" label="Montant du prêt cautionné" />
      <TextField source="bank" label="Banque" />
      <DateField source="endDate" label="Date de fin" />
    </Datagrid>
  );
};
