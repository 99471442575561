import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

// eslint-disable-next-line import/prefer-default-export
export const useChipFieldStyle = makeStyles<Theme>(() =>
  createStyles({
    chip: {
      margin: 4,
      cursor: 'inherit',
      maxWidth: 300,
    },
  }),
);
