import React from 'react';
import { Sidebar } from 'react-admin';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    version: {
      position: 'fixed',
      pointerEvents: 'none',
      bottom: theme.spacing(1),
      left: theme.spacing(2),
      opacity: 0.7,
    },
  }),
);

type CustomSidebarProps = React.PropsWithChildren<{
  children: JSX.Element;
}>;
const CustomSidebar: React.FC<CustomSidebarProps> = ({
  children,
  ...props
}: CustomSidebarProps) => {
  const classes = useStyles();

  return (
    <>
      <Sidebar classes={{}} {...props} size={200}>
        {children}
      </Sidebar>
      <div className={classes.version}>
        <Typography color="textSecondary">
          {process.env.REACT_APP_VERSION}
        </Typography>
      </div>
    </>
  );
};

export default CustomSidebar;
