import AWS from 'aws-sdk';
import { AuthOptions, AUTH_TYPE } from 'aws-appsync-auth-link';
import { Environment } from '../types';
import { AwsExports } from './aws-exports';

export type AppSyncConfig = {
  url: string;
  region: string;
  auth: AuthOptions;
};

export function getAwsAppSyncProjectConfig(
  awsExports: AwsExports,
  awsCredentials: AWS.Credentials,
  environment: Environment,
): AppSyncConfig {
  const appSyncExports = awsExports.appSync[environment.level];

  if (!appSyncExports) {
    throw new Error(
      `No appSync exports available for environment ${environment.level}`,
    );
  }
  const { apiKey, graphqlEndpoint, region } = appSyncExports;
  const { assumedRole: assumedRoleRaw } =
    awsExports.cognito[environment.instance] ?? {};
  const assumedRole = assumedRoleRaw?.[environment.level];

  let auth: AuthOptions;
  if (apiKey) {
    auth = { type: AUTH_TYPE.API_KEY, apiKey };
  } else {
    const credentials = assumedRole
      ? new AWS.ChainableTemporaryCredentials({
          params: { RoleArn: assumedRole, RoleSessionName: 'adminClient' }, // TODO: RoleSessionName value to be confirmed non-specific?
          masterCredentials: awsCredentials,
        })
      : awsCredentials;
    auth = { type: AUTH_TYPE.AWS_IAM, credentials };
  }

  const config = {
    auth,
    // disableOffline: true,
  };
  return {
    ...config,
    url: graphqlEndpoint,
    region,
  };
}
