import React from 'react';
import { Layout } from 'react-admin';

import CustomAppBar from './CustomAppBar';
import CustomSidebar from './CustomSidebar';
import CustomMenu from './CustomMenu';

const CustomLayout: React.FC = props => {
  return (
    <Layout
      {...props}
      appBar={CustomAppBar}
      sidebar={CustomSidebar}
      menu={CustomMenu}
    />
  );
};

export default CustomLayout;
