import React from 'react';
import { TextFieldProps as RATextFieldProps } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import _get from 'lodash/get';

import sanitizeRestProps from './sanitizeRestProps';

import { clearTypenames } from '../../dataProvider/utils';

export type TextFieldProps = RATextFieldProps;
const TextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  const { record, source, label, emptyText, className, ...rest } = props;

  if (!record || !source) return null;

  const value = _get(record, source);
  const isString = value === null || typeof value === 'string';

  return (
    <Typography
      className={className}
      component={isString ? 'span' : 'pre'}
      {...sanitizeRestProps(rest)}
    >
      {!isString
        ? JSON.stringify(clearTypenames(value), null, 2)
        : value || emptyText}
    </Typography>
  );
};

export default TextField;
