import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, ClickAwayListener, Paper, Popper } from '@material-ui/core';

import { CommonOptionProps, useOptionStyles } from './common';

import { ClassName } from '../../../../../../types/styles';
import { usePrevious } from '../../../../../../hooks/use-previous';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    popper: {
      zIndex: theme.zIndex.modal,
    },
    popperPaper: {
      padding: theme.spacing(1),
    },
    actionButtons: {
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'center',
      margin: theme.spacing(0, -2),
      '& > *': {
        margin: theme.spacing(0, 2),
      },
    },
  }),
);

export type PopperOptionProps = CommonOptionProps &
  React.PropsWithChildren<{
    onBeforeOpen?: () => void;
    doOpen?: boolean;
    resetFields?: () => void;
    isValid?: boolean;
    onOk: () => void;
    onClose?: () => void;
    hasOpenedDialog?: boolean;
    popperClassName?: ClassName;
  }>;

const PopperOption: React.FC<PopperOptionProps> = ({
  className,
  Icon,
  onBeforeOpen,
  doOpen,
  resetFields,
  isValid,
  onOk,
  onClose,
  hasOpenedDialog,
  disabled,
  children,
  popperClassName,
}: PopperOptionProps) => {
  const classes = useStyles();
  const optionClasses = useOptionStyles();

  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => {
    if (onBeforeOpen) {
      onBeforeOpen();
    }
    setOpen(true);
  }, [onBeforeOpen]);
  const wasDoOpen = usePrevious(doOpen);
  useEffect(() => {
    if (doOpen && !wasDoOpen) {
      handleOpen();
    }
  }, [doOpen, wasDoOpen, handleOpen]);
  const handleToggle = useCallback(
    (event: React.MouseEvent | React.KeyboardEvent) => {
      event.preventDefault();
      if (!disabled) {
        if (!open) {
          handleOpen();
        } else {
          setOpen(false);
        }
      }
    },
    [disabled, handleOpen, open],
  );
  const handleClose = useCallback(() => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
    if (resetFields) {
      resetFields();
    }
  }, [setOpen, onClose, resetFields]);
  const handleClickAway = useCallback(() => {
    if (!hasOpenedDialog) {
      handleClose();
    }
  }, [handleClose, hasOpenedDialog]);

  const handleOk = useCallback(() => {
    onOk();
    // Async so if BOTH onOk and onClose manipulates the EditorState, it has time to be updated
    setTimeout(handleClose, 1);
  }, [handleClose, onOk]);

  return (
    <>
      <div
        className={clsx('rdw-option-wrapper', className, {
          [optionClasses.disabled]: disabled,
        })}
        tabIndex={-1}
        role="button"
        onKeyPress={handleToggle}
        onClick={handleToggle}
        ref={anchorRef}
      >
        <Icon fontSize="small" />
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        className={classes.popper}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper square className={clsx(classes.popperPaper, popperClassName)}>
            {children}
            <div className={classes.actionButtons}>
              <Button
                size="small"
                variant="contained"
                onClick={handleOk}
                disabled={isValid === false}
              >
                Ok
              </Button>
              <Button size="small" variant="text" onClick={handleClose}>
                Annuler
              </Button>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default PopperOption;
