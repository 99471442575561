import React, { useCallback } from 'react';
import { SimpleForm, useGetOne } from 'react-admin';
import { useDataProvider, ReduxState, useNotify, Record } from 'ra-core';
import { useSelector } from 'react-redux';

import { ID } from 'phicomas-client';

import { ResourceKey } from '../../../project/projectInfos';

// Cycle needed because a connection edition lives within a record edition
import generateAllResourceInputs from '../generateInputs'; // eslint-disable-line import/no-cycle
import { clearDataBeforeSubmit } from '../../../dataProvider/utils';
import EditToolbar from '../../Toolbars/EditToolbar';

type ConnectionEditFormProps = {
  resource: ResourceKey;
  id: ID;
  onClose?: () => void;
};

const ConnectionEditForm: React.FC<ConnectionEditFormProps> = ({
  resource,
  id,
  onClose,
}: ConnectionEditFormProps) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const submitting = useSelector<ReduxState, boolean>(
    state => state.admin.loading > 0,
  );
  const { data: record, loading, loaded, error } = useGetOne(resource, id);

  const handleSave = useCallback(
    (rawData: Record) => {
      if (!record) return;
      const data = clearDataBeforeSubmit(rawData);
      dataProvider
        .update(resource, {
          id: record.id,
          data,
          previousData: record,
        })
        .then(() => {
          notify('ra.notification.updated', 'info', 1);
          if (onClose) {
            onClose();
          }
        });
    },
    [resource, record, dataProvider, notify, onClose],
  );

  const handleCancel = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  if (loading || !loaded || error) return <div />;

  return (
    /* Cannot use RA's Edit because it handles more than just the form
       - Page title
       - Redirections
       - etc. */
    <SimpleForm
      record={record}
      save={handleSave}
      saving={submitting}
      redirect={false}
      toolbar={<EditToolbar onCancel={handleCancel} />}
      resource={resource}
      submitOnEnter={false}
    >
      {generateAllResourceInputs(resource)}
    </SimpleForm>
  );
};

export default ConnectionEditForm;
