import React from 'react';
import { useFormState, useForm } from 'react-final-form';

import { FormValues } from './types';

let lastActives: (string | undefined)[];
let lastValues: Partial<FormValues>;

const resetLast = () => {
  lastActives = ['amount'];
  lastValues = {
    amount: undefined,
    monthlyAmount: undefined,
    size: undefined,
  };
};

resetLast();

export const ConsistencySpy: React.FC = () => {
  const form = useForm<FormValues>();
  const { active, values } = useFormState<FormValues>();

  React.useEffect(() => resetLast, []);

  React.useEffect(() => {
    // detect modified :
    const modified = (
      ['amount', 'monthlyAmount', 'size'] as (keyof FormValues)[]
    ).filter(k => lastValues[k] !== values[k]);
    if (!modified.length) return;
    lastValues = values;
    const current = active && modified.includes(active) && active;
    if (!current) return;

    let [a, b] = lastActives;
    if (current !== a) {
      b = a;
      a = current;
      lastActives = [a, b];
    }

    switch (b) {
      case 'amount': {
        if (a === 'monthlyAmount') {
          // form.change('size ', ???);
          form.change('amount', values.monthlyAmount * values.size);
        } else if (a === 'size') {
          form.change('monthlyAmount', Math.round(values.amount / values.size));
        }
        break;
      }
      case 'monthlyAmount': {
        if (a === 'amount') {
          // form.change('size ', ???);

          form.change('monthlyAmount', Math.round(values.amount / values.size));
        } else if (a === 'size') {
          form.change('amount', values.monthlyAmount * values.size);
        }
        break;
      }
      case 'size': {
        if (a === 'amount') {
          form.change('monthlyAmount', values.amount / values.size);
        } else if (a === 'monthlyAmount') {
          form.change('amount', values.monthlyAmount * values.size);
        }
        break;
      }
      default: {
        // don't chnange
      }
    }
  }, [form, values, active]);

  return null;
};
