/**
 * Export Schedule - See phileog/cpret#234
 */

import { format } from 'date-fns';
import { useCallback } from 'react';
import { useRecordContext } from 'react-admin';
import { stringify } from 'csv-stringify/dist/esm/sync';

import { IndexedSchedule } from '../resource/borrower/ScheduleDatagrid/types';
import { Borrower, Owner } from '../types/schema';
import download from '../lib/download';
import { centsToEur } from '../lib/utils';

export const scheduleFilename = (cp = 'user', now = new Date()) =>
  `${format(now, 'yyyyMMdd-HHmmss')}-${cp}-amortissement.csv`;

type ScheduleCSVEntry = {
  number: number | string;
  date: string;
  amount: string;
  principal: string;
  interest: string;
  remaining: string;
};

export const exportSchedule = (
  schedule: IndexedSchedule,
): ScheduleCSVEntry[] => {
  const { payments = [] } = schedule;
  if (!payments.length) return [];
  const [total, pTotal, iTotal] = payments.reduce(
    ([tT, pT, iT], pmt) => [
      tT + (pmt.principalAmount || 0) + (pmt.interestAmount || 0),
      pT + (pmt.principalAmount || 0),
      iT + (pmt.interestAmount || 0),
    ],
    [0, 0, 0],
  );
  let pRemain = pTotal;
  return [
    ...payments.map(
      ({ principalAmount = 0, interestAmount = 0, dueDate }, i) => {
        pRemain -= principalAmount;
        return {
          number: i + 1,
          date: dueDate,
          amount: centsToEur(-(principalAmount + interestAmount)).toFixed(2),
          principal: centsToEur(-principalAmount).toFixed(2),
          interest: centsToEur(-interestAmount).toFixed(2),
          remaining: centsToEur(-pRemain).toFixed(2),
        } as ScheduleCSVEntry;
      },
    ),
    {
      number: 'Total',
      date: '',
      amount: centsToEur(-total).toFixed(2),
      principal: centsToEur(-pTotal).toFixed(2),
      interest: centsToEur(-iTotal).toFixed(2),
      remaining: '',
    },
  ];
};

export const useExportSchedule = (schedule: IndexedSchedule) => {
  const record: Borrower = useRecordContext();
  const owner: Owner | undefined = record?.owner?.edges?.[0]?.node;

  const cb = useCallback(() => {
    const data = exportSchedule(schedule);

    const out = stringify(data, {
      header: true,
      delimiter: ';',
      columns: [
        { key: 'number', header: 'Échéance' },
        { key: 'date', header: 'Date' },
        { key: 'amount', header: 'Mensualité' },
        { key: 'principal', header: 'Amortissement' },
        { key: 'interest', header: 'Intérêts' },
        { key: 'remaining', header: 'Reste à amortir' },
      ],
    });
    download(Buffer.from(out, 'latin1'), scheduleFilename(owner.sncfCP));
  }, [schedule, owner.sncfCP]);
  return cb;
};
