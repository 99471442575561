import React from 'react';

import {
  AgnosticSelectField,
  AgnosticSelectFieldProps,
} from '../../../components/Fields/agnostic/AgnosticSelectField';
import {
  WFAction as GuaranteeActionType,
  ACTION_LABEL,
} from '../../../workflow/guarantee-enum';

export type ActionTypeFieldProps = Omit<
  AgnosticSelectFieldProps,
  'options' | 'name'
>;

// Options excluding TRANSFER
const options = Object.keys(GuaranteeActionType)
  .filter(a => a !== GuaranteeActionType.TRANSFER)
  .map(a => ({
    value: a,
    label:
      ACTION_LABEL[
        a as Exclude<GuaranteeActionType, GuaranteeActionType.TRANSFER>
      ],
  }));

export function formatActionType(actionType: GuaranteeActionType) {
  return options.find(o => o.value === actionType)?.label;
}

export const ActionTypeField: React.FC<ActionTypeFieldProps> = props => {
  return (
    <AgnosticSelectField
      {...props}
      options={options}
      name="actionType"
      label="Type d'action"
    />
  );
};
