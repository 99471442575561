import React from 'react';
import { useTranslate } from 'react-admin';
import clsx from 'clsx';

import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    minWidth: theme.spacing(5),
  },
  collList: {
    '& a': {
      paddingLeft: theme.spacing(4),
      // transition: theme.transitions.create('padding-left'),
    },
  },
  collListSidebarClosed: {
    '& a': {
      paddingLeft: theme.spacing(2),
    },
  },
}));

type SubMenuProps = React.PropsWithChildren<{
  dense?: boolean;
  handleToggle: () => void;
  icon?: React.ReactElement;
  isOpen: boolean;
  name: string;
  sidebarIsOpen: boolean;
}>;

const SubMenu: React.FC<SubMenuProps> = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  children,
  dense,
}: SubMenuProps) => {
  const translate = useTranslate();
  const classes = useStyles();

  const header = (
    <MenuItem dense={dense} button onClick={handleToggle}>
      <ListItemIcon className={classes.icon}>
        {isOpen ? <ExpandMore /> : icon || <ExpandLess />}
      </ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {translate(name)}
      </Typography>
    </MenuItem>
  );

  return (
    <>
      {sidebarIsOpen ? (
        header
      ) : (
        <Tooltip title={translate(name)} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto">
        <List
          dense={dense}
          component="div"
          disablePadding
          className={clsx(classes.collList, {
            [classes.collListSidebarClosed]: !sidebarIsOpen,
          })}
        >
          {children}
        </List>
      </Collapse>
    </>
  );
};

export default SubMenu;
