import React from 'react';

import { AgnosticTextField } from '../../../../components/Fields/agnostic/AgnosticTextField';
import { AgnosticAmountField } from '../../../../components/Fields/agnostic/AgnosticAmountField';
import { AgnosticFormControl } from '../../../../components/Fields/agnostic/AgnosticFormControl';

export interface ExcedentRepaymentByBankTransferValues {
  payee: string;
  amount: number;
}

export const ExcedentRepaymentByBankTransferLedgerActionFields: React.FC =
  () => {
    const validateAmount = React.useCallback((value: number) => {
      if (!value) {
        return 'Vous devez saisir un montant';
      }

      if (value <= 0) {
        return 'Le montant doit être supérieur à 0';
      }

      return null;
    }, []);

    const validatePayee = React.useCallback((value: string) => {
      if (!value) {
        return 'Vous devez saisir un motif';
      }

      return null;
    }, []);

    return (
      <>
        <AgnosticFormControl fullWidth>
          <AgnosticTextField
            name="payee"
            label="Motif"
            required
            validate={validatePayee}
          />
        </AgnosticFormControl>
        <AgnosticFormControl fullWidth>
          <AgnosticAmountField
            name="amount"
            label="Montant (€) (ex: 12,34)"
            required
            validate={validateAmount}
          />
        </AgnosticFormControl>
      </>
    );
  };
