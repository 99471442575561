import React, { useCallback, useState } from 'react';
import { sanitizeInputRestProps } from 'react-admin';
import {
  useInput,
  FieldTitle,
  InputProps as InputPropsType,
  useTranslate,
} from 'ra-core';
// import { v4 as uuid } from 'uuid';
import YAML from 'yaml';
import _cloneDeep from 'lodash/cloneDeep';
import clsx from 'clsx';
import { SimpleCms } from 'phicomas-client';

import {
  Typography,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { Code as CodeIcon } from '@material-ui/icons';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import { ResourceKey } from '../../project/projectInfos';

import ComponentsWrapper from './PageConstructor/ComponentsWrapper'; // eslint-disable-line import/no-cycle
import Components from './PageConstructor/Components'; // eslint-disable-line import/no-cycle
import YamlComponent from './PageConstructor/Components/YamlComponent';

const DEFAULT_PAGE_CONTENT: SimpleCms.PageContent = { components: [] };

const parseRawContent = (rawContent: SimpleCms.PageRaw['rawContent']) => {
  try {
    if (rawContent) {
      const pageContent: SimpleCms.PageContent = YAML.parse(rawContent);
      return pageContent;
    }
  } catch {
    return DEFAULT_PAGE_CONTENT;
  }
  return DEFAULT_PAGE_CONTENT;
};

/** Remove `key` entries */
function stringifyContent(pageContent: SimpleCms.PageContent) {
  return YAML.stringify(
    JSON.parse(
      JSON.stringify(pageContent, (k, v) => (k === 'key' ? undefined : v)),
    ),
  );
}

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    label: {
      marginBottom: theme.spacing(2),
      position: 'relative',
      transform: 'none',
    },
    viewSourceButton: {
      position: 'absolute',
      top: theme.spacing(1),
      left: theme.spacing(1),
      backgroundColor: theme.palette.background.default,
      zIndex: 1,

      height: '39px',
      width: '39px',
      overflow: 'hidden',
      justifyContent: 'start',

      transition: theme.transitions.create('width'),

      '&:hover, &$viewSourceActive': {
        width: '176px',
      },
    },
    viewSourceActive: {},
    viewSourceButtonIcon: {
      marginRight: theme.spacing(1),
    },
    viewSourceButtonLabel: {
      margin: 0,
      marginBottom: '-3px !important',
      whiteSpace: 'nowrap',
    },
    sourceYamlEditor: {
      marginTop: theme.spacing(4),
    },

    addButton: {
      '&:last-child': {
        transform: `translateY(${theme.spacing(0.5)}px)`,
      },
    },
    firstAddButton: {
      transform: `translateY(${-theme.spacing(0.5)}px)`,
    },
  }),
);

export type PageConstructorProps = InputPropsType<void>;

const PageConstructor: React.FC<PageConstructorProps> = (
  props: PageConstructorProps,
) => {
  const { source, resource } = props;
  const classes = useStyles();
  const translate = useTranslate();

  const {
    id: inputId,
    input,
    isRequired,
    meta: { error, submitError, touched },
  } = useInput({
    ...props,
    type: 'text',
  });

  const [viewSource, setViewSource] = React.useState(false);
  const handleToggleViewSource = useCallback(() => {
    setViewSource(!viewSource);
  }, [viewSource]);

  const [pageContent, setPageContentState] = useState(() =>
    parseRawContent(input.value),
  );

  const setPageContent = useCallback(
    (newValue: SimpleCms.PageContent) => {
      setPageContentState(newValue);
      input.onChange({
        target: { value: stringifyContent(_cloneDeep(newValue)) },
      });
    },
    [input],
  );

  const setComponents = useCallback(
    (newValue: SimpleCms.PageComponents) => {
      const newPageContent: SimpleCms.PageContent = { ...pageContent };
      newPageContent.components = newValue;
      setPageContent(newPageContent);
    },
    [pageContent, setPageContent],
  );

  const hasError = touched && !!(error || submitError);

  // To simulate focus changes (on focus change)
  //
  // input.onFocus(event as React.FocusEvent<HTMLElement>);
  // input.onBlur(event as React.FocusEvent<HTMLElement>);

  if (!resource) return null;

  return (
    <FormControl
      id={inputId}
      fullWidth
      margin="dense"
      required={isRequired}
      {...sanitizeInputRestProps(props)}
    >
      <InputLabel shrink className={classes.label} error={hasError}>
        <FieldTitle source={source} resource={resource} />
      </InputLabel>
      <ComponentsWrapper>
        <ToggleButton
          value=""
          selected={viewSource}
          onChange={handleToggleViewSource}
          size="small"
          className={clsx(classes.viewSourceButton, {
            [classes.viewSourceActive]: viewSource,
          })}
        >
          <CodeIcon fontSize="small" className={classes.viewSourceButtonIcon} />
          <Typography className={classes.viewSourceButtonLabel}>
            Voir la source
          </Typography>
        </ToggleButton>
        {viewSource ? (
          <YamlComponent
            sourceValue={pageContent}
            setSourceValue={setPageContent as (value: any) => void}
            className={classes.sourceYamlEditor}
          />
        ) : (
          <Components
            resource={resource as ResourceKey}
            components={pageContent.components}
            setComponents={setComponents}
          />
        )}
      </ComponentsWrapper>
      <FormHelperText error={hasError}>
        {hasError ? translate(error) : <>&#8203;</>}
      </FormHelperText>
    </FormControl>
  );
};

export default PageConstructor;
