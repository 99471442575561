import memoizeOne from 'memoize-one';

// eslint-disable-next-line import/prefer-default-export
export const sleep = (ms: number): Promise<void> =>
  new Promise(resolve => setTimeout(resolve, ms));

export const getWidenedMimeType = memoizeOne((mimeType: string) => {
  const widenedMimeTypes = ['image', 'audio', 'video'];
  for (const tmpWidenMimeType of widenedMimeTypes) {
    if (mimeType?.startsWith(tmpWidenMimeType)) {
      return `${tmpWidenMimeType}/*`;
    }
  }
  return mimeType;
});

export function copyToClipboard(text: string): void {
  const id = 'mycustom-clipboard-textarea-hidden-id';
  let textarea = document.getElementById(id) as HTMLTextAreaElement | null;

  if (!textarea) {
    textarea = document.createElement('textarea') as HTMLTextAreaElement;
    textarea.id = id;
    textarea.readOnly = true;
    // Place in top-left corner of screen regardless of scroll position.
    textarea.style.position = 'fixed';
    textarea.style.top = '0px';
    textarea.style.left = '0px';

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textarea.style.width = '1px';
    textarea.style.height = '1px';

    // We don't need padding, reducing the size if it does flash render.
    textarea.style.padding = '0px';

    // Clean up any borders.
    textarea.style.border = 'none';
    textarea.style.outline = 'none';
    textarea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textarea.style.background = 'transparent';
    document.body.appendChild(textarea);
  }
  textarea.value = text;

  // iOS trick
  if (navigator.userAgent.match(/ipad|iphone/i)) {
    const range = document.createRange();
    range.selectNodeContents(textarea);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
    textarea.setSelectionRange(0, 999999);
  } else {
    textarea.select();
  }

  document.execCommand('copy');
  document.body.removeChild(textarea);
}

export const centsToEur = (n = 0) => Math.round(n) / 100;

export const eurToCents = (n = 0) => Math.round(n * 100);

/** Shorten seqUuid (uuid v4) */
export const shortId = (id: string, size = 6) =>
  id.split('-')[4].slice(0, size);

export const csvAmount = (amount: number) =>
  centsToEur(amount).toFixed(2).split('.').join(',');
