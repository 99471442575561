import {
  createStyles,
  InputAdornment,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { TextInput, DateInput, required } from 'react-admin';
import { useFormState } from 'react-final-form';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';

import FormRow from '../../components/baseElements/FormRow';
import FormSection from '../../components/baseElements/FormSection';
import TooltipRGPD from '../../components/Tooltip/TooltipRGPD';
import { GuarantyLoan } from '../../types/schema';
import { CurrencyInput } from '../loan/CurrencyFields';
import { useCanAccess } from '../../hooks/use-can-access';
import {
  workflow,
  WFState,
  WFAction,
  ACTION_LABEL,
} from '../../workflow/guaranty-loans';
import { ActionInput } from './ActionInput';
import { Assets } from '../../components/Fields/asset/Assets';
import { ASSET_USER_NAMESPACE } from '../../aws/cpret-config';
import { PostmailDialog } from '../shared/PostmailDialog';
import { GuarantyLoanActionDialog } from './GuarantyLoanActionDialog';
import { ActionHistory } from '../common/ActionHistory';
import { ActionSchedule } from '../common/ActionSchedule';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'row',
      margin: theme.spacing(-1),
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    leftSide: {
      flex: '1 0 0%',

      '& $formSection': {
        display: 'flex',
        flexFlow: 'column nowrap',

        '& $field': {
          width: '100%',
        },
      },
    },
    rightSide: {
      flex: '3 0 0%',

      '& $formSection': {
        display: 'flex',
        flexFlow: 'row wrap',

        '& $field': {
          flexGrow: 1,
          flexBasis: '26%', // More that 1/4
        },
      },
    },
    formSection: {},
    field: {},
    ledgerActionDialog: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    createLoanButton: {
      marginTop: theme.spacing(2),
    },
    guarantyLoanActionDialog: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
  }),
);

interface GuarantyLoanFormProps {
  record?: GuarantyLoan;
  resource?: string;
}
// taken from generateInput.tsx - improve ?
const inputOrNull = (input: any) => input || null;
const disableOn = (condition: boolean) => ({
  disabled: condition,
  InputProps: condition
    ? {
        endAdornment: (
          <InputAdornment position="end">
            <LockIcon />
          </InputAdornment>
        ),
      }
    : {},
});

export const GuarantyLoanForm: React.FC<GuarantyLoanFormProps> = ({
  record,
  resource,
}) => {
  // const guarantee = get(record, 'guarantee.edges[0].node') as Guarantee;
  const classes = useStyles();
  const { isAdmin, isSuper, canWrite } = useCanAccess();
  const formState = useFormState();
  const wfActions = record && workflow[record.wfStatus as WFState];

  const { values: formValues } = useFormState();

  const [fullActions, setFullActions] = useState(false);

  const actionChoices = useMemo(() => {
    const allowedActions =
      (wfActions &&
        Object.keys(wfActions).filter(
          a => isAdmin || !wfActions[a as WFAction]?.workerOnly,
        )) ||
      [];
    return allowedActions.map(a => ({
      id: a,
      name: fullActions ? ACTION_LABEL[a as WFAction] : a,
    }));
  }, [fullActions, isAdmin, wfActions]);

  const setFullCb = useCallback(() => {
    setFullActions(true);
  }, [setFullActions]);

  const unsetFullCb = useCallback(() => {
    setFullActions(false);
  }, [setFullActions]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateEnDate = useCallback(
    Object.assign(
      (value: string) => {
        if ((formValues.wfAction as WFAction) === WFAction.ANTICIPE && !value) {
          return 'Vous devez saisir une date';
        }
        return null;
      },
      {
        isRequired: (formValues.wfAction as WFAction) === WFAction.ANTICIPE,
      },
    ),
    [formValues.wfAction],
  );

  return (
    <div className={classes.root}>
      <section className={classes.leftSide}>
        <FormSection>
          <FormRow>
            <TextInput
              source="wfStatus"
              label="Statut"
              disabled={
                !isAdmin ||
                ['prod', 'stage'].includes(process.env.REACT_APP_ENV || '')
              }
            />
            {actionChoices.length ? (
              <ActionInput
                source="wfAction"
                label="Action"
                allowEmpty
                choices={actionChoices}
                onFocus={setFullCb}
                onBlur={unsetFullCb}
              />
            ) : null}
          </FormRow>
          <FormRow>
            <TooltipRGPD>
              <TextInput
                label="Commentaires"
                source="comment"
                multiline
                rows={10}
                rowsMax={10}
                fullWidth
              />
            </TooltipRGPD>
          </FormRow>
          <FormRow>
            <Assets
              displayNamespaceSelectOnMailFile
              namespace={ASSET_USER_NAMESPACE}
              label="Pièces jointes"
              record={record}
              resource={resource}
              source="attachments"
              fullWidth
            />
          </FormRow>
        </FormSection>
      </section>
      <section className={classes.rightSide}>
        <FormSection title="Demandeur">
          <FormRow>
            <TextInput
              source="guarantee.edges[0].node.owner.edges[0].node.sncfCP"
              label="Immatriculation"
              {...disableOn(true)}
            />
            <TextInput
              source="guarantee.edges[0].node.owner.edges[0].node.contact.honorificPrefix"
              label="Civilité"
              {...disableOn(true)}
              format={(e: 'MME' | 'MR') => {
                return { MME: 'Madame', MR: 'Monsieur' }[e];
              }}
            />
            <TextInput
              source="guarantee.edges[0].node.owner.edges[0].node.contact.familyName"
              label="Nom usuel"
              {...disableOn(true)}
            />
            <TextInput
              source="guarantee.edges[0].node.owner.edges[0].node.contact.givenName"
              label="Prénom"
              {...disableOn(true)}
            />
          </FormRow>
        </FormSection>
        <FormSection title="Gestion prêt cautionné">
          <FormRow>
            <TextInput source="bank" label="Banque" {...disableOn(true)} />
            <TextInput
              source="label"
              label="Intitulé Prêt"
              {...disableOn(true)}
            />
          </FormRow>
          <FormRow>
            <DateInput
              source="signature"
              label="Date signature"
              parse={inputOrNull}
              format={inputOrNull}
              {...disableOn(true)}
            />
            <CurrencyInput source="amount" label="Montant" disabled />
          </FormRow>
          <FormRow>
            <DateInput
              source="startDate"
              label="Date début remboursement"
              parse={inputOrNull}
              format={inputOrNull}
              {...disableOn(true)}
            />
            <DateInput
              source="endDate"
              label="Date fin remboursement"
              parse={inputOrNull}
              format={inputOrNull}
              {...disableOn(
                (formValues.wfAction as WFAction) !== WFAction.ANTICIPE &&
                  false /* #372 */,
              )}
              validate={validateEnDate}
            />
          </FormRow>
          <div className={classes.guarantyLoanActionDialog}>
            <GuarantyLoanActionDialog disabled={!formState.pristine || !canWrite} />
          </div>
        </FormSection>
        {[WFAction.AVENANT, WFAction.ANTICIPE].includes(
          formValues.wfAction as WFAction,
        ) && (
          <FormSection title="Action">
            <TextInput
              label="Motif"
              source="message"
              fullWidth
              // required
              validate={required()}
            />
            {(formValues.wfAction as WFAction) === WFAction.AVENANT && (
              <DateInput
                source="actionDate"
                label="Date"
                parse={inputOrNull}
                format={inputOrNull}
                validate={required()}
              />
            )}
          </FormSection>
        )}

        <FormSection title="Courriers">
          <PostmailDialog
            record={record as GuarantyLoan}
            templatePrefix="guarantyLoan"
          />
        </FormSection>
        {isAdmin || isSuper ? (
          <FormSection title="Activité">
            <Typography variant="h6">Programmé</Typography>
            <ActionSchedule type="GuarantyLoan" />
            <Typography variant="h6">Effectué</Typography>
            <ActionHistory type="GuarantyLoan" />
          </FormSection>
        ) : null}
      </section>
    </div>
  );
};
