import * as React from 'react';
import { useRecordContext, Labeled } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

type Props = {
  label?: string; // eslint-disable-line react/no-unused-prop-types
  addLabel?: boolean; // eslint-disable-line react/no-unused-prop-types
  source: string;
};

const COMPANIES = ['001', '002', '005', '006', '007'] as const;
export const SNCF_COMPANIES: { [k in typeof COMPANIES[number]]: string } = {
  '001': 'SNCF',
  '002': 'SNCF RÉSEAU',
  '005': 'SNCF VOYAGEURS',
  '006': 'SNCF GARES & CONNEXIONS',
  '007': 'FRET SNCF',
};

const useStyles = makeStyles({
  /* Styles applied to the root element. */
  root: {
    maxHeight: '2em',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    pointerEvents: 'none',
    position: 'absolute',
    right: 10,
    top: 10,
  },
});

export const SncfCompanyField: React.FC<Props> = props => {
  const { source, label } = props;
  const record: any = useRecordContext();
  const { [source]: value } = record || {};
  const name = SNCF_COMPANIES[value as typeof COMPANIES[number]] || value;
  const classes = useStyles();
  return (
    <Labeled label={label}>
      <div>
        <Typography component="span" variant="body2">
          {name}
        </Typography>
        <div className={clsx(classes.root)}>
          <LockIcon />
        </div>
      </div>
    </Labeled>
  );
};
