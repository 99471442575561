import { ID, Node } from './common';

export type Connection<N extends Node = Node> = {
  edges: Edge<N>[];
  pageInfo: PageInfo;
};
export type Edge<N extends Node = Node> = {
  node: N;
  cursor?: ID;
  __typename?: string;
};
export type PageInfo = {
  /** When paginating forwards, are there more items? */
  hasNextPage: boolean;
  /** When paginating backwards, are there more items? */
  hasPreviousPage: boolean;
  /** When paginating backwards, the cursor to continue */
  startCursor?: string;
  /** When paginating forwards, the cursor to continue */
  endCursor?: string;
};

export function isConnectionObject(data: unknown): data is Connection {
  return (
    typeof data === 'object' &&
    !!data &&
    'edges' in data &&
    Array.isArray((data as any).edges)
  );
}

const pageFragmentName = 'PageInfo';
const pageFragment = `
  fragment ${pageFragmentName} on Connection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
`;

export const pageInfo = {
  fragment: pageFragment,
  name: pageFragmentName,
};

/**
 * Connection Input
 */
export type ConnectionInput = {
  /**
   * Add connections to ID[]
   */
  connect?: ID[];
  /**
   * Remove connections to ID[]
   */
  disconnect?: ID[];
};

export function isConnectionInputObject(
  data: unknown,
): data is ConnectionInput {
  return (
    typeof data === 'object' &&
    !!data &&
    (('connect' in data && Array.isArray((data as any).connect)) ||
      ('disconnect' in data && Array.isArray((data as any).disconnect)))
  );
}
