import React from 'react';
import { EditProps } from 'react-admin';

import { BorrowerEdit } from '../resource/borrower/BorrowerEdit';
import { OwnerEdit } from '../resource/owner/OwnerEdit';
import { LoanEdit } from '../resource/loan/LoanEdit';
import GeneratedEdit from './GeneratedEdit';
import { GuaranteeEdit } from '../resource/guarantee/GuaranteeEdit';
import { GuarantyLoanEdit } from '../resource/guarantyLoan/GuarantyLoanEdit';

export const CustomEdit: React.FC<EditProps> = ({
  resource,
  ...otherProps
}) => {
  switch (resource) {
    case 'cpretOwner':
      return <OwnerEdit resource={resource} {...otherProps} />;

    case 'cpretBorrower':
      return <BorrowerEdit resource={resource} {...otherProps} />;

    case 'cpretLoan':
      return <LoanEdit resource={resource} {...otherProps} />;

    case 'cpretGuarantee':
      return <GuaranteeEdit resource={resource} {...otherProps} />;

    case 'cpretGuarantyLoan':
      return <GuarantyLoanEdit resource={resource} {...otherProps} />;

    default:
      return <GeneratedEdit resource={resource} {...otherProps} />;
  }
};
