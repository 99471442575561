import React, { useCallback, useMemo } from 'react';
import { SimpleCms } from 'phicomas-client';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Tooltip,
  Typography,
  Card,
  CardContent,
  Switch,
} from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { Remove as RemoveIcon, Code as CodeIcon } from '@material-ui/icons';

// eslint-disable-next-line import/no-cycle
import pageComponentMapping from './componentsMapping';

import YamlComponent from './Components/YamlComponent';
import ConditionnalWrapper from '../../../lib/ConditionnalWrapper';
import { ResourceKey } from '../../../project/projectInfos';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    componentWrapper: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      margin: theme.spacing(-0.5),

      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    leftColumn: {
      alignSelf: 'flex-start',
      flex: '0 0 auto',
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
    },
    rightColumn: {
      flex: '1 0 auto',
      width: 100, // For flex grow to kick in without overflowing
    },
    removeIcon: {
      '& svg': {
        transform: 'scale(0.5)',
        transition: theme.transitions.create('transform'),
      },
      '&:hover': {
        '& svg': {
          transform: 'scale(1)',
          fill: theme.palette.secondary.main,
        },
      },
    },
    source: {
      marginTop: theme.spacing(1),
    },
  }),
);

type RenderComponentProps = {
  resource: ResourceKey;
  type: SimpleCms.PageComponentType;
  componentProps: any;
  setComponentProps: (position: number, newProps: any) => void;
  position: number;
  onRemove: (position: number) => void;
};

const RenderComponent: React.FC<RenderComponentProps> = ({
  resource,
  type,
  componentProps,
  setComponentProps,
  position,
  onRemove,
}: RenderComponentProps) => {
  const classes = useStyles();

  const handleClickRemove = useCallback(() => {
    onRemove(position);
  }, [position, onRemove]);

  const { Component, SourceComponent = YamlComponent } = useMemo(() => {
    const componentMappingInfos = pageComponentMapping.get(type);
    if (!componentMappingInfos) {
      throw new Error('Attempted to render a component of unknown type');
    }
    return componentMappingInfos;
  }, [type]);

  const handleSetComponentProps = useCallback(
    (newComponentProps: any) => {
      setComponentProps(position, newComponentProps);
    },
    [position, setComponentProps],
  );

  const isYamlComponent = Component === YamlComponent;

  const [viewSourceManual, setViewSource] = React.useState(false);
  const handleChangeViewSource = useCallback(() => {
    setViewSource(!viewSourceManual);
  }, [viewSourceManual]);

  const [blockedSource, setBlockedSource] = React.useState<boolean>(
    componentProps.blockedSource ?? false,
  );
  const handleChangeBlockSource = useCallback(() => {
    handleSetComponentProps({
      ...componentProps,
      blockedSource: !blockedSource,
    });
    setBlockedSource(!blockedSource);
    if (blockedSource) {
      setViewSource(false);
    }
  }, [blockedSource, componentProps, handleSetComponentProps]);

  const viewSource = viewSourceManual || blockedSource;

  return (
    <Card>
      <CardContent className={classes.componentWrapper}>
        <div className={classes.leftColumn}>
          <Tooltip title="Supprimer le composant">
            <IconButton
              onClick={handleClickRemove}
              size="small"
              className={classes.removeIcon}
            >
              <RemoveIcon />
            </IconButton>
          </Tooltip>
          {!isYamlComponent && (
            <>
              <ConditionnalWrapper
                condition={!blockedSource}
                wrapper={children => (
                  <Tooltip title="Voir la source">{children}</Tooltip>
                )}
              >
                <ToggleButton
                  value=""
                  selected={viewSource}
                  onChange={handleChangeViewSource}
                  size="small"
                  className={classes.source}
                  disabled={blockedSource}
                >
                  <CodeIcon fontSize="small" />
                </ToggleButton>
              </ConditionnalWrapper>
              <Tooltip title="Bloquer en source">
                <Switch
                  checked={blockedSource}
                  onChange={handleChangeBlockSource}
                  className={classes.source}
                  size="small"
                />
              </Tooltip>
            </>
          )}
        </div>
        <div className={classes.rightColumn}>
          <Typography variant="h3">
            {pageComponentMapping.get(type)?.title}
          </Typography>
          {viewSource ? (
            <SourceComponent
              key={
                blockedSource
                  ? `yamlComponent ${position} blocked`
                  : `yamlComponent ${position} unblocked`
              }
              sourceValue={componentProps}
              setSourceValue={handleSetComponentProps}
            />
          ) : (
            <Component
              resource={resource}
              sourceValue={componentProps}
              setSourceValue={handleSetComponentProps}
              viewSource={viewSource}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default RenderComponent;
