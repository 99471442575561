import { flatten, omit } from 'lodash';

import { Ledger, LedgerPosting } from '../types/schema';
import { centsToEur } from './utils';
import { LedgerAccount, LedgerDigest } from '../dataProvider/type';

// export const USER_ACCOUNT = '467422';
// export const PRINCIPAL_ACCOUNT = '274380';
// export const INTEREST_ACCOUNT = '762800';
// export const BANK_ACCOUNT = '512000';
// export const ROUNDING_ACCOUNT = '758000';
// export const LOSSES_ACCOUNT = '678800';

export interface PostingWithLedger extends LedgerPosting {
  ledger: Omit<Ledger, 'postings'>;
  otherAccounts?: string;
}

export const currencyOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  style: 'currency',
  currency: 'EUR',
};

type ExtendedPosting = LedgerPosting & { ledger: Ledger };

export const getAccountPostings = (
  ledgers: Ledger[],
  account: string,
  maxDate?: Date,
): ExtendedPosting[] => {
  return ledgers
    .filter(l => !maxDate || new Date(l.createdAt) <= maxDate)
    .reduce(
      (postings, ledger) =>
        flatten([
          postings,
          ledger.postings
            ?.filter(posting => posting.account === account)
            .map(p => ({ ...p, ledger })) || ([] as ExtendedPosting[]),
        ]),
      [] as ExtendedPosting[],
    );
};

/**
 *
 * @param ledgers
 * @param account
 * @param maxDate
 * @returns Array of [debit, credit, balanc]
 */
export const getAccountBalance = (
  ledgers: Ledger[],
  account: string,
  maxDate?: Date,
) => {
  return getAccountPostings(ledgers, account, maxDate).reduce(
    ([d, c, b], { amount = 0 }) => [
      d + Math.max(0, amount),
      c + Math.min(0, amount),
      b + amount,
    ],
    [0, 0, 0],
  );
};

export const getDigestBalance = (
  d: LedgerDigest | undefined,
  account: LedgerAccount /* , maxDate?: Date */,
) => d?.[account] || [0, 0, 0];

export const getAccountPostingsWithExtras = (
  ledgers: Ledger[],
  account: string,
  locales: string | string[],
): PostingWithLedger[] => {
  return getAccountPostings(ledgers, account).map(p => ({
    ...p,
    ledger: omit(p.ledger, 'postings'),
    // list other accounts contributing to this credit or debit, with their amounts
    otherAccounts: p.ledger.postings
      ?.filter(({ account: a }) => a !== account)
      .map(po =>
        p.ledger.postings && p.ledger.postings.length > 2
          ? `#${po.account?.slice(0, 3)}: ${centsToEur(
              po.amount,
            ).toLocaleString(locales, currencyOptions)}`
          : `#${po.account?.slice(0, 3)}`,
      )
      .join('\n'),
  }));
};
