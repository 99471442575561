import React from 'react';
import { useFormState } from 'react-final-form';

import { Income } from '../../types/schema';

export function useFamilyQuotient() {
  const { values } = useFormState();
  const { income = [], taxShares = 1 } = values;
  const totalIncome = React.useMemo(
    () => (income || []).reduce((p: number, v: Income) => p + (v.amount || 0), 0),
    [income],
  );
  return totalIncome / taxShares || 0;
}
