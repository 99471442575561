import { useCallback, useContext, useRef } from 'react';
import { DataProviderContext, Identifier } from 'react-admin';
import createReport from 'docx-templates';
import { addDays, addMonths, format } from 'date-fns';
import _, { get, set } from 'lodash';
import { Record as RARecord } from 'ra-core';

import { EnhancedBorrower, EnhancedOwner } from '../types/schema-custom';
import { downloadFromS3 } from '../aws/s3-utils';

import { ASSET_MAIL_NAMESPACE, TEMPLATE_NAMESPACE } from '../aws/cpret-config';
import {
  HonorificPrefix,
  Loan,
  ScheduleType,
  Guarantee,
  GuarantyLoan,
  Borrower,
} from '../types/schema';
import { centsToEur } from '../lib/utils';
import { currencyOptions as options } from '../lib/ledger';
import { useCreateAsset } from '../dataProvider/useAsset';
import TemplatesContext, {
  TemplatesContextType,
} from '../context/templatesContext';
import { ResourceKey } from '../project/projectInfos';
import { CustomDataProvider } from '../dataProvider/type'; // import { getDataBlob } from '../resource/guarantee/GuaranteeDataBlobField';

const titleCleanup = (title: HonorificPrefix | undefined) => {
  if (!title) return '';
  return title === HonorificPrefix.MR ? 'Monsieur' : 'Madame';
};

const givenNameCleanup = (n: string | undefined) => {
  if (!n) return '';
  return n
    .match(/(\p{L}+)|(-|\s)/gu) // see #445 - thx chatgpt
    ?.map(s => _.upperFirst(_.toLower(s)))
    .join('');
};

const formatPayments = (data: any) => {
  const schedule = data.schedules?.filter(
    (s: { type: string }) => s.type === ScheduleType.CAPITAL,
  );
  if (schedule === undefined || !schedule[0]?.payments.length) return {};
  const [total, pTotal, iTotal] = schedule[0].payments.reduce(
    ([tT, pT, iT]: any, pmt: { principalAmount: any; interestAmount: any }) => [
      tT + (pmt.principalAmount || 0) + (pmt.interestAmount || 0),
      pT + (pmt.principalAmount || 0),
      iT + (pmt.interestAmount || 0),
    ],
    [0, 0, 0],
  );
  let pRemain = pTotal;
  const remain = (principalAmount: any) => {
    pRemain -= principalAmount;
    return pRemain;
  };
  const payments = schedule[0].payments.map(
    (
      p: { interestAmount: any; principalAmount: any; dueDate: any },
      index: any,
    ) => ({
      number: index + 1,
      date: p.dueDate,
      amount: centsToEur(-(p.principalAmount + p.interestAmount)).toFixed(2),
      principal: centsToEur(-p.principalAmount).toFixed(2),
      interest: centsToEur(-p.interestAmount).toFixed(2),
      remaining: centsToEur(-remain(p.principalAmount)).toFixed(2),
    }),
  );
  const paymentTotal = {
    number: 'Total',
    date: '',
    amount: centsToEur(-total).toFixed(2),
    principal: centsToEur(-pTotal).toFixed(2),
    interest: centsToEur(-iTotal).toFixed(2),
    remaining: '',
  };
  return { payments, paymentTotal };
};

const dataCleanupOwner = (owner: EnhancedOwner): any => ({
  owner: {
    ...owner,
    sncfContractEndDate: owner.sncfContractEndDate
      ? format(new Date(owner.sncfContractEndDate), 'dd/MM/yyyy')
      : owner.sncfContractEndDate,
    contact: {
      ...owner.contact,
      honorificPrefix: titleCleanup(owner.contact?.honorificPrefix),
      givenName: givenNameCleanup(owner.contact?.givenName),
      familyName: owner.contact?.familyName?.toUpperCase(),
      bday:
        owner.contact?.bday &&
        format(new Date(owner.contact.bday), 'dd/MM/yyyy'),
    },
  },
});

const dataCleanupBorrowerLoan = (
  data: Omit<EnhancedBorrower, 'owner'> & {
    loan?: Loan;
  },
): any => ({
  ...data,
  $$todayDate: format(new Date(), 'dd/MM/yyyy'),
  $$loanAmount: centsToEur(data.$$loanAmount).toLocaleString('fr', options),
  $$loanLastSignature:
    data.$$loanLastSignature && Number(data.$$loanLastSignature),
  $$paymentAmount: centsToEur(data.$$paymentAmount).toLocaleString(
    'fr',
    options,
  ),
  $$userBalance: centsToEur(data.$$userBalance).toLocaleString('fr', options),
  $$restantDu: centsToEur(data.$$restantDu).toLocaleString('fr', options),
  $$userCreditor: centsToEur(data.$$userCreditor).toLocaleString('fr', options),
  $$currentDate: format(new Date(), 'dd/MM/yyyy'),
  /* Validity = current date + 30 */
  $$validityDate: format(addMonths(new Date(), 1), 'dd/MM/yyyy'),
  contact: {
    ...data.contact,
    honorificPrefix: titleCleanup(data.contact?.honorificPrefix),
    givenName: givenNameCleanup(data.contact?.givenName),
    familyName: data.contact?.familyName?.toUpperCase(),
    bday:
      data.contact?.bday && format(new Date(data.contact.bday), 'dd/MM/yyyy'),
  },
  loan: {
    ...data.loan,
    amount: centsToEur(data.loan?.amount || 0).toLocaleString('fr', options),
    amountEloignement: centsToEur(
      data.loan?.amountEloignement || 0,
    ).toLocaleString('fr', options),
    amountEnseignement: centsToEur(
      data.loan?.amountEnseignement || 0,
    ).toLocaleString('fr', options),
    amountEntretien: centsToEur(data.loan?.amountEntretien || 0).toLocaleString(
      'fr',
      options,
    ),
    // this field is not the same as owner.$$retractation (TBC)
    $$retractation:
      data.loan?.signature &&
      format(addDays(new Date(data.loan.signature), 14), 'dd/MM/yyyy'),
  },
});

const dataCleanupGuarantee = (
  data: Omit<Guarantee, 'owner' | 'dataBlob'> & {
    loan?: GuarantyLoan;
    dataBlob?: Record<string, unknown>;
  },
): any => ({
  ...data,
  $$todayDate: format(new Date(), 'dd/MM/yyyy'),
  contact: data.contacts?.map(c => {
    return {
      honorificPrefix: titleCleanup(c.honorificPrefix),
      givenName: givenNameCleanup(c.givenName),
      familyName: c.familyName?.toUpperCase(),
    };
  }),
  amount: centsToEur(data.amount).toLocaleString('fr', options),
  signature: format(new Date(data.signature), 'dd/MM/yyyy'),
  loan: {
    ...data.loan,
    amount: centsToEur(data.loan?.amount || 0).toLocaleString('fr', options),
    signature:
      data.loan?.signature === undefined
        ? null
        : format(new Date(data.loan?.signature), 'dd/MM/yyyy'),
  },
});

const dataCleanupDataBlob = (dataBlob: Record<string, unknown>) => {
  set(
    dataBlob,
    'synthese.projet.montantCautionne',
    centsToEur(
      (get(dataBlob, 'synthese.projet.montantCautionne', '') as number) || 0,
    ).toLocaleString('fr', options),
  );
  return dataBlob;
};

const docxFilename = (
  cp: string,
  template: string,
  templates?: TemplatesContextType,
) => {
  return `${format(new Date(), 'yyMMdd-HHmmss')}-${cp}-${
    templates ? _.get(templates, template) : 'Courrier'
  }.docx`;
};

// TODO - useMakeDocx('cpretBorrower', 'cpretOwner', 'cpretLoan') ...
export const useMakeDocx = (resource: ResourceKey = 'cpretBorrower') => {
  const dataProvider = useContext(DataProviderContext) as CustomDataProvider;
  const tplCache = useRef<{ [k: string]: Buffer }>({});
  const templates = useContext(TemplatesContext);
  const otherField = (
    {
      cpretBorrower: 'borrower',
      cpretLoan: 'loan',
      cpretGuarantee: 'guarantee',
      cpretGuarantyLoan: 'guarantyLoan',
    } as { [k: string]: string }
  )[resource];
  if (!otherField) throw new Error('Bad resource field');
  const createAsset = useCreateAsset(otherField);

  const cb = useCallback(
    (
      bidOrGidOrLid: Identifier,
      tplFactory: string | ((data: any) => string | null),
      saveAsset = true,
    ) => {
      const action = async () => {
        const { data: input } = await dataProvider.getOne(resource, {
          id: bidOrGidOrLid,
        });
        let connectedOtherValue: RARecord;
        let oid;
        let data;
        let o;
        if (resource === 'cpretGuarantee' || resource === 'cpretGuarantyLoan') {
          let g: Guarantee;
          let gl: GuarantyLoan | undefined;
          if (resource === 'cpretGuarantee') {
            g = input as Guarantee;
            connectedOtherValue = g;
            oid = g.owner.edges?.[0]?.node.id;
          } else {
            gl = input as GuarantyLoan;
            const bid = gl.guarantee.edges?.[0]?.node.id;
            if (!bid) throw new Error('GuarantyLoan without Guarantee !');
            ({ data: g } = await dataProvider.getOne('cpretGuarantee', {
              id: bid,
            }));
            oid = g.owner.edges?.[0]?.node.id;
            connectedOtherValue = gl;
          }
          if (!oid) throw new Error('Guarantee without owner !');
          ({ data: o } = await dataProvider.getOne<EnhancedOwner>(
            'cpretOwner',
            { id: oid },
          ));
          let dBlob = {};
          try {
            dBlob = g?.dataBlob
              ? dataCleanupDataBlob(JSON.parse(g.dataBlob))
              : {};
          } catch (e) {
            // Do nothing
          }
          const cleanup = dataCleanupGuarantee({
            ...g,
            loan: gl,
            dataBlob: dBlob,
          });
          data = { ...cleanup, ...dataCleanupOwner(o) };
        } else {
          let b: EnhancedBorrower;
          let l: Loan | undefined;
          if (resource === 'cpretLoan') {
            l = input as Loan;
            const { bid } = l.borrower.edges?.[0]?.node as Borrower & {
              bid: string;
            };
            if (!bid) throw new Error('Loan without borrower !');
            ({ data: b } = await dataProvider.getOne('cpretBorrower', {
              id: bid,
            }));
            oid = b.owner.edges?.[0]?.node.id;
            connectedOtherValue = l;
          } else {
            b = input as EnhancedBorrower;
            connectedOtherValue = b;
            oid = b.owner.edges?.[0]?.node.id;
          }
          if (!oid) throw new Error('Borrower without owner !');
          ({ data: o } = await dataProvider.getOne<EnhancedOwner>(
            'cpretOwner',
            { id: oid },
          ));
          const cleanup = dataCleanupBorrowerLoan({
            ...b,
            loan: l,
          });
          const payments = formatPayments(cleanup);
          data = { ...cleanup, ...payments, ...dataCleanupOwner(o) };
        }

        const template =
          typeof tplFactory === 'string' ? tplFactory : tplFactory(data);
        if (!template) return null;
        if (!tplCache.current[template]) {
          const tpl = (
            (await downloadFromS3(`${TEMPLATE_NAMESPACE}/${template}.docx`))
              .Body as Uint8Array
          ).buffer;
          if (!tpl) throw new Error('Missing template');
          tplCache.current[template] = Buffer.from(tpl);
        }

        const report = await createReport({
          template: tplCache.current[template],
          data,
          cmdDelimiter: ['{{', '}}'],
        });

        const file = new File(
          [report],
          docxFilename(o.sncfCP || 'UNKNOWN', template, templates),
          {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            lastModified: Date.now(),
          },
        );
        if (saveAsset) {
          // create and upload to s3
          await createAsset({
            file,
            prefix: ASSET_MAIL_NAMESPACE,
            connectedOther: connectedOtherValue,
          });
          // TODO - Log here ?
        }

        return file;
      };
      return action();
    },
    [createAsset, dataProvider, resource, templates],
  );

  return cb;
};

export const useReminder = () => {
  const generate = useMakeDocx();

  const cb = useCallback(
    (bid: Identifier, saveAsset?: boolean) => {
      const tplFactory = (data: any) => {
        const type =
          data.$$rappel &&
          (data.$$rappel === '1er' ? 'reminder.first' : 'reminder.second');
        return type || null;
      };
      return generate(bid, tplFactory, saveAsset);
    },
    [generate],
  );
  return cb;
};
