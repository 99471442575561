const config = {
  dates: {
    awsDateFormat: 'YYYY-MM-DD',
    displayDateFormat: 'DD/MM/YYYY',
    awsDateTimeFormat: 'YYYY-MM-DDThh:mm:ss.SSSZ',
    displayDateTimeFormat: 'DD/MM/YYYY HH:mm',
  },
  textFieldMaxLength: 10000, // 10kb
  exportedCSVconfig: {
    rowDelimiter: ';',
    booleanTrueString: 'Oui',
    booleanFalseString: 'Non',
    typeHandlers: {
      /* (value: any, index: any, parent: any) */
      // Array: (value: any, index: any, parent: any): any => {
      //   console.log('Array', { value, index, parent });
      //   return value;
      // },
      // Boolean: (value: any, index: any, parent: any): any => {
      //   console.log('Boolean', { value, index, parent });
      //   return value;
      // },
      // Function: (value: any, index: any, parent: any): any => {
      //   console.log('Function', { value, index, parent });
      //   return value;
      // },
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      Number: (value: any): any => {
        if (value === Infinity) return '';
        // Prevent zeros to disapear on CSV
        return `${value}`;
      },
      // String: (value: any, index: any, parent: any): any => {
      //   console.log('String', { value, index, parent });
      //   const date = moment(value);
      //   if (date.isValid()) return date.toString();
      //   return value;
      // },
      // Buffer: (value: any, index: any, parent: any): any => {
      //   console.log('Buffer', { value, index, parent });
      //   return value;
      // },
      // Object: (value: any, index: any, parent: any): any => {
      //   console.log('Object', { value, index, parent });
      //   return value;
      // },
    },
  },
};

export default config;
