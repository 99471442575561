import { WFState as LoanWFState, WFAction as LoanWFAction } from './loans-enum';
import {
  WFState as GuarantyWFState,
  WFAction as GuarantyWFAction,
} from './guaranty-loans-enum';
import { ActionHistory, Node as SchemaNode } from '../types/schema';

type WFStates = LoanWFState | GuarantyWFState;
type WFActions = LoanWFAction | GuarantyWFAction;

export type ValidationErrors = Record<string, string>; // Could be { [k: string]: { message: string, args: { ... } } } ?
export type WorkflowState = { loading: boolean };
export type WorkflowNode = SchemaNode & {
  __typename?: string;
  wfStatus?: string;
  wfAction?: string;
  actionHistory?: ActionHistory[];
  // [k: string]: any;
};

// from RA HttpError
export class WorkflowError extends Error {
  constructor(
    public readonly message: string,
    public readonly validation: ValidationErrors,
  ) {
    super(message);
    Object.setPrototypeOf(this, WorkflowError.prototype);
    this.name = this.constructor.name;
    // this.validation = validation; // not needed ?
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
    this.stack = new Error().stack;
  }
}

type LogArgs = Record<string, any>;
type LogArgsFunction = (
  next: WorkflowNode,
  prev?: WorkflowNode | Partial<WorkflowNode>,
) => LogArgs;
type WorkflowLogArgs = LogArgs | LogArgsFunction;

type WFActionFunction<WFState extends WFStates> = {
  (next: WorkflowNode, prev: WorkflowNode | Partial<WorkflowNode>): WFState;
  logMessage: string;
  logArgs?: WorkflowLogArgs;
  workerOnly?: boolean;
};

type WFActionFactoryParams = {
  workerOnly?: boolean;
  logMessage: string;
  logArgs?: WorkflowLogArgs;
};

export const actionFactory = <WFState extends WFStates>(
  f: (
    ...args: Parameters<WFActionFunction<WFState>>
  ) => ReturnType<WFActionFunction<WFState>>,
  { workerOnly, logMessage, logArgs }: WFActionFactoryParams,
): WFActionFunction<WFState> => {
  const af = f as WFActionFunction<WFState>;
  if (workerOnly) af.workerOnly = true;
  af.logMessage = logMessage;
  if (logArgs) af.logArgs = logArgs;
  return af;
};

export type Workflow<WFAction extends WFActions, WFState extends WFStates> = {
  [state in WFState]?: {
    [action in WFAction]?: WFActionFunction<WFState>;
  };
};

export const executeWorkFlow = <
  T extends WorkflowNode,
  WFAction extends WFActions,
  WFState extends WFStates,
>({
  next,
  prev = { wfStatus: 'UNDEFINED' } as Partial<T>, // used for creation
  wfAction,
  workflow,
}: {
  next: T;
  prev?: T | Partial<T>;
  wfAction: WFAction;
  workflow: Workflow<WFAction, WFState>;
}):
  | [ValidationErrors, undefined]
  | [
      undefined,
      {
        next: Partial<T>;
        logMessage?: string;
        logArgs?: LogArgs;
      },
    ] => {
  const action = wfAction && workflow[prev.wfStatus as WFState]?.[wfAction];
  if (!action) {
    if (wfAction && !action) {
      throw new Error(
        `Ignoring invalid workflow action ${wfAction} for current state ${prev.wfStatus}`,
      );
    }
    // simple pass through
    return [undefined, { next }];
  }
  try {
    const wfStatus = action({ ...prev, ...next } as T, prev);

    const argsOrFunc = action.logArgs;
    const logArgs =
      argsOrFunc &&
      (typeof argsOrFunc === 'object' ? argsOrFunc : argsOrFunc(next, prev));

    // Here update actionHistory with new action details based on logArgs
    const prevHistory: ActionHistory[] | undefined =
      next.actionHistory || prev.actionHistory;
    const nextHistory = (prevHistory || []).filter(
      a => a.wfAction !== wfAction,
    );

    if (wfAction) {
      const { message: motif, ...restArgs } = logArgs || {};
      const actionHistory: ActionHistory = {
        wfAction,
        date: new Date().toISOString(),
        message: motif || undefined,
        args: Object.keys(restArgs).length
          ? JSON.stringify(restArgs)
          : undefined,
        prevStatus: prev.wfStatus,
        nextStatus: wfStatus, // Some nextStatus broken before june 2024, docker deploy issue for worker
      };
      nextHistory.push(actionHistory);
    }

    return [
      undefined,
      {
        next: { ...next, wfStatus, actionHistory: nextHistory },
        logMessage: action.logMessage,
        logArgs,
      },
    ];
  } catch (e: any) {
    return [e.validation, undefined];
  }
};
