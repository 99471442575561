import React from 'react';
import { FunctionField } from 'react-admin';

import { Schedule, ScheduleType } from '../../../../types/schema';

export interface TypeProps {
  // eslint-disable-next-line react/no-unused-prop-types
  label?: string;
}

export const Type: React.FC<TypeProps> = () => {
  const render = React.useCallback((record?: Schedule) => {
    return (
      <>
        {record?.type === ScheduleType.CAPITAL && 'Primaire'}
        {record?.type === ScheduleType.DEBT && 'Secondaire'}
      </>
    );
  }, []) as any;

  return <FunctionField render={render} />;
};
