/**
 * Loan Workflow
 *
 * Triggered from Loan Edit - needs record context
 * https://marmelab.com/blog/2021/04/07/react-admin-april-update.html#code-classlanguage-textuserecordcontextcode
 *
 */

import { useCallback, useContext, useState } from 'react';
import {
  CreateResult,
  DataProviderContext,
  EditProps,
  Record,
  UpdateResult,
  useDataProvider,
  UseDataProviderOptions,
  useEditContext,
  useGetOne,
  useNotify,
  // useRecordContext,
  useRedirect,
} from 'react-admin';

import { Borrower, Loan } from '../types/schema';
import { clearDataBeforeSubmit } from '../dataProvider/utils';
import { CustomDataProvider } from '../dataProvider/type';
import { useCpretHistory } from './history';
import {
  actionFactory,
  executeWorkFlow,
  ValidationErrors,
  WorkflowNode,
  Workflow,
  WorkflowError,
  WorkflowState,
} from './workflow';
import { WFState, WFAction } from './loans-enum';

export { WFState, WFAction };

type LoanSaveCallback = (
  data: Loan & WorkflowNode,
  redirect?: any,
) => Promise<undefined | ValidationErrors>;

type ExecuteResult = [ValidationErrors | undefined, string | undefined];
type LoanExecuteCallback = (params: {
  loan: Loan;
  prevLoan?: Loan;
  wfAction: WFAction;
  onRedirect: (data: any) => void; // ReturnType<typeof useRedirect>;
}) => Promise<ExecuteResult>;

export type LoanWorkflowCallback = (params: {
  loanId: string;
  wfAction: WFAction;
}) => Promise<ExecuteResult>;

// reducer - https://github.com/phileog/cpret-archi/issues/3
export const workflow: Workflow<WFAction, WFState> = {
  [WFState.UNDEFINED]: {
    [WFAction.CREATION]: actionFactory(
      () => {
        // TODO - Log
        return WFState.INSTRUCTION;
      },
      { logMessage: 'cpret.workflow.loan.creation' },
    ),
  },
  [WFState.INSTRUCTION]: {
    [WFAction.REFUS]: actionFactory(
      () => {
        return WFState.REFUSE;
      },
      { logMessage: 'cpret.workflow.loan.refus' },
    ),
    [WFAction.ACCORD]: actionFactory(
      () => {
        return WFState.ACCORDE;
      },
      { logMessage: 'cpret.workflow.loan.accord' },
    ),
  },
  [WFState.REFUSE]: {
    [WFAction.INSTRUCTION]: actionFactory(
      () => {
        return WFState.INSTRUCTION;
      },
      { logMessage: 'cpret.workflow.loan.refus' },
    ),
  },
  [WFState.ACCORDE]: {
    [WFAction.INSTRUCTION]: actionFactory(
      () => {
        return WFState.INSTRUCTION;
      },
      { logMessage: 'cpret.workflow.loan.instruction' },
    ),
    [WFAction.EMISSION]: actionFactory(
      () => {
        return WFState.EMIS;
      },
      { logMessage: 'cpret.workflow.loan.emission' },
    ),
    [WFAction.ANNULATION]: actionFactory(
      () => {
        return WFState.ANNULE;
      },
      { logMessage: 'cpret.workflow.loan.annulation' },
    ),
  },
  [WFState.EMIS]: {
    [WFAction.ANNULATION]: actionFactory(
      () => {
        return WFState.ANNULE;
      },
      { logMessage: 'cpret.workflow.loan.annulation' },
    ),
    [WFAction.SIGNATURE]: actionFactory(
      next => {
        const loan = next as Loan;
        if (!loan?.signature)
          throw new WorkflowError('Cannot Apply', {
            signature: 'Signature obligatoire',
          });
        return WFState.SIGNE;
      },
      { logMessage: 'cpret.workflow.loan.signature' },
    ),
  },
  [WFState.ANNULE]: {
    [WFAction.INSTRUCTION]: actionFactory(
      () => {
        return WFState.INSTRUCTION;
      },
      {
        logMessage: 'cpret.workflow.loan.instruction',
        workerOnly: true,
      },
    ),
  },
  [WFState.SIGNE]: {
    [WFAction.ANNULATION]: actionFactory(
      () => {
        return WFState.ANNULE;
      },
      { logMessage: 'cpret.workflow.loan.annulation' },
    ),
    [WFAction.GESTION]: actionFactory(
      () => {
        return WFState.GESTION;
      },
      { logMessage: 'cpret.workflow.loan.gestion', workerOnly: true },
    ),
  },
  [WFState.GESTION]: {
    [WFAction.CONTENTIEUX]: actionFactory(
      () => {
        return WFState.CONTENTIEUX;
      },
      { logMessage: 'cpret.workflow.loan.contentieux' },
    ),
    [WFAction.SURENDETTEMENT]: actionFactory(
      () => {
        return WFState.SURENDETTEMENT;
      },
      { logMessage: 'cpret.workflow.loan.surendettement' },
    ),
    [WFAction.CLOTURE]: actionFactory(
      () => {
        return WFState.CLOTURE;
      },
      { logMessage: 'cpret.workflow.loan.cloture', workerOnly: true },
    ),
    [WFAction.INSTRUCTION]: actionFactory(
      () => {
        return WFState.INSTRUCTION;
      },
      {
        logMessage: 'cpret.workflow.loan.instruction',
        workerOnly: true,
      },
    ),
  },
  [WFState.CONTENTIEUX]: {
    [WFAction.CLOTURE]: actionFactory(
      () => {
        return WFState.CLOTURE;
      },
      { logMessage: 'cpret.workflow.loan.cloture', workerOnly: true },
    ),
  },
  [WFState.SURENDETTEMENT]: {
    [WFAction.GESTION]: actionFactory(
      () => {
        return WFState.GESTION;
      },
      { logMessage: 'cpret.workflow.loan.gestion' },
    ),
    [WFAction.CLOTURE]: actionFactory(
      () => {
        return WFState.CLOTURE;
      },
      { logMessage: 'cpret.workflow.loan.cloture', workerOnly: true },
    ),
  },
  [WFState.CLOTURE]: {
    [WFAction.GESTION]: actionFactory(
      () => {
        return WFState.GESTION;
      },
      { logMessage: 'cpret.workflow.loan.gestion', workerOnly: true },
    ),
  },
};

const useExecuteLoanWorkflow = (): [LoanExecuteCallback, WorkflowState] => {
  const dataProvider = useDataProvider();
  const dp = useContext(DataProviderContext) as CustomDataProvider;
  const client = dp.getClient();
  const { onSuccessRef, onFailureRef, transformRef } = useEditContext(); // useEditController uses useSaveModifiers ?
  const notify = useNotify();
  const [state, setState] = useState<WorkflowState>({ loading: false });
  const log = useCpretHistory();
  const cb = useCallback<LoanExecuteCallback>(
    async ({ loan, prevLoan, wfAction, onRedirect }) => {
      let [error, wfResult]: ReturnType<typeof executeWorkFlow> = [
        {},
        undefined,
      ];
      const prevStatus = prevLoan?.wfStatus || WFState.UNDEFINED;
      try {
        [error, wfResult] = executeWorkFlow<Loan, WFAction, WFState>({
          next: loan,
          prev: prevLoan,
          wfAction: (wfAction as WFAction) || undefined,
          workflow,
        });
      } catch (e: any) {
        console.error(e);
        notify('cpret.workflow.error', 'error', {
          status: prevStatus,
          action: wfAction,
        });
        return [undefined, undefined] as ExecuteResult;
      }
      if (!wfResult) {
        // notify('ra.message.invalid_form', 'warning'); // ra.message.invalid_form
        return [error, undefined] as ExecuteResult;
      }

      // actual update now
      const { next: nextLoan, logMessage, logArgs } = wfResult;
      const data = await (transformRef?.current || clearDataBeforeSubmit)(
        nextLoan as Loan,
      );
      delete data.wfAction;

      const options: UseDataProviderOptions = {
        // inspired from ra-core/src/controller/details/useEditController (!)
        // https://github.com/marmelab/react-admin/blob/v3.16.6/packages/ra-core/src/controller/details/useEditController.ts
        onSuccess:
          onSuccessRef?.current ||
          (({ data: newRecord }) => {
            notify(logMessage || 'ra.notification.updated', 'info', {
              ...logArgs,
              wfStatus: nextLoan.wfStatus,
              prevStatus,
              smart_count: 1,
            });
            onRedirect(newRecord);
          }),
        onFailure:
          onFailureRef?.current ||
          (err => {
            notify(
              typeof err === 'string'
                ? err
                : err.message || 'ra.notification.http_error',
              'warning',
              {
                _:
                  typeof err === 'string'
                    ? err
                    : (err && err.message) || undefined,
              },
            );
          }),
      };

      const mutate: Promise<UpdateResult<Record> | CreateResult<Record>> =
        prevLoan
          ? dataProvider.update(
              'cpretLoan',
              {
                id: prevLoan.id,
                data,
                previousData: prevLoan,
              },
              options,
            )
          : dataProvider.create(
              'cpretLoan',
              {
                data,
              },
              options,
            );

      setState({ loading: true });
      return mutate
        .then(async ({ data: nextRecord }) => {
          // cleanup cache here - will be refetched later
          if (prevLoan)
            client.cache.evict({
              id: client.cache.identify({
                __typename: 'Borrower',
                id: (
                  prevLoan.borrower.edges[0]?.node as Borrower & {
                    bid: string;
                  }
                ).bid,
              }),
              fieldName: 'loans',
              broadcast: false,
            });
          // no need to call dataProvider.observeOne here

          setState({ loading: false });
          if (logMessage)
            log(
              {
                message: logMessage,
                args: {
                  ...logArgs,
                  wfStatus: nextLoan.wfStatus,
                  prevStatus,
                },
              },
              [nextRecord as Partial<Node>],
            );
          return [undefined, logMessage] as ExecuteResult;
        })
        .catch((e: any) => {
          setState({ loading: false });
          throw e;
        });
    },
    [
      transformRef,
      onSuccessRef,
      onFailureRef,
      dataProvider,
      notify,
      client,
      log,
    ],
  );
  return [cb, state];
};

export const useLoanWorkflowCreate = (/* props: Partial<SimpleFormProps>, */): [
  LoanSaveCallback,
  WorkflowState,
] => {
  const [execute, state] = useExecuteLoanWorkflow();
  const redirectTo = useRedirect();
  const cb = useCallback<LoanSaveCallback>(
    (data /* , redirect */) => {
      return execute({
        loan: data,
        wfAction: WFAction.CREATION,
        onRedirect: newRecord => {
          // redirectTo(redirect, props.basePath, newRecord.id, newRecord);
          redirectTo(
            'edit',
            '/cpretBorrower',
            (
              (newRecord as Loan).borrower.edges[0]?.node as Borrower & {
                bid: string;
              }
            ).bid,
          );
        },
      }).then(([errors]) => errors);
    },
    [execute, redirectTo],
  );
  return [cb, state];
};

export const useLoanWorkflowSave = (
  props: Partial<EditProps>,
): [LoanSaveCallback, WorkflowState] => {
  const { id, resource } = props;
  const { data: prevLoan } = useGetOne<Loan>(resource ?? '', id ?? '');

  const [execute, state] = useExecuteLoanWorkflow();
  const redirectTo = useRedirect();
  const cb = useCallback<LoanSaveCallback>(
    (data /* , redirect */) => {
      if (!prevLoan || prevLoan.__typename !== 'Loan')
        throw new Error('Not a Loan');
      const { wfAction, ...rest } = data;
      return execute({
        loan: {
          ...rest,
        },
        prevLoan,
        wfAction: (wfAction as WFAction) || undefined,
        onRedirect: newRecord => {
          // redirectTo(redirect, props.basePath, newRecord.id, newRecord);
          redirectTo(
            'edit',
            '/cpretBorrower',
            (
              (newRecord as Loan).borrower.edges[0]?.node as Borrower & {
                bid: string;
              }
            ).bid,
          );
        },
      }).then(([errors]) => errors);
    },
    [prevLoan, execute, redirectTo],
  );
  return [cb, state];
};

export const useLoanWorkflow = (): [LoanWorkflowCallback, WorkflowState] => {
  const dataProvider = useDataProvider();
  const [execute, state] = useExecuteLoanWorkflow();
  const cb = useCallback<LoanWorkflowCallback>(
    ({ loanId, wfAction }) =>
      dataProvider
        .getOne<Loan>('cpretLoan', { id: loanId })
        .then(async ({ data: prevLoan }) => {
          const [errors, result] = await execute({
            loan: { ...prevLoan },
            prevLoan,
            wfAction,
            onRedirect: () => {},
          });
          // TODO - Notify here ?
          // if (errors) return 'cpret.workflow.error'; // could be { message: 'cpret.workflow.error', args: { ... } }
          return [errors, result];
        }),
    [dataProvider, execute],
  );
  return [cb, state];
};
