import cssParser, { StyleRules, Media, Rule, Declaration } from 'css';

//
// Transform implementation or originally thanks to
// https://github.com/raphamorim/native-css
//

function cleanPropertyName(name: string) {
  // turn things like 'align-items' into 'alignItems'
  return name.replace(/(-.)/g, v => {
    return v[1].toUpperCase();
  });
}

function mediaNameGenerator(name: string) {
  return `@media ${name}`;
}

function nameGenerator(name: string) {
  return name
    .replace(/\s\s+/g, ' ')
    .replace(/[^a-zA-Z0-9]/g, '_')
    .replace(/^_+/g, '')
    .replace(/_+$/g, '');
}

function ruleIsMedia(rule: StyleRules['rules'][number]): rule is Media {
  return rule.type === 'media';
}

function ruleIsRule(rule: StyleRules['rules'][number]): rule is Rule {
  return rule.type === 'rule';
}

function declarationIsDeclaration(
  declaration: NonNullable<Rule['declarations']>[number],
): declaration is Declaration {
  return declaration.type === 'declaration';
}

function transformRules(
  rules: StyleRules['rules'],
  result: { [k: string]: any },
) {
  rules.forEach(rule => {
    const obj: typeof result = {};
    if (ruleIsMedia(rule) && rule.media && rule.rules) {
      const name = mediaNameGenerator(rule.media);
      result[name] = result[name] || {
        __expression__: rule.media,
      };
      const media = result[name];
      transformRules(rule.rules, media);
    } else if (ruleIsRule(rule)) {
      rule.declarations?.forEach(declaration => {
        if (declarationIsDeclaration(declaration) && declaration.property) {
          const cleanProperty = cleanPropertyName(declaration.property);
          obj[cleanProperty] = declaration.value;
        }
      });
      rule.selectors?.forEach(selector => {
        const name = nameGenerator(selector.trim());
        result[name] = obj;
      });
    }
  });
}

export default function transformCssToReact(inputCssTextRaw: string) {
  if (!inputCssTextRaw) {
    throw new Error('missing css text to transform');
  }

  let inputCssText = inputCssTextRaw;

  // If the input "css" doesn't wrap it with a css class (raw styles)
  // we need to wrap it with a style so the css parser doesn't choke.
  let bootstrapWithCssClass = false;
  if (inputCssText.indexOf('{') === -1) {
    bootstrapWithCssClass = true;
    inputCssText = `.bootstrapWithCssClass { ${inputCssText} }`;
  }

  const css = cssParser.parse(inputCssText);
  let result: { [k: string]: any } = {};
  if (css.stylesheet) {
    transformRules(css.stylesheet.rules, result);
  }

  // Don't expose the implementation detail of our wrapped css class.
  if (bootstrapWithCssClass && result.bootstrapWithCssClass) {
    result = result.bootstrapWithCssClass;
  }

  return result;
}
