import React, { useCallback, useState } from 'react';

import 'codemirror/lib/codemirror.css';
// import 'codemirror/theme/material.css';
// import 'codemirror/theme/neat.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
// import 'codemirror/mode/javascript/javascript';
import '../../CodeMirror.css';
import { Controlled as CodeMirror } from 'react-codemirror2';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { YamlComponentProps } from './YamlComponent';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    editor: {
      padding: theme.spacing(0.5),
      border: '1px solid #e1e1e1',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.white,
    },
    hasError: {
      borderColor: theme.palette.error.main,
      boxShadow: `0px 0px 3px ${theme.palette.error.main}`,
    },
  }),
);

type HtmlSourceComponentProps = YamlComponentProps;

const HtmlSourceComponent: React.FC<HtmlSourceComponentProps> = ({
  sourceValue,
  setSourceValue,
  readOnly,
  className,
}: HtmlSourceComponentProps) => {
  const classes = useStyles();

  const [htmlValue, setHtmlValue] = useState(sourceValue?.content ?? '');
  const [hasError, setError] = useState(false);

  const onChange = useCallback(
    (editor, data, newValue) => {
      setHtmlValue(newValue);
      try {
        setSourceValue({ ...sourceValue, content: newValue });
        setError(false);
      } catch (error) {
        setError(true);
      }
    },
    [setSourceValue, sourceValue],
  );

  return (
    <CodeMirror
      value={htmlValue}
      // options={options}
      onBeforeChange={onChange}
      // onChange={(editor, value) => {
      //   console.log('controlled', {value});
      // }}
      className={clsx(
        classes.editor,
        { [classes.hasError]: hasError },
        className,
      )}
      options={{
        // theme: 'phicomas',
        fixedGutter: true,
        // scrollbarStyle: 'null',
        coverGutterNextToScrollbar: false,
        lineNumbers: true,
        mode: 'text/html',
        lineWrapping: true,
        // htmlMode: true,
        // matchClosing: true,
        indentWithTabs: false,
        readOnly,
      }}
    />
  );
};

export default HtmlSourceComponent;
