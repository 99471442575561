import { differenceBy } from 'lodash';
import React from 'react';
import { DataProviderContext } from 'react-admin';
import { ApolloClient } from '@apollo/client';
import gql from 'graphql-tag';

import { Asset } from '../types/schema';

export const useDisconnectAttachments = (
  resource: string,
  resourceId: string,
) => {
  const dataProvider = React.useContext(DataProviderContext);
  const client = dataProvider.getClient() as ApolloClient<any>;

  const disconnect = React.useCallback(
    (previousAtachments: Asset[], nextAttachments: Asset[]) => {
      const attachmentsToDelete = differenceBy(
        previousAtachments,
        nextAttachments,
        'id',
      );

      const deletes = [];

      for (const attachment of attachmentsToDelete) {
        deletes.push(
          client.mutate({
            mutation: gql`
          mutation disconnect($id: ID!, $resourceId: ID!) {
            updateAsset(
              data: { ${resource}: { disconnect: [$resourceId] } }
              id: $id
            ) {
              id
            }
          }
          `,
            variables: {
              id: attachment.id,
              resourceId,
            },
            fetchPolicy: 'no-cache',
            refetchQueries: false as any, // https://github.com/apollographql/apollo-client/issues/10238
          }),
        );
      }

      return Promise.all(deletes);
    },
    [client, resource, resourceId],
  );

  return disconnect;
};
