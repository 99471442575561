import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Form, useFormState } from 'react-final-form';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import { Button } from 'react-admin';

import { ActionTypeField, LedgerActionType } from './ActionTypeField';
import { DisbursementLedgerActionFields } from './fields/DisbursementLedgerActionFields';
import { RepaymentLedgerActionFields } from './fields/RepaymentLedgerActionFields';
import { Borrower, Loan, Ledger } from '../../../types/schema';
import { AgnosticFormControl } from '../../../components/Fields/agnostic/AgnosticFormControl';
import { useOnSubmit } from './useOnSubmit';
import { UnpaidCheckLedgerActionFields } from './fields/UnpaidCheckLedgerActionFields';
import { RejectedBankTransferLedgerActionFields } from './fields/RejectedBankTransferLedgerActionFields';
import { DelayedScheduleRepaymentByCheckLedgerActionFields } from './fields/DelayedScheduleRepaymentByCheckLedgerActionFields';
import { ExcedentRepaymentByBankTransferLedgerActionFields } from './fields/ExcedentRepaymentByBankTransferLedgerActionFields copy';
import { PrincipalRoundingLedgerActionFields } from './fields/PrincipalRoundingLedgerActionFields';
import { UserRoundingLedgerActionFields } from './fields/UserRoundingLedgerActionFields';
import { PrincipalUserDebitLedgerActionFields } from './fields/PrincipalUserDebitLedgerActionFields';
import { LossesUserCreditLedgerActionFields } from './fields/LossesUserCreditLedgerActionFields';
import { CustomTransactionLedgerActionFields } from './fields/CustomTransactionLedgerActionFields';
import Loading from '../../../components/layout/Loading';

type LedgerActionProps = {
  ledgers: Ledger[];
  disabled?: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      height: 'fit-content',
      '& h1, & >div>div>div:last-child': {
        display: 'none',
      },
      '& svg': {
        color: 'rgb(255, 0, 0)',
      },
      marginTop: '-9px',
      marginBottom: '-10px',
      '& div > div': {
        margin: '0px',
      },
      '& .MuiCircularProgress-root': {
        width: '16px !important',
        height: '16px !important',
      },
    },
    checkIcon: {
      marginBottom: '-6px',
    },
    button: {
      height: '40px',
    },
    error: {
      color: 'red', // improve...
    },
  }),
);

export const LedgerActionDialog: React.FC<LedgerActionProps> = ({
  ledgers,
  disabled,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { values: parentValues } = useFormState();
  const { id: borrowerId, loans } = parentValues as Borrower;

  const signedLoans = loans?.edges
    .filter(e => (e.node as Loan).wfStatus === 'SIGNE')
    .map(e => e.node as Loan);
  const toggleOpen = React.useCallback(() => {
    setOpen(!open);
    setLoading(false);
  }, [open]);
  const onSubmit = useOnSubmit(borrowerId, toggleOpen);

  const validateActionType = React.useCallback(
    (value?: LedgerActionType) => {
      if (!value) {
        return 'Vous devez sélectionner un type de mouvement';
      }
      if (value === LedgerActionType.DISBURSEMENT && !signedLoans.length) {
        return 'Le versement des fonds est impossible, aucun prêts signés disponibles.';
      }
      return null;
    },
    [signedLoans?.length],
  );

  const initialValues = React.useMemo(
    () => ({
      actionType: null,
    }),
    [],
  );

  return (
    <>
      <Button
        color="default"
        variant="contained"
        size="medium"
        label="Ajouter un mouvement"
        onClick={toggleOpen}
        disabled={disabled}
      >
        <AddIcon />
      </Button>
      <Dialog fullWidth open={open} onClose={toggleOpen} maxWidth="lg">
        <DialogTitle>Ajouter un mouvement</DialogTitle>
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ values, submitting, submitError, invalid, form }) => (
            <>
              <DialogContent>
                {submitError && (
                  <div className={classes.error}>{submitError}</div>
                )}
                <AgnosticFormControl fullWidth>
                  <ActionTypeField validate={validateActionType} />
                </AgnosticFormControl>

                {values.actionType === LedgerActionType.DISBURSEMENT && (
                  <DisbursementLedgerActionFields loans={signedLoans} />
                )}
                {values.actionType === LedgerActionType.REPAYMENT && (
                  <RepaymentLedgerActionFields ledgers={ledgers} />
                )}
                {values.actionType === LedgerActionType.UNPAID_CHECK && (
                  <UnpaidCheckLedgerActionFields />
                )}
                {values.actionType ===
                  LedgerActionType.REJECTED_BANK_TRANSFER && (
                  <RejectedBankTransferLedgerActionFields />
                )}
                {values.actionType ===
                  LedgerActionType.DELAYED_SCHEDULE_REPAYMENT && (
                  <DelayedScheduleRepaymentByCheckLedgerActionFields />
                )}
                {values.actionType ===
                  LedgerActionType.EXCEDENT_REPAYMENT_BY_BANK_TRANFER && (
                  <ExcedentRepaymentByBankTransferLedgerActionFields />
                )}
                {values.actionType === LedgerActionType.PRINCIPAL_ROUNDING && (
                  <PrincipalRoundingLedgerActionFields ledgers={ledgers} />
                )}
                {values.actionType === LedgerActionType.USER_ROUNDING && (
                  <UserRoundingLedgerActionFields ledgers={ledgers} />
                )}
                {values.actionType ===
                  LedgerActionType.PRINCIPAL_USER_DEBIT && (
                  <PrincipalUserDebitLedgerActionFields ledgers={ledgers} />
                )}
                {values.actionType === LedgerActionType.LOSSES_USER_CREDIT && (
                  <LossesUserCreditLedgerActionFields ledgers={ledgers} />
                )}
                {values.actionType === LedgerActionType.CUSTOM_TRANSACTION && (
                  <CustomTransactionLedgerActionFields />
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  color="default"
                  variant="contained"
                  size="medium"
                  label="Annuler"
                  onClick={toggleOpen}
                  type="button"
                />
                <Button
                  disabled={submitting || invalid}
                  color="primary"
                  variant="contained"
                  size="medium"
                  label={submitting ? 'Ajout en cours...' : 'Valider'}
                  onClick={() => {
                    setLoading(true);
                    form.submit();
                  }}
                  type="submit"
                  className={classes.button}
                >
                  <span>
                    {!loading && <CheckIcon className={classes.checkIcon} />}
                    {loading && (
                      <div className={classes.loader}>
                        <Loading />
                      </div>
                    )}
                  </span>
                </Button>
              </DialogActions>
            </>
          )}
        </Form>
      </Dialog>
    </>
  );
};
