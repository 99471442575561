import { ID } from '../types/common';

export type PageRaw = {
  title: string;
  /** Stringified PageContent */
  rawContent: string;
};

export type PageContent = {
  components: PageComponents;
};

export type PageComponents = Array<PageComponentInfos>;

export type PageComponentInfos =
  | PageComponentYamlInfos
  | PageComponentHtmlInfos
  | PageComponentImageInfos
  | PageComponentCarouselInfos
  | PageComponentAccordionInfos
  | PageComponentPushInfos;

type PageComponentCommonInfos = {
  key: string;
  type: PageComponentType;
};

export type PageComponentYamlInfos = PageComponentCommonInfos & {
  type: PageComponentType.YAML;
  componentProps: PageComponentYamlProps;
};
export type PageComponentHtmlInfos = PageComponentCommonInfos & {
  type: PageComponentType.HTML;
  componentProps: PageComponentHtmlProps;
};
export type PageComponentImageInfos = PageComponentCommonInfos & {
  type: PageComponentType.IMAGE;
  componentProps: PageComponentImageProps;
};
export type PageComponentCarouselInfos = PageComponentCommonInfos & {
  type: PageComponentType.CAROUSEL;
  componentProps: PageComponentCarouselProps;
};
export type PageComponentAccordionInfos = PageComponentCommonInfos & {
  type: PageComponentType.ACCORDION;
  componentProps: PageComponentAccordionProps;
};
export type PageComponentPushInfos = PageComponentCommonInfos & {
  type: PageComponentType.PUSH;
  componentProps: PageComponentPushProps;
};

export enum PageComponentType {
  YAML = 'yaml',
  HTML = 'html',
  IMAGE = 'image',
  CAROUSEL = 'carousel',
  ACCORDION = 'accordion',
  PUSH = 'push',
}

export type PageComponentYamlProps = {
  [k: string]: any;
};

export type PageComponentHtmlProps = {
  content: string;
};

export type PageComponentImageProps = {
  assetId?: ID;
  imageKey?: string;
  legend?: string;
};

export type PageComponentCarouselProps = {
  title?: string;
  components?: PageComponents;
};

export type PageComponentAccordionProps = {
  title?: string;
  components?: PageComponents;
};

export type PageComponentPushProps = {
  title?: string;
  components?: PageComponents;
};

/* *****
 * Type guards
 * ***** */

export function isPageComponentYaml(
  pageComponent: PageComponentInfos,
): pageComponent is PageComponentYamlInfos {
  return pageComponent.type === PageComponentType.YAML;
}
export function isPageComponentHtml(
  pageComponent: PageComponentInfos,
): pageComponent is PageComponentHtmlInfos {
  return pageComponent.type === PageComponentType.HTML;
}
export function isPageComponentImage(
  pageComponent: PageComponentInfos,
): pageComponent is PageComponentImageInfos {
  return pageComponent.type === PageComponentType.IMAGE;
}
export function isPageComponentCarousel(
  pageComponent: PageComponentInfos,
): pageComponent is PageComponentCarouselInfos {
  return pageComponent.type === PageComponentType.CAROUSEL;
}
export function isPageComponentAccordion(
  pageComponent: PageComponentInfos,
): pageComponent is PageComponentAccordionInfos {
  return pageComponent.type === PageComponentType.ACCORDION;
}
export function isPageComponentPush(
  pageComponent: PageComponentInfos,
): pageComponent is PageComponentPushInfos {
  return pageComponent.type === PageComponentType.PUSH;
}
