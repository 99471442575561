import React, { useCallback } from 'react';
import { SimpleCms } from 'phicomas-client';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import ComponentsWrapper from '../ComponentsWrapper';
import Components from '../Components'; // eslint-disable-line import/no-cycle
import { ResourceKey } from '../../../../project/projectInfos';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    componentsWrapper: {
      marginTop: theme.spacing(1),
    },
  }),
);

type PushComponentProps = {
  sourceValue: SimpleCms.PageComponentPushProps;
  setSourceValue: (newValue: SimpleCms.PageComponentPushProps) => void;
  resource: ResourceKey;
};

const PushComponent: React.FC<PushComponentProps> = ({
  sourceValue,
  setSourceValue,
  resource,
}: PushComponentProps) => {
  const classes = useStyles();

  const handleSetComponents = useCallback(
    (newValue: SimpleCms.PageComponents) => {
      setSourceValue({ ...sourceValue, components: newValue });
    },
    [setSourceValue, sourceValue],
  );

  return (
    <div className={classes.root}>
      <ComponentsWrapper className={classes.componentsWrapper}>
        <Components
          resource={resource}
          components={sourceValue.components ?? []}
          setComponents={handleSetComponents}
          parent={SimpleCms.PageComponentType.PUSH}
        />
      </ComponentsWrapper>
    </div>
  );
};

export default PushComponent;
