import React from 'react';
import { EditProps, Record, SimpleForm, SimpleFormProps } from 'react-admin';

import { Borrower, Loan, LoanType } from '../../types/schema';
import { LoanForm } from './LoanForm';
import { clearDataBeforeSubmit } from '../../dataProvider/utils';
import { Breadcrumb } from '../../components/Breadcrumb';
import { LoanHistory } from './LoanHistory';
import UploadToolbar from '../../components/Toolbars/AttachmentToolbar';
import { ASSET_USER_NAMESPACE } from '../../aws/cpret-config';
import { useLoanWorkflowSave } from '../../workflow/loans';
import { EditWithAttachmentsAndLog } from '../../components/EditWithAttachmentsAndLog';

export interface LoanEditTitleProps {
  record?: Loan;
}

export const LoanEditTitle: React.FC<LoanEditTitleProps> = ({ record }) => {
  const id = record?.id;
  const brwr = record?.borrower.edges[0]?.node as Borrower & { bid: string };
  const borrowerId = brwr?.bid;
  const ownerId = brwr?.owner.edges[0]?.node.id;
  const year = record?.signature
    ? new Date(record?.signature).getFullYear()
    : new Date().getFullYear();

  const fragments = React.useMemo(
    () => [
      {
        title: 'Demandeur',
        typename: 'Owner',
        id: ownerId,
      },
      {
        title: `Bénéficiaire ${
          brwr?.loanType === LoanType.HONNEUR ? 'PH' : 'Autre'
        }`,
        typename: 'Borrower',
        id: borrowerId,
      },
      {
        title: `${year /* id && shortId(id) */}`,
        typename: 'Loan',
        id,
      },
    ],
    [ownerId, borrowerId, id, brwr, year],
  );

  return <Breadcrumb fragments={fragments} />;
};

const Form: React.FC<
  Partial<SimpleFormProps> & { editProps: Partial<EditProps> }
> = ({ editProps, ...props }) => {
  const [loanSave, { loading: saving }] = useLoanWorkflowSave(editProps);

  return (
    <SimpleForm
      {...props}
      toolbar={
        <UploadToolbar
          field="loan"
          namespace={ASSET_USER_NAMESPACE}
          kind="edit"
        />
      }
      redirect={false}
      // warnWhenUnsavedChanges
      submitOnEnter={false}
      save={loanSave}
      saving={saving}
    >
      <LoanForm />
    </SimpleForm>
  );
};

export const LoanEdit: React.FC<EditProps> = props => {
  return (
    <EditWithAttachmentsAndLog
      {...props}
      title={<LoanEditTitle />}
      undoable={false}
      transform={(data: Record): Record => clearDataBeforeSubmit(data)}
      aside={<LoanHistory />}
    >
      <Form editProps={props} />
    </EditWithAttachmentsAndLog>
  );
};
