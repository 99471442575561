import React from 'react';
import clsx from 'clsx';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import { ClassName } from '../types/styles';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    hidden: {
      display: 'none',
    },
  }),
);

export type ContainerForwardProps = React.PropsWithChildren<{
  containerClassName?: ClassName;
  hidden?: boolean;
}>;
const ContainerForward: React.FC<ContainerForwardProps> = ({
  children,
  containerClassName,
  hidden,
  ...props
}: ContainerForwardProps) => {
  const classes = useStyles();

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });

  return (
    <div className={clsx(containerClassName, { [classes.hidden]: hidden })}>
      {childrenWithProps}
    </div>
  );
};

export default ContainerForward;
