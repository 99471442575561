import { Level } from 'phicomas-client';

import projectInfos from './projectInfos';

type AvailableEnvironment = {
  value: Level;
  isDefault?: boolean;
  hidden?: boolean;
};
export const availableEnvironments: Array<AvailableEnvironment> = (() => {
  const configuredLevels: Set<Level> = new Set();
  const {
    awsExports: { appSync },
  } = projectInfos;
  const levels = Object.keys(appSync) as Level[];
  levels.forEach(configuredLevels.add, configuredLevels);

  const availableEnvironmentsTmp: Array<AvailableEnvironment> = [];
  if (process.env.NODE_ENV === 'development') {
    /* Local Dev */
    if (configuredLevels.has(Level.DEV)) {
      availableEnvironmentsTmp.push({
        value: Level.DEV,
        isDefault: true,
      });
    }
    /*
    if (configuredLevels.has(Level.STAGE)) {
      availableEnvironmentsTmp.push({
        value: Level.STAGE,
      });
    }
    if (configuredLevels.has(Level.PROD)) {
      availableEnvironmentsTmp.push({
        value: Level.PROD,
        hidden: true,
      });
    }
    */
  } else if (process.env.NODE_ENV === 'production') {
    /* We are hosted */
    if (process.env.REACT_APP_ENV === 'dev') {
      /* Dev */
      if (configuredLevels.has(Level.DEV)) {
        availableEnvironmentsTmp.push({
          value: Level.DEV,
          isDefault: true,
        });
      }
    } else if (process.env.REACT_APP_ENV === 'stage') {
      /* Stage */
      /*
      if (configuredLevels.has(Level.DEV)) {
        availableEnvironmentsTmp.push({
          value: Level.DEV,
        });
      }
      */
      if (configuredLevels.has(Level.STAGE)) {
        availableEnvironmentsTmp.push({
          value: Level.STAGE,
          isDefault: true,
        });
      }
      /*
      if (configuredLevels.has(Level.PROD)) {
        availableEnvironmentsTmp.push({
          value: Level.PROD,
        });
      }
      */
    } else if (configuredLevels.has(Level.PROD)) {
      /* Prod */
      availableEnvironmentsTmp.push({
        value: Level.PROD,
        isDefault: true,
      });
    }
  }
  return availableEnvironmentsTmp;
})();

export const defaultEnvironment =
  availableEnvironments.find(({ isDefault }) => isDefault === true)?.value ??
  Level.DEV;
