import { Level, Instance } from '../types';
import { XOR } from '../types/utils';

export enum Provider {
  'Google' = 'Google',
  'Anonymous' = 'Anonymous',
  'SNCF' = 'SNCF',
}

type AwsAppSync = {
  region: string;
  graphqlEndpoint: string;
  apiKey?: string;
};

type AwsS3 = {
  region: string;
  apiVersion: string;
  bucket: string;
  prefix: string;
};

type AwsCognitoSNCFProps = {
  provider: Provider.SNCF;
  loginEnv: OnLevel<string>;
};

export type AwsCognito = {
  assumedRole?: OnLevelNotDev<string>;
} & XOR<{ provider: Provider }, AwsCognitoSNCFProps>;
type AwsCognitoSNCF = AwsCognito & AwsCognitoSNCFProps;
export type AwsCognitoNotAnonymous = Omit<AwsCognito, 'provider'> & {
  provider: Exclude<Provider, Provider.Anonymous>;
};

type OnInstance<T> = {
  [key in Instance]?: T;
} & {
  [Instance.BACK]: T;
};

type OnLevel<T> = {
  [key in Level]?: T;
} & {
  [Level.DEV]: T;
};
type OnLevelNotDev<T> = {
  [key in Level]?: T;
};

export type AwsExports = {
  /**
   * Maybe deprecated - WIP
   * @deprecated - https://github.com/phileog/phicomas-client/issues/7
   */
  cognito: OnInstance<AwsCognito>;
  appSync: OnLevel<AwsAppSync>;
  s3?: OnLevel<AwsS3>;
};

export function isAwsCognitoSNCF(
  awsCognito: AwsCognito | AwsCognitoNotAnonymous,
): awsCognito is AwsCognitoSNCF {
  return awsCognito.provider === Provider.SNCF;
}
