import React from 'react';
import { EditProps, Record, SimpleForm } from 'react-admin';

import { Guarantee } from '../../types/schema';
import { GuaranteeForm } from './GuaranteeForm';
import { clearDataBeforeSubmit } from '../../dataProvider/utils';
import { Breadcrumb } from '../../components/Breadcrumb';
import { GuaranteeHistory } from './GuaranteeHistory';
import UploadToolbar from '../../components/Toolbars/AttachmentToolbar';
import { ASSET_USER_NAMESPACE } from '../../aws/cpret-config';
import { EditWithAttachmentsAndLog } from '../../components/EditWithAttachmentsAndLog';

export interface GuaranteeEditTitleProps {
  record?: Guarantee;
}

export const GuaranteeEditTitle: React.FC<GuaranteeEditTitleProps> = ({
  record,
}) => {
  const fragments = React.useMemo(
    () =>
      [
        {
          title: 'Demandeur',
          typename: 'Owner',
          id: record?.owner.edges[0]?.node.id,
        },
        {
          title: `Caution Globale`,
          typename: 'Guarantee',
          id: record?.id,
        },
      ].filter(Boolean),
    [record],
  );

  return <Breadcrumb fragments={fragments} />;
};

export const GuaranteeSimpleForm: React.FC<any> = props => {
  return (
    <SimpleForm
      {...props}
      redirect={false}
      // warnWhenUnsavedChanges
      submitOnEnter={false}
      toolbar={
        <UploadToolbar
          field="guarantee"
          namespace={ASSET_USER_NAMESPACE}
          kind="edit"
        />
      }
    >
      <GuaranteeForm />
    </SimpleForm>
  );
};

export const GuaranteeEdit: React.FC<EditProps> = props => {
  return (
    <EditWithAttachmentsAndLog
      {...props}
      title={<GuaranteeEditTitle />}
      undoable={false}
      transform={(data: Record): Record => clearDataBeforeSubmit(data)}
      aside={<GuaranteeHistory />}
    >
      <GuaranteeSimpleForm />
    </EditWithAttachmentsAndLog>
  );
};
