import React from 'react';
import { Link, Record } from 'react-admin';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import projectInfos from '../project/projectInfos';

function getPathFromTypename(typename: string) {
  const customizationResource = Object.entries(
    projectInfos.customization.resources,
  ).find(([, resourceCusto]) => resourceCusto?.typename === typename);
  if (customizationResource) {
    return `/${customizationResource[0]}`;
  }
  return '/';
}

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    link: {
      color: theme.palette.text.contrastText,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);

type BreadcrumbLink = { title: string; typename: string; id?: Record['id'] };
type Fragment = React.ReactNode | BreadcrumbLink;

function fragmentIsBreadcrumbLink(
  fragment: Fragment,
): fragment is BreadcrumbLink {
  return (
    typeof fragment === 'object' &&
    fragment !== null &&
    typeof (fragment as BreadcrumbLink).title !== 'undefined'
  );
}

export interface BreadcrumbProps {
  fragments: Array<Fragment>;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ fragments }) => {
  const classes = useStyles();
  const breadcrumb = React.useMemo(
    () =>
      fragments.reduce(
        (p, fragment) => {
          let fragmentNode: React.ReactNode;
          if (fragmentIsBreadcrumbLink(fragment)) {
            let to = getPathFromTypename(fragment.typename);
            if (fragment.id) {
              to += `/${fragment.id}`;
            }
            fragmentNode = (
              <Link to={to} className={classes.link}>
                {fragment.title}
              </Link>
            );
          } else {
            fragmentNode = fragment;
          }

          return (
            <>
              {p} {'>'} {fragmentNode}
            </>
          );
        },
        <Link to="/" className={classes.link}>
          C&apos;Prêt
        </Link>,
      ),
    [fragments, classes],
  );
  return <div>{breadcrumb}</div>;
};
