import * as React from 'react';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Theme,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useRecordContext } from 'react-admin';
import { format } from 'date-fns';
import get from 'lodash/get';

import { Loan } from '../../types/schema';
import { centsToEur } from '../../lib/utils';

interface LegacyData {
  apresPretRav: number;
  apresPretTauxEndet: number;
  avantPretRav: number;
  avantPretTauxEndet: number;
  coutTotalpret: number;
  dateMiseajour: string;
  datePaiement: string;
  differeRembousement: number;
  mensualite: number;
  modePaiement: string;
  nombreMensualite: number;
}

type LegacyDataFieldProps = {
  label?: string; // eslint-disable-line react/no-unused-prop-types
  source: string;
};

function toCurrency(value: number) {
  return centsToEur(Math.abs(value)).toLocaleString('fr', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'EUR',
  });
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topMargin: {
      marginTop: theme.spacing(1),
    },
  }),
);

export const LegacyDataField: React.FC<LegacyDataFieldProps> = props => {
  const classes = useStyles();
  const record: Loan = useRecordContext();
  const { source } = props;
  const blob = get(record, source);
  let data: Partial<LegacyData> = {};
  try {
    data = JSON.parse(blob);
  } catch (e) {
    console.warn(e);
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Date de signature</TableCell>
              <TableCell>Mode de paiement</TableCell>
              <TableCell>Date de mise à jour</TableCell>
              <TableCell>Date de paiement</TableCell>
              <TableCell>Montant du prêt</TableCell>
              <TableCell>Différé de remboursement</TableCell>
              <TableCell>Mensualité</TableCell>
              <TableCell>Taux d'intérêt</TableCell>
              <TableCell>Nombre de mensualité</TableCell>
              <TableCell>Coût total du prêt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCell>
              {record.signature
                ? format(new Date(record.signature), 'dd/MM/yyyy')
                : ''}
            </TableCell>
            <TableCell>{data.modePaiement}</TableCell>
            <TableCell>
              {data.dateMiseajour
                ? format(new Date(data.dateMiseajour), 'dd/MM/yyyy')
                : ''}
            </TableCell>
            <TableCell>
              {data.datePaiement
                ? format(new Date(data.datePaiement), 'dd/MM/yyyy')
                : ''}
            </TableCell>
            <TableCell>
              {record.amount !== undefined ? toCurrency(record.amount) : ''}
            </TableCell>
            <TableCell>{data.differeRembousement}</TableCell>
            <TableCell>
              {data.mensualite !== undefined ? toCurrency(data.mensualite) : ''}
            </TableCell>
            <TableCell>{record.rate} %</TableCell>
            <TableCell>{data.nombreMensualite}</TableCell>
            <TableCell>
              {data.coutTotalpret !== undefined
                ? toCurrency(data.coutTotalpret)
                : ''}
            </TableCell>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer component={Paper} className={classes.topMargin}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Taux d'endettement avant le prêt</TableCell>
              <TableCell>Taux d'endettement après le prêt</TableCell>
              <TableCell>Reste à vivre avant le prêt</TableCell>
              <TableCell>Reste à vivre après le prêt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCell>{data.avantPretTauxEndet}%</TableCell>
            <TableCell>{data.apresPretTauxEndet}%</TableCell>
            <TableCell>
              {data.avantPretRav !== undefined
                ? toCurrency(data.avantPretRav)
                : ''}
            </TableCell>
            <TableCell>
              {data.apresPretRav !== undefined
                ? toCurrency(data.apresPretRav)
                : ''}
            </TableCell>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
