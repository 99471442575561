/**
 * Export Ledger
 * - Données Comptables
 * - PFE
 */

import { addMonths, format } from 'date-fns';
import { useCallback, useContext } from 'react';
import { DataProviderContext } from 'react-admin';
import Table from 'cli-table3';

import { Ledger, LedgerPosting } from '../types/schema';
import { getAccountBalance } from '../lib/ledger';
import { LedgerAccount } from '../dataProvider/type';
import { centsToEur, csvAmount } from '../lib/utils';

type LedgerCustomPosting = {
  id: string;
  createdAt: string;
  payee: string;
  account: string;
  debit?: string;
  credit?: string;
  owner?: string;
};

// TODO useExportLedger as ledger cli format !

export const ledgerFilename = (now = new Date()) =>
  `${format(now, 'yyyyMMdd-HHmmss')}-cpret-journal.csv`;

export const pfeFilename = (now = new Date()) =>
  `${format(now, 'yyyyMMdd-HHmmss')}-cpret-pfe.txt`;

export const useExportLedgerCSV = () => {
  const dataProvider = useContext(DataProviderContext);
  const cb = useCallback((): Promise<LedgerCustomPosting[]> => {
    const action = async (): Promise<LedgerCustomPosting[]> => {
      /*
      // Note: Filtering has moved to ledgerPagination (see phicomas-client/apollo/pagination)
      const start = new Date(now.getFullYear(), month, 1);
      const stop = addMonths(start, 1);
      */
      const { data: list } = await dataProvider.getList<Ledger>('cpretLedger', {
        filter: {},
        pagination: { page: 0, perPage: -1 },
        sort: { field: 'createdAt', order: 'ASC' },
      });
      console.log('list length', list.length);
      const ledger = list
        /*
        .filter(l => {
          const created = new Date(l.createdAt);
          return created >= start && created < stop;
        })
        */
        .map((l: Ledger) =>
          l.postings?.map(
            p =>
              ({
                id: l.id,
                createdAt: l.createdAt,
                payee: l.payee || '',
                account: p.account,
                debit:
                  (p?.amount || 0) > 0
                    ? p.amount && csvAmount(p.amount)
                    : undefined,
                credit:
                  (p?.amount || 0) < 0
                    ? p.amount && csvAmount(-p.amount)
                    : undefined,
                owner: l.borrower.edges[0]?.node.owner.edges[0]?.node.sncfCP,
              } as LedgerCustomPosting),
          ),
        )
        .filter((l): l is LedgerCustomPosting[] => !!l);
      console.log(ledger.length);
      return ([] as LedgerCustomPosting[]).concat(...ledger);
    };
    return action();
  }, [dataProvider]);
  return cb;
};

enum MvtType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  ANY = 'ANY',
}

const hasMovement =
  (account: string, type = MvtType.ANY) =>
  (p: LedgerPosting): boolean => {
    const hasAccount = p.account === account;
    if (!hasAccount) return false;
    const amount = p.amount || 0;
    if (type === MvtType.ANY) return !!amount;
    return type === MvtType.CREDIT ? amount < 0 : amount > 0;
  };

const isDech = (l: Ledger) => l.payee?.match(/^d.ch.ance/i);

const locales = ['fr-FR'];
const currencyOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};
const toPFELocale = (
  gab: Parameters<typeof getAccountBalance>,
  index: number,
): Table.Cell => {
  const amount = getAccountBalance(...gab)[index];
  return {
    content: centsToEur(Math.abs(amount || 0)).toLocaleString(
      locales,
      currencyOptions,
    ),
    hAlign: 'right',
  };
};

export const useExportLedgerPFE = () => {
  const dataProvider = useContext(DataProviderContext);
  const cb = useCallback(
    (now = new Date()): Promise<{ [k: string]: number }> => {
      const month = now.getMonth();
      const start = new Date(now.getFullYear(), month, 1);
      const stop = addMonths(start, 1);
      const action = async (): Promise<any> => {
        const { data: list } = await dataProvider.getList<Ledger>(
          'cpretLedger',
          {
            filter: {},
            pagination: { page: 0, perPage: -1 },
            sort: { field: 'createdAt', order: 'ASC' },
          },
        );
        console.log('list length', list.length);
        const ledger = list.filter(l => {
          const created = new Date(l.createdAt);
          return created >= start && created < stop;
        });
        /*
        TODO - see https://docs.google.com/spreadsheets/d/1DDaZN1Nl6tUzlqUwImo--klTDeNHrqPU1P1f79uwmKE/edit#gid=0
        - Octroi de pret mouvements Debit 274 credit 467
        - Remboursement capital : Debit 467 Credit 274
        - Remboursement interet : Debit 467 Credit 762
        - Déchéance : TBC
        - Abandon de créance : TBC
        */
        /** repayments */
        console.log(ledger.length);
        const rpmts = ledger.filter(
          l =>
            !isDech(l) &&
            l.postings?.some(
              hasMovement(LedgerAccount.USER_ACCOUNT, MvtType.DEBIT),
            ) &&
            l.postings?.some(
              hasMovement(LedgerAccount.PRINCIPAL_ACCOUNT, MvtType.CREDIT),
            ),
        );
        /** déchéance */
        const dech = ledger.filter(
          l =>
            isDech(l) &&
            l.postings?.some(
              hasMovement(LedgerAccount.USER_ACCOUNT, MvtType.DEBIT),
            ) &&
            l.postings?.some(
              hasMovement(LedgerAccount.PRINCIPAL_ACCOUNT, MvtType.CREDIT),
            ),
        );
        const t = new Table({
          head: ['DESCRIPTION', 'MONTANT (€)'],
          style: { border: [], head: [] },
        });
        t.push([
          'Nouvel octroi de prêt',
          toPFELocale([ledger, LedgerAccount.PRINCIPAL_ACCOUNT], 0),
        ]);
        t.push([
          'Intérêts courrus non échus fin de mois',
          { content: '0,00', hAlign: 'right' },
        ]);
        t.push([
          'Remboursement prêt part capital',
          toPFELocale([rpmts, LedgerAccount.PRINCIPAL_ACCOUNT], 1),
        ]);
        t.push([
          'Remboursement prêt part intérêt',
          toPFELocale([rpmts, LedgerAccount.INTEREST_ACCOUNT], 1),
        ]);
        /** Déchéance */
        t.push([
          "Passage capital réstant dû en créance lors de l'abandon de créance",
          toPFELocale([dech, LedgerAccount.USER_ACCOUNT], 0),
        ]);
        /** Pertes */
        t.push([
          'Abandon de la créance (montant total créance)',
          toPFELocale([ledger, LedgerAccount.LOSSES_ACCOUNT],0),
        ]);
        t.push([
          'Écart de centimes des reglements Solde du compte (arrondi)',
          toPFELocale([ledger, LedgerAccount.ROUNDING_ACCOUNT], 2),
        ]);
        return t.toString();
      };
      return action();
    },
    [dataProvider],
  );
  return cb;
};
