import _memoize from 'lodash/memoize';

import projectInfosBase from 'phicomas-client/src/projects/cpret/projectInfos';
import {
  ResourceKey as ProjectResourceKey,
  EnumKey as ProjectEnumKey,
} from 'phicomas-client/src/projects/cpret/projectKeys';

import { GenericResourceInfos, GenericEnumsInfos } from 'phicomas-client';
// eslint-disable-next-line import/no-cycle
import {
  enhanceCustomization,
  EnhancedResourceCustomization,
  EnhancedCustomization,
} from '../types/customization'; // There is NO dependency cycle, as only types are cycling (in other files), but TS doesnt handles is correctly yet

import customization from './customization';
import { cpretOverrides } from './override';

export type ResourceKey = ProjectResourceKey;
export type ResourceInfos = GenericResourceInfos<ResourceKey>;
export type EnumKey = ProjectEnumKey;
export type EnumsInfos = GenericEnumsInfos<EnumKey>;
export type ProjectInfosWithoutCustomization = typeof projectInfosBase;
export type ProjectInfos = typeof projectInfosBase & {
  customization: EnhancedCustomization;
};

const projectInfos: ProjectInfos = {
  ...cpretOverrides(projectInfosBase),
  customization: enhanceCustomization(customization, projectInfosBase),
};

/* ------------------------------------------------
 * ==================== COMMON ====================
 * ------------------------------------------------ */

export const getResourceInfos = _memoize(
  (resource: ResourceKey): GenericResourceInfos<ResourceKey> => {
    return projectInfos.resourcesInfos[resource];
  },
);

export const getAllResourcesInfos = () => projectInfos.resourcesInfos;

export const getResourceCustomization = _memoize(
  (resource: ResourceKey): EnhancedResourceCustomization => {
    const resourceCusto = projectInfos.customization.resources[resource];
    if (!resourceCusto) {
      throw new Error(`No customization found for resource ${resource}`);
    }
    return resourceCusto;
  },
);

export const getEnumsInfos = () => projectInfos.enumsInfos;

export default projectInfos;
