import React from 'react';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import { Asset } from '../../types/schema';
import { ResourceKey } from '../../project/projectInfos';

import FilePreviewer from '../../lib/FilePreviewer';
import { ClassName } from '../../types/styles';

export const useCellStyles = makeStyles<Theme>(theme =>
  createStyles({
    noGrowCell: {
      width: '1%',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  }),
);

type FilePreviewerFieldProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  headerClassName?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  cellClassName?: string;
  resource?: ResourceKey;
  record?: Asset;
  height?: number;
  fit?: boolean;
  placeholder?: boolean;
  className?: ClassName;
};
const FilePreviewerField: React.FC<FilePreviewerFieldProps> = ({
  resource,
  record,
  height = 100,
  fit,
  placeholder,
  className,
}: FilePreviewerFieldProps) => {
  if (placeholder) {
    return (
      <FilePreviewer
        mimeType="unknown"
        height={height}
        fit={fit}
        className={className}
      />
    );
  }
  if (!resource || !record || !record.mimeType) return null;
  return (
    <FilePreviewer
      mimeType={record.mimeType}
      s3FileInfos={{
        resource,
        key: record.key,
      }}
      height={height}
      fit={fit}
      className={className}
    />
  );
};

export default FilePreviewerField;
