import React from 'react';
import { EditProps, Record, SimpleForm } from 'react-admin';
import _ from 'lodash';

import { Owner } from '../../types/schema';
import { OwnerForm } from './OwnerForm';
import { clearDataBeforeSubmit } from '../../dataProvider/utils';
import { Breadcrumb } from '../../components/Breadcrumb';
import { OwnerHistory } from './OwnerHistory';
import UploadToolbar from '../../components/Toolbars/AttachmentToolbar';
import { ASSET_USER_NAMESPACE } from '../../aws/cpret-config';
import { EditWithAttachmentsAndLog } from '../../components/EditWithAttachmentsAndLog';

export interface OwnerTitleProps {
  record?: Owner;
}

const OwnerTitle: React.FC<OwnerTitleProps> = ({ record }) => {
  const person = record?.contact;
  const fragments = React.useMemo(
    () =>
      [
        {
          title: `Demandeur: ${
            person &&
            [person?.givenName, person?.familyName]
              .filter(Boolean)
              .join(' ')
              .trim()
              .match(/(\p{L}+)|(-|\s)/gu)
              ?.map(s => _.upperFirst(_.toLower(s)))
              .join('')
          }`,
          typename: 'Owner',
          id: record?.id,
        },
      ].filter(Boolean),
    [record, person],
  );

  return <Breadcrumb fragments={fragments} />;
};

export const OwnerEdit: React.FC<EditProps> = props => {
  return (
    <EditWithAttachmentsAndLog
      {...props}
      title={<OwnerTitle />}
      undoable={false}
      transform={(data: Record): Record => clearDataBeforeSubmit(data)}
      aside={<OwnerHistory />}
    >
      <SimpleForm
        redirect={false}
        // warnWhenUnsavedChanges
        submitOnEnter={false}
        toolbar={
          <UploadToolbar
            field="owner"
            namespace={ASSET_USER_NAMESPACE}
            kind="edit"
          />
        }
      >
        <OwnerForm />
      </SimpleForm>
    </EditWithAttachmentsAndLog>
  );
};
