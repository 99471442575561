import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useAppContext } from '../../context/AppContext';
import Loading from './Loading';

const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      height: 'fit-content',
      marginTop: '1em',
      marginLeft: 'auto',
      zIndex: 100,
      position: 'relative',
      '& h1, & >div>div:last-child': {
        display: 'none',
      },
      '& svg': {
        color: 'rgb(255, 0, 0)',
      },
    },
  }),
);

export const Loader: React.FC = () => {
  const classes = useStyles();
  const appState = useAppContext();
  // console.log('appState.loading : ', appState.loading);
  return (
    <div>{!!appState.loading && <Loading className={classes.loader} />}</div>
  );
};
