import { get } from 'lodash';
import React from 'react';
import { FunctionField } from 'react-admin';

import { Schedule, SchedulePayment } from '../../../../types/schema';
import { creditToCurrency } from '../EditScheduleDialog/OccurenceField';

export interface PaymentAmountLeftProps {
  locales?: string[] | string;
  // eslint-disable-next-line react/no-unused-prop-types
  label: string;
}

export const PrincipalAmountLeft: React.FC<PaymentAmountLeftProps> = () => {
  const render = React.useCallback((record?: Schedule) => {
    const payments = get(record, ['payments'], null) as
      | SchedulePayment[]
      | null;

    const amountLeft =
      payments?.reduce(
        (totalAmount, payment) =>
          totalAmount + (payment.done ? 0 : payment.principalAmount || 0),
        0,
      ) || 0;

    return creditToCurrency(amountLeft);
  }, []) as any;

  return <FunctionField render={render} />;
};
