import { useCallback, useState } from 'react';
import {
  CreateResult,
  EditProps,
  Record as RARecord,
  UpdateResult,
  useDataProvider,
  UseDataProviderOptions,
  useEditContext,
  useGetOne,
  useNotify,
  // useRecordContext,
  useRedirect,
} from 'react-admin';
import { addDays } from 'date-fns';

import { GuarantyLoan } from '../types/schema';
import {
  actionFactory,
  executeWorkFlow,
  ValidationErrors,
  Workflow,
  WorkflowError,
  WorkflowNode,
  WorkflowState,
} from './workflow';
import { WFState, WFAction, ACTION_LABEL } from './guaranty-loans-enum';
import { useCpretHistory } from './history';
import { clearDataBeforeSubmit } from '../dataProvider/utils';

export { WFState, WFAction, ACTION_LABEL };

export const workflow: Workflow<WFAction, WFState> = {
  [WFState.GESTION]: {
    [WFAction.GARANTIE]: actionFactory(
      () => {
        return WFState.GARANTIE;
      },
      {
        logMessage: 'cpret.workflow.guarantyLoan.garantie',
        workerOnly: true,
      },
    ),
    [WFAction.CLOTURE]: actionFactory(
      () => {
        return WFState.CLOTURE;
      },
      {
        logMessage: 'cpret.workflow.guarantyLoan.cloture',
      },
    ),
    [WFAction.AVENANT]: actionFactory(
      () => {
        return WFState.GESTION; // does nothing, log only !
      },
      {
        logMessage: 'cpret.workflow.guarantyLoan.avenant',
        logArgs: g => ({
          message: (g as any).message,
          date: (g as any).actionDate,
        }),
        workerOnly: true,
      },
    ),
    [WFAction.ANTICIPE]: actionFactory(
      (n, p) => {
        const next = n as GuarantyLoan;
        const prev = p as GuarantyLoan;
        if (next.endDate === prev.endDate) {
          throw new WorkflowError('Cannot apply workflow', {
            endDate: 'La date doit être modifiée',
          });
        }
        if (next.endDate) {
          const end = new Date(next.endDate);
          const now = addDays(new Date(), 1);
          const max = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
          );
          if (end > max)
            throw new WorkflowError('Cannot apply workflow', {
              endDate: 'La date de fin ne peut pas être dans le futur',
            });
        }
        return WFState.CLOTURE;
      },
      {
        logMessage: 'cpret.workflow.guarantyLoan.anticipe',
        logArgs: (g: GuarantyLoan) => ({
          message: (g as any).message,
          date: g.endDate,
        }),
        workerOnly: true,
      },
    ),
  },
  [WFState.GARANTIE]: {
    [WFAction.PAIEMENT]: actionFactory(
      () => {
        return WFState.PAIEMENT;
      },
      {
        logMessage: 'cpret.workflow.guarantyLoan.paiement',
      },
    ),
  },
  [WFState.PAIEMENT]: {
    [WFAction.RECOUVREMENT]: actionFactory(
      () => {
        return WFState.RECOUVREMENT;
      },
      {
        logMessage: 'cpret.workflow.guarantyLoan.recouvrement',
      },
    ),
  },
  [WFState.RECOUVREMENT]: {
    [WFAction.CLOTURE]: actionFactory(
      () => {
        return WFState.CLOTURE;
      },
      {
        logMessage: 'cpret.workflow.guarantyLoan.cloture',
      },
    ),
  },
  [WFState.CLOTURE]: {
    [WFAction.GESTION]: actionFactory(
      () => {
        return WFState.GESTION;
      },
      {
        logMessage: 'cpret.workflow.guarantyLoan.gestion',
        workerOnly: true,
      },
    ),
  },
};

type SaveCallback<T extends WorkflowNode> = (
  data: T,
  redirect?: any,
) => Promise<undefined | ValidationErrors>;

export type ExecuteResult = [ValidationErrors | undefined, string | undefined];
type ExecuteCallback<T extends WorkflowNode> = (params: {
  next: Partial<T>;
  prev?: T;
  wfAction: WFAction;
  onRedirect: (data: any) => void; // ReturnType<typeof useRedirect>;
}) => Promise<ExecuteResult>;

export type WorkflowCallback<T extends WorkflowNode> = (params: {
  id: string;
  wfAction: WFAction;
  next?: Partial<T>;
}) => Promise<ExecuteResult>;

const useExecuteGuarantyLoanWorkflow = <
  T extends WorkflowNode = GuarantyLoan,
>(): [ExecuteCallback<T>, WorkflowState] => {
  const dataProvider = useDataProvider();
  const { onSuccessRef, onFailureRef, transformRef } = useEditContext();
  const notify = useNotify();
  const [state, setState] = useState<WorkflowState>({ loading: false });
  const log = useCpretHistory();
  const cb = useCallback<ExecuteCallback<T>>(
    async ({ next, prev, wfAction, onRedirect }) => {
      let [error, wfResult]: ReturnType<typeof executeWorkFlow> = [
        {},
        undefined,
      ];
      const prevStatus = prev?.wfStatus || WFState.UNDEFINED;
      try {
        [error, wfResult] = executeWorkFlow<T, WFAction, WFState>({
          next: { ...prev, ...next } as T,
          prev,
          wfAction: (wfAction as WFAction) || undefined,
          workflow,
        });
      } catch (e: any) {
        console.error(e);
        notify('cpret.workflow.error', 'error', {
          status: prevStatus,
          action: wfAction,
        });
        return [undefined, undefined] as ExecuteResult;
      }
      if (!wfResult) {
        // notify('ra.message.invalid_form', 'warning'); // ra.message.invalid_form
        return [error, undefined] as ExecuteResult;
      }

      // actual update now
      const { next: nextLoan, logMessage, logArgs } = wfResult;
      const data = await (transformRef?.current || clearDataBeforeSubmit)(
        nextLoan as any,
      );
      delete data.wfAction;

      const options: UseDataProviderOptions = {
        // inspired from ra-core/src/controller/details/useEditController (!)
        onSuccess:
          onSuccessRef?.current ||
          (({ data: newRecord }) => {
            notify(logMessage || 'ra.notification.updated', 'info', {
              ...logArgs,
              wfStatus: nextLoan.wfStatus,
              prevStatus,
              smart_count: 1,
            });
            onRedirect(newRecord);
          }),
        onFailure:
          onFailureRef?.current ||
          (err => {
            notify(
              typeof err === 'string'
                ? err
                : err.message || 'ra.notification.http_error',
              'warning',
              {
                _:
                  typeof err === 'string'
                    ? err
                    : (err && err.message) || undefined,
              },
            );
          }),
      };

      const mutate: Promise<UpdateResult<RARecord> | CreateResult<RARecord>> =
        prev
          ? dataProvider.update(
              'cpretGuarantyLoan',
              {
                id: prev.id,
                data,
                previousData: prev,
              },
              options,
            )
          : dataProvider.create(
              'cpretGuarantyLoan',
              {
                data,
              },
              options,
            );

      setState({ loading: true });
      return mutate
        .then(async ({ data: nextRecord }) => {
          // no need to call dataProvider.observeOne here

          setState({ loading: false });
          if (logMessage)
            log(
              {
                message: logMessage,
                args: {
                  ...logArgs,
                  wfStatus: nextLoan.wfStatus,
                  prevStatus,
                },
              },
              [nextRecord as Partial<WorkflowNode>],
            );
          return [undefined, logMessage] as ExecuteResult;
        })
        .catch((e: any) => {
          setState({ loading: false });
          throw e;
        });
    },
    [transformRef, onSuccessRef, onFailureRef, dataProvider, notify, log],
  );
  return [cb, state];
};

export const useGuarantyLoanWorkflowSave = <
  T extends WorkflowNode = GuarantyLoan,
>(
  props: Partial<EditProps>,
): [SaveCallback<T>, WorkflowState] => {
  const { id, resource } = props;
  const { data: prev } = useGetOne<T>(resource ?? '', id ?? '');

  const [execute, state] = useExecuteGuarantyLoanWorkflow<T>();
  const redirectTo = useRedirect();
  const cb = useCallback<SaveCallback<T>>(
    (data /* , redirect */) => {
      if (!prev || prev.__typename !== 'GuarantyLoan')
        throw new Error('Not a Guaranty Loan');
      const { wfAction } = data;
      return execute({
        next: data,
        prev,
        wfAction: (wfAction as WFAction) || undefined,
        onRedirect: newRecord => {
          // redirectTo(redirect, props.basePath, newRecord.id, newRecord);
          redirectTo(
            'edit',
            '/cpretGuarantee',
            (newRecord as GuarantyLoan).guarantee.edges[0]?.node.id,
          );
        },
      }).then(([errors]) => errors);
    },
    [prev, execute, redirectTo],
  );
  return [cb, state];
};

export const useGuarantyLoanWorkflow = (): [
  WorkflowCallback<GuarantyLoan & { message?: string; actionDate?: string }>,
  WorkflowState,
] => {
  const dataProvider = useDataProvider();
  const [execute, state] = useExecuteGuarantyLoanWorkflow();
  const cb = useCallback<WorkflowCallback<GuarantyLoan>>(
    ({ id, wfAction, next }) =>
      dataProvider
        .getOne<GuarantyLoan>('cpretGuarantyLoan', { id })
        .then(async ({ data: prev }) => {
          const [errors, result] = await execute({
            next: { ...prev, ...next } as GuarantyLoan,
            prev,
            wfAction,
            onRedirect: () => {},
          });
          // TODO - Notify here ?
          // if (errors) return 'cpret.workflow.error'; // could be { message: 'cpret.workflow.error', args: { ... } }
          return [errors, result];
        }),
    [dataProvider, execute],
  );
  return [cb, state];
};
