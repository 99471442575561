import React from 'react';
import { Pagination } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import { Loader } from './Loader';

const useStyles = makeStyles(() =>
  createStyles({
    loaderParent: {
      display: 'flex',
      justifyContent: 'space-between',
      '& >div:first-child': {
        width: '20px',
      },
      '& .MuiCircularProgress-root': {
        width: '20px !important',
        height: '20px !important',
      },
    },
  }),
);

const PaginationWithLoader: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.loaderParent}>
      <Loader />
      <Pagination />
    </div>
  );
};

export default PaginationWithLoader;
