import {
  GetListResult,
  GetListParams,
  GetOneResult,
  GetManyResult,
  CrudGetListAction,
  CrudGetOneAction,
  CrudGetManyAction,
  Identifier,
  GetOneParams,
} from 'ra-core';
import { ApolloQueryResult, Observer } from '@apollo/client';
import { eventChannel, END, EventChannel } from 'redux-saga';
// import { DocumentNode } from 'graphql';
import Observable from 'zen-observable';
// import gql from 'graphql-tag';
import _ from 'lodash';
import { CacheUpdateResult } from 'phicomas-client';

import { isGetListAction, isGetOneAction, isGetManyAction } from './saga';
import { ObservableTuple } from './observable';

import { CustomDataProvider } from './type';
import { ResourceKey } from '../project/projectInfos';

export type Observables = { [k: string]: ObservableTuple | undefined };

// const { resourcesInfos } = projectInfos;

const isApolloResult = (res: any): res is ApolloQueryResult<any> => !!res?.data;

class DebounceQueue {
  action: (...args: any[]) => void;

  argses: any[][] = [];

  dblRaf: number | undefined;

  constructor(action: (...args: any[]) => void) {
    this.action = action;
  }

  emit(...args: any[]): void {
    this.argses.push(args);
    if (this.dblRaf) return;
    this.dblRaf = requestAnimationFrame(() => {
      this.dblRaf = requestAnimationFrame(this.cb);
    });
  }

  cb = () => {
    const { argses } = this;
    if (!argses.length) return;
    // take last one only
    const args = argses.slice(-1)[0];
    this.action(...args);
    this.argses = [];
    this.dblRaf = undefined;
  };
}

const withObserver = (
  dataProvider: CustomDataProvider,
  // apolloClient: ApolloClient<NormalizedCacheObject>,
  // environmentLevel: Level,
  // UpdateFragmentsBase: (resource?: 'all') => DocumentNode,
  // updateStore: (
  //   resourcesInfos: ProjectInfos['resourcesInfos'],
  //   update: NodeUpdate,
  //   client: ApolloClient<NormalizedCacheObject>,
  // ) => Promise<CacheUpdateResult | undefined>,
): CustomDataProvider => {
  // create a set of observers who will register to obsUpdate
  // see inspiration https://github.com/apollographql/apollo-client/blob/master/src/core/ObservableQuery.ts#L515
  const subObservers = new Set<ZenObservable.Observer<any>>();

  const updateSubObservers = (update: any) => {
    subObservers.forEach(o => {
      if (!o.next) return;
      o.next(update);
    });
  };

  const obsUpdate = new Observable(observer => {
    subObservers.add(observer);
    return (): void => {
      subObservers.delete(observer);
    };
  });

  /*
  // Setup subscription
  const SUB_QUERY = gql`
    subscription {
      subscribeToNode {
        ...UpdateFragmentsBase
      }
    }
    ${UpdateFragmentsBase('all')}
  `;
  const obsSubscription = apolloClient.subscribe({
    query: SUB_QUERY,
    // fetchPolicy: 'network-only',
    // fetchPolicy: 'no-cache',
  });
  obsSubscription.subscribe({
    next: ({
      data: { subscribeToNode: nodeUpdate },
    }: {
      data: { subscribeToNode: NodeUpdate<any> };
    }) => {
      if (!nodeUpdate) return; // happens when there is no subscription link (local)
      // We should never care about History nodes
      if (nodeUpdate.node.__typename === 'History') return;
      console.warn('Cpret - Ignoring all subscriptions for now');
      return;

      updateStore(resourcesInfos, nodeUpdate, apolloClient).then(
        updateResult => {
          if (!updateResult) return; // somebody is already taking care of it
          // CHECK - should GetManys be updated first ?
          requestAnimationFrame(() => updateSubObservers(updateResult));
        },
      );
      // Note: no need to obsRequest.updateQuery(), it is done by Apollo
    },
    error: (...args: any[]) => {
      console.warn(JSON.stringify(args));
    },
  });
  */

  // GetOne subscriptions cache
  const getOneSubs = new Set<Identifier>();

  return {
    ...dataProvider,
    /** Force observer updates */
    observeOne: (resource: ResourceKey, { id }: GetOneParams) => {
      console.info('dp.observeOne', id);
      updateSubObservers({ [resource]: { [id]: true } });
    },
    getObserver: (
      resource: ResourceKey,
      params: CrudGetListAction | CrudGetOneAction | CrudGetManyAction,
    ): EventChannel<GetListResult | GetOneResult | GetManyResult> =>
      eventChannel(emit => {
        const dbQ = new DebounceQueue(emit);
        const { type } = params;
        if (isGetListAction(params)) {
          const { payload } = params;
          const obsTuple: ObservableTuple | undefined =
            dataProvider.getObservable(resource);
          if (!obsTuple) {
            dbQ.emit(END);
            return (): void => {};
          }
          const [observable, init] = obsTuple;
          let isReady = false;
          let cacheData: any; // is there a better way to detect udpates ?
          dataProvider.getList(resource, payload as GetListParams).then(d => {
            cacheData = d;
          });
          const sub = init.then(() =>
            observable.subscribe({
              next() {
                if (!isReady) {
                  // apollo passes an update directly onSubscribe - ignore it
                  isReady = true;
                  return;
                }
                // ignore data, refetch the list (or the one) and flatten from api
                dataProvider
                  .getList(resource, payload as GetListParams)
                  .then(newData => {
                    if (
                      _.isEqual(newData.data, cacheData.data) &&
                      newData.total === cacheData.total
                      // ie ignore validUntil !
                    )
                      return;
                    // this is GET_LIST emit
                    dbQ.emit(newData);
                    cacheData = newData;
                  });
              },
              error(err: any) {
                dbQ.emit(err); // new Error(err) ??
              },
              complete() {
                dbQ.emit(END);
              },
            }),
          );
          return (): void => {
            sub.then(subscription => {
              subscription.unsubscribe();
            });
          };
        }
        let sub2: Promise<any>;
        if (isGetOneAction(params)) {
          const { payload } = params;
          const { id: oneId } = payload;
          if (getOneSubs.has(oneId)) {
            // don't re-subscribe
            dbQ.emit(END);
            return (): void => {};
          }
          getOneSubs.add(oneId);
          let isReady = false;
          let cacheData: any; // is there a better way to detect udpates ?
          dataProvider.getOne(resource, payload).then(d => {
            cacheData = d;
          });
          const getOneObserver: Observer<
            CacheUpdateResult | ApolloQueryResult<any>
          > = {
            next(data) {
              if (!isReady && isApolloResult(data)) {
                // apollo passes an update directly onSubscribe
                // We can't unsubscribe because we need updates when add ledger entries
                // TODO - Find a better way to avoid double notification...
                /*
                if (data?.data?.allOwner?.pageInfo?.hasNextPage === false)
                  if (sub2)
                    // allOwner fully loaded, unsubscribe
                    sub2.then(s => s.unsubscribe());
                */
                isReady = true;
                return;
              }
              if (isApolloResult(data) || data[resource]?.[oneId]) {
                // that's me - emit get_one !
                const force = !isApolloResult(data) && data[resource]?.[oneId];
                dataProvider.getOne(resource, payload).then(newData => {
                  if (!force && _.isEqual(newData.data, cacheData.data)) return;
                  // GET_ONE emit
                  dbQ.emit({
                    data: {
                      ...newData.data,
                      $$cacheId: Date.now(), // used by some connections to refresh (attachments, ledger...)
                    },
                  });
                  cacheData = newData;
                });
              }
            },
            error(err: any) {
              dbQ.emit(err); // new Error(err) ??
            },
            complete() {
              dbQ.emit(END);
            },
          };
          const sub1 = obsUpdate.subscribe(getOneObserver);
          // we also subscribe to the owners main loop
          const obsTuple: ObservableTuple | undefined =
            dataProvider.getObservable('cpretOwner');
          const [listObservable, init] = obsTuple;
          sub2 = init.then(() => listObservable.subscribe(getOneObserver));
          return (): void => {
            sub1.unsubscribe();
            sub2.then(s => s.unsubscribe());
            getOneSubs.delete(oneId);
          };
        }
        if (isGetManyAction(params)) {
          const { payload } = params;
          const sub = obsUpdate.subscribe({
            next(data: CacheUpdateResult) {
              const ids: Identifier[] = Object.keys(data[resource] ?? {});
              if (payload.ids.some(id => ids.includes(id))) {
                // that's one of mine - emit get_many !
                dataProvider.getMany(resource, payload).then(newData => {
                  // GET_MANY emit
                  dbQ.emit(newData);
                });
              }
            },
            error(err: any) {
              dbQ.emit(err); // new Error(err) ??
            },
            complete() {
              dbQ.emit(END);
            },
          });
          return (): void => {
            sub.unsubscribe();
          };
        }
        console.warn(`${type} not supported yet`);
        dbQ.emit(END);
        return (): void => {};
      }),
  };
};

export default withObserver;
