import React from 'react';

import Toolbar, { BaseToolbarProps } from './Toolbar';

type EditToolbarProps = BaseToolbarProps;
const EditToolbar: React.FC<EditToolbarProps> = (props: EditToolbarProps) => {
  return <Toolbar {...props} kind="edit" />;
};

export default EditToolbar;
