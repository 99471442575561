import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from 'react';
import { useDataProvider } from 'ra-core';
import { AtomicBlockUtils, EditorState } from 'draft-js';
import { Asset, ID } from 'phicomas-client';
import _isEqual from 'lodash/isEqual';

import NumberFormat from 'react-number-format';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';
import { ImageOutlined as ImageIcon } from '@material-ui/icons';

import Option from '../Option/Option';
import AssetPickerDialog from '../../../../../Dialogs/AssetPickerDialog/AssetPickerDialog'; // eslint-disable-line import/no-cycle

import projectInfos from '../../../../../../project/projectInfos';
import { useEnvironmentContext } from '../../../../../../context/environmentContext';
import { isValidUrl } from '../../../../../../lib/regexValidators';
import { SelectedContext } from '../selectedContext';
import { usePrevious } from '../../../../../../hooks/use-previous';

type NumberPxInputProps = {
  inputRef?: React.Ref<any>;
  onChange?: (s: any) => any;
};

const NumberPxInput: React.FC<NumberPxInputProps> = ({
  inputRef,
  onChange,
  ...propsRest
}: NumberPxInputProps) => {
  return (
    <NumberFormat
      {...propsRest}
      getInputRef={inputRef}
      onValueChange={values => {
        if (onChange) {
          onChange({
            target: {
              value: values.floatValue ? Math.round(values.floatValue) : '',
            },
          });
        }
      }}
      suffix="px"
      allowNegative={false}
      allowLeadingZeros={false}
    />
  );
};

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    fieldWrapper: {
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',

      margin: theme.spacing(-0.5),
      marginBottom: theme.spacing(1 - 0.5),
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    field: {
      flexGrow: 1,
      '& label': {
        fontSize: '1rem',
      },
      '& input': {
        fontSize: '1rem',
      },
    },
  }),
);

type AssetImageButtonProps = {
  onChange?: (s: any) => any;
  getEditorState: () => EditorState;
};

const AssetImageButton: React.FC<AssetImageButtonProps> = ({
  onChange,
  getEditorState,
}: AssetImageButtonProps) => {
  const classes = useStyles();
  const {
    assetResource,
    awsExports: { s3: rawS3 },
  } = projectInfos;
  const [environmentLevel] = useEnvironmentContext();
  const [selectedEntity, setSelectedEntity] = useContext(SelectedContext);

  const dataProvider = useDataProvider();

  const [src, setSrc] = useState('');
  const handleSrcChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSrc(event.target.value);
    },
    [setSrc],
  );
  const [width, setWidth] = useState('');
  const handleWidthChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setWidth(value);
    },
    [setWidth],
  );
  const [height, setHeight] = useState('');
  const handleHeightChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setHeight(value);
    },
    [setHeight],
  );

  const [linkUrl, setLinkUrl] = useState('');
  const handleLinkUrlChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLinkUrl(event.target.value);
    },
    [setLinkUrl],
  );

  const resetFields = useCallback(() => {
    setSrc('');
    setWidth('');
    setHeight('');
    setLinkUrl('');
  }, []);

  const hasSelectedImage = useMemo(
    () => selectedEntity?.type === 'image',
    [selectedEntity],
  );

  const hadSelectedImage = usePrevious(hasSelectedImage);
  /** Set selected image as current (edition) */
  useEffect(() => {
    if (selectedEntity && !hadSelectedImage && hasSelectedImage) {
      const editorStatee = getEditorState();
      const contentState = editorStatee.getCurrentContent();
      const entity = contentState.getEntity(selectedEntity.entityKey);
      const data = entity.getData();

      setSrc(data.src);
      const dataLink: { [k: string]: string } = data['data-link'] ?? {};
      setLinkUrl(dataLink.href ?? '');
      const { width: cssWidth, height: cssHeight } = data.style as {
        [k: string]: string | undefined;
      };
      if (cssWidth) {
        setWidth(cssWidth.slice(0, -2));
      }
      if (cssHeight) {
        setHeight(cssHeight.slice(0, -2));
      }
    }
  }, [getEditorState, hadSelectedImage, hasSelectedImage, selectedEntity]);

  const [showDialog, setShowDialog] = useState(false);
  const handleOpenDialog = useCallback(() => {
    setShowDialog(true);
  }, [setShowDialog]);
  const handleCloseDialog = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  const handleSelect = useCallback(
    async (ids: ID[]) => {
      const s3 = rawS3?.[environmentLevel];
      if (s3 && assetResource) {
        const { data } = await dataProvider.getOne<Asset>(assetResource, {
          id: ids[0],
        });

        const imageSrc = `https://assets.content.phileog.com/image/${btoa(
          JSON.stringify({
            bucket: s3.bucket,
            key: data.key,
            // edits: {
            //   resize: {
            //     width:
            //     height:
            //     fit: 'inside',
            //   },
            // },
          }),
        )}`;
        setSrc(imageSrc);
      }
    },
    [assetResource, dataProvider, environmentLevel, rawS3],
  );

  const handleOk = useCallback(() => {
    if (onChange && src) {
      let entityData: {
        src: string;
        style: {
          maxWidth: string;
          width?: string;
          height?: string;
        };
        'data-link'?: string;
      } = {
        src,
        style: {
          maxWidth: '100%',
          width: width ? `${width}px` : undefined,
          height: height ? `${height}px` : undefined,
        },
      };

      if (linkUrl) {
        entityData['data-link'] = JSON.stringify({
          href: linkUrl,
        });
      }

      const editorState = getEditorState();

      // Do nothing if data hasn't changed
      // Retrieve old props otherwise
      if (selectedEntity && hasSelectedImage) {
        const contentState = editorState.getCurrentContent();
        const entity = contentState.getEntity(selectedEntity.entityKey);
        const data = entity.getData();

        if (_isEqual(entityData, data)) {
          return;
        }

        const {
          src: oldSrc,
          'data-link': oldDataLink,
          style,
          ...otherData
        } = data;
        entityData = {
          ...otherData,
          ...entityData,
          style: { ...style, ...entityData.style },
        };
      }

      // DO NOT merge or replace entity data as it doesnt rerender correctly, instead, trust the fact that it is selected and creating a new entity will replace the old one
      const entityKey = editorState
        .getCurrentContent()
        .createEntity('IMAGE', 'IMMUTABLE', entityData)
        .getLastCreatedEntityKey();
      const newEditorState = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        entityKey,
        ' ',
      );

      onChange(newEditorState);
    }
  }, [
    getEditorState,
    hasSelectedImage,
    height,
    linkUrl,
    onChange,
    selectedEntity,
    src,
    width,
  ]);

  const handleOnClose = useCallback(() => {
    const editorState = getEditorState();
    if (hasSelectedImage && onChange) {
      setSelectedEntity(null, editorState, onChange);
    }
  }, [getEditorState, hasSelectedImage, onChange, setSelectedEntity]);

  const isValid = useMemo(() => isValidUrl(src), [src]);

  if (!assetResource) return null;

  return (
    <Option
      className="rdw-toolbar-image"
      Icon={ImageIcon}
      resetFields={resetFields}
      isValid={isValid}
      onOk={handleOk}
      onClose={handleOnClose}
      doOpen={hasSelectedImage}
      hasOpenedDialog={showDialog}
    >
      <div className={classes.fieldWrapper}>
        <TextField
          value={src}
          onChange={handleSrcChange}
          size="small"
          label="Image url"
          variant="filled"
          className={classes.field}
          required
        />
        <Button onClick={handleOpenDialog} size="small" variant="outlined">
          Parcourir
        </Button>
      </div>
      <div className={classes.fieldWrapper}>
        <TextField
          value={width}
          InputProps={{ inputComponent: NumberPxInput }}
          placeholder="auto"
          onChange={handleWidthChange}
          size="small"
          label="Largeur"
          variant="filled"
          className={classes.field}
        />
        <TextField
          value={height}
          InputProps={{ inputComponent: NumberPxInput }}
          placeholder="auto"
          onChange={handleHeightChange}
          size="small"
          label="Hauteur"
          variant="filled"
          className={classes.field}
        />
      </div>
      <div className={classes.fieldWrapper}>
        <TextField
          value={linkUrl}
          placeholder="auto"
          onChange={handleLinkUrlChange}
          size="small"
          label="Lien (url)"
          variant="filled"
          className={classes.field}
        />
      </div>
      {showDialog && (
        <AssetPickerDialog
          resource={assetResource}
          onSelect={handleSelect}
          onClose={handleCloseDialog}
          filter={{ type: 'image/*' }}
        />
      )}
    </Option>
  );
};

export default AssetImageButton;
