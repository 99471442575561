import memoizeOne from 'memoize-one';
import {
  RefreshableCredentials,
  GoogleLogin,
  AnonymousLogin,
  SncfLogin,
} from 'phileog-login';
import { Environment } from '../types';
import {
  AwsCognitoNotAnonymous,
  AwsExports,
  isAwsCognitoSNCF,
  Provider,
} from './aws-exports';


const getRefreshableCredentials = memoizeOne(
  (awsCognito: AwsCognitoNotAnonymous, environment: Environment) => {
    if (isAwsCognitoSNCF(awsCognito)) {
      return new RefreshableCredentials(
        SncfLogin.createDefault({
          loginEnv: awsCognito.loginEnv[environment.level],
        }),
      );
    }
    return new RefreshableCredentials(GoogleLogin.createDefault());
  },
);

const getAnonymousCredentials = memoizeOne(() =>
  AnonymousLogin.awsCredentials(),
);

export function isRefreshableCredentials(
  credentials: AWS.Credentials,
): credentials is RefreshableCredentials {
  return credentials instanceof RefreshableCredentials;
}

/**
 * @todo pass AwsCognito directly, instead of referencing from env.instance
 */
export const getAwsCredentials = memoizeOne(
  (
    awsCognitoRaw: AwsExports['cognito'],
    environment: Environment,
  ): AWS.Credentials => {
    const awsCognito = awsCognitoRaw[environment.instance];

    if (!awsCognito) {
      throw new Error(
        `Cognito config missing for instance ${environment.instance}`,
      );
    }

    switch (awsCognito.provider) {
      case Provider.Anonymous:
        return getAnonymousCredentials();
      case Provider.Google:
      case Provider.SNCF:
        return getRefreshableCredentials(
          awsCognito as AwsCognitoNotAnonymous,
          environment,
        );
      default:
        throw new Error(`Provider ${(awsCognito as any).provider} not handled`);
    }
  },
);
