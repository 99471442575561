import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Backdrop,
  CircularProgress,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faFile,
  faFileImage,
  faFileAudio,
  faFileVideo,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileAlt,
  faFileCode,
  faFileArchive,
} from '@fortawesome/free-solid-svg-icons';

import { ClassName } from '../types/styles';
import projectInfos, { ResourceKey } from '../project/projectInfos';
import { getS3DownloadUrl } from '../aws/s3-utils';
import ConditionnalWrapper from './ConditionnalWrapper';
import { level as environmentLevel } from '../auth/authProvider';

function getFontAwesomeIconFromMimeType(mimeType: string): IconDefinition {
  // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
  const iconClasses: { [k in string]?: IconDefinition } = {
    unknown: faFile,
    // Media
    image: faFileImage,
    audio: faFileAudio,
    video: faFileVideo,
    // Documents
    'application/pdf': faFilePdf,
    'application/msword': faFileWord,
    'application/vnd.ms-word': faFileWord,
    'application/vnd.oasis.opendocument.text': faFileWord,
    'application/vnd.openxmlformats-officedocument.wordprocessingml':
      faFileWord,
    'application/vnd.ms-excel': faFileExcel,
    'application/vnd.openxmlformats-officedocument.spreadsheetml': faFileExcel,
    'application/vnd.oasis.opendocument.spreadsheet': faFileExcel,
    'application/vnd.ms-powerpoint': faFilePowerpoint,
    'application/vnd.openxmlformats-officedocument.presentationml':
      faFilePowerpoint,
    'application/vnd.oasis.opendocument.presentation': faFilePowerpoint,
    'text/plain': faFileAlt,
    'text/html': faFileCode,
    'application/json': faFileCode,
    // Archives
    'application/gzip': faFileArchive,
    'application/zip': faFileArchive,
  };

  const mimeTypeFirstPart = mimeType.split('/')[0];
  return iconClasses[mimeType] || iconClasses[mimeTypeFirstPart] || faFile;
}

const DEFAULT_IMAGE_WIDTH = 230;
const DEFAULT_IMAGE_RATIO = 3 / 4;

const useStyles = makeStyles<
  Theme,
  { src: string | null; width: number; height: number }
>(theme =>
  createStyles({
    wrapper: {
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.default,
    },
    wrapperFixed: {
      width: ({ width }) => width,
      height: ({ height }) => height,
    },
    wrapperFit: {
      maxHeight: ({ height }) => height,
    },
    imageBackground: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundImage: ({ src }) => `url('${src}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      transform: 'scale(1.5)',
      filter: 'blur(8px)',
      backfaceVisibility: 'hidden', // Fix the blur issue on hover a button above it in the DOM hierarchy
      '&::after': {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        content: '""',
      },
    },
    image: {
      position: 'relative',
      display: 'block',
      backgroundColor: theme.palette.background.default,
    },
    imageFixed: {
      maxWidth: ({ width }) => width,
      maxHeight: ({ height }) => height,
    },
    imageFit: {
      width: '100%',
      height: 'auto',
      maxHeight: ({ height }) => height,
    },
    icon: {
      width: '100% !important',
      height: '100%',
      maxHeight: ({ height }) => height,
      padding: ({ height }) => `${height * 0.1}px 0`,
      color: theme.palette.augmentColor({
        main: theme.palette.background.default,
      }).dark,
    },
    link: {
      cursor: 'pointer',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      display: 'flex',
      flexFlow: 'column',
    },
  }),
);

type FilePreviewerProps = {
  file?: File;
  s3FileInfos?: {
    resource?: ResourceKey;
    key?: string;
    filename?: string;
  };
  mimeType: string;
  className?: ClassName;
  width?: number;
  height?: number;
  downloadOnClick?: boolean;
  fit?: boolean;
};

const FilePreviewer: React.FC<FilePreviewerProps> = ({
  file,
  s3FileInfos = {},
  mimeType,
  className,
  width,
  height,
  downloadOnClick = false,
  fit = false,
}: FilePreviewerProps) => {
  const { resource, key, filename } = s3FileInfos;

  const calculatedWidth = Math.ceil(
    width ?? (height ? height / DEFAULT_IMAGE_RATIO : DEFAULT_IMAGE_WIDTH),
  );
  const calculatedHeight = Math.ceil(
    height ?? calculatedWidth * DEFAULT_IMAGE_RATIO,
  );

  const src = useMemo(() => {
    if (mimeType.startsWith('image/')) {
      if (!file && resource && key) {
        const {
          awsExports: { s3: rawS3 },
        } = projectInfos;
        const s3 = rawS3?.[environmentLevel];
        if (!s3) return null;
        return `https://assets.content.phileog.com/image/${btoa(
          JSON.stringify({
            bucket: s3.bucket,
            key,
            edits: {
              resize: {
                width: calculatedWidth,
                height: calculatedHeight,
                fit: 'inside',
              },
            },
          }),
        )}`;
      }
      if (file) {
        return URL.createObjectURL(file);
      }
    }
    return null;
  }, [calculatedHeight, calculatedWidth, file, key, mimeType, resource]);

  const classes = useStyles({
    src,
    width: calculatedWidth,
    height: calculatedHeight,
  });

  const [loadingLink, setLoadingLink] = useState(false);

  const handleClickDownload = useCallback(async () => {
    let link: string;
    if (file) {
      link = URL.createObjectURL(file);
    } else if (resource && key) {
      setLoadingLink(true);
      link = await getS3DownloadUrl(key, {
        asset: { filename },
      });
      setLoadingLink(false);
    } else {
      return;
    }
    window.open(link);
  }, [file, filename, key, resource]);

  return (
    <div
      className={clsx(className, classes.wrapper, {
        [classes.wrapperFixed]: !fit,
        [classes.wrapperFit]: fit,
      })}
    >
      <ConditionnalWrapper
        condition={downloadOnClick}
        wrapper={children => (
          <Tooltip title="Télécharger" arrow>
            <a
              tabIndex={-1}
              role="button"
              onClick={handleClickDownload}
              onKeyPress={handleClickDownload}
              className={classes.link}
            >
              {children}
            </a>
          </Tooltip>
        )}
      >
        {src ? (
          <>
            <div className={classes.imageBackground} />
            <img
              className={clsx(classes.image, {
                [classes.imageFixed]: !fit,
                [classes.imageFit]: fit,
              })}
              src={src}
              alt="preview"
            />
          </>
        ) : (
          <FontAwesomeIcon
            icon={getFontAwesomeIconFromMimeType(mimeType)}
            className={classes.icon}
          />
        )}
      </ConditionnalWrapper>
      <Backdrop className={classes.backdrop} open={loadingLink}>
        <CircularProgress color="inherit" />
        <Typography>Génération du lien de téléchargement...</Typography>
      </Backdrop>
    </div>
  );
};

export default FilePreviewer;
