import React, { useCallback, useEffect, useState } from 'react';
import { useGetOne } from 'react-admin';
import Color from 'color';

import { ID, SimpleCms } from 'phicomas-client';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, TextField } from '@material-ui/core';

import { Asset } from '../../../../types/schema';
import AssetPickerDialog from '../../../Dialogs/AssetPickerDialog/AssetPickerDialog'; // eslint-disable-line import/no-cycle
import FilePreviewerField from '../../../Fields/FilePreviewerField';

import projectInfos from '../../../../project/projectInfos';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
      margin: theme.spacing(-0.5),
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    rightColumn: {
      flex: 1,
    },
    rightColumnContent: {
      display: 'flex',
      flexFlow: 'column',

      margin: theme.spacing(-1),
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    imageWrapper: {
      position: 'relative',
    },
    loading: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: Color(theme.palette.background.default)
        .alpha(0.75)
        .toString(),
    },
  }),
);

type ImageComponentProps = {
  sourceValue?: SimpleCms.PageComponentImageProps;
  setSourceValue: (newValue: SimpleCms.PageComponentImageProps) => void;
};

const ImageComponent: React.FC<ImageComponentProps> = ({
  sourceValue,
  setSourceValue,
}: ImageComponentProps) => {
  const classes = useStyles();

  const { assetResource } = projectInfos;

  const [showDialog, setShowDialog] = useState(false);
  const handleOpen = useCallback(
    event => {
      event.preventDefault();
      setShowDialog(true);
    },
    [setShowDialog],
  );
  const handleClose = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  const [tempAssetId, setTempAssetId] = useState<ID>();
  const handleSelect = useCallback(
    async (assetIds: ID[]) => {
      setTempAssetId(assetIds[0]);
      setSourceValue({});
    },
    [setSourceValue],
  );
  const [legend, setLegend] = useState<string | undefined>(sourceValue?.legend);

  const connected: ID[] = sourceValue?.assetId ? [sourceValue.assetId] : [];

  const { data: selectedAssetData, loading } = useGetOne<Asset>(
    assetResource ?? '',
    tempAssetId ?? sourceValue?.assetId ?? '',
  );

  useEffect(() => {
    if (selectedAssetData && selectedAssetData.id === tempAssetId) {
      setTempAssetId(undefined);
      setSourceValue({
        ...sourceValue,
        assetId: tempAssetId,
        imageKey: selectedAssetData.key,
      });
    }
  }, [selectedAssetData, loading, tempAssetId, setSourceValue, sourceValue]);

  const handleLegendChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLegend(event.target.value);
      setSourceValue({ ...sourceValue, legend: event.target.value });
    },
    [setSourceValue, sourceValue],
  );

  if (!assetResource) return null;

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.imageWrapper}>
          {loading || !selectedAssetData ? (
            <FilePreviewerField placeholder height={200} />
          ) : (
            <FilePreviewerField
              resource={assetResource}
              record={selectedAssetData}
              height={200}
            />
          )}
          {loading && (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )}
        </div>
        <div className={classes.rightColumn}>
          <div className={classes.rightColumnContent}>
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Séléctionner une image
            </Button>
            <TextField
              value={legend}
              onChange={handleLegendChange}
              label="Légende (optionnelle)"
            />
          </div>
        </div>
      </div>
      {showDialog && (
        <AssetPickerDialog
          resource={assetResource}
          onSelect={handleSelect}
          onClose={handleClose}
          connected={connected}
          filter={{ type: 'image/*' }}
        />
      )}
    </>
  );
};

export default ImageComponent;
