import React, { useCallback, useState } from 'react';
import { DashboardMenuItem, MenuItemLink, MenuProps } from 'react-admin';
import { getResources, useTranslate, ReduxState } from 'ra-core';
import { shallowEqual, useSelector } from 'react-redux';
import clsx from 'clsx';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import DefaultResourceIcon from '@material-ui/icons/ViewList';

import { key as cguKey, Icon as CguIcon } from '../customPages/CGU';
import { key as fluxGPGKey } from '../customPages/FluxGPG';
import { key as rappelsKey } from '../customPages/Rappels';
import { key as virementsKey } from '../customPages/Virements';
import { key as exportsComptablesKey } from '../customPages/ExportsComptables';
import projectInfos from '../../project/projectInfos';
import SubMenu from './Menu/SubMenu';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    nonActive: {
      fontWeight: 'normal',
    },
  }),
);

const CustomMenu: React.FC<MenuProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  classes: classesOverride = {},
  className = '',
  dense = false,
  hasDashboard,
  // onMenuClick = (): void => {},
  logout = null,
  ...rest
}: MenuProps) => {
  const translate = useTranslate();
  const classes = useStyles();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs'),
  );
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);

  type RaResource = {
    name: string;
    icon?: React.FC;
    hasList: boolean;
  };
  const raResources: RaResource[] = useSelector(getResources, shallowEqual);

  const raProjectListResources = raResources.filter(
    r => r.hasList && Object.keys(projectInfos.resourcesInfos).includes(r.name),
  );

  const [subMenuOpen, toggleSubMenu] = useState({ gestion: false });
  const handleToggleSubMenu = useCallback(
    (menuName: keyof typeof subMenuOpen) => (): void => {
      toggleSubMenu({ ...subMenuOpen, [menuName]: !subMenuOpen[menuName] });
    },
    [subMenuOpen],
  );

  // Used to force redraw on navigation
  useSelector((state: ReduxState) => state.router.location.pathname);

  const ownerResource = raProjectListResources.find(
    r => r.name === 'cpretOwner',
  );

  return (
    <div className={clsx(classes.main, className)} {...rest}>
      {hasDashboard && <DashboardMenuItem dense={dense} />}
      {raProjectListResources.map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={translate(`resources.${resource.name}.name`, 2)}
          leftIcon={resource.icon ? <resource.icon /> : <DefaultResourceIcon />}
          dense={dense}
        />
      ))}

      <MenuItemLink
        to={{
          pathname: '/cpretOwner',
          search: `filter=${JSON.stringify({ $$cpretType: 'CAUTION' })}`,
        }}
        primaryText="Liste cautions"
        leftIcon={
          ownerResource?.icon ? <ownerResource.icon /> : <DefaultResourceIcon />
        }
        activeClassName={classes.nonActive}
      />
      <MenuItemLink
        to={{
          pathname: '/cpretOwner',
          search: `filter=${JSON.stringify({ $$cpretType: 'PRET' })}`,
        }}
        primaryText="Liste prêts"
        leftIcon={
          ownerResource?.icon ? <ownerResource.icon /> : <DefaultResourceIcon />
        }
        activeClassName={classes.nonActive}
      />

      <SubMenu
        handleToggle={handleToggleSubMenu('gestion')}
        isOpen={subMenuOpen.gestion}
        sidebarIsOpen={open}
        name="menu.gestion"
        // icon={<orders.icon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/${fluxGPGKey}`}
          primaryText={translate(`${fluxGPGKey}.name`, 2)}
          leftIcon={<DefaultResourceIcon />}
          dense={dense}
        />
        <MenuItemLink
          to={`/${rappelsKey}`}
          primaryText={translate(`${rappelsKey}.name`, 2)}
          leftIcon={<DefaultResourceIcon />}
          dense={dense}
        />
        <MenuItemLink
          to={`/${virementsKey}`}
          primaryText={translate(`${virementsKey}.name`, 2)}
          leftIcon={<DefaultResourceIcon />}
          dense={dense}
        />
        <MenuItemLink
          to={`/${exportsComptablesKey}`}
          primaryText={translate(`${exportsComptablesKey}.name`, 2)}
          leftIcon={<DefaultResourceIcon />}
          dense={dense}
        />
      </SubMenu>

      <MenuItemLink
        key={`/${cguKey}`}
        to={`/${cguKey}`}
        primaryText={translate(`${cguKey}.name`)}
        leftIcon={<CguIcon />}
        dense={dense}
      />
      {isXSmall && logout}
    </div>
  );
};

export default CustomMenu;
