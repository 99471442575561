import { TranslationMessages } from 'ra-core';
import frenchMessages from 'ra-language-french';

import { key as cguKey } from '../components/customPages/CGU';
import { key as fluxGPGKey } from '../components/customPages/FluxGPG';
import { key as rappelsKey } from '../components/customPages/Rappels';
import { key as virementsKey } from '../components/customPages/Virements';
import { key as exportsComptablesKey } from '../components/customPages/ExportsComptables';

import projectInfos, {
  ProjectInfos,
  ResourceKey,
} from '../project/projectInfos';
import { EnhancedResourceCustomization } from '../types/customization';

const commonFields = {
  id: 'Id',
  createdAt: 'Création',
  updatedAt: 'Dernière mise à jour',
  version: 'Version',
  status: 'Statut',
};

type Messages = {
  menu: {
    [k in ProjectInfos['name']]: string;
  };
  resources: {
    [k in ResourceKey]?: {
      name: string;
      create?: string;
      delete?: string;
      list?: string;
      fields?: {
        [k: string]: string;
      };
    };
  };
};

const translationMessages: TranslationMessages & Messages = {
  ...frenchMessages,
  menu: {
    gestion: 'Gestion prêts',
  },
  resources: {},
  action: {
    selectOne: 'Séléctionner un %{name}',
    selectMany: 'Séléctionner des %{name}',
    connect: 'Lier %{name}',
    disconnect: 'Retirer',
    upload_and_save: 'Uploader et enregistrer',
    upload: 'Uploader',
  },
  error: {
    required_file: "Aucun fichier n'a été séléctionné",
    one_file_only: "Merci de ne sélectionner qu'un seul fichier",
    bad_format: 'Le fichier proposé ne correspond pas au format imposé',
  },
  warning: {
    changed_format:
      'Attention : vous avez changé le type de fichier de cet asset',
  },
  cpret: {
    /** Modal Actions */
    action: {
      success: `L'action %{action} a bien été exécutée'`,
      error: `Une erreur est survenue, l'action %{action} n'a pas été exécutée : %{error}`,
    },
    /** Workflow actions */
    workflow: {
      error: `Action impossible: %{status} -> %{action}`,
      loan: {
        creation: `Workflow : %{prevStatus} -> CREATION`,
        instruction: `Workflow : %{prevStatus} -> INSTRUCTION`,
        refus: `Workflow : %{prevStatus} -> REFUS`,
        accord: `Workflow : %{prevStatus} -> ACCORD`,
        emission: `Workflow : %{prevStatus} -> EMISSION`,
        annulation: `Workflow : %{prevStatus} -> ANNULATION`,
        signature: `Workflow : %{prevStatus} -> SIGNATURE`,
        gestion: `Workflow : %{prevStatus} -> GESTION`,
        contentieux: `Workflow : %{prevStatus} -> CONTENTIEUX`,
        surendettement: `Workflow : %{prevStatus} -> SURENDETTEMENT`,
        cloture: `Workflow : %{prevStatus} -> CLOTURE`,
      },
      guarantee: {
        dissociation: `Action: Désolidarisation/Motif: %{message}/Date: %{date}`,
        transfer: 'Action: Transfert/Motif: %{message}',
        call: `Action: Appel en garantie/Motif: %{message}/Indication: %{incident}/Montant: %{amount}`,
        denunciation: `Action: Dénonciation [%{confirm}]/Motif: %{message}/Date: %{date}`,
      },
      guarantyLoan: {
        anticipe: `Workflow: ANTICIPE, %{prevStatus} -> CLOTURE/Motif: %{message}/Date: %{date}`,
        avenant: `Workflow: AVENANT/Motif: %{message}/Date: %{date}`,
        cloture: `Workflow : %{prevStatus} -> CLOTURE`,
        garantie: `Workflow : %{prevStatus} -> GARANTIE`,
        paiement: `Workflow : %{prevStatus} -> PAIEMENT`,
        recouvrement: `Workflow : %{prevStatus} -> RECOUVREMENT`,
        gestion: `Workflow : %{prevStatus} -> GESTION`,
      },
    },
    /** Ledger actions */
    ledger: {
      disbursement: '%{payee} [#%{loanId}]/%{amount}/(versement)',
      repayment: '%{payee}/cap: %{pPmt}/int: %{iPmt}/(remboursement)',
      userdebit: '%{payee}/%{amount}/(debit)',
      usercredit: '%{payee}/%{amount}/(credit)',
      rounding: '%{payee}/%{amount}/(arrondis)',
      generic:
        '%{payee}/%{amount}/Du compte: %{sourceAccount}/Au compte: %{targetAccount}/(mouvement)',
    },
    schedule: {
      creation: `Création de l'échéancier %{label}`,
      deletion: `Suppression d'un échéancier %{label}`,
      repayment: '%{payee}/cap: %{pPmt}/int: %{iPmt}/(remboursement)',
      userdebit: '%{payee}/%{amount}/(debit)',
      usercredit: '%{payee}/%{amount}/(credit)',
      rounding: '%{payee}/%{amount}/(arrondis)',
      generic:
        '%{payee}/%{amount}/src: %{sourceAccount}/dst: %{targetAccount}/(mouvement)',
    },
    worker: {
      gpg: 'Consommation échéancier TBC',
      npf: {
        import: 'Mise à jour NPF',
        // export: 'Un demandeur exporté |||| %{smart_count} demandeurs exportés',
      },
    },
    template: {
      error: `Erreur de génération: %{message}`,
      started:
        'Génération du document en cours, merci de patienter |||| %{smart_count} documents en cours de génération, merci de patienter.',
      complete: 'Génération terminée |||| %{smart_count} documents générés.',
    },
    mail: {
      error: `Une erreur est survenue, le courrier n'a pas été créé : %{error}`,
      success: 'Le courrier a bien été créé',
    },
    export: {
      gpg: {
        error: 'Erreur GPG %{message}',
        success: 'Export GPG terminé',
      },
    },
    mutation: {
      // created: 'TBC',
      updated: 'Modifications enregistrées',
      // deleted: 'TBC',
      asset: {
        created: 'Pièce jointe ajoutée: %{filename}',
      },
    },
  },
  // Custom pages
  [cguKey]: {
    name: 'CGU',
    title: "Conditions Générales d'Utilisation de l'application C'PRET",
  },
  [fluxGPGKey]: {
    name: 'Flux GPG',
    title: 'Flux GPG',
  },
  [rappelsKey]: {
    name: 'Rappels',
    title: 'Rappels',
  },
  [virementsKey]: {
    name: 'Virements',
    title: 'Virements',
  },
  [exportsComptablesKey]: {
    name: 'Exports comptables',
    title: 'Exports comptables',
  },
};

// translationMessages.resources
(
  Object.entries(projectInfos.customization.resources) as Array<
    [ResourceKey, EnhancedResourceCustomization]
  >
).forEach(([resourceKey, resourceCusto]) => {
  translationMessages.resources[resourceKey] = {
    name: resourceCusto.i18nName,
    list: resourceCusto.list?.i18nName,
    fields: {
      ...commonFields,
      ...resourceCusto.i18nFields,
    },
  };
});

export default translationMessages;
