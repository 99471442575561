import gql from 'graphql-tag';
import { print } from 'graphql';
import projectInfosBase from 'phicomas-client/src/projects/cpret/projectInfos';

// CpretBorrowerFieldsBase
const fragCpretBorrowerFieldsBase = gql`
  ${projectInfosBase.resourcesInfos.cpretBorrower.fragments.base}
`;

// CpretLoanFieldsBase
const fragCpretLoanFieldsBase = gql`
  ${projectInfosBase.resourcesInfos.cpretLoan.fragments.base}
`;

// CpretOwnerFieldsBase
const fragCpretOwnerFieldsBase = gql`
  ${projectInfosBase.resourcesInfos.cpretOwner.fragments.base}
`;
// CpretLedgerFieldsBase
const fragCpretLedgerFieldsBase = gql`
  ${projectInfosBase.resourcesInfos.cpretLedger.fragments.base}
`;

// CpretAssetFieldsBase
const fragCpretAssetFieldsBase = gql`
  ${projectInfosBase.resourcesInfos.cpretAsset.fragments.base}
`;

// CpretGuaranteeFieldsBase
const fragCpretGuaranteeFieldsBase = gql`
  ${projectInfosBase.resourcesInfos.cpretGuarantee.fragments.base}
`;

// CpretGuarantyLoanFieldsBase
const fragCpretGuarantyLoanFieldsBase = gql`
  ${projectInfosBase.resourcesInfos.cpretGuarantyLoan.fragments.base}
`;

// CpretHistoryFieldsBase
// const fragCpretHistoryFieldsBase = gql`
//   ${projectInfosBase.resourcesInfos.cpretHistory.fragments.base}
// `;

const pageInfoFields = gql`
  fragment PageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;

const fragCpretOwnerFieldsFull = gql`
  fragment CpretOwnerFieldsFull on Owner {
    ...CpretOwnerFieldsBase
    # attachments @connection(key: "attachments") {
    #   edges {
    #     node {
    #       ...CpretAssetFieldsBase
    #     }
    #   }
    #   pageInfo {
    #     ...PageInfo
    #   }
    # }
    borrowers @connection(key: "borrowers") {
      edges {
        node {
          ...CpretBorrowerFieldsBase
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
    guarantees @connection(key: "guarantees") {
      edges {
        node {
          ...CpretGuaranteeFieldsBase
          loans @connection(key: "loans") {
            edges {
              node {
                ...CpretGuarantyLoanFieldsBase
              }
            }
            pageInfo {
              ...PageInfo
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFields}
  ${fragCpretOwnerFieldsBase}
  ${fragCpretBorrowerFieldsBase}
  ${fragCpretGuaranteeFieldsBase}
  ${fragCpretGuarantyLoanFieldsBase}
`;

// CpretBorrowerFieldsFull
const fragCpretBorrowerFieldsFull = gql`
  fragment CpretBorrowerFieldsFull on Borrower {
    ...CpretBorrowerFieldsBase
    # attachments @connection(key: "attachments") {
    #   edges {
    #     node {
    #       ...CpretAssetFieldsBase
    #     }
    #   }
    #   pageInfo {
    #     ...PageInfo
    #   }
    # }
    # @client directive could prevent from being sent to server
    # ledgers were already pre-loaded by the owner list, but deprecated for now
    loans @connection(key: "loans") {
      edges {
        node {
          ...CpretLoanFieldsBase
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
    # history and ledger are loaded in edit form on demand
    # history @connection(key: "history") {
    #   edges {
    #     node {
    #       ...CpretHistoryFieldsBase
    #     }
    #   }
    #   pageInfo {
    #     ...PageInfo
    #   }
    # }
    owner @connection(key: "owner") {
      edges {
        node {
          ...CpretOwnerFieldsBase
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFields}
  ${fragCpretBorrowerFieldsBase}
  ${fragCpretLoanFieldsBase}
  ${fragCpretOwnerFieldsBase}
`;

const fragCpretLoanFieldsFull = gql`
  fragment CpretLoanFieldsFull on Loan {
    ...CpretLoanFieldsBase
    # attachments @connection(key: "attachments") {
    #   edges {
    #     node {
    #       ...CpretAssetFieldsBase
    #     }
    #   }
    #   pageInfo {
    #     ...PageInfo
    #   }
    # }
    borrower @connection(key: "borrower") {
      edges {
        node {
          bid: id # rename to bid to prevents cache reconciliation (but will still be renamed to id by apollo) - see #187, fixed in apolloClient
          owner @connection(key: "owner") {
            edges {
              node {
                id
                sncfCP
              }
            }
            pageInfo {
              ...PageInfo
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFields}
  ${fragCpretLoanFieldsBase}
`;

const fragCpretLedgerFieldsFull = gql`
  fragment CpretLedgerFieldsFull on Ledger {
    ...CpretLedgerFieldsBase
    borrower @connection(key: "borrower") {
      edges {
        node {
          bid: id # rename to bid to prevents cache reconciliation (but will still be renamed to id by apollo) - see #187, fixed in apolloClient
          owner @connection(key: "owner") {
            edges {
              node {
                id
                sncfCP
              }
            }
            pageInfo {
              ...PageInfo
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFields}
  ${fragCpretLedgerFieldsBase}
`;

// CpretGuaranteeFieldsFull
export const fragCpretGuaranteeFieldsFull = gql`
  fragment CpretGuaranteeFieldsFull on Guarantee {
    ...CpretGuaranteeFieldsBase
    # attachments @connection(key: "attachments") {
    #   edges {
    #     node {
    #       ...CpretAssetFieldsBase
    #     }
    #   }
    #   pageInfo {
    #     ...PageInfo
    #   }
    # }
    loans @connection(key: "loans") {
      edges {
        node {
          ...CpretGuarantyLoanFieldsBase
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
    owner @connection(key: "owner") {
      edges {
        node {
          ...CpretOwnerFieldsBase
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFields}
  ${fragCpretGuaranteeFieldsBase}
  ${fragCpretGuarantyLoanFieldsBase}
  ${fragCpretOwnerFieldsBase}
`;

export const fragCpretGuarantyLoanFieldsFull = gql`
  fragment CpretGuarantyLoanFieldsFull on GuarantyLoan {
    ...CpretGuarantyLoanFieldsBase
    guarantee @connection(key: "guarantee") {
      edges {
        node {
          ...CpretGuaranteeFieldsBase
          # owner is used for breadcrumbs and owner details
          owner @connection(key: "owner") {
            edges {
              node {
                id
                sncfCP
                contact {
                  honorificPrefix
                  givenName
                  familyName
                }
              }
            }
            pageInfo {
              ...PageInfo
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFields}
  ${fragCpretGuarantyLoanFieldsBase}
  ${fragCpretGuaranteeFieldsBase}
`;

const fragCpretAssetFieldsFull = gql`
  fragment CpretAssetFieldsFull on Asset {
    ...CpretAssetFieldsBase
  }
  ${fragCpretAssetFieldsBase}
`;

export const cpretOverrides = (
  base: typeof projectInfosBase,
): typeof projectInfosBase => ({
  ...base,
  resourcesInfos: {
    ...base.resourcesInfos,
    cpretOwner: {
      ...base.resourcesInfos.cpretOwner,
      fragments: {
        ...base.resourcesInfos.cpretOwner.fragments,
        full: print(fragCpretOwnerFieldsFull),
      },
    },
    cpretBorrower: {
      ...base.resourcesInfos.cpretBorrower,
      fragments: {
        ...base.resourcesInfos.cpretBorrower.fragments,
        full: print(fragCpretBorrowerFieldsFull),
      },
    },
    cpretLoan: {
      ...base.resourcesInfos.cpretLoan,
      fragments: {
        ...base.resourcesInfos.cpretLoan.fragments,
        full: print(fragCpretLoanFieldsFull),
      },
    },
    cpretGuarantee: {
      ...base.resourcesInfos.cpretGuarantee,
      fragments: {
        ...base.resourcesInfos.cpretGuarantee.fragments,
        full: print(fragCpretGuaranteeFieldsFull),
      },
    },
    cpretGuarantyLoan: {
      ...base.resourcesInfos.cpretGuarantyLoan,
      fragments: {
        ...base.resourcesInfos.cpretGuarantyLoan.fragments,
        full: print(fragCpretGuarantyLoanFieldsFull),
      },
    },
    cpretLedger: {
      ...base.resourcesInfos.cpretLedger,
      fragments: {
        ...base.resourcesInfos.cpretLedger.fragments,
        full: print(fragCpretLedgerFieldsFull),
      },
    },
    cpretAsset: {
      ...base.resourcesInfos.cpretAsset,
      fragments: {
        ...base.resourcesInfos.cpretAsset.fragments,
        full: print(fragCpretAssetFieldsFull),
      },
    },
  },
});
