import React from 'react';
import { FilterPayload, InputProps as RaInputProps } from 'ra-core';

import projectInfos, {
  ResourceKey,
  getResourceCustomization,
  getResourceInfos,
} from '../../project/projectInfos';

// Cycle needed because a connection edition lives within a record edition
import generateInput from './generateInput'; // eslint-disable-line import/no-cycle
import AssetUploadInput from './AssetUploadInput';
import {
  customizationFormFieldIsComponent,
  customizationFormFieldIsInputComponent,
} from '../../types/customization';

export type GenerateInputsOptions = {
  asset?: {
    filter?: FilterPayload;
  };
};

export default function generateAllResourceInputs(
  resource: ResourceKey,
  isCreate = false,
  options?: GenerateInputsOptions,
): React.ReactNode {
  const { type } = getResourceInfos(resource);
  const { fields } = type;

  if (!fields) return null;

  const resourceCusto = getResourceCustomization(resource);
  const { assetResource } = projectInfos;

  let inputs: React.ReactNode[] = [];

  const isAssetUpload = assetResource === resource;
  if (isAssetUpload) {
    inputs.push(
      <AssetUploadInput key="AssetUpload" filter={options?.asset?.filter} />,
    );
  }

  if (resourceCusto.form?.fields) {
    inputs = [
      ...inputs,
      resourceCusto.form.fields?.map(formField => {
        if (
          !customizationFormFieldIsInputComponent(formField) &&
          customizationFormFieldIsComponent(formField)
        ) {
          const { Component } = formField;
          return <Component />;
        }
        const { name } = formField;
        const field = fields?.find(({ name: n }) => n && n === name);
        if (!field) {
          // Field described in custo not found in resourceType
          // => Error in project's custo
          throw Error(
            `field [${name}] defined in resource [${resource}] customization have not been found`,
          );
        }
        let CustomComponent: React.FC<RaInputProps> | undefined;
        if (customizationFormFieldIsInputComponent(formField)) {
          CustomComponent = formField.Component;
        }
        return generateInput(resource, field, { isCreate }, CustomComponent);
      }),
    ];
  } else {
    inputs = [
      ...inputs,
      fields.map(field => generateInput(resource, field, { isCreate })),
    ];
  }

  return inputs;
}
