import React, { useCallback } from 'react';
import { InputProps, useTranslate } from 'ra-core';

import { Node } from 'phicomas-client';

import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

// Cycle needed because a connection edition lives within a record edition
import ConnectionEditForm from './ConnectionEditForm'; // eslint-disable-line import/no-cycle
import { ResourceKey } from '../../../project/projectInfos';

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      padding: 0,
    },
  }),
);

type ConnectionEditDialogProps = InputProps & {
  resource: ResourceKey;
  record: Node | null;
  onClose: () => void;
};

const ConnectionEditDialog: React.FC<ConnectionEditDialogProps> = ({
  resource,
  record,
  onClose,
}: ConnectionEditDialogProps) => {
  const translate = useTranslate();
  const classes = useStyles();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <Dialog
        open={record !== null}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>{`${translate(
          `resources.${resource}.name`,
          1,
        )}`}</DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          {record !== null && (
            <ConnectionEditForm
              id={record.id}
              resource={resource}
              onClose={handleClose}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConnectionEditDialog;
