import { TextField, TextFieldProps, MenuItem } from '@material-ui/core';
import React from 'react';
import { useField } from 'react-final-form';
import { FieldValidator } from 'final-form';

export type AgnosticSelectFieldProps = TextFieldProps & {
  name: string;
  defaultValue?: any;
  options: Array<{ label: string; value: any }>;
  validate?: FieldValidator<any>;
};

export const AgnosticSelectField: React.FC<AgnosticSelectFieldProps> = ({
  name,
  defaultValue,
  options,
  validate,
  ...otherProps
}) => {
  const { input, meta } = useField(name, { defaultValue, validate });

  return (
    <TextField
      {...otherProps}
      {...input}
      select
      variant="filled"
      {...(meta.touched &&
        meta.invalid && {
          error: true,
          helperText: meta.error || meta.submitError,
        })}
    >
      {options.map(option => (
        <MenuItem value={option.value} key={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
