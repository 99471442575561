import React from 'react';
import { PublicFieldProps, InjectedFieldProps } from 'react-admin';
import { get } from 'lodash';
import { useFieldArray } from 'react-final-form-arrays';

import FileField from '../FileField';
import { Asset } from '../../../types/schema';

export interface AssetsFieldProps extends PublicFieldProps, InjectedFieldProps {
  namespace?: string;
  readonly?: boolean;
  showCreatedAt?: boolean;
}

export const AssetsField: React.FC<AssetsFieldProps> = props => {
  const {
    namespace = '',
    readonly = false,
    source,
    showCreatedAt,
    record,
  } = props;

  const { fields } = useFieldArray(`${source}.edges`);
  const isInNamespace = React.useCallback(
    name => {
      const asset = get(record, `${name}.node`) as Asset;
      return asset?.key?.startsWith(namespace);
    },
    [namespace, record],
  );

  if (!record || !source) return null;

  return (
    <>
      {fields.map((name, index) =>
        isInNamespace(name) ? (
          <FileField
            key={name}
            source={`${name}.node`}
            {...(!readonly && {
              onRemove: () => {
                fields.remove(index);
              },
            })}
            showCreatedAt={showCreatedAt}
          />
        ) : null,
      )}
    </>
  );
};
