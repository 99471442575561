import React from 'react';

import { AgnosticTextField } from '../../../../components/Fields/agnostic/AgnosticTextField';
import { AgnosticFormControl } from '../../../../components/Fields/agnostic/AgnosticFormControl';

export interface TransferValues {
  reason: string;
}

export const TransferGuaranteeActionFields: React.FC = () => {
  const validateReason = React.useCallback((value: string) => {
    if (!value) {
      return 'Vous devez saisir un motif';
    }

    return null;
  }, []);

  return (
    <>
      <AgnosticFormControl fullWidth>
        <AgnosticTextField
          name="reason"
          label="Motif"
          required
          validate={validateReason}
        />
      </AgnosticFormControl>
    </>
  );
};
