import { get } from 'lodash';
import React from 'react';
import { FunctionField } from 'react-admin';

import { Schedule, SchedulePayment } from '../../../../types/schema';
import { creditToCurrency } from '../EditScheduleDialog/OccurenceField';

export interface PaymentAmountProps {
  locales?: string[] | string;
  // eslint-disable-next-line react/no-unused-prop-types
  label: string;
}

export const PrincipalAmount: React.FC<PaymentAmountProps> = () => {
  const render = React.useCallback((record?: Schedule) => {
    const payments = get(record, ['payments'], null) as
      | SchedulePayment[]
      | null;

    const amount =
      payments?.reduce(
        (totalAmount, payment) => totalAmount + (payment.principalAmount || 0),
        0,
      ) || 0;

    return creditToCurrency(amount);
  }, []) as any;

  return <FunctionField render={render} />;
};
