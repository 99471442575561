import React from 'react';
import { useFormState, useForm } from 'react-final-form';
import { getAccountBalance } from '../../../../lib/ledger';
import { Ledger, ScheduleType } from '../../../../types/schema';
import { FormValues } from './types';
import { LedgerAccount } from '../../../../dataProvider/type';

export interface ScheduleTypeSpyProps {
  ledgers: Ledger[];
}

export const ScheduleTypeSpy: React.FC<ScheduleTypeSpyProps> = ({
  ledgers,
}) => {
  const form = useForm();
  const { values } = useFormState<FormValues>();

  const [, , principalAccountBalance] = React.useMemo(
    () => getAccountBalance(ledgers, LedgerAccount.PRINCIPAL_ACCOUNT),
    [ledgers],
  );
  const [, , userAccountBalance] = React.useMemo(
    () => getAccountBalance(ledgers, LedgerAccount.USER_ACCOUNT),
    [ledgers],
  );

  React.useEffect(() => {
    if (values.type === ScheduleType.CAPITAL && principalAccountBalance) {
      requestAnimationFrame(() => {
        form.change('amount', -principalAccountBalance);
        form.change('monthlyAmount', '');
        form.change('size', '');
      });
    } else if (values.type === ScheduleType.DEBT && userAccountBalance) {
      requestAnimationFrame(() => {
        form.change('amount', -userAccountBalance);
        form.change('monthlyAmount', '');
        form.change('size', '');
      });
    }
  }, [form, values.type, principalAccountBalance, userAccountBalance]);

  return null;
};
