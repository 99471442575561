export enum WFAction {
  /** Désolidarisation */
  DISSOCIATION = 'DISSOCIATION',
  /** Transfert */
  TRANSFER = 'TRANSFER',
  /** Appel en garantie */
  CALL = 'CALL',
  /** Dénonciation */
  DENUNCIATION = 'DENUNCIATION',
}

export const ACTION_LABEL: { [k in WFAction]: string } = {
  [WFAction.CALL]: 'APPEL EN GARANTIE',
  [WFAction.DENUNCIATION]: 'DÉNONCIATION',
  [WFAction.DISSOCIATION]: 'DÉSOLIDARISATION',
  [WFAction.TRANSFER]: 'TRANSFERT',
};

/** No state for Guarantees ! */
export enum WFState {}
