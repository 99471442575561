import AWS from 'aws-sdk';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client';
// import { maybe } from '@apollo/client/utilities'; // trying to detect fetch but not working

import {
  InMemoryCache,
  // InMemoryCacheConfig,
  // defaultDataIdFromObject,
  PossibleTypesMap,
  TypePolicies,
  NormalizedCacheObject,
} from '@apollo/client/cache';
import { RetryLink } from '@apollo/client/link/retry';

import fetch from 'node-fetch';

import { RefreshableCredentials } from 'phileog-login';

import { AppSyncConfig, getAwsAppSyncProjectConfig } from '../aws/config';
import { AwsExports } from '../aws/aws-exports';
import { Environment } from '../types';
import { NodesBasicInfos } from '../types/json';
import {
  relayStylePagination,
  relayLedgerPagination,
  makeEmptyConnection,
} from './pagination';
import { OwnerConnection, LoanConnection } from '../projects/cpret/schema';

export function getLocalApolloLink(
  {
    awsExports,
    awsCredentials,
    awsAppSyncCustomConfig,
  }: {
    awsExports: AwsExports;
    awsCredentials: AWS.Credentials;
    awsAppSyncCustomConfig?: Partial<AppSyncConfig>;
  },
  environment: Environment,
): ApolloLink {
  const awsAppSyncConfig = {
    ...getAwsAppSyncProjectConfig(awsExports, awsCredentials, environment),
    ...awsAppSyncCustomConfig,
  };

  const link = ApolloLink.from([
    createAuthLink(awsAppSyncConfig),
    createHttpLink({
      uri: awsAppSyncConfig.url,
      fetch: fetch as any,
    }),
  ]);
  return link;
}

export function getApolloLink(
  {
    awsExports,
    awsCredentials,
    awsAppSyncCustomConfig,
    retry,
  }: {
    awsExports: AwsExports;
    awsCredentials: AWS.Credentials;
    awsAppSyncCustomConfig?: Partial<AppSyncConfig>;
    retry?: RetryLink.Options;
  },
  environment: Environment,
): ApolloLink {
  const awsAppSyncConfig = {
    ...getAwsAppSyncProjectConfig(awsExports, awsCredentials, environment),
    ...awsAppSyncCustomConfig,
  };

  let link = ApolloLink.from([
    createAuthLink(awsAppSyncConfig),
    createSubscriptionHandshakeLink(awsAppSyncConfig),
  ]);

  if (retry) {
    const retryLink = new RetryLink(retry);
    link = retryLink.concat(link);
  }

  return link;
}

export function getApolloCache({
  nodesBasicInfos,
  possibleTypes,
}: {
  nodesBasicInfos?: NodesBasicInfos;
  possibleTypes?: PossibleTypesMap;
}): InMemoryCache {
  // const dataIdFromObject: InMemoryCacheConfig['dataIdFromObject'] = object => {
  //   if (
  //     object.__typename &&
  //     nodesBasicInfos?.nodeTypenames.includes(object.__typename)
  //   ) {
  //     return `${object.__typename}:${object.id}`;
  //   }
  //   return defaultDataIdFromObject(object);
  // };

  const typePolicies = (function cacheRedirectsGenerator():
    | TypePolicies
    | undefined {
    if (!nodesBasicInfos) {
      return undefined;
    }

    const { nodeQueries } = nodesBasicInfos;

    const Query: TypePolicies[string] = { fields: {} };
    const { fields } = Query;
    if (!fields) throw new Error('bad');

    // Object.keys(nodeQueries)
    //   .forEach(nodeName => {
    //     const queryName = nodeQueries[nodeName];
    //     fields[queryName] = {
    //       keyArgs: false,
    //     };
    //   });

    ['Borrower'].forEach(nodeName => {
      const queryName = nodeQueries[nodeName];
      fields[queryName] = {
        // keyArgs: false,
        read(_, { args, toReference }) {
          if (!args) return undefined;
          return toReference({
            __typename: nodeName,
            id: args.id,
          });
        },
      };
    });

    // all queries - TODO automate !
    fields.allOwner = relayStylePagination();
    fields.allBorrower = relayStylePagination();
    fields.allLoan = relayStylePagination();
    fields.allGuarantee = relayStylePagination();
    fields.allGuarantyLoan = relayStylePagination();
    fields.allLedger = relayLedgerPagination();

    // TODO - automate ?

    const Owner: TypePolicies[string] = {
      keyFields: false,
      fields: {},
    };

    const Borrower: TypePolicies[string] = {
      // keyFields: false,
      fields: {
        // we don't actually need @connection but why not
        // ledger: ledgerDigestPagination,
        loans: {
          read(existing: LoanConnection, { variables }) {
            if (!variables?.id && !existing) {
              // if variables.id is not set, we assume we are in allOwner or allBorrower
              // make sure we don't break the cached query by returning an empty connection
              return makeEmptyConnection();
            }
            return existing;
          },
        },
        // TBC: cache-only: read not merge, network-only: merge then read, no-cache: no read no merge, cache-first read then merge but returns incoming !
        attachments: {
          // nice way to not cache full node, but we're not using it anymore
          read(_, { storage }) {
            return storage.incoming;
          },
          merge(_, incoming, { storage }) {
            storage.incoming = incoming;
            return incoming?.edges?.length; // apollo seems to have a second layer of cache, try to invalidate when count changes
          },
        },
        // history and ledger are handled in their own queries.
        // owner merge, see issue #187 - see also override comment about query
        owner: {
          merge(
            existing: OwnerConnection | undefined,
            incoming: OwnerConnection | undefined,
            { mergeObjects },
          ) {
            if (!existing || !incoming) return incoming;
            if (existing.edges.length === 1 && incoming.edges.length === 1) {
              return {
                ...incoming,
                edges: [
                  {
                    ...existing.edges[0],
                    node: mergeObjects(
                      existing.edges[0].node,
                      incoming.edges[0].node,
                    ),
                  },
                ],
              };
            }
            // or fallback
            return mergeObjects(existing, incoming);
          },
        },
      },
    };

    const ConnectionUpdate: TypePolicies[string] = {
      keyFields: false,
    };

    const Asset: TypePolicies[string] = {
      keyFields: false,
    };

    const History: TypePolicies[string] = {
      keyFields: false,
    };

    const Loan: TypePolicies[string] = {
      keyFields: false,
      fields: {
        contacts: {
          keyArgs: false,
        },
        income: {
          keyArgs: false,
        },
      },
    };

    const Ledger: TypePolicies[string] = {
      keyFields: false,
    };

    const Guarantee: TypePolicies[string] = {
      keyFields: false,
    };

    const GuarantyLoan: TypePolicies[string] = {
      keyFields: false,
    };

    // note Contact is already a subtype...
    const Contact: TypePolicies[string] = {
      keyFields: false,
    };

    // note Schedule is already a subtype...
    const Schedule: TypePolicies[string] = {
      keyFields: false,
    };

    // note ActionHistory is already a subtype...
    const ActionHistory: TypePolicies[string] = {
      keyFields: false,
    };

    // note ActionSchedule is already a subtype...
    const ActionSchedule: TypePolicies[string] = {
      keyFields: false,
    };

    const NodeUpdate: TypePolicies[string] = {
      merge: false,
      keyFields: false,
    };

    const NodeUpdateInfo: TypePolicies[string] = {
      merge: false,
      keyFields: false,
    };

    return {
      ActionHistory,
      ActionSchedule,
      Asset,
      Borrower,
      Contact,
      ConnectionUpdate,
      Guarantee,
      GuarantyLoan,
      History,
      Ledger,
      Loan,
      NodeUpdate,
      NodeUpdateInfo,
      Owner,
      Query,
      Schedule,
    };
  })();

  const cache = new InMemoryCache({
    possibleTypes,
    // dataIdFromObject,
    typePolicies,
    // freezeResults: true, // https://github.com/apollographql/apollo-client/pull/4543
  });

  return cache;
}

export function getApolloClient(
  {
    awsCredentials,
    awsExports,
    awsAppSyncCustomConfig,
    nodesBasicInfos,
    possibleTypes,
    retry,
  }: {
    awsCredentials: AWS.Credentials;
    awsExports: AwsExports;
    awsAppSyncCustomConfig?: Partial<AppSyncConfig>;
    nodesBasicInfos?: NodesBasicInfos;
    possibleTypes?: PossibleTypesMap;
    retry?: RetryLink.Options;
  },
  environment: Environment,
): ApolloClient<NormalizedCacheObject> {
  const link =
    awsCredentials instanceof RefreshableCredentials
      ? getApolloLink(
          {
            awsCredentials,
            awsExports,
            awsAppSyncCustomConfig,
            retry,
          },
          environment,
        )
      : getLocalApolloLink(
          {
            awsCredentials,
            awsExports,
            awsAppSyncCustomConfig,
          },
          environment,
        );

  const cache = getApolloCache({
    nodesBasicInfos,
    possibleTypes,
  });

  const client = new ApolloClient({
    link,
    cache,
    assumeImmutableResults: true, // https://github.com/apollographql/apollo-client/pull/4543
    connectToDevTools: process.env.NODE_ENV !== 'production',
    // { addTypename: false }
  });
  if (process.env.NODE_ENV !== 'production') (<any>window).AC = client;
  return client;
}
