export enum WFState {
  UNDEFINED = 'UNDEFINED',
  INSTRUCTION = 'INSTRUCTION',
  REFUSE = 'REFUSE',
  ACCORDE = 'ACCORDE',
  EMIS = 'EMIS',
  ANNULE = 'ANNULE',
  SIGNE = 'SIGNE',
  GESTION = 'GESTION',
  CONTENTIEUX = 'CONTENTIEUX',
  SURENDETTEMENT = 'SURENDETTEMENT',
  CLOTURE = 'CLOTURE',
}

export enum WFAction {
  CREATION = 'CREATION',
  INSTRUCTION = 'INSTRUCTION',
  REFUS = 'REFUS',
  ACCORD = 'ACCORD',
  EMISSION = 'EMISSION',
  ANNULATION = 'ANNULATION',
  SIGNATURE = 'SIGNATURE',
  GESTION = 'GESTION',
  CONTENTIEUX = 'CONTENTIEUX',
  SURENDETTEMENT = 'SURENDETTEMENT',
  CLOTURE = 'CLOTURE',
}

// no ACTION_LABEL for now (idem)