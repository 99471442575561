import { create } from 'xmlbuilder2';
import { parse, format, setDate, addMonths } from 'date-fns';

import { EnhancedBorrower } from '../../types/schema-custom';
import {
  Borrower,
  Schedule,
  SchedulePayment,
  ScheduleType,
} from '../../types/schema';

const makeTxInf = ({
  endToEndId,
  dueDate,
  umr,
  amount,
  index,
}: {
  endToEndId: string;
  dueDate: string;
  umr: string;
  /** Amount in CENTS */
  amount: number;
  index: number;
}) => ({
  '@txId': index + 1,
  SddCreatn: {
    SddCmnInf: {
      EndToEndId: endToEndId, // "AARHP000005516529CPHO002022-02-15",
      DueDate: dueDate, // "2022-02-15",
    },
    SddCreatnInf: {
      MndtFullInf: {
        'emdd:CdtrId': {
          'emdd:SCI': 'FR61RHP121908',
        },
        'emdd:MndtId': {
          'emdd:UMR': umr, // "AARHP000005516529CPHO00            ",
        },
      },
      Amount: (amount / 100).toFixed(2),
      RemtceInf: 'REMBOURSEMENT PRET SNCF',
    },
  },
});

/**
 * Active payment filter (current or future)
 * @param nowOrLater
 * @returns
 */
const isActivePayment = (nowOrLater?: Date) => (p: SchedulePayment) => {
  const pmtDate = parse(p.dueDate, 'yyyy-MM-dd', new Date());
  return !nowOrLater
    ? !p.done
    : pmtDate.getMonth() === nowOrLater.getMonth() &&
        pmtDate.getFullYear() === nowOrLater.getFullYear();
};

/**
 * Returns active payments, by done or by date
 * @param nowOrLater Optional date
 * @returns
 */
const activePayments =
  (nowOrLater?: Date) =>
  (s: Schedule): SchedulePayment[] =>
    (s.payments || []).filter(isActivePayment(nowOrLater));

/**
 * Returns Schedules having active payments
 * @param nowOrLater Optional date
 * @returns
 */
const activeSchedules =
  (nowOrLater?: Date) =>
  (s: Schedule): Schedule | false => {
    const pp = activePayments(nowOrLater)(s);
    return pp.length ? s : false;
  };

const updatedPayment =
  (nowOrLater: Date) =>
  (p: SchedulePayment): SchedulePayment =>
    isActivePayment(nowOrLater)(p)
      ? {
          ...p,
          done: true,
        }
      : p;

export type PaymentUpdate = {
  brwr: Borrower;
  principalPmt: number;
  userPmt: number;
  interestPmt: number;
  newSchedules: Schedule[] | null;
};

export type PaymentStats = {
  [day: number]: { count: number; sum: number; dueDate: string };
};

/**
 * All Active scheduled payments (not done / by month)
 * @param brwr
 * @param now
 * @returns
 */

type AllActivePayments = {
  (brwr: Borrower): Omit<PaymentUpdate, 'newSchedules'> & {
    newSchedules: null;
  };
  (brwr: Borrower, now: Date): Omit<PaymentUpdate, 'newSchedules'> & {
    newSchedules: Schedule[];
  };
};

export const allActivePayments: AllActivePayments = (
  brwr: Borrower,
  nowOrLater?: Date,
): any => {
  const allPrincipal = (brwr.schedules || []).filter(
    s => s.type === ScheduleType.CAPITAL,
  );
  const allActivePrincipal = allPrincipal
    .map(activeSchedules(nowOrLater))
    .filter((s: any): s is Schedule => !!s);
  const allPrincipalPayments = ([] as SchedulePayment[]).concat(
    ...allActivePrincipal.map(activePayments(nowOrLater)),
  );

  const allUser = (brwr.schedules || []).filter(
    s => s.type === ScheduleType.DEBT,
  );
  const allActiveUser = allUser
    .map(activeSchedules(nowOrLater))
    .filter((s: any): s is Schedule => !!s);
  const allUserPayments = ([] as SchedulePayment[]).concat(
    ...allActiveUser.map(activePayments(nowOrLater)),
  );

  const sumPmt =
    (a: 'principalAmount' | 'interestAmount' = 'principalAmount') =>
    (r: number, p: SchedulePayment) =>
      r + (p[a] || 0);
  const principalPmt = allPrincipalPayments.reduce(sumPmt(), 0);
  const interestPmt = allPrincipalPayments.reduce(sumPmt('interestAmount'), 0);
  const userPmt = allUserPayments.reduce(sumPmt(), 0);

  let newSchedules: Schedule[] | null = null;
  if (nowOrLater) {
    // prepare for update, deep clone
    let hasChange = false;
    const newPrincipal: Schedule[] = allPrincipal.map(s => {
      // find active schedules not marked as done
      const active = activePayments(nowOrLater)(s).some(p => !p.done);
      if (active) {
        hasChange = true;
        return {
          ...s,
          payments: (s.payments || []).map(updatedPayment(nowOrLater)),
        } as Schedule;
      }
      return s;
    });
    const newUser = allUser.map(s => {
      // find active schedules not marked as done
      const active = activePayments(nowOrLater)(s).some(p => !p.done);
      if (active) {
        hasChange = true;
        return {
          ...s,
          payments: (s.payments || []).map(updatedPayment(nowOrLater)),
        } as Schedule;
      }
      return s;
    });
    if (hasChange) newSchedules = [...newPrincipal, ...newUser];
  }
  return {
    brwr,
    principalPmt,
    userPmt,
    interestPmt,
    newSchedules,
  };
};

export const currentPayment = (brwr: Borrower, now: Date) =>
  // Also returns the actual schedule for editing
  allActivePayments(brwr, now);

export const borrowersToGPG = (
  bb: EnhancedBorrower[],
): [string, PaymentUpdate[], PaymentStats] => {
  const now = new Date();
  const updates: PaymentUpdate[] = [];
  const stats: PaymentStats = {};
  const doc = create(
    { version: '1.0', encoding: 'utf-8' },
    {
      Document: {
        '@xmlns:schemaLocation':
          'urn:awl:emd2:tech:xsd:emdd.sdd.data.managmnt.001 emdd.sdd.data.managmnt.001.xsd',
        '@xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
        '@xmlns': 'urn:awl:emd2:tech:xsd:emdd.sdd.data.managmnt.001',
        '@xmlns:emdd': 'urn:awl:emdd:tech:xsd:common.001',
        Sdds: {
          GrpHdr: {
            'emdd:MsgId': `${format(now, 'yyyyMMddHHmmss')}PrelevementsSEPA`, // "20220110175537PrelevementsSEPA",
            'emdd:CreDtTm': format(now, "yyyy-MM-dd'T'HH:mm:ss"), // no timezone?
            'emdd:NbOfTxs': bb.length,
          },
          SddTxInf: bb
            .sort(
              (a, b) =>
                a.owner?.edges[0].node.sncfCP?.localeCompare(
                  b.owner?.edges[0].node.sncfCP || '',
                ) || 0,
            )
            .map((b, index) => {
              const update = currentPayment(b, now);
              const { principalPmt, userPmt, interestPmt, newSchedules } =
                update;
              const sncfCP = b.owner.edges?.[0]?.node.sncfCP;
              if (!sncfCP) throw new Error('Could not get sncfCP');
              if (!b.umr || !b.pmtDay) return false;
              const pmtDay: number = b.pmtDay || 1;
              const { count, sum } = stats[pmtDay] || { count: 0, sum: 0 };
              const dueDate = format(
                addMonths(setDate(now, pmtDay), 1),
                'yyyy-MM-dd',
              );
              stats[pmtDay] = {
                count: count + 1,
                sum: sum + principalPmt + userPmt + interestPmt,
                dueDate,
              };
              if (newSchedules) updates.push(update);
              return makeTxInf({
                endToEndId: `${b.id.split('-')[4]}-${format(
                  now,
                  'yyMMdd',
                )}-${sncfCP}`,
                amount: Math.abs(principalPmt + userPmt + interestPmt),
                dueDate,
                umr: b.umr,
                index,
              });
            })
            .filter(Boolean),
        },
      },
    },
  );
  return [doc.end({ prettyPrint: true }), updates, stats];
};
