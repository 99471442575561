import React, { useCallback } from 'react';
import { Record } from 'ra-core';
import { FieldProps } from 'react-admin';
import { Tooltip, IconButton } from '@material-ui/core';
import LinkOff from '@material-ui/icons/LinkOff';
import { useTranslateIf } from '../../../hooks/use-translate-if';

type ConnectionDisconnectButtonProps = FieldProps & {
  onDisconnect?: (record: Record) => void;
};

const ConnectionDisconnectButton: React.FC<ConnectionDisconnectButtonProps> = ({
  resource,
  record,
  onDisconnect,
  className,
}: ConnectionDisconnectButtonProps) => {
  const translate = useTranslateIf();
  const handleDisconnect = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      if (record && onDisconnect) {
        // Once deleted this component no longer exists
        onDisconnect(record);
      }
    },
    [record, onDisconnect],
  );

  if (!record || !resource) return null;

  return (
    <>
      <Tooltip title={translate(`action.disconnect`)} className={className}>
        <IconButton onClick={handleDisconnect}>
          <LinkOff />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ConnectionDisconnectButton;
