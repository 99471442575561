import React from 'react';

import { AgnosticTextField } from '../../../../components/Fields/agnostic/AgnosticTextField';
import { AgnosticAmountField } from '../../../../components/Fields/agnostic/AgnosticAmountField';
import {
  AgnosticSelectField,
  AgnosticSelectFieldProps,
} from '../../../../components/Fields/agnostic/AgnosticSelectField';
import { AgnosticFormControl } from '../../../../components/Fields/agnostic/AgnosticFormControl';
import { LedgerAccount } from '../../../../dataProvider/type';

export interface CustomTransactionValues {
  payee: string;
  amount: number;
  sourceAccount: string;
  targetAccount: string;
}

type AccountSelectFieldProps = Omit<AgnosticSelectFieldProps, 'options'>;

const AccountSelectField: React.FC<AccountSelectFieldProps> = props => {
  const options = React.useMemo(
    () => [
      { label: 'AGENT', value: LedgerAccount.USER_ACCOUNT },
      { label: 'PRÊT', value: LedgerAccount.PRINCIPAL_ACCOUNT },
      { label: 'INTERÊTS', value: LedgerAccount.INTEREST_ACCOUNT },
      { label: 'ARRONDIS', value: LedgerAccount.ROUNDING_ACCOUNT },
      { label: 'CRÉANCES', value: LedgerAccount.LOSSES_ACCOUNT },
      { label: 'BANQUE', value: LedgerAccount.BANK_ACCOUNT },
    ],
    [],
  );

  return <AgnosticSelectField {...props} options={options} />;
};

export const CustomTransactionLedgerActionFields: React.FC = () => {
  const validateAmount = React.useCallback((value: number) => {
    if (!value) {
      return 'Vous devez saisir un montant';
    }

    if (value <= 0) {
      return 'Le montant doit être supérieur à 0';
    }

    return null;
  }, []);

  const validatePayee = React.useCallback((value: string) => {
    if (!value) {
      return 'Vous devez saisir un motif';
    }

    return null;
  }, []);

  const validateSourceAccount = React.useCallback(
    (value: string, { targetAccount }: CustomTransactionValues) => {
      if (!value) {
        return 'Vous devez sélectionner un compte';
      }

      if (value === targetAccount) {
        return 'Le compte à débiter ne peut pas être le même que le compte à créditer';
      }

      return null;
    },
    [],
  );

  const validateTargetAccount = React.useCallback(
    (value: string, { sourceAccount }: CustomTransactionValues) => {
      if (!value) {
        return 'Vous devez sélectionner un compte';
      }

      if (value === sourceAccount) {
        return 'Le compte à créditer ne peut pas être le même que le compte à débiter';
      }

      return null;
    },
    [],
  );

  return (
    <>
      <AgnosticFormControl fullWidth>
        <AgnosticTextField
          name="payee"
          label="Motif"
          required
          validate={validatePayee}
        />
      </AgnosticFormControl>
      <AgnosticFormControl fullWidth>
        <AgnosticAmountField
          name="amount"
          label="Montant (€) (ex: 12,34)"
          required
          validate={validateAmount}
        />
      </AgnosticFormControl>
      <AgnosticFormControl fullWidth>
        <AccountSelectField
          name="sourceAccount"
          label="Compte à débiter"
          required
          validate={validateSourceAccount as any}
        />
      </AgnosticFormControl>
      <AgnosticFormControl fullWidth>
        <AccountSelectField
          name="targetAccount"
          label="Compte à créditer"
          required
          validate={validateTargetAccount as any}
        />
      </AgnosticFormControl>
    </>
  );
};
