import React, { useCallback } from 'react';
import { sanitizeInputRestProps } from 'react-admin';
import { InputProps as InputPropsType, useInput, useTranslate } from 'ra-core';
import clsx from 'clsx';

import { Controlled as CodeMirror } from 'react-codemirror2';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';

import 'codemirror/lib/codemirror.css';
// import 'codemirror/theme/material.css';
// import 'codemirror/theme/neat.css';
import 'codemirror/mode/yaml/yaml';
// import 'codemirror/mode/javascript/javascript';
import './CodeMirror.css';
import { FieldTitle } from 'ra-core/esm/util/FieldTitle';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    label: {
      marginBottom: theme.spacing(2),
      position: 'relative',
      transform: 'none',
    },
    editor: {
      padding: theme.spacing(0.5),
      border: '1px solid #e1e1e1',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.white,
    },
    hasError: {
      borderColor: theme.palette.error.main,
      boxShadow: `0px 0px 3px ${theme.palette.error.main}`,
    },
  }),
);

export type YamlTextInputProps = InputPropsType<TextFieldProps>;

const YamlTextInput: React.FC<YamlTextInputProps> = ({
  label,
  format,
  helperText,
  onBlur,
  onFocus,
  onChange,
  // options = {},
  parse,
  resource,
  source,
  record,
  validate,
  ...rest
}: YamlTextInputProps) => {
  const classes = useStyles();
  const translate = useTranslate();

  const {
    // id,
    input,
    isRequired,
    meta: { error, submitError, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'text',
    validate,
    ...rest,
  });

  const hasError = touched && !!(error || submitError);

  const handleChange = useCallback(
    (
      editor: CodeMirror.Editor,
      data: CodeMirror.EditorChange,
      value: string,
    ) => {
      // Simulate event
      input.onChange({
        target: { value },
      });
    },
    [input],
  );
  const handleFocus = useCallback(
    (editor: CodeMirror.Editor, event: React.SyntheticEvent) => {
      input.onFocus(event as React.FocusEvent<HTMLElement>);
    },
    [input],
  );
  const handleBlur = useCallback(
    (editor: CodeMirror.Editor, event: React.SyntheticEvent) => {
      input.onBlur(event as React.FocusEvent<HTMLElement>);
    },
    [input],
  );

  return (
    <FormControl
      fullWidth
      margin="dense"
      required={isRequired}
      {...sanitizeInputRestProps(rest)}
    >
      <InputLabel shrink className={classes.label} error={hasError}>
        <FieldTitle source={source} resource={resource} />
      </InputLabel>
      <CodeMirror
        value={input.value}
        // options={options}
        onBeforeChange={handleChange}
        // onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={clsx(
          classes.editor,
          { [classes.hasError]: hasError },
          // className,
        )}
        options={{
          // theme: 'phicomas',
          fixedGutter: true,
          // scrollbarStyle: 'null',
          coverGutterNextToScrollbar: false,
          lineNumbers: true,
          mode: 'text/x-yaml',
          lineWrapping: true,
          // htmlMode: true,
          // matchClosing: true,
          indentWithTabs: false,
        }}
      />
      <FormHelperText error={hasError}>
        {hasError ? translate(error) : helperText || <>&#8203;</>}
      </FormHelperText>
    </FormControl>
  );
};

export default YamlTextInput;
