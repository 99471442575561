import { useCallback, useEffect, useState } from 'react';

// eslint-disable-next-line import/prefer-default-export
export function useKeyPressed(targetKey: string): boolean {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  const handleKeyDown = useCallback(
    (event: KeyboardEvent): any => {
      if (event.key === targetKey) {
        setKeyPressed(true);
      }
    },
    [targetKey],
  );

  // If released key is our target key then set to false
  const handleKeyUp = useCallback(
    (event: KeyboardEvent): any => {
      if (event.key === targetKey) {
        setKeyPressed(false);
      }
    },
    [targetKey],
  );

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
}
