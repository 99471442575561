import React from 'react';
import { EditProps, Record, SimpleForm, SimpleFormProps } from 'react-admin';

import { GuarantyLoan } from '../../types/schema';
import { GuarantyLoanForm } from './GuarantyLoanForm';
import { clearDataBeforeSubmit } from '../../dataProvider/utils';
import { Breadcrumb } from '../../components/Breadcrumb';
import { GuarantyLoanHistory } from './GuarantyLoanHistory';
import UploadToolbar from '../../components/Toolbars/AttachmentToolbar';
import { ASSET_USER_NAMESPACE } from '../../aws/cpret-config';
import { useGuarantyLoanWorkflowSave } from '../../workflow/guaranty-loans';
import { EditWithAttachmentsAndLog } from '../../components/EditWithAttachmentsAndLog';

export interface GuarantyLoanEditTitleProps {
  record?: GuarantyLoan;
}

export const GuarantyLoanEditTitle: React.FC<GuarantyLoanEditTitleProps> = ({
  record,
}) => {
  const fragments = React.useMemo(
    () =>
      [
        {
          title: 'Demandeur',
          typename: 'Owner',
          id: record?.guarantee.edges[0]?.node.owner?.edges[0]?.node.id,
        },
        {
          title: `Caution Globale`,
          typename: 'Guarantee',
          id: record?.guarantee.edges[0]?.node.id,
        },
        {
          title: record?.label,
          typename: 'GuarantyLoan',
          id: record?.id,
        },
      ].filter(Boolean),
    [record],
  );

  return <Breadcrumb fragments={fragments} />;
};

export const GuarantyLoanSimpleForm: React.FC<
  Partial<SimpleFormProps> & { editProps: Partial<EditProps> }
> = ({ editProps, ...props }) => {
  const [save, { loading: saving }] = useGuarantyLoanWorkflowSave(editProps);

  return (
    <SimpleForm
      {...props}
      redirect={false}
      // warnWhenUnsavedChanges
      submitOnEnter={false}
      toolbar={
        <UploadToolbar
          field="guarantyLoan"
          namespace={ASSET_USER_NAMESPACE}
          kind="edit"
        />
      }
      save={save}
      saving={saving}
    >
      <GuarantyLoanForm />
    </SimpleForm>
  );
};

export const guarantyLoanEditTransform = (data: Record): Record => {
  const next = clearDataBeforeSubmit(data);
  delete next.message;
  delete next.actionDate;
  return next;
};

export const GuarantyLoanEdit: React.FC<EditProps> = props => {
  return (
    <EditWithAttachmentsAndLog
      {...props}
      title={<GuarantyLoanEditTitle />}
      undoable={false}
      transform={guarantyLoanEditTransform}
      aside={<GuarantyLoanHistory />}
    >
      <GuarantyLoanSimpleForm editProps={props} />
    </EditWithAttachmentsAndLog>
  );
};
