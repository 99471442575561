import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { SimpleCms } from 'phicomas-client';

import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import { Add as AddIcon } from '@material-ui/icons';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

// eslint-disable-next-line import/no-cycle
import pageComponentMapping from './componentsMapping';

import { ClassName } from '../../../types/styles';

const useStyles = makeStyles<Theme>(theme => {
  const ICON_PADDING = 3;
  const FONT_SIZE = 32;
  return createStyles({
    addButton: {
      margin: `${-ICON_PADDING}px 0`,
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      cursor: 'pointer',

      '&:before, &:after': {
        flex: '1 0 0%',
        content: '""',
        height: 0,
        borderBottom: '1px solid black',
        opacity: 0,
      },

      '&:hover, &$buttonOpen': {
        '&:before, &:after': {
          opacity: 1,
        },
        '& $icon': {
          opacity: 1,
          transform: 'scale(1)',
        },
      },
    },
    buttonOpen: {},
    listIcon: {
      minWidth: 'auto',
      marginRight: theme.spacing(2),
    },
    icon: {
      flex: '0 0 auto',
      padding: ICON_PADDING,
      margin: theme.spacing(0, 1),
      opacity: 0.2,
      transform: 'scale(0.5)',
      transition: theme.transitions.create('transform'),

      '& svg': {
        fontSize: FONT_SIZE,
      },
    },
  });
});

type AddButtonProps = {
  parent?: SimpleCms.PageComponentType;
  position: number;
  onAdd: (position: number, componentType: SimpleCms.PageComponentType) => void;
  className?: ClassName;
};

const AddButton: React.FC<AddButtonProps> = ({
  parent,
  position,
  onAdd,
  className,
}: AddButtonProps) => {
  const classes = useStyles();

  const availableSubTypes = useMemo(() => {
    const parentProps = parent && pageComponentMapping.get(parent);

    if (parentProps?.availableSubTypes) return parentProps.availableSubTypes;
    if (parentProps?.forbiddenSubTypes) {
      return Array.from(pageComponentMapping).reduce<
        Array<SimpleCms.PageComponentType>
      >((acc, [type]) => {
        if (!parentProps.forbiddenSubTypes?.includes(type)) {
          acc.push(type);
        }
        return acc;
      }, []);
    }

    return Array.from(pageComponentMapping).map(([type]) => type);
  }, [parent]);

  const [menuState, setMenuState] = useState<{
    open: boolean;
    mouseX: number | null;
    mouseY: number | null;
  }>({
    open: false,
    mouseX: null,
    mouseY: null,
  });

  function closeMenu() {
    setMenuState({
      open: false,
      mouseX: null,
      mouseY: null,
    });
  }

  const handleClickAdd = useCallback(
    (event: React.MouseEvent<HTMLLIElement>) => {
      onAdd(
        position,
        event.currentTarget.dataset
          .componentType as SimpleCms.PageComponentType,
      );
      closeMenu();
    },
    [position, onAdd],
  );

  const handleClickButton = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (availableSubTypes.length === 1) {
        // If there is only one available type, add it instead of showing a menu with 1 option
        onAdd(position, availableSubTypes[0]);
      } else {
        setMenuState({
          open: true,
          mouseX: event.clientX,
          mouseY: event.clientY,
        });
      }
    },
    [availableSubTypes, onAdd, position],
  );

  const handleClose = useCallback(() => {
    closeMenu();
  }, []);

  const parentCanHaveChild = availableSubTypes.length > 0;

  if (!parentCanHaveChild) return null;

  return (
    <>
      <div
        role="button"
        tabIndex={-1}
        onClick={handleClickButton}
        onKeyPress={() => {}}
        className={clsx(
          classes.addButton,
          {
            [classes.buttonOpen]: menuState.open,
          },
          className,
        )}
      >
        <IconButton size="small" className={classes.icon}>
          <AddIcon />
        </IconButton>
      </div>
      {availableSubTypes.length > 1 && (
        <Menu
          keepMounted
          open={menuState.open}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            menuState.mouseY !== null && menuState.mouseX !== null
              ? { top: menuState.mouseY, left: menuState.mouseX }
              : undefined
          }
        >
          {Array.from(pageComponentMapping).map(([type, { title, Icon }]) => {
            if (!availableSubTypes.includes(type)) return null;
            return (
              <MenuItem
                onClick={handleClickAdd}
                data-component-type={type}
                key={type}
              >
                <ListItemIcon classes={{ root: classes.listIcon }}>
                  <Icon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={title} />
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
};

export default AddButton;
