import React, { useEffect, useState } from 'react';

import { downloadFromS3 } from '../aws/s3-utils';
import { TEMPLATE_NAMESPACE } from '../aws/cpret-config';

type StringMap = {
  [key: string]: StringMap | string | undefined;
};

export type TemplatesContextType = StringMap & {
  loan: StringMap;
  borrower: StringMap;
};

const TemplatesContext = React.createContext<TemplatesContextType | undefined>(
  undefined,
);

export const TemplatesProvider: React.FC = ({ children }) => {
  const [templates, setTemplates] = useState<TemplatesContextType>();

  useEffect(() => {
    const action = async () => {
      try {
        const r = await downloadFromS3(`${TEMPLATE_NAMESPACE}/templates.json`);
        const result: TemplatesContextType =
          r.Body && JSON.parse(r.Body.toString('utf-8'));
        setTemplates(result);
      } catch (e) {
        console.warn('Could not load template - ignoring.');
        console.error(e);
      }
    };
    action();
  }, []);

  return (
    <TemplatesContext.Provider value={templates}>
      {children}
    </TemplatesContext.Provider>
  );
};

export default TemplatesContext;
