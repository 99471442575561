import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDataProvider } from 'ra-core';
import { RichUtils, EditorState, Modifier } from 'draft-js';
import {
  getSelectionText,
  getEntityRange,
  getSelectionEntity,
} from 'draftjs-utils';
import linkifyIt from 'linkify-it';
import { Asset, ID } from 'phicomas-client';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import { Link as LinkIcon, LinkOff as UnlinkIcon } from '@material-ui/icons';
import clsx from 'clsx';

import Option from '../Option/Option';
import AssetPickerDialog from '../../../../../Dialogs/AssetPickerDialog/AssetPickerDialog'; // eslint-disable-line import/no-cycle

import projectInfos from '../../../../../../project/projectInfos';
import { useEnvironmentContext } from '../../../../../../context/environmentContext';

enum Target {
  BLANK = '_blank',
  SELF = '_self',
}
const DEFAULT_TITLE = '';
const DEFAULT_HREF = '';
const DEFAULT_TARGET = Target.BLANK;

type LinkInfos = {
  title: string;
  url: string;
  targetOption: Target;
  [k: string]: string | undefined;
};

const linkify = linkifyIt();
const linkifyLink = (params: LinkInfos) => {
  const links = linkify.match(params.url);
  return {
    ...params,
    url: (links && links[0] && links[0].url) || params.url,
  };
};

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    wrapper: {
      display: 'flex',
    },
    popper: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    urlFieldWrapper: {
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',

      margin: theme.spacing(-0.5),
      marginBottom: theme.spacing(1 - 0.5),
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    urlField: {
      flexGrow: 1,
      '& label': {
        fontSize: '1rem',
      },
      '& input': {
        fontSize: '1rem',
      },
    },

    titleField: {
      '& label': {
        fontSize: '1rem',
      },
      '& input': {
        fontSize: '1rem',
      },
    },
  }),
);

type AssetLinkButtonProps = {
  onChange?: (s: any) => any;
  editorState?: EditorState;
};

const AssetLinkButton: React.FC<AssetLinkButtonProps> = ({
  onChange,
  editorState,
}: AssetLinkButtonProps) => {
  const classes = useStyles();

  const {
    assetResource,
    awsExports: { s3: rawS3 },
  } = projectInfos;
  const [environmentLevel] = useEnvironmentContext();
  const dataProvider = useDataProvider();

  const [currentEntity, setCurrentEntity] = useState<string | undefined>(
    editorState ? getSelectionEntity(editorState) : undefined,
  );
  useEffect(() => {
    setCurrentEntity(getSelectionEntity(editorState));
  }, [editorState]);

  const [title, setTitle] = useState(DEFAULT_TITLE);
  const handleTitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setTitle(value);
    },
    [setTitle],
  );
  const [url, setUrl] = useState(DEFAULT_HREF);
  const handleUrlChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUrl(event.target.value);
    },
    [setUrl],
  );
  const [targetOption, setTargetOption] = useState<Target>(DEFAULT_TARGET);
  const handleTargetOptionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setTargetOption(checked ? Target.BLANK : Target.SELF);
    },
    [setTargetOption],
  );

  const { editedLink, selectionText } = useMemo(() => {
    const currentValues: {
      editedLink?: Partial<LinkInfos>;
      selectionText?: any;
    } = {};
    if (editorState) {
      const contentState = editorState.getCurrentContent();
      const entity = currentEntity && contentState.getEntity(currentEntity);
      if (entity && entity.getType() === 'LINK') {
        const currentLink: Partial<LinkInfos> = {};
        if (currentEntity) {
          const {
            url: dataUrl,
            targetOption: dataTargetOption,
            title: dataTitle,
            ...rest
          } = entity.getData();
          currentLink.url = dataUrl;
          currentLink.targetOption = dataTargetOption;
          const entityRange = getEntityRange(editorState, currentEntity);
          if (entityRange) {
            currentLink.title = entityRange.text;
          } else {
            currentLink.title = title;
          }
          Object.keys(rest).forEach(key => {
            const value = rest[key];
            if (!value) {
              currentLink[key] = key;
            } else {
              currentLink[key] = value;
            }
          });
        }
        currentValues.editedLink = currentLink;
      }
      currentValues.selectionText = getSelectionText(editorState);
    }
    return currentValues;
  }, [currentEntity, editorState, title]);

  const handleBeforeOpen = useCallback(() => {
    const {
      title: linkTitle,
      url: linkTarget,
      targetOption: linkTargetOptions,
    } = editedLink ?? {};

    setTitle(linkTitle || selectionText);
    setUrl(linkTarget || DEFAULT_HREF);
    setTargetOption(linkTargetOptions || DEFAULT_TARGET);
  }, [editedLink, selectionText]);

  const resetFields = useCallback(() => {
    setTitle(DEFAULT_TITLE);
    setUrl(DEFAULT_HREF);
    setTargetOption(DEFAULT_TARGET);
  }, []);

  const handleAddLink = useCallback(() => {
    if (editorState && onChange) {
      const {
        title: linkTitle,
        url: linkUrl,
        targetOption: linkTargetOption,
      } = linkifyLink({ title, url, targetOption });

      let selection = editorState.getSelection();

      if (currentEntity) {
        const entityRange = getEntityRange(editorState, currentEntity);
        const isBackward = selection.getIsBackward();
        if (isBackward) {
          selection = selection.merge({
            anchorOffset: entityRange.end,
            focusOffset: entityRange.start,
          });
        } else {
          selection = selection.merge({
            anchorOffset: entityRange.start,
            focusOffset: entityRange.end,
          });
        }
      }
      const {
        title: editedLinkTitle,
        url: editedLinkUrl,
        targetOption: editedLinkTargetOption,
        ...passThroughtProps
      } = editedLink ?? {};
      const entityKey = editorState
        .getCurrentContent()
        .createEntity('LINK', 'MUTABLE', {
          url: linkUrl,
          targetOption: linkTargetOption,
          ...passThroughtProps,
        })
        .getLastCreatedEntityKey();

      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        selection,
        `${linkTitle}`,
        editorState.getCurrentInlineStyle(),
        entityKey,
      );
      const newEditorState = EditorState.push(
        editorState,
        contentState,
        'insert-characters',
      );

      // // insert a blank space after link
      // selection = newEditorState.getSelection().merge({
      //   anchorOffset: selection.get('anchorOffset') + linkTitle.length,
      //   focusOffset: selection.get('anchorOffset') + linkTitle.length,
      // });
      // newEditorState = EditorState.acceptSelection(newEditorState, selection);
      // contentState = Modifier.insertText(
      //   newEditorState.getCurrentContent(),
      //   selection,
      //   ' ',
      //   newEditorState.getCurrentInlineStyle(),
      //   undefined,
      // );
      onChange(
        EditorState.push(newEditorState, contentState, 'insert-characters'),
      );
    }
  }, [
    currentEntity,
    editorState,
    onChange,
    targetOption,
    title,
    url,
    editedLink,
  ]);

  const handleRemoveLink = useCallback(() => {
    if (editorState && onChange) {
      let selection = editorState.getSelection();
      if (currentEntity) {
        const entityRange = getEntityRange(editorState, currentEntity);
        const isBackward = selection.getIsBackward();
        if (isBackward) {
          selection = selection.merge({
            anchorOffset: entityRange.end,
            focusOffset: entityRange.start,
          });
        } else {
          selection = selection.merge({
            anchorOffset: entityRange.start,
            focusOffset: entityRange.end,
          });
        }
        onChange(RichUtils.toggleLink(editorState, selection, null));
      }
    }
  }, [currentEntity, editorState, onChange]);

  const [showDialog, setShowDialog] = useState(false);
  const handleOpenDialog = useCallback(() => {
    setShowDialog(true);
  }, [setShowDialog]);
  const handleCloseDialog = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  const handleSelect = useCallback(
    async (ids: ID[]) => {
      const s3 = rawS3?.[environmentLevel];
      if (s3 && assetResource) {
        const { data } = await dataProvider.getOne<Asset>(assetResource, {
          id: ids[0],
        });

        if (data.key) {
          const src = `https://${s3.bucket}/${data.key}`;
          setUrl(src);
        }
      }
    },
    [assetResource, dataProvider, environmentLevel, rawS3],
  );

  const isValid = useMemo(() => url.length > 1, [url]);

  if (!assetResource) return null;

  return (
    <div className={clsx(classes.wrapper, 'rdw-toolbar-link')}>
      <Option
        Icon={LinkIcon}
        onBeforeOpen={handleBeforeOpen}
        resetFields={resetFields}
        isValid={isValid}
        onOk={handleAddLink}
        popperClassName={classes.popper}
      >
        <div>
          <TextField
            value={title}
            placeholder="auto"
            onChange={handleTitleChange}
            size="small"
            label="Titre"
            variant="filled"
            className={classes.titleField}
            fullWidth
          />
        </div>
        <div>
          <div className={classes.urlFieldWrapper}>
            <TextField
              value={url}
              onChange={handleUrlChange}
              size="small"
              label="Url"
              variant="filled"
              className={classes.urlField}
              required
            />
            <Button onClick={handleOpenDialog} size="small" variant="outlined">
              Parcourir
            </Button>
          </div>
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={targetOption === Target.BLANK}
                onChange={handleTargetOptionChange}
              />
            }
            label="Ouvrir le lien dans une nouvelle fenêtre"
          />
        </div>

        {showDialog && (
          <AssetPickerDialog
            resource={assetResource}
            onSelect={handleSelect}
            onClose={handleCloseDialog}
          />
        )}
      </Option>
      <Option
        Icon={UnlinkIcon}
        onClick={handleRemoveLink}
        disabled={!editedLink}
      />
    </div>
  );
};

export default AssetLinkButton;
