import React, { useCallback } from 'react';
import {
  Button,
  //   DataProviderProxy,
  useDataProvider,
  //   useListContext,
} from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';

import { stringify } from 'csv-stringify/dist/esm/sync';
import { format } from 'date-fns';
import {
  Guarantee,
  GuarantyLoan,
} from 'phicomas-client/src/projects/cpret/schema';
import { Status } from 'phicomas-client';
import { get } from 'lodash';

import { EnhancedOwner } from '../../types/schema-custom';
// import { Loan } from '../../types/schema';
// import { centsToEur } from '../../lib/utils';
import download from '../../lib/download';
import { useAppContext } from '../../context/AppContext';
import { getDataBlob } from './GuaranteeDataBlobField';
import { csvAmount } from '../../lib/utils';
import { WFAction as GuarantyAction } from '../../workflow/guaranty-loans';
import { WFAction as GuaranteeAction } from '../../workflow/guarantee-enum';
import { SNCF_COMPANIES } from '../owner/SncfCompanyField';
import { ANON_STREET, maskName } from '../../lib/anonymize';

const exportFilename = (now = new Date()) =>
  `${format(now, 'yyyyMMdd-HHmmss')}-cpret-export.csv`;

const COLS = [
  'ID caution globale ',
  'ID caution',
  'statut en cours',
  'date statut en cours',
  'Immatriculation',
  'Nom demandeur',
  'Prénom demandeur',
  'Nom co-demandeur',
  'Prénom co-demandeur',
  'Commentaires Demandeur',
  'Commentaires Caution',
  'Commentaires Prêt',
  'adresse projet financé',
  'Code postal projet financé',
  'Ville projet financé',
  'Date de signature',
  'montant prêt cautionné',
  'Date fin caution',
  'Nom Banque',
  // 'EPIC/SA au jour instruction', // does not exist
  'SA Actuelle agent',
  'date désolidarisation',
  'date transfert',
  'date dénonciation',
  'motif dénonciation',
  'date avenant',
  'date remboursement anticipé',
  'date statut dossier en gestion',
  'Date appel en garantie',
  'date de paiement',
  'date de recouvrement',
  'date clôturé',
  'Non-dénonciable',
];

const brwrsToCsv = async (oo: EnhancedOwner[]): Promise<string[][]> => {
  const output: string[][] = [];
  await oo.reduce(async (ooP, o) => {
    await ooP;
    const guarantees = o.guarantees?.edges.map(({ node }) => node as Guarantee);
    await guarantees.reduce(async (ggP, g) => {
      await ggP;
      const loans = g.loans?.edges.map(({ node }) => node as GuarantyLoan);
      const contact =
        g.contacts !== undefined && g.contacts.length > 1 ? g.contacts[1] : {};
      const dataBlob = getDataBlob(g);

      const gActionDate = (action: GuaranteeAction) => {
        const date = g.actionHistory?.find(
          ah => (ah.wfAction as GuaranteeAction) === action,
        )?.date;
        return date ? format(new Date(date), 'dd/MM/yyyy') : '';
      };

      const anon = g.status !== Status.PUBLISHED;
      await loans.reduce(async (llP, l) => {
        await llP;
        const lActionDate = (action: GuarantyAction) => {
          const date = l.actionHistory?.find(
            ah => (ah.wfAction as GuarantyAction) === action,
          )?.date;
          return date ? format(new Date(date), 'dd/MM/yyyy') : '';
        };
        output.push(
          [
            g.id, // ID caution globale
            l.id, // ID caution
            l.wfStatus, // statut en cours +
            l.startDate, // date statut en cours
            anon ? await maskName(o?.sncfCP) : o?.sncfCP, // Immatriculation
            anon
              ? await maskName(o?.contact?.familyName)
              : o?.contact?.familyName, // Nom demandeurs
            anon
              ? await maskName(o?.contact?.givenName)
              : o?.contact?.givenName, // Prénom demandeur
            anon ? await maskName(contact.familyName) : contact.familyName, // Nom co-demandeur
            anon ? await maskName(contact.givenName) : contact.givenName, // Prénom co-demandeur
            o.comment, // Commentaire demandeur
            g.comment, // Commentaire caution
            l.comment, // Commentaire prêt
            anon ? ANON_STREET : get(dataBlob, 'detail.adresseDuBien.adresse'), // adresse projet financé
            get(dataBlob, 'detail.adresseDuBien.codePostal'), // Code postal projet financé
            get(dataBlob, 'detail.adresseDuBien.ville'), // l.wfStatus, // Ville projet financé
            l.signature && format(new Date(l.signature), 'dd/MM/yyyy'), // Date de signature
            l.amount && csvAmount(l.amount), // montant prêt cautionné
            l.endDate, // Date fin caution
            l.bank, // Nom Banque
            // g.sncfCompany, // EPIC/SA au jour instruction - does not exist
            o?.sncfCompany &&
              (SNCF_COMPANIES[o.sncfCompany as keyof typeof SNCF_COMPANIES] ||
                o.sncfCompany), // SA Actuelle agent
            gActionDate(GuaranteeAction.DISSOCIATION), // date désolidarisation
            gActionDate(GuaranteeAction.TRANSFER), // date transfert
            gActionDate(GuaranteeAction.DENUNCIATION), // date dénonciation
            g.actionHistory?.find(
              ah =>
                (ah.wfAction as GuaranteeAction) ===
                GuaranteeAction.DENUNCIATION,
            )?.message || '', // motif dénonciation
            lActionDate(GuarantyAction.AVENANT), // date avenant
            lActionDate(GuarantyAction.ANTICIPE), // date remboursement anticipé,
            lActionDate(GuarantyAction.GESTION), // date statut dossier en gestion,
            lActionDate(GuarantyAction.GARANTIE), // Date appel en garantie,
            lActionDate(GuarantyAction.PAIEMENT), // date de paiement,
            lActionDate(GuarantyAction.RECOUVREMENT), // date de recouvrement,
            lActionDate(GuarantyAction.CLOTURE), // date clôturé,
            g.nonDenunciation ? 'OUI' : 'NON', // Non-dénonciable
          ].map(s => s || ''),
        );
      }, Promise.resolve());
    }, Promise.resolve());
  }, Promise.resolve());
  return output;
};

export const GuaranteeExportButtons: React.FC = () => {
  const dataProvider = useDataProvider();
  const { loading } = useAppContext();

  const handleExportAll = useCallback(() => {
    const action = async () => {
      const { data } = await dataProvider.getList<EnhancedOwner>('cpretOwner', {
        filter: {},
        pagination: { page: 0, perPage: -1 },
        sort: { field: 'createdAt', order: 'ASC' },
      });
      //   const bbAlerts = data.filter(d => d.$$scheduleAlert > 0);
      const csvData = await brwrsToCsv(data);
      const out = stringify(csvData, {
        header: true,
        delimiter: ';',
        columns: COLS,
      });
      download(Buffer.from(out, 'latin1'), exportFilename());
    };
    return action();
  }, [dataProvider]);

  return (
    <Button
      onClick={handleExportAll}
      label="Exp. Cautions"
      disabled={!!loading}
    >
      <DownloadIcon />
    </Button>
  );
};
