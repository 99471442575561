/* eslint-disable @typescript-eslint/ban-types */

export type ReplaceKeys<T, K extends keyof T, TReplace> = {
  [key in keyof T]: key extends K ? TReplace : T[key];
};
export type ReplaceExtends<T, Extends, TReplace> = {
  [key in keyof T]: T[key] extends Extends ? TReplace : T[key];
};

export type Optional<T> = T | undefined;
export type OptionalKeys<T, K extends keyof T> = {
  [key in keyof T]: key extends K ? Optional<T[key]> : T[key];
};
export type OptionalExtends<T, Extends> = {
  [key in keyof T]: T[key] extends Extends ? Optional<T[key]> : T[key];
};

/** Mark some properties as required, leaving others unchanged */
export type MarkRequired<T, K extends keyof T> = Exclude<T, K> &
  Required<Pick<T, K>>;
/** Mark some properties as optionnal, leaving others unchanged */
export type MarkOptionnal<T, K extends keyof T> = Pick<Partial<T>, K> &
  Omit<T, K>;

export type KeysOfUnion<T> = T extends any ? keyof T : never;

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T[P] extends {}
    ? DeepPartial<T[P]>
    : T[P];
};

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };

/** Get the XOR type which could make 2 types exclude each other */
export type XOR<T, U = {}> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U;

export type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> &
  U[keyof U];

type StringCohercible = 'string' | 'number';
export function isStringCohercible(data: unknown): data is StringCohercible {
  return ['string', 'number'].includes(typeof data);
}

export type ReplaceReturnType<T extends (...a: any) => any, TNewReturn> = (
  ...a: Parameters<T>
) => TNewReturn;

export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;
