import React from 'react';
import { ArrayInput, SimpleFormIterator } from 'react-admin';
import { InputProps } from 'ra-core';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    arrayInput: {
      // Cannot modify it through ArrayInput
      '& > label': {
        marginBottom: theme.spacing(2),
        position: 'relative',
        transform: 'none',
      },
    },
  }),
);

export type ListInputProps = InputProps & {
  Input?: React.FC<Partial<InputProps>>;
  inputProps?: Partial<InputProps>;
};
const ListInput: React.FC<ListInputProps> = (props: ListInputProps) => {
  const classes = useStyles();
  const { record, source, Input, inputProps, ...rest } = props;

  if (!record || !source || !Input) return null;

  return (
    <>
      <ArrayInput {...rest} source={source} className={classes.arrayInput}>
        <SimpleFormIterator>
          <Input {...inputProps} />
        </SimpleFormIterator>
      </ArrayInput>
      <FormHelperText>&#8203;</FormHelperText>
    </>
  );
};

export default ListInput;
