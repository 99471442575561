export enum Kind {
  /** Such as Int, String, Boolean, and AWSDateTime */
  Scalar = 'SCALAR',
  Object = 'OBJECT',
  /** has NO fields, check for `possibleTypes` */
  Union = 'UNION',
  /** has fields, check for `possibleTypes` */
  Interface = 'INTERFACE',
  /** check for `enumValues` */
  Enum = 'ENUM',
  /** has inputFields */
  InputObject = 'INPUT_OBJECT',
  /** check for `ofType` (can be of type LIST) */
  List = 'LIST',
  /** check for `ofType` (cannot be of type NON_NULL) */
  NonNull = 'NON_NULL',
}

export interface SimpleType {
  name: string;
}

export interface SimpleTypeNull {
  name: string | null;
}
