import React from 'react';
import { Datagrid, TextField, useListContext } from 'react-admin';
import { useTheme, Theme } from '@material-ui/core';
import Color from 'color';

import { HideZeroNumberField } from './HideZeroNumberField';
import { Style } from '../../types/styles';

const rowStyle =
  (theme: Theme) =>
  (record: any, index: number): Style => {
    return {
      backgroundColor:
        index % 2
          ? 'transparent'
          : Color(theme.palette.specials.cpretBlue).alpha(0.1).toString(),
    };
  };

export const OwnerDataGrid = () => {
  const theme = useTheme();
  const { filterValues } = useListContext();

  const showCaution = filterValues?.$$cpretType === 'CAUTION';
  const showPret = filterValues?.$$cpretType === 'PRET';

  return (
    <Datagrid rowClick="edit" rowStyle={rowStyle(theme)}>
      <TextField source="sncfCP" label="Immatriculation" />
      <TextField source="contact.familyName" label="Nom" />
      <TextField source="contact.givenName" label="Prénom" />
      <TextField source="$$workStatus" label="Statut" />
      <HideZeroNumberField source="$$honneurLoanCount" label="Prêt Honneur" />
      <HideZeroNumberField source="$$otherLoanCount" label="Autre Prêt" />
      <HideZeroNumberField source="$$guaranteeCount" label="Caution" />
      <HideZeroNumberField source="$$cpretActive" label="En cours" />
      {showPret && (
        <HideZeroNumberField
          source="$$surrendettement"
          label="Surendettement"
        />
      )}
      {showPret && (
        <HideZeroNumberField source="$$contentieux" label="Contentieux" />
      )}
      {showPret && <HideZeroNumberField source="$$impaye" label="Impayé" />}
      {showPret && (
        <HideZeroNumberField source="$$retractation" label="Rétractation" />
      )}
      {showCaution && (
        <HideZeroNumberField
          source="$$guarantiesCalled"
          label="Appel en garantie"
        />
      )}
      {showCaution && (
        <HideZeroNumberField source="$$ownerCessation" label="Cessation" />
      )}
    </Datagrid>
  );
};
