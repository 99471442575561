import { useCallback, useContext } from 'react';
import { DataProviderContext } from 'react-admin';
import { format } from 'date-fns';

import { EnhancedBorrower } from '../types/schema-custom';
import { borrowersToGPG, PaymentStats, PaymentUpdate } from './lib/gpg';
import { useLedgerRepayment, useLedgerUserCredit } from './ledger';
import { clearDataBeforeSubmit } from '../dataProvider/utils';
import { useCpretHistory } from './history';

export const gpgFilename = (now: Date) =>
  `RHP.${format(now, 'yyyyMMddHHmmss')}PrelevementsSEPA.emdd-sdd-dat.xml`;

export const statsFilename = (now: Date) =>
  `RHP.${format(now, 'yyyyMMddHHmmss')}PrelevementsSEPA.stats.txt`;

export const receiptHeader = `       Générer retenues mensuelles – Certificat de prélèvements bancaires
                            Traitement du ${format(new Date(), 'dd/MM/yyyy')}

`;

export const useExportGPG = () => {
  const dataProvider = useContext(DataProviderContext);
  const [repayment] = useLedgerRepayment();
  const [rpmtDebt] = useLedgerUserCredit();
  const log = useCpretHistory();
  const cb = useCallback(
    (commit = false) => {
      const commitBorrower = async (
        u: PaymentUpdate,
        i: number,
        uu: PaymentUpdate[],
      ) => {
        const { principalPmt, interestPmt, userPmt, newSchedules, brwr } = u;
        console.log(`Committing borrower #${i + 1}/${uu.length} ${brwr.id}`);
        if (global.gc) {
          console.log(process.memoryUsage());
          global.gc(); // note: our memory problems were solved with apollo.clearCache() ...
        }
        const day = (brwr.pmtDay || 1) === 1 ? '1ᵉʳ' : brwr.pmtDay?.toString();
        if (principalPmt) {
          const payee = [
            `Retenue attendue (prél. au ${day}) : Éch. principal`,
            `Retenue prélevée (prél. au ${day}) : Éch. principal`,
          ];
          console.log('---- commit stage 1: principal ledger', brwr.id, {
            payee,
            principalAmount: principalPmt,
            interestAmount: interestPmt,
          });
          await repayment(
            brwr.id,
            {
              payee,
              principalAmount: principalPmt,
              interestAmount: interestPmt,
            },
            {
              payee,
              pPmt: principalPmt, // convert to EUR ?
              iPmt: interestPmt,
            },
          );
        }
        if (userPmt) {
          const payee = `Retenue prélevée (prél. au ${day}) : Éch. secondaire`;
          console.log('---- commit stage 1: user ledger', brwr.id, {
            payee,
            amount: userPmt,
          });
          await rpmtDebt(
            brwr.id,
            {
              payee,
              amount: userPmt,
            },
            {
              payee,
              amount: userPmt, // convert to EUR ?
            },
          );
        }
        if (principalPmt || userPmt) {
          const nextData = clearDataBeforeSubmit({
            ...brwr,
            schedules: newSchedules,
          });
          console.log('---- commit stage 2: schedules', brwr.id);
          await dataProvider.update('cpretBorrower', {
            id: brwr.id,
            previousData: brwr,
            data: nextData,
          });
        }
        log(
          {
            message: 'cpret.worker.gpg',
            args: {
              tbc: 'TBC',
            },
          },
          [brwr],
        );
        console.log('done');
      };
      const action = async (): Promise<
        [string, PaymentStats, PaymentUpdate[]]
      > => {
        const { data } = await dataProvider.getList<EnhancedBorrower>(
          'cpretBorrower',
          {
            filter: {},
            pagination: { page: 0, perPage: -1 },
            sort: { field: 'id', order: 'ASC' },
          },
        );
        const gpg = data.filter(
          (r: EnhancedBorrower) =>
            r.$$paymentAmount && !r.$$paymentScheduleAlert,
        );
        console.log(gpg.length);
        const [xml, updates, stats] = borrowersToGPG(gpg);

        if (commit) {
          console.log(`Updating ${updates.length} borrowers`);
          const input = updates.slice(0, undefined).entries();
          // parallelize !?
          await Promise.all(
            [...new Array(4)].map(() =>
              (async () => {
                let next = input.next();
                while (!next.done) {
                  const {
                    value: [i, u],
                  } = next;
                  await commitBorrower(u, i, updates);
                  next = input.next();
                }
              })(),
            ),
          );
        }
        return [xml, stats, updates];
      };
      return action();
    },
    [dataProvider, repayment, rpmtDebt, log],
  );
  return cb;
};
