import React from 'react';
import { Title } from 'react-admin';
import { useAuthenticated, useTranslate } from 'ra-core';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@material-ui/core';

import LegalIcon from '@material-ui/icons/Gavel';

export const key = 'cgu';
export const Icon = LegalIcon;

const CGU: React.FC = () => {
  useAuthenticated();
  const translate = useTranslate();
  return (
    <Card>
      <Title title={translate(`${key}.title`)} />
      <CardContent>
        <Typography variant="h3">PRÉAMBULE</Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Les présentes Conditions Générales d&apos;Utilisation (ci-après les «
          CGU ») ont pour objet de déterminer les conditions d&apos;accès et
          règles d&apos;utilisation de l&apos;Application « CPRET » accessible
          par le lien suivant :{' '}
          <a href="https://cpret.sncf.fr">https://cpret.sncf.fr/</a>.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Accès à l&apos;Application est subordonné au respect des
          présentes CGU et est strictement réservé à l&apos;Utilisateur tel que
          défini ci-après.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          La Société se réserve le droit de modifier ou de mettre à jour à tout
          moment, unilatéralement, les présentes CGU au gré des changements ou
          additions effectués, afin notamment de se conformer à toutes
          évolutions légales, jurisprudentielles, éditoriales et/ou techniques
          ainsi qu'aux éventuelles modifications des conditions générales
          d&apos;utilisation des services de l&apos;Hébergeur.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L'Utilisateur doit consulter régulièrement la dernière version des CGU
          disponible en permanence sur l&apos;Application.
        </Typography>

        <Typography variant="h3">ARTICLE 1 – DÉFINITIONS</Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Au sein des CGU, les mots ou expressions commençant avec une
          majuscule, au singulier ou au pluriel, auront la signification
          suivante :
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          <strong>Application</strong> : désigne CPRET, application{' '}
          <strong>
            d&apos;instruction et de gestion des prêts et cautions
          </strong>{' '}
          accessible par le biais d&apos;un navigateur internet via l&apos;URL
          suivante : https://cpret.sncf.fr, développée pour être utilisable
          depuis des ordinateurs.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          <strong>Contribution(s)</strong> : désigne l&apos;ensemble des
          commentaires et/ou contenus que les Utilisateurs ont la possibilité de
          publier sur CPRET.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          <strong>Cookie(s)</strong> : désigne des fichiers informatiques,
          stockés sur le disque dur de l&apos;ordinateur ou de tout appareil
          mobile tel que smartphone ou tablette de l&apos;Utilisateur.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          <strong>Éditeur</strong> : désigne la société PHILEOG ELEVEN.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          <strong>Hébergeur</strong> : désigne la société AMAZON
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          <strong>Information(s)</strong> : désigne l&apos;ensemble des
          informations et publications accessibles sur l&apos;Application.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          <strong>Société</strong> : désigne la SA SNCF
        </Typography>

        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 5px 20px' }}
        >
          <strong>Utilisateur(s)</strong> : désigne les salariés du service «
          Contrôle de Gestion » de l&apos;Agence Logement de la DRH Groupe /
          Performance RH & Systèmes ayant accès à l&apos;Application CPRET quel
          que soit le lieu où il se trouve et les modalités de connexion.
        </Typography>
        <Typography variant="h3">
          ARTICLE 2 – OBJET DE L&apos;APPLICATION
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Application a pour objet{' '}
          <strong>
            l&apos;instruction des prêts d&apos;honneur et la gestion des autres
            prêts accordés par la SNCF et des cautions.
          </strong>
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 5px 20px' }}
        >
          Toute utilisation de l&apos;Application se fera dans le respect des
          présentes CGU.
        </Typography>
        <Typography variant="h3">
          ARTICLE 3 – ACCÈS ET UTILISATION DE L&apos;APPLICATION
        </Typography>

        <Typography
          variant="h5"
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          3.1 Accès à l&apos;Application
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 40px' }}
        >
          L&apos;accès à l&apos;Application est possible à partir d&apos;un
          ordinateur connecté à un réseau de télécommunication selon les
          protocoles de communication utilisés sur le réseau Internet.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 40px' }}
        >
          La Société accorde à l&apos;Utilisateur un droit de consultation,
          d&apos;utilisation et d&apos;accès aux Informations de
          l&apos;Application.
        </Typography>
        <Typography
          variant="h5"
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          3.2 Coût d&apos;accès
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 40px' }}
        >
          L&apos;accès à l&apos;Application est gratuit et ne fait pas
          l&apos;objet d&apos;un abonnement. Tous les logiciels et matériels
          nécessaires à l&apos;utilisation ou au fonctionnement de
          l&apos;Application, l&apos;accès à l&apos;Internet ou les frais de
          communication sont à la charge exclusive de l&apos;Utilisateur en
          dehors de son lieu de travail.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 40px' }}
        >
          Chaque Utilisateur est entièrement responsable de ses données de
          connexion le concernant. Sauf preuve contraire, toute connexion à
          l&apos;Application, ou transmission de données effectuées à partir des
          informations de connexion de l&apos;Utilisateur, sera réputée avoir
          été effectuée par ce dernier.
        </Typography>
        <Typography
          variant="h5"
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          3.3 Durée d&apos;accessibilité
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 40px' }}
        >
          L&apos;accès de l&apos;Utilisateur à l&apos;Application est effectué
          pour une durée illimitée, sans préjudice de la faculté pour
          l&apos;Utilisateur ou la Société de résilier unilatéralement, à tout
          moment, sans préavis, ni motif, ou indemnité.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 40px' }}
        >
          L&apos;Utilisateur reconnaît, qu&apos;en cas de violation des
          dispositions législatives et/ou des présentes CGU, la Société peut
          bloquer et/ou résilier l&apos;accès à l&apos;Application, sans
          notification préalable, avec effet immédiat.
        </Typography>
        <Typography
          variant="h5"
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          3.4 Utilisation de l&apos;Application
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 40px' }}
        >
          L&apos;Utilisateur s&apos;interdit notamment, sans que cette liste
          soit limitative :
        </Typography>
        <ul style={{ textAlign: 'justify', padding: '0px 20px 0px 60px' }}>
          <li>
            <Typography>
              d&apos;utiliser l&apos;Application sur un terminal personnel ;
            </Typography>
          </li>
          <li>
            <Typography>
              de « revendre » ou de mettre à disposition d&apos;un tiers et/ou
              des autres Utilisateurs le contenu de l&apos;Application, à titre
              onéreux et/ou gratuit ;
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h5"
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          3.5 Informations et fonctionnalités proposées
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 40px' }}
        >
          L&apos;Application offre la possibilité à l&apos;Utilisateur :
        </Typography>
        <ul style={{ textAlign: 'justify', padding: '0px 20px 5px 40px' }}>
          <li>
            <Typography>
              D&apos;instruire et gérer les prêts d&apos;honneur,
            </Typography>
          </li>
          <li>
            <Typography>De gérer les cautions,</Typography>
          </li>
          <li>
            <Typography>
              De gérer les autres prêts accordés par la SNCF.
            </Typography>
          </li>
        </ul>
        <Typography variant="h3">
          ARTICLE 4 – DISPONIBILITÉ DE L&apos;APPLICATION
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Application est accessible 24 heures sur 24, 7 jours sur 7,
          sauf cas de force majeure ou d&apos;évènement hors du contrôle de
          l&apos;Éditeur.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Éditeur se réserve le droit de suspendre, d&apos;interrompre ou
          de limiter, sans avis préalable, l&apos;accès à tout ou partie de
          l&apos;Application, notamment pour des opérations de maintenance et de
          mises à jour nécessaires au bon fonctionnement de l&apos;Application
          et des matériels afférents, ou pour toute autre raison, notamment
          technique. La Société n&apos;est pas par conséquent responsable de
          toute suspension, toute interruption ou toute limitation issue
          d&apos;une maintenance et/ou de mises à jour opérées par
          l&apos;Éditeur.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          La Société n&apos;est tenue à aucune obligation de résultat concernant
          la disponibilité de l&apos;Application. La Société n&apos;est en aucun
          cas responsable des interruptions et des conséquences qui peuvent en
          découler pour l&apos;Utilisateur notamment lorsque les interruptions
          proviennent de celles de l&apos;Éditeur pour ses besoins de
          maintenance ou autres.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 10px 20px' }}
        >
          L&apos;Utilisateur est informé que la Société peut mettre fin ou
          modifier les caractéristiques de l&apos;Application, à tout moment,
          sans préavis et sans que l&apos;Utilisateur dispose d&apos;un recours
          à l&apos;encontre de la Société.
        </Typography>

        <Typography variant="h3">
          ARTICLE 5 – HÉBERGEMENT DE L&apos;APPLICATION
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 10px 20px' }}
        >
          Les Utilisateurs sont expressément informés que les contenus publiés
          sur l&apos;application sont hébergés et stockés en France sur les
          serveurs de la société AMAZON.
        </Typography>
        <Typography variant="h3">ARTICLE 6 – COMPTE D&apos;ACCÈS</Typography>
        <Typography
          variant="h5"
          style={{ textAlign: 'justify', padding: '10px 20px 0px 20px' }}
        >
          6.1 Données fournies pour la création d&apos;un compte par la Société
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 40px' }}
        >
          Pour accéder à l&apos;Application, l&apos;Utilisateur doit disposer
          des droits d&apos;accès.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 40px' }}
        >
          Les droits sont attribués par les administrateurs du SID “CPRET”.
          Chaque Utilisateur ne peut disposer que d&apos;un seul Compte.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 40px' }}
        >
          Les comptes sont créés sur le SID “CPRET”.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 40px' }}
        >
          L&apos;Utilisateur est seul responsable de tout usage qui pourrait
          être fait de ses mots de passe et identifiants (adresse électronique
          ou autres), et, est seul garant de leur confidentialité, ainsi que de
          toute utilisation de son Compte.
        </Typography>

        <Typography
          variant="h5"
          style={{ textAlign: 'justify', padding: '10px 20px 0px 20px' }}
        >
          6.2 Sécurité
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur s&apos;engage à informer immédiatement la Société
          de toute utilisation non autorisée de son Compte, et de toute atteinte
          à la confidentialité et à la sécurité de ses moyens
          d&apos;identification en envoyant un message à :{' '}
          <a href="mailto:SIRH_Logement@sncf.fr">SIRH_Logement@sncf.fr</a>.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 10px 20px' }}
        >
          Si la Société a des motifs légitimes de penser que la sécurité de
          l&apos;Application est menacée ou que celle-ci est mal utilisée du
          fait d&apos;une utilisation non autorisée des moyens
          d&apos;identification de l&apos;Utilisateur, il pourra procéder à la
          suspension temporaire du Compte, afin notamment de préserver
          l&apos;intégrité de l&apos;Application, et, si cela apparaît
          approprié, exiger la modification de ses moyens d&apos;identification.
        </Typography>
        <Typography variant="h3">
          ARTICLE 7 – PROPRIÉTÉ INTELLECTUELLE
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          De manière générale, l&apos;Utilisateur s&apos;interdit de porter
          atteinte aux droits de propriété intellectuelle (droit d&apos;auteur,
          droits voisins, droit sui generis du producteur de bases de données,
          droit des marques, noms de domaine…) de la Société, et/ou de tout
          tiers.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          La Société est titulaire de tous les droits de propriété
          intellectuelle tant sur la structure que sur le contenu de
          l&apos;Application (textes, logos, images, éléments sonores,
          logiciels, icônes, mise en page, base de données…) ou a acquis
          régulièrement les droits permettant l&apos;exploitation de la
          structure et du contenu de l&apos;Application, sans aucune limitation.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 10px 20px' }}
        >
          Ainsi, il est interdit à l&apos;Utilisateur notamment de copier,
          reproduire, représenter, modifier et/ou exploiter, transférer de
          quelque façon que ce soit et à quelque fin que ce soit, tout ou partie
          de la structure et du contenu de l&apos;Application, sauf en cas
          d&apos;autorisation expresse, préalable et écrite de la Société si
          cela ne rentre pas dans les missions de l&apos;Utilisateur de gérer
          les prêts et cautions accordés par la SNCF et/ou si l&apos;Utilisateur
          ne dispose pas d&apos;une autorisation expresse, préalable et écrite
          de la Société.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 10px 20px' }}
        >
          Tout téléchargement est strictement interdit, sauf autorisation
          expresse de la Société et/ou sauf pour gérer les prêts et les cautions
          accordés par la SNCF. Tout téléchargement abusif qui serait constaté
          pourra entraîner, de la part de la Société et/ou de tout tiers
          concerné, la prise de sanctions qui se révéleraient appropriées.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 10px 20px' }}
        >
          Tout téléchargement de logiciel proposé par l&apos;Éditeur sur
          l&apos;Application doit respecter les conditions d&apos;utilisation de
          l&apos;Éditeur. L&apos;Utilisateur doit s&apos;adresser à la Société,
          avant tout téléchargement, afin de savoir si ce logiciel figure parmi
          les services souscrits par la Société auprès de l&apos;Éditeur.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 10px 20px' }}
        >
          Les conséquences des atteintes provenant de tout téléchargement
          effectué par l&apos;Utilisateur sans avoir informé la Société et/ou
          contrevenant aux conditions d&apos;utilisation de l&apos;Éditeur sont
          entièrement à la charge de l&apos;Utilisateur.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 10px 20px' }}
        >
          Les marques et logos reproduits sur l&apos;Application sont déposés
          auprès des Offices compétents par les sociétés qui en sont titulaires.
          Toute reproduction des noms ou logos, par quelque moyen que ce soit,
          sans autorisation préalable du titulaire concerné, est interdite.
        </Typography>

        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 10px 20px' }}
        >
          Le non-respect de ces interdictions peut notamment constituer un acte
          de contrefaçon et/ou de concurrence déloyale et parasitaire engageant
          la responsabilité civile et/ou pénale de l&apos;Utilisateur.
        </Typography>

        <Typography variant="h3">ARTICLE 8 – DONNÉES PERSONNELLES</Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Les Utilisateurs sont informés que les données à caractère personnel
          suivantes sont traitées dans le cadre de l&apos;Application :
          l&apos;immatriculation SNCF.
        </Typography>

        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Ces données à caractère personnel sont traitées sous la responsabilité
          de la Direction Ressources Humaines-Logement de la SA SNCF,
          Responsable de traitement.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          SA SNCF a désigné un délégué à la protection des données dont les
          coordonnées sont :{' '}
          <a href="mailto:dpo-sncf@sncf.fr">dpo-sncf@sncf.fr</a>
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Ces données personnelles ont un caractère obligatoire pour disposer
          d&apos;un Compte et utiliser l&apos;Application. En conséquence, la
          Société sera dans l&apos;obligation de supprimer le Compte des
          Utilisateurs s&apos;opposant à leur traitement.
        </Typography>

        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Le Responsable de traitement collecte ces données à caractère
          personnel en vue de tracer les connexions et les actions des
          utilisateurs. Le traitement a pour base légale : l&apos;intérêt
          légitime du responsable du traitement (amélioration de la qualité de
          vie des employés au sein la Société)
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Les données à caractère personnel ne seront conservées que pour une
          durée nécessaire à la réalisation des finalités énumérées ci-dessus,
          soit pendant une durée de :
        </Typography>
        <ul style={{ textAlign: 'justify' }}>
          <Typography>
            <li>6 mois après le complet remboursement d&apos;un prêt,</li>
          </Typography>
          <Typography>
            <li>5 ans après le complet remboursement d&apos;une caution.</li>
          </Typography>
        </ul>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          En tout état de cause, les données récoltées ne font l&apos;objet
          d&apos;aucune communication ou cession à des tiers à l&apos;intérieur
          ou en dehors de l&apos;Union Européenne. Elles conservent un caractère
          strictement confidentiel.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Conformément à la loi n°78-17 du 6 janvier 1978 et au règlement
          2016/679 du Parlement Européen et du Conseil du 27 avril 2016, les
          Utilisateurs disposent du droit de demander à la Société l&apos;accès
          à leurs données, la rectification ou l&apos;effacement de celles-ci ou
          une limitation du traitement, ainsi que du droit de s&apos;opposer au
          traitement et du droit de retirer son consentement au traitement, du
          droit d&apos;introduire une réclamation auprès d&apos;une autorité de
          contrôle, du droit de définir les conditions d&apos;utilisation, de
          conservation et de communication de ses données personnelles en cas de
          décès.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          A ce titre, toute personne justifiant de son identité pourra exercer
          ces droits en adressant sa demande par courriel :{' '}
          <a href="mailto:donneespersonnelleslogement@sncf.fr">
            donneespersonnelleslogement@sncf.fr
          </a>
          .
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Si vous considérez par ailleurs que le traitement vous concernant
          constitue une violation de la règlementation, vous disposez du droit
          d&apos;introduire une réclamation auprès de la CNIL ou de
          l&apos;autorité de contrôle de l&apos;État où se trouve votre
          résidence habituelle, votre lieu de travail, ou le lieu où la
          violation aurait été commis.
        </Typography>

        <Typography variant="h3">ARTICLE 9 – COOKIES</Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Un cookie est un fichier de petite taille, qui ne permet pas
          l&apos;identification de l&apos;Utilisateur ni de lire les
          informations contenues dans les fichiers de son ordinateur, mais qui
          enregistre des informations relatives à la navigation d&apos;un
          appareil électronique sur l&apos;Application. Les données ainsi
          obtenues visent par exemple à faciliter la navigation ultérieure sur
          l&apos;Application, proposer des services personnalisés, améliorer les
          performances des contenus de l&apos;Application et permettre diverses
          mesures de fréquentation.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Lors de la consultation de l&apos;Application un seul type de Cookies
          est déposé sur l&apos;ordinateur de l&apos;Utilisateur : - Cookie de
          session déposé par l&apos;Éditeur, dont la durée de conservation est
          équivalente à la durée de la session dont le nom est «
          https://cpret.sncf.fr/ ». Ce cookie est nécessaire pour s&apos;assurer
          que toutes les demandes de pages de l&apos;Utilisateur sont envoyées
          sur le même serveur.
        </Typography>
        <Typography variant="h3">
          ARTICLE 10 – GARANTIES/RESPONSABILITÉ
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur s&apos;engage à accomplir toutes les diligences
          nécessaires à la bonne sécurité de son compte, à respecter les règles
          de sécurité énoncées dans le référentiel RG00044 relatif à la charte
          de l&apos;Utilisateur des Systèmes d&apos;Information SNCF. De manière
          générale l&apos;Utilisateur s&apos;engage également à respecter les
          règles et usages internes relatifs à l&apos;utilisation des systèmes
          d&apos;information et réseaux du groupe SNCF.
        </Typography>

        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur s&apos;engage, lors de l&apos;utilisation
          qu&apos;il fait de l&apos;Application, à ne pas contrevenir aux
          dispositions législatives et réglementaires en vigueur, aux bons
          usages de l&apos;Intranet et de la netiquette, et aux présentes CGU.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur est informé que toute utilisation de
          l&apos;Application en violation des dispositions des CGU est
          susceptible d&apos;engager sa responsabilité et d&apos;entraîner des
          poursuites judiciaires et des sanctions à son encontre.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur est responsable envers la Société et/ou les tiers
          de tout préjudice matériel et/ou immatériel, direct et/ou indirect de
          quelque nature que ce soit causé par l&apos;Utilisateur, et/ou ses
          préposés en cas d&apos;utilisation non-conforme de l&apos;Application.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur les tiendra informés, à tout moment et à première
          demande, contre tout dommage et/ou contre toute demande, action,
          plainte émanant de tiers résultant de toute violation desdites
          dispositions.{' '}
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur garantit la Société, ainsi que ses sociétés mères,
          sœurs ou affiliées, ses représentants, ses salariés, ses partenaires,
          contre toute demande, réclamation, revendication et/ou recours de
          toute sorte, résultant de toute violation desdites dispositions.
        </Typography>

        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Cette garantie couvre tant les dommages et intérêts qui seraient
          éventuellement versés, quelle que soit leur origine directe ou
          indirecte, que les honoraires d&apos;avocat, frais d&apos;expertise,
          frais de justice...
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Utilisateur s&apos;engage à agir avec diligence pour répondre à
          toute réclamation.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          L&apos;Éditeur s&apos;engage à prendre toutes les mesures techniques
          et d&apos;organisation nécessaires afin de protéger les Informations
          circulant sur l&apos;Application et de garantir la sécurité des
          données personnelles qu&apos;il a collectées et éviter leur
          divulgation à des tiers non autorisés.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Il appartient toutefois à l&apos;Utilisateur de mettre en œuvre tous
          moyens utiles aux fins de préserver la confidentialité des
          Informations transmises et notamment de les assigner à des espaces
          privés.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Chaque Utilisateur de l&apos;Application s&apos;engage expressément :
        </Typography>
        <ul style={{ textAlign: 'justify', padding: '0px 20px 0px 60px' }}>
          <li>
            <Typography>
              à ne pas utiliser de logiciels ou de procédés destinés à copier
              les Informations de l&apos;Application sans l&apos;autorisation
              expresse et préalable de l&apos;Éditeur ;
            </Typography>
          </li>
          <li>
            <Typography>
              à renoncer expressément à utiliser des logiciels ou dispositifs
              susceptibles de perturber le bon fonctionnement de
              l&apos;Application, ni à engager d&apos;action de nature à imposer
              une charge disproportionnée pour les infrastructures de
              l&apos;Éditeur ;
            </Typography>
          </li>
          <li>
            <Typography>
              à ne pas altérer ou modifier voire créer des œuvres dérivées à
              partir des Informations de l&apos;Application sans le consentement
              exprès et préalable de l&apos;Éditeur ;
            </Typography>
          </li>
          <li>
            <Typography>
              à ne pas extraire ou réutiliser, y compris à des fins privées,
              sans autorisation écrite et préalable de l&apos;Éditeur, une
              partie substantielle ou non du contenu des bases de données et
              archives constituées par l&apos;Application ;
            </Typography>
          </li>
          <li>
            <Typography>
              à ne pas collecter des informations sur des tiers, y compris les
              adresses électroniques, afin de les utiliser pour l&apos;envoi de
              sollicitations commerciales ou équivalentes, ou de les intégrer au
              sein d&apos;un service de référencement ou équivalent, gratuit ou
              payant, ou encore afin d&apos;effectuer une veille concurrentielle
              ;
            </Typography>
          </li>
          <li>
            <Typography>
              à ne pas mettre en place des systèmes susceptibles ou de nature à
              pirater l&apos;Application en tout ou partie, ou de nature à
              violer les présentes CGU ;
            </Typography>
          </li>
          <li>
            <Typography>
              à informer la Société dès la connaissance d&apos;une atteinte
              quelle qu&apos;elle soit (notamment aux droits de propriété
              intellectuelle de l&apos;Éditeur) en particulier de toute
              utilisation illicite ou non contractuelle des Informations de
              l&apos;Application et ce, quel que soit le mode de diffusion
              utilisé.
            </Typography>
          </li>
        </ul>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          La Société se dégage de toute responsabilité en cas d&apos;utilisation
          non conforme aux CGU de ses services.
        </Typography>

        <Typography variant="h3">ARTICLE 11 – CONTACT</Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Pour toute question relative au fonctionnement de l&apos;Application
          et aux CGU, vous pouvez vous adresser à :{' '}
          <a href="mailto:SIRH_Logement@sncf.fr">SIRH_Logement@sncf.fr</a>
        </Typography>

        <Typography variant="h3">
          ARTICLE 12 – NULLITE D&apos;UNE CLAUSE
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          La nullité en tout ou partie d&apos;une ou de plusieurs dispositions
          des présentes CGU, aux termes d&apos;une disposition légale ou
          réglementaire ou d&apos;une décision de justice devenue définitive,
          n&apos;entraîne pas la nullité des autres dispositions ou de la partie
          de la disposition non entachées de nullité.
        </Typography>

        <Typography variant="h3">
          ARTICLE 13 – DROIT APPLICABLE – RÈGLEMENT DES LITIGES
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Les présentes CGU sont soumises au droit français.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          En cas de différends relatifs à l&apos;interprétation, la validité ou
          l&apos;exécution des présentes CGU, l&apos;Utilisateur et
          l&apos;Éditeur conviennent de déployer leurs meilleurs efforts afin de
          régler à l&apos;amiable le litige.
        </Typography>
        <Typography
          style={{ textAlign: 'justify', padding: '0px 20px 0px 20px' }}
        >
          Dans le cas où un accord amiable ne serait pas trouvé, tout éventuel
          litige se rapportant à l&apos;interprétation ou l&apos;exécution des
          présentes CGU sera soumis à la compétence des tribunaux de Paris.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CGU;
