import React, { useCallback } from 'react';
import { SimpleForm } from 'react-admin';
import { ReduxState, Record } from 'ra-core';
import { useSelector } from 'react-redux';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

// Cycle needed because a connection edition lives within a record edition
import generateAllResourceInputs, { // eslint-disable-line import/no-cycle
  GenerateInputsOptions,
} from '../generateInputs';
import CreateToolbar from '../../Toolbars/CreateToolbar';

import { useTranslateIf } from '../../../hooks/use-translate-if';
import { ResourceKey } from '../../../project/projectInfos';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    dialogContent: {
      padding: 0,
    },
  }),
);

type ConnectionCreateDialogProps = {
  resource: ResourceKey;
  onSave: (record: Record) => void;
  onStandaloneSuccess?: (response: { data: Record }) => void;
  onClose: () => void;
  generateInputsOptions?: GenerateInputsOptions;
};

const ConnectionCreateDialog: React.FC<ConnectionCreateDialogProps> = ({
  resource,
  onSave,
  onStandaloneSuccess,
  onClose,
  generateInputsOptions,
}: ConnectionCreateDialogProps) => {
  const classes = useStyles();
  const translate = useTranslateIf();
  const submitting = useSelector<ReduxState, boolean>(
    state => state.admin.loading > 0,
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog open fullWidth maxWidth="xl" onClose={handleClose}>
      <DialogTitle>
        {`${translate(`ra.action.create`)} ${translate(
          `resources.${resource}.name`,
          1,
        )}`}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <SimpleForm
          save={onSave}
          saving={submitting}
          redirect={false}
          toolbar={
            <CreateToolbar
              onCancel={handleClose}
              onStandaloneSuccess={onStandaloneSuccess}
            />
          }
          resource={resource}
          submitOnEnter={false}
        >
          {generateAllResourceInputs(resource, true, generateInputsOptions)}
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};

export default ConnectionCreateDialog;
