export enum WFState {
  UNDEFINED = 'UNDEFINED',
  GESTION = 'GESTION',
  GARANTIE = 'GARANTIE',
  PAIEMENT = 'PAIEMENT',
  RECOUVREMENT = 'RECOUVREMENT',
  CLOTURE = 'CLOTURE',
}

export enum WFAction  {
  /** Appel en Garantie */
  GARANTIE = 'GARANTIE',
  /** Passage en paiement */
  PAIEMENT = 'PAIEMENT',
  /** Passage en recouvrement */
  RECOUVREMENT = 'RECOUVREMENT',
  /** Cloture */
  CLOTURE = 'CLOTURE',
  /** Avenant */
  AVENANT = 'AVENANT',
  /** Remboursement anticipé */
  ANTICIPE = 'ANTICIPE',
  /** Retour gestion */
  GESTION = 'GESTION',
}

export const ACTION_LABEL: { [k in WFAction]: string } = {
  [WFAction.GARANTIE]: 'APPEL EN GARANTIE',
  [WFAction.CLOTURE]: 'CLOTURE',
  [WFAction.AVENANT]: 'AVENANT',
  [WFAction.ANTICIPE]: 'REMBOURSEMENT ANTICIPE',
  [WFAction.PAIEMENT]: 'PAIEMENT',
  [WFAction.RECOUVREMENT]: 'RECOUVREMENT',
  [WFAction.GESTION]: 'GESTION',
};
