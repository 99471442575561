import React, { useCallback, useState } from 'react';
import { useDataProvider, useNotify, Record, FilterPayload } from 'ra-core';

import { Button } from '@material-ui/core';

// Cycle needed because a connection edition lives within a record edition
import ConnectionCreateDialog from '../Inputs/ConnectionInput/ConnectionCreateDialog'; // eslint-disable-line import/no-cycle

import { useTranslateIf } from '../../hooks/use-translate-if';
import { ClassName } from '../../types/styles';

import { ResourceKey } from '../../project/projectInfos';

type AssetCreateButtonProps = {
  resource: ResourceKey;
  onCreate?: (record: Record) => void;
  filter?: FilterPayload;
  className?: ClassName;
};

const AssetCreateButton: React.FC<AssetCreateButtonProps> = ({
  resource,
  onCreate,
  filter,
  className,
}: AssetCreateButtonProps) => {
  const translate = useTranslateIf();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [showDialog, setShowDialog] = useState(false);
  const handleOpen = useCallback(
    event => {
      event.preventDefault();
      setShowDialog(true);
    },
    [setShowDialog],
  );
  const handleClose = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  const onSuccess = useCallback(
    (response: { data: Record }) => {
      if (onCreate) {
        onCreate(response.data);
      }
      handleClose();
      notify('ra.notification.created', 'info');
    },
    [handleClose, notify, onCreate],
  );

  const handleSave = useCallback(
    values => {
      dataProvider
        .create(resource, {
          data: values,
        })
        .then(response => {
          onSuccess(response);
        });
    },
    [dataProvider, resource, onSuccess],
  );

  return (
    <>
      <Button onClick={handleOpen} className={className} variant="outlined">
        {translate(`ra.action.create`)}
      </Button>
      {showDialog && (
        <ConnectionCreateDialog
          resource={resource}
          onSave={handleSave}
          onStandaloneSuccess={onSuccess}
          onClose={handleClose}
          generateInputsOptions={{ asset: { filter } }}
        />
      )}
    </>
  );
};

export default AssetCreateButton;
