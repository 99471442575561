import * as React from 'react';
import { NumberField, useRecordContext } from 'react-admin';

type Props = {
  label?: string; // eslint-disable-line react/no-unused-prop-types
  source: string;
};

export const HideZeroNumberField: React.FC<Props> = props => {
  const { source } = props;
  const record: any = useRecordContext();
  const { [source]: value } = record || {};
  return <>{value ? <NumberField {...props} /> : null}</>;
};
