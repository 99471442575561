import { format } from 'date-fns';
import { get } from 'lodash';
import React from 'react';
import { FunctionField } from 'react-admin';

import { Schedule, SchedulePayment } from '../../../../types/schema';

export interface LastPaymentDueDateProps {
  // eslint-disable-next-line react/no-unused-prop-types
  label: string;
}
export const LastPaymentDueDate: React.FC<LastPaymentDueDateProps> = () => {
  const render = React.useCallback((record?: Schedule) => {
    const payments = get(record, ['payments'], null) as
      | SchedulePayment[]
      | null;

    const lastPayment = payments ? payments[payments.length - 1] : null;

    if (!lastPayment) {
      return '';
    }

    return format(new Date(lastPayment.dueDate), 'MM/yyyy');
  }, []) as any;

  return <FunctionField render={render} />;
};
