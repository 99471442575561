import React from 'react';
import { useNotify } from 'react-admin';
import { FORM_ERROR } from 'final-form';

import { WFAction as GuaranteeActionType } from '../../../workflow/guarantee-enum';
import { DissociationValues } from './fields/DissociationGuaranteeActionFields';
import { TransferValues } from './fields/TransferGuaranteeActionFields';
import { CallValues } from './fields/CallGuaranteeActionFields';
import { DenunciationValues } from './fields/DenunciationGuaranteeActionFields';
import { Values } from './types';
import { Guarantee } from '../../../types/schema';
import {
  useGuaranteeDissociation,
  useGuaranteeTransfer,
  useGuaranteeCall,
  useGuaranteeDenunciation,
} from '../../../workflow/guarantee';

export function useOnSubmit(guarantee: Guarantee, onSuccess: () => void) {
  const dissociateGuarantee = useGuaranteeDissociation();
  const transferGuarantee = useGuaranteeTransfer();
  const callGuarantee = useGuaranteeCall();
  const denounceGuarantee = useGuaranteeDenunciation();
  const notify = useNotify();

  const commit = React.useCallback(
    async (actionType: GuaranteeActionType, callback: () => Promise<any>) => {
      try {
        await callback();
      } catch (error: any) {
        notify('cpret.action.error', 'error', {
          _: `Une erreur est survenue, l'action n'a pas été exécutée : %{error}`,
          action: actionType,
          error: error.message,
        });
        return { [FORM_ERROR]: `Une erreur est survenue: ${error.message}` };
      }
      notify('cpret.action.success', 'success', {
        _: `L'action a bien été exécutée'`, // default translation - avoids console errors
        action: actionType,
      });
      onSuccess();
      return null;
    },
    [notify, onSuccess],
  );

  const onSubmit = React.useCallback(
    async (values: Values) => {
      const { actionType, ...restValues } = values;
      switch (actionType) {
        case GuaranteeActionType.DISSOCIATION: {
          const { reason: message, dissociationDate } =
            restValues as DissociationValues;
          return commit(actionType, () =>
            dissociateGuarantee(guarantee, {
              message: message.replace(/\//g, '-'),
              date: dissociationDate,
            }),
          );
        }

        case GuaranteeActionType.TRANSFER: {
          const { reason: message } = restValues as TransferValues;
          return commit(actionType, () =>
            transferGuarantee(guarantee, {
              message: message.replace(/\//g, '-'),
            }),
          );
        }

        case GuaranteeActionType.CALL: {
          const {
            reason: message,
            incident,
            amount,
          } = restValues as CallValues;
          return commit(actionType, () =>
            callGuarantee(guarantee, {
              message: message.replace(/\//g, '-'),
              incident: incident.replace(/\//g, '-'),
              amount,
            }),
          );
        }

        case GuaranteeActionType.DENUNCIATION: {
          const {
            confirmation: confirm,
            reason: message,
            denunciationDate: date,
          } = restValues as DenunciationValues;
          return commit(actionType, () =>
            denounceGuarantee(guarantee, confirm, {
              message: message.replace(/\//g, '-'),
              date,
              confirm,
            }),
          );
        }

        default:
          throw new Error(`Unsupported action type ${values.actionType}`);
      }
    },
    [
      commit,
      dissociateGuarantee,
      guarantee,
      transferGuarantee,
      callGuarantee,
      denounceGuarantee,
    ],
  );

  return onSubmit;
}
