import * as React from 'react';
import {
  ArrayInput,
  SimpleFormIterator,
  // DateField,
  // TextField,
  DateInput,
  useRecordContext,
  // FormDataConsumer,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
} from 'react-admin';

import { GuarantyLoan } from '../../types/schema';
import {
  WFAction as GuarantyAction,
  ACTION_LABEL as GuarantyLabels,
} from '../../workflow/guaranty-loans';

type ActionScheduleProps = {
  type: 'GuarantyLoan';
};

// taken from generateInput.tsx - improve ?
const inputOrNull = (input: any) => input || null;

export const ActionSchedule: React.FC<ActionScheduleProps> = props => {
  const { type } = props;
  const record: GuarantyLoan = useRecordContext();

  const [labels, actions] = React.useMemo(() => {
    let memoLabels: { [k: string]: string } | undefined;

    let memoActions: typeof GuarantyAction;

    if (type === 'GuarantyLoan') {
      memoActions = GuarantyAction;
      memoLabels = GuarantyLabels;
    } else {
      throw new Error('Other types not supported for now');
    }
    return [memoLabels, memoActions];
  }, [type]);

  const actionChoices = React.useMemo(() => {
    return (
      Object.keys(actions)
        // we only support CLOTURE for now
        .filter(a => a === GuarantyAction.CLOTURE)
        .map(a => ({
          id: a,
          name: labels ? labels[a] : a,
        }))
    );
  }, [labels, actions]);

  return (
    <ArrayInput source="actionSchedule" record={record} label="">
      <SimpleFormIterator /* disableRemove disableAdd */>
        <FormDataConsumer>
          {({ getSource, scopedFormData }) => {
            const source = getSource?.('wfAction');
            if (source)
              return (
                <SelectInput
                  source={source}
                  label="Action"
                  choices={actionChoices}
                  disabled={scopedFormData?.done}
                />
              );
            return null;
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ getSource, scopedFormData }) => {
            const source = getSource?.('date');
            if (source)
              return (
                <DateInput
                  source={source}
                  label="Date"
                  parse={inputOrNull}
                  format={inputOrNull}
                  disabled={scopedFormData?.done}
                />
              );
            return null;
          }}
        </FormDataConsumer>
        {/* <TextInput source="args" label="Props" /> */}
        <BooleanInput
          source="done"
          label="Done" /* empty string forces null in FieldTitle */
          helperText={false}
          disabled
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
