import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useFormState } from 'react-final-form';

import { centsToEur } from '../../lib/utils';
import { PostingsDialog } from './PostingsDialog';
import {
  getAccountBalance,
  getDigestBalance,
  getAccountPostingsWithExtras,
  PostingWithLedger,
  currencyOptions as options,
} from '../../lib/ledger';
import { EnhancedBorrower } from '../../types/schema-custom';
import { Ledger } from '../../types/schema';
import { LedgerAccount } from '../../dataProvider/type';

interface Row {
  name: string;
  balance: [number, number, number];
  postings: PostingWithLedger[];
}

const useStyles = makeStyles(() =>
  createStyles({
    ledgerRow: {
      cursor: 'pointer',
    },
    credit: {
      color: 'red',
    },
    debit: {
      color: 'green',
    },
    loader: {
      width: '100%',
      flexGrow: 0,
    },
  }),
);

type LedgerProps = {
  ledgers: Ledger[];
  loading?: boolean;
  locales?: string | string[];
};

export const Ledgers: React.FC<LedgerProps> = ({
  locales = "fr-FR",
  ledgers,
  loading = false,
}) => {
  const [openRowIndex, setOpenRowIndex] = React.useState<number | null>(null);
  const { values } = useFormState();
  const classes = useStyles();
  const brwr = values as EnhancedBorrower;
  const { loanType } = brwr;

  const { rows, warning } = React.useMemo<{
    rows: Row[];
    warning: boolean;
  }>(() => {
    const interestLedgerBalance = getAccountBalance(
      ledgers,
      LedgerAccount.INTEREST_ACCOUNT,
    );
    const interestDigestBalance = getDigestBalance(
      brwr.$$ledgerDigest,
      LedgerAccount.INTEREST_ACCOUNT,
    );
    const interestBalance = ledgers.length
      ? interestLedgerBalance
      : interestDigestBalance;

    const userLedgerBalance = getAccountBalance(
      ledgers,
      LedgerAccount.USER_ACCOUNT,
    );
    const userDigestBalance = getDigestBalance(
      brwr.$$ledgerDigest,
      LedgerAccount.USER_ACCOUNT,
    );
    const userBalance = ledgers.length ? userLedgerBalance : userDigestBalance;

    const principalLedgerBalance = getAccountBalance(
      ledgers,
      LedgerAccount.PRINCIPAL_ACCOUNT,
    );
    const principalDigestBalance = getDigestBalance(
      brwr.$$ledgerDigest,
      LedgerAccount.PRINCIPAL_ACCOUNT,
    );
    const principalBalance = ledgers.length
      ? principalLedgerBalance
      : principalDigestBalance;

    // sanity check - TODO improve
    const mismatch =
      userDigestBalance[2] &&
      ledgers.length &&
      userLedgerBalance[2] !== userDigestBalance[2];

    return {
      rows: [
        {
          name: 'Compte Prêt (274)',
          balance: principalBalance,
          postings: getAccountPostingsWithExtras(
            ledgers,
            LedgerAccount.PRINCIPAL_ACCOUNT,
            locales,
          ),
        },
        {
          name: 'Compte Agent (467)',
          balance: userBalance,
          postings: getAccountPostingsWithExtras(
            ledgers,
            LedgerAccount.USER_ACCOUNT,
            locales,
          ),
        },
        loanType !== 'HONNEUR' &&
          interestBalance[2] !== 0 && {
            name: 'Compte Intérêts (762)',
            balance: interestBalance,
            postings: getAccountPostingsWithExtras(
              ledgers,
              LedgerAccount.INTEREST_ACCOUNT,
              locales,
            ),
          },
      ].filter(Boolean) as Row[],
      warning: !!mismatch,
    };
  }, [brwr, ledgers, loanType, locales]);

  const openRow = React.useCallback(index => setOpenRowIndex(index), []);
  const close = React.useCallback(() => setOpenRowIndex(null), []);

  return (
    <>
      {openRowIndex !== null && (
        <PostingsDialog
          postings={rows[openRowIndex].postings}
          locales={locales}
          onClose={close}
          title={rows[openRowIndex].name}
        />
      )}
      {loading && (
        <div className={classes.loader}>
          <LinearProgress />
        </div>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Libellé</TableCell>
              <TableCell align="right">Débits</TableCell>
              <TableCell align="right">Crédits</TableCell>
              <TableCell align="right">Solde{warning ? ' ⚠️' : ''}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(({ name, balance: [deb, cred, bal] }, index) => (
              <TableRow
                hover
                key={name}
                onClick={() => openRow(index)}
                className={classes.ledgerRow}
              >
                <TableCell component="th" scope="row">
                  {name}
                </TableCell>
                <TableCell align="right" className={classes.debit}>
                  {!!deb && centsToEur(deb).toLocaleString(locales, options)}
                </TableCell>
                <TableCell align="right" className={classes.credit}>
                  {!!cred && centsToEur(-cred).toLocaleString(locales, options)}
                </TableCell>
                <TableCell
                  align="right"
                  className={bal < 0 ? classes.credit : classes.debit}
                >
                  {centsToEur(Math.abs(bal)).toLocaleString(locales, options)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
