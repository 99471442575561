import React from 'react';
import { sanitizeInputRestProps, Labeled } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'clsx';

import { AssetDropzone, AssetDropzoneProps } from './AssetDropzone';
import { AssetsField, AssetsFieldProps } from './AssetsField';
import { useCanAccess } from '../../../hooks/use-can-access';

const useStyles = makeStyles(
  () => ({
    root: { width: '100%' },
  }),
  { name: 'RaFileInput' },
);

export interface AssetsProps extends AssetsFieldProps, AssetDropzoneProps {}

export const Assets: React.FC<AssetsProps> = props => {
  const {
    children,
    className,
    label,
    resource,
    source,
    displayNamespaceSelectOnMailFile,
    record,
    ...otherProps
  } = props;
  const classes = useStyles(props);

  const { canWrite } = useCanAccess();

  if (!record || !source) return null;

  return (
    <Labeled
      label={label}
      className={classnames(classes.root, className)}
      source={source}
      resource={resource}
      {...sanitizeInputRestProps(otherProps)}
    >
      <>
        {canWrite && (
          <AssetDropzone
            record={record as any}
            resource={resource}
            source={source}
            displayNamespaceSelectOnMailFile={displayNamespaceSelectOnMailFile}
            fullWidth
          />
        )}
        <AssetsField
          {...otherProps}
          record={record as any}
          resource={resource}
          source={source}
        />
      </>
    </Labeled>
  );
};
