import React from 'react';
import { DataProviderContext } from 'react-admin';
import gql from 'graphql-tag';
import _ from 'lodash';

import { CustomDataProvider } from '../dataProvider/type';
import { History, HistoryConnection, PageInfo } from '../types/schema';

const PageInfoFragment = gql`
  fragment PageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;
const CpretHistoryFieldsBaseFragment = gql`
  fragment CpretHistoryFieldsBase on History {
    id
    message
    args
    identity # author TBC
    createdAt
  }
`;

export type HistoryFetcher = (
  after?: string,
  pageSize?: number,
) => Promise<{ nodes: History[] | null; pageInfo: PageInfo }>;

export function useResourceHistory<T extends { history: HistoryConnection }>(
  resource: string,
  id: string,
): HistoryFetcher {
  const dataProvider = React.useContext(
    DataProviderContext,
  ) as CustomDataProvider;
  const client = dataProvider.getClient();

  const rsrc = _.camelCase(resource);

  const query = React.useMemo(
    () => gql`
      query getHistory($id: ID!, $after: ID, $first: Int) {
        ${rsrc}(id: $id) {
          # id # not requiring id prevents apollo from caching this query, yay!
          history(after: $after, first: $first) {
            edges {
              node {
                ...CpretHistoryFieldsBase
              }
            }
            pageInfo {
              ...PageInfo
            }
          }
        }
      }
      ${PageInfoFragment}
      ${CpretHistoryFieldsBaseFragment}
    `,
    [rsrc],
  );

  const fetchHistory = React.useCallback(
    async (after?: string, pageSize = 100) => {
      const { data } = await client.query({
        query,
        variables: { id, after, first: pageSize },
        fetchPolicy: 'no-cache',
      });

      const { history } = data[rsrc] as T;
      const nodes = history.edges.map(edge => edge.node as History);
      return { nodes, pageInfo: history.pageInfo };
    },
    [client, query, id, rsrc],
  );

  return fetchHistory;
}
