import React from 'react';
import clsx from 'clsx';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ClassName } from '../../../types/styles';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    wrapper: {
      position: 'relative',
      display: 'flex',
      flexFlow: 'column',
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3, 2),
    },
  }),
);

type ComponentsWrapperProps = React.PropsWithChildren<{
  className?: ClassName;
}>;

const ComponentsWrapper: React.FC<ComponentsWrapperProps> = ({
  children,
  className,
}: ComponentsWrapperProps) => {
  const classes = useStyles();

  return <div className={clsx(classes.wrapper, className)}>{children}</div>;
};

export default ComponentsWrapper;
