import React from 'react';
import { Create, EditProps, Record, SimpleForm } from 'react-admin';

import { Borrower } from '../../types/schema';
import { BorrowerForm } from './BorrowerForm';
import { clearDataBeforeSubmit } from '../../dataProvider/utils';
import { Breadcrumb } from '../../components/Breadcrumb';

export interface BorrowerCreateTitleProps {
  record?: Borrower;
}

export const BorrowerCreateTitle: React.FC<BorrowerCreateTitleProps> = ({
  record,
}) => {
  const fragments = React.useMemo(
    () =>
      [
        {
          title: 'Demandeur',
          typename: 'Owner',
          id: record?.owner.edges[0]?.node.id,
        },
        {
          title: `Créer un bénéficiaire`,
          typename: 'Borrower',
        },
      ].filter(Boolean),
    [record],
  );

  return <Breadcrumb fragments={fragments} />;
};

export const BorrowerCreate: React.FC<EditProps> = props => {
  return (
    <Create
      {...props}
      title={<BorrowerCreateTitle />}
      transform={(data: Record): Record => clearDataBeforeSubmit(data)}
    >
      <SimpleForm redirect="edit" submitOnEnter={false}>
        <BorrowerForm kind="create" />
      </SimpleForm>
    </Create>
  );
};
