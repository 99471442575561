import { get } from 'lodash';
// import moment from 'moment';
import { parse, addYears, format } from 'date-fns';
import { Node, Introspection } from 'phicomas-client';
import { Identifier } from 'ra-core';

import { getDigestBalance } from '../lib/ledger';
import { LedgerAccount, LedgerDigest } from './type';
import {
  Borrower,
  BorrowerEdge,
  Guarantee,
  GuarantyLoan,
  Loan,
  LoanEdge,
  LoanType,
  Owner,
} from '../types/schema';
import { allActivePayments } from '../workflow/lib/gpg';
import { BorrowerExtras } from '../types/schema-custom';
import { WFState as LoanWFState } from '../workflow/loans';
import { WFState as GuarantyWFState } from '../workflow/guaranty-loans';

const FIRST_REMINDER_AFTER = 5;
const SECOND_REMINDER_AFTER = 10;
const ALERT_REMINDER_AFTER = 10; // 11 ?

type FilterFieldTypes = { [k: string]: Partial<Introspection.Field> };

export const customFilterFieldTypes: { [r: string]: FilterFieldTypes } = {
  Owner: {
    $$workStatus: {
      name: '$$workStatus',
      type: { kind: Introspection.Kind.Enum, name: 'String', ofType: null },
    },
    $$honneurLoanCount: {
      name: '$$honneurLoanCount',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$honneurLoanCount_gt: {
      name: '$$honneurLoanCount_gt',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$otherLoanCount: {
      name: '$$otherLoanCount',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$otherLoanCount_gt: {
      name: '$$otherLoanCount_gt',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$guaranteeCount: {
      name: '$$guaranteeCount',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$guaranteeCount_gt: {
      name: '$$guaranteeCount_gt',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$cpretActive: {
      name: '$$cpretActive',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$cpretActive_gt: {
      name: '$$cpretActive_gt',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$surrendettement: {
      name: '$$surrendettement',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$surrendettement_gt: {
      name: '$$surrendettement_gt',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$contentieux: {
      name: '$$contentieux',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$contentieux_gt: {
      name: '$$contentieux_gt',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$impaye: {
      name: '$$impaye',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$impaye_gt: {
      name: '$$impaye_gt',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$retractation: {
      name: '$$retractation',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$retractation_gt: {
      name: '$$retractation_gt',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    // $$guarantiesActive: {
    //   name: '$$guarantiesActive',
    //   type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    // },
    $$guarantiesCalled: {
      name: '$$guarantiesCalled',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$guarantiesCalled_gt: {
      name: '$$guarantiesCalled_gt',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$ownerCessation: {
      name: '$$ownerCessation',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$ownerCessation_gt: {
      name: '$$ownerCessation_gt',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$cpretType: {
      name: '$$cpretType',
      type: { kind: Introspection.Kind.Enum, name: 'String', ofType: null },
    },
  },
  Borrower: {
    $$rappel: {
      name: '$$rappel',
      type: { kind: Introspection.Kind.Enum, name: 'Int', ofType: null },
    },
    $$restantDu: {
      name: '$$restantDu',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$impaye: {
      name: '$$impaye',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$loanCount: {
      name: '$$loanCount',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$loanAmount: {
      name: '$$loanAmount',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$loanLastSignature: {
      name: '$$loanLastSignature',
      type: { kind: Introspection.Kind.Scalar, name: 'String', ofType: null },
    },
    $$paymentAmount: {
      name: '$$paymentAmount',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$paymentAmount_lt: {
      name: '$$paymentAmount_lt',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$paymentScheduleAlert: {
      name: '$$paymentScheduleAlert',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$scheduleAlert: {
      name: '$$scheduleAlert',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$userCreditor: {
      name: '$$userCreditor',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$userCreditor_lt: {
      name: '$$userCreditor_lt',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
  },
  Loan: {
    $$reminderYear1: {
      name: '$$reminderYear1',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
    $$reminderYear2: {
      name: '$$reminderYear2',
      type: { kind: Introspection.Kind.Scalar, name: 'Int', ofType: null },
    },
  },
};

export const balance = (
  account: LedgerAccount,
  d?: LedgerDigest,
  /* , maxDate?: Date */
) =>
  // TODO - Cache ?
  /*
  // deprecated
  getAccountBalance(
    (b.ledger?.edges || []).map(({ node }) => node as Ledger),
    account,
    // maxDate,
  )[2];
  */
  getDigestBalance(d, account)[2];

const ownerActive = (owner: Owner) => {
  if (!owner.sncfContractEndDate) {
    return true;
  }

  if (new Date(owner.sncfContractEndDate) > new Date()) {
    return true;
  }
  return false;
};

const ownerWorkStatus = (owner: Owner) => {
  return ownerActive(owner) ? 'ACTIF' : 'NON ACTIF';
};

export const ownerHonneurLoanCount = (owner: Owner) => {
  if (!owner) {
    return 0;
  }

  return ([] as any[]).concat(
    ...(get(owner, 'borrowers.edges', []) as BorrowerEdge[])
      .filter(edge => get(edge, 'node.loanType', null) === LoanType.HONNEUR)
      .map(({ node: bNode }) => (bNode.loans ?? ([] as LoanEdge[])).edges),
  ).length;
};

export const ownerOtherLoanCount = (owner: Owner) => {
  if (!owner) {
    return 0;
  }

  return get(owner, 'borrowers.edges', []).filter(
    (edge: BorrowerEdge) =>
      get(edge, 'node.loanType', null) !== LoanType.HONNEUR,
  ).length;
};

export const ownerGuarantiesActive = (loans: GuarantyLoan[][]) => {
  return ([] as GuarantyLoan[])
    .concat(...loans)
    .filter(l => (l.wfStatus as GuarantyWFState) !== GuarantyWFState.CLOTURE)
    .length;
};

export const ownerGuaranteeCount = (owner: Owner) => {
  if (!owner) {
    return 0;
  }

  return get(owner, 'guarantees.edges', []).length;
};

// Consolidated loan status
export const ownerLoansActive = (loans: Loan[][]) => {
  const notAllowed: LoanWFState[] = [
    LoanWFState.CLOTURE,
    LoanWFState.ANNULE,
    LoanWFState.REFUSE,
  ];
  return ([] as Loan[])
    .concat(...loans)
    .filter(
      l =>
        !notAllowed.includes(
          (l.wfStatus as LoanWFState) || LoanWFState.UNDEFINED,
        ),
    ).length;
};

/** Count loans by status */
export const ownerLoanCount = (loans: Loan[][], status: LoanWFState) => {
  return ([] as Loan[]).concat(...loans).filter(l => l.wfStatus === status)
    .length;
};

export const ownerGuarantyCount = (
  loans: GuarantyLoan[][],
  statuses: GuarantyWFState[],
) => {
  return ([] as GuarantyLoan[])
    .concat(...loans)
    .filter(l => statuses.includes(l.wfStatus as GuarantyWFState)).length;
};

/**
 * Prets SIGNE avec signature > 14 jours
 * @param loans
 */
export const ownerRetractation = (loans: Loan[][], now: Date) =>
  ([] as Loan[]).concat(...loans).filter(
    l =>
      (l.wfStatus as LoanWFState) === LoanWFState.SIGNE &&
      l.signature &&
      now.getTime() - new Date(l.signature).getTime() > 14 * 24 * 3600e3, // more than 14 days
  ).length;

const ownerCessation = (owner: Owner, gg: Guarantee[]) => {
  return gg.filter(g => {
    const ll = g.loans?.edges.map(({ node }) => node as GuarantyLoan);
    return (
      !g.nonDenunciation &&
      owner.sncfContractEndDate &&
      // ownerActive(owner) && // TBC
      ownerGuarantiesActive([ll])
    );
  }).length;
};

const isActiveLoan = (l: Loan) =>
  [LoanWFState.GESTION, LoanWFState.SURENDETTEMENT].includes(
    (l.wfStatus as LoanWFState) || LoanWFState.UNDEFINED,
  ) && l.signature;

const latestLoanYear = (loans: Loan[]) =>
  loans
    .filter(isActiveLoan)
    .map(l => parse(l.signature, 'yyyy-MM-dd', new Date()).getFullYear())
    .sort((a, b) => b - a)[0];

const borrowerRappel = (
  brwr: Borrower,
  loans: Loan[],
  options: {
    /** Should pay but not scheduled to */
    alert?: boolean;
  },
  now: Date,
): '1er' | '2e' | '⚠️' | undefined => {
  if (brwr.loanType !== LoanType.HONNEUR || !loans.length) return undefined;
  const latest = latestLoanYear(loans);
  const current = now.getFullYear();
  if (!options.alert) return undefined;
  if (current === latest + FIRST_REMINDER_AFTER) return '1er';
  if (current === latest + SECOND_REMINDER_AFTER) return '2e';
  if (current > latest + ALERT_REMINDER_AFTER) return '⚠️';
  return undefined;
};

const borrowerLoanAmount = (loans: Loan[]) =>
  loans.reduce((r, l) => r + (l.amount || 0), 0);

/**
 * @param now date use for current month
 */
export const borrowerThisSchedule = (
  brwr: Borrower,
  loans: Loan[],
  options: { principalBal: number; userBal: number },
  now: Date,
): [number, boolean] => {
  if (!loans.some(l => isActiveLoan(l))) return [0, true];
  const { principalBal, userBal } = options;
  const { principalPmt, userPmt, interestPmt } = allActivePayments(brwr, now);
  const toPay = principalPmt + userPmt + interestPmt;
  const canPayment =
    !!toPay &&
    principalBal + principalPmt >= 0 &&
    userBal + userPmt >= 0 &&
    !!brwr.umr &&
    !!brwr.pmtDay;
  return [toPay, canPayment];
};

const borrowerLoanSignature = (loans: Loan[]): string => {
  const year = latestLoanYear(loans);
  return year ? year.toString() : '';
};

/** Returns sum of principal + user debt */
const borrowerAllDebt = (
  brwr: Borrower,
  loans: Loan[],
  options: {
    principalBal: number;
    userBal: number;
  },
) => {
  if (!loans.length || !loans.some(isActiveLoan)) return 0;
  const { principalBal, userBal } = options;
  return principalBal + userBal;
};

const isElligible = (brwr: Borrower, loans: Loan[], now: Date) => {
  if (brwr.loanType === LoanType.HONNEUR) {
    // check due date
    const latest = latestLoanYear(loans);
    const current = now.getFullYear();
    if (latest + ALERT_REMINDER_AFTER >= current) return false;
  }
  return loans.some(isActiveLoan);
};

/**
 * @param now date used for current year
 */
const borrowerPaymentNeeded = (
  brwr: Borrower,
  loans: Loan[],
  digest?: LedgerDigest,
  now: Date = new Date(),
) => {
  const principalBal = balance(LedgerAccount.PRINCIPAL_ACCOUNT, digest);
  const userBal = balance(LedgerAccount.USER_ACCOUNT, digest);
  const elligible = isElligible(brwr, loans, now);
  const debt = borrowerAllDebt(brwr, loans, { principalBal, userBal });
  const { principalPmt: p, userPmt: u } = allActivePayments(brwr); // ignore interests
  const willPay = p + u;
  if (elligible || willPay) return { principalBal, userBal, debt, willPay };
  return { principalBal, userBal, debt: 0, willPay: 0 };
};

const loanSignatureOffsetYear = (loan: Loan, years: number) => {
  if (!loan || !loan.signature) {
    return '';
  }

  return format(
    addYears(parse(loan.signature, 'yyyy-MM-dd', new Date()), years),
    'yyyy',
  );
};

const guaranteeLoanCount = (guarantee: Guarantee) => {
  return guarantee.loans?.edges.length;
};

export const calculatedFields = (
  r: Node,
  digests: Map<Identifier, LedgerDigest>,
  now = new Date(),
) => {
  // const bookEnd = new Date(now.getFullYear(), now.getMonth(), 1); // deprecated after optim - remove
  switch (r.__typename) {
    case 'Owner': {
      const ownr = r as Owner;
      const brwrs: Borrower[] =
        ownr.borrowers?.edges.map(({ node }) => node as Borrower) || [];
      const loans: Loan[][] = brwrs
        .map(b => b.loans?.edges.map(({ node }) => node as Loan))
        .filter(Boolean);
      //   const principalBals = brwrs.map(b => balance(b, PRINCIPAL_ACCOUNT));
      // const userBals = brwrs.map(b => balance(b, USER_ACCOUNT));
      const paymentAlerts = brwrs
        .filter(b => b.loans) // loans are lazy loaded
        .map((b, i) => {
          const { debt, willPay } = borrowerPaymentNeeded(
            b,
            loans[i],
            digests.get(b.id),
            now,
          );
          return debt + willPay > 0; // see $$scheduleAlert
        })
        .filter(Boolean).length;

      const guarts: Guarantee[] =
        ownr.guarantees?.edges.map(({ node }) => node as Guarantee) || [];
      const guaranties: GuarantyLoan[][] = guarts
        .map(g => g.loans?.edges.map(({ node }) => node as GuarantyLoan))
        .filter(Boolean);

      const honneurLoanCount = ownerHonneurLoanCount(ownr);
      const otherLoanCount = ownerOtherLoanCount(ownr);
      const guaranteeCount = ownerGuaranteeCount(ownr);
      const cpretType = [];
      if (honneurLoanCount || otherLoanCount) cpretType.push('PRET');
      if (guaranteeCount) cpretType.push('CAUTION');
      return {
        $$workStatus: ownerWorkStatus(ownr),
        $$honneurLoanCount: honneurLoanCount,
        $$otherLoanCount: otherLoanCount,
        $$guaranteeCount: guaranteeCount,
        $$cpretActive:
          ownerLoansActive(loans) + ownerGuarantiesActive(guaranties),
        // $$guarantiesActive: ownerGuarantiesActive(guaranties),
        $$surrendettement: ownerLoanCount(loans, LoanWFState.SURENDETTEMENT),
        $$contentieux: ownerLoanCount(loans, LoanWFState.CONTENTIEUX),
        $$impaye: paymentAlerts,
        $$retractation: ownerRetractation(loans, now),
        $$guarantiesCalled: ownerGuarantyCount(guaranties, [
          GuarantyWFState.GARANTIE,
          GuarantyWFState.PAIEMENT,
        ]),
        $$ownerCessation: ownerCessation(ownr, guarts),
        $$cpretType: cpretType,
      };
    }
    case 'Borrower': {
      const brwr = r as Borrower;
      const loans = brwr.loans?.edges.map(({ node }) => node as Loan);
      // const debug = loans.some(l => l.sncfCP === '6801526N');
      const { principalBal, userBal, debt, willPay } = borrowerPaymentNeeded(
        brwr,
        loans,
        digests.get(brwr.id),
        now,
      );
      const pmtPrincipalBal = balance(
        LedgerAccount.PRINCIPAL_ACCOUNT,
        digests.get(r.id),
        /* , bookEnd */
      );
      const pmtUserBal = balance(
        LedgerAccount.USER_ACCOUNT,
        digests.get(r.id),
        /* , bookEnd */
      );
      const [pmtAmount, canPayment] = borrowerThisSchedule(
        brwr,
        loans,
        {
          principalBal: pmtPrincipalBal,
          userBal: pmtUserBal,
        },
        now,
      );

      const userCreditor = userBal < 0 ? userBal : 0;
      let creditedLoans: string[] = [];
      if (userCreditor) {
        // search for signed loans
        const signed = loans.filter(l => l.wfStatus === LoanWFState.SIGNE);
        // check if sum of loans is covered by user credit
        if (
          signed.length &&
          -userBal >= signed.reduce((res, l) => res + (l.amount || 0), 0)
        ) {
          // we have a match
          creditedLoans = signed.map(l => l.id);
        }
      }

      const owner = brwr.owner.edges?.[0]?.node;

      return {
        $$rappel: borrowerRappel(
          brwr,
          loans,
          { alert: !!principalBal && (!willPay || debt + willPay > 0) },
          now,
        ),
        $$restantDu: principalBal || 0,
        $$impaye: userBal > 0, // not used? remove ?
        $$loanCount: loans.length,
        $$loanAmount: borrowerLoanAmount(loans),
        $$loanLastSignature: borrowerLoanSignature(loans),
        $$paymentAmount: pmtAmount, // canPayment ? pmtAmount : 0,
        $$paymentScheduleAlert: pmtAmount && (!canPayment || !owner) ? 1 : 0,
        $$scheduleAlert: debt + willPay, // aka Impayé
        $$userCreditor: userCreditor,
        $$creditedLoans: creditedLoans,
        // used by Courriers
        $$userBalance: userBal,
        $$principalBalance: principalBal,
        $$ledgerDigest: digests.get(r.id),
      } as BorrowerExtras;
    }
    case 'Loan': {
      const loan = r as Loan;
      return {
        $$reminderYear1: loanSignatureOffsetYear(loan, FIRST_REMINDER_AFTER),
        $$reminderYear2: loanSignatureOffsetYear(loan, SECOND_REMINDER_AFTER),
      };
    }
    case 'Guarantee': {
      const guarantee = r as Guarantee;
      return {
        $$loanCount: guaranteeLoanCount(guarantee),
      };
    }
    default:
      return {};
  }
};
