import React from 'react';
import {
  NumberInput,
  NullableBooleanInput,
  DateInput,
  DateTimeInput,
  SelectInput,
  ChoicesInputProps,
  InputProps,
  required as requiredValidator,
} from 'react-admin';

import _castArray from 'lodash/castArray';
import _uniq from 'lodash/uniq';
import _omitBy from 'lodash/omitBy';
import _pickBy from 'lodash/pickBy';
import _isUndefined from 'lodash/isUndefined';

import { Introspection } from 'phicomas-client';

import { GQLField, GQLFieldType } from '../../types/gqlTypes';

import projectInfos, {
  ResourceKey,
  EnumKey,
  EnumsInfos,
  getEnumsInfos,
  getResourceCustomization,
  getResourceInfos,
} from '../../project/projectInfos';

import TextInput from './TextInput';
import WysiwygTextInput, { WysiwygType } from './WysiwygTextInput';
import PageConstructor from './PageConstructor'; // eslint-disable-line import/no-cycle

// Cycle needed because a connection edition lives within a record edition
import ConnectionInput, { ConnectionInputProps } from './ConnectionInput'; // eslint-disable-line import/no-cycle
import ListInput, { ListInputProps } from './ListInput';

// eslint-disable-next-line import/no-cycle
import ObjectInput, { ObjectInputProps } from './ObjectInput';
import { CustomizationFieldsProps, FieldName } from '../../types/customization';
import ContainerForward from '../../lib/ContainerForward';
import YamlTextInput from './YamlTextInput';

type BasicInputComponentInfos = {
  Input: React.FC<InputProps> | typeof DateInput; // Weird but react-admin made it not a React component ...
  inputProps?: Partial<InputProps>;
};
type ChoicesInputComponentInfos = {
  Input: React.FC<ChoicesInputProps>;
  inputProps?: Partial<ChoicesInputProps>;
};
type ConnectionInputComponentInfos = {
  Input: typeof ConnectionInput;
  inputProps: Partial<ConnectionInputProps>;
};
type ListInputComponentInfos = {
  Input: typeof ListInput;
  inputProps: Partial<ListInputProps>;
};
type ObjectInputComponentInfos = {
  Input: typeof ObjectInput;
  inputProps: Partial<ObjectInputProps>;
};
type BooleanInputComponentInfos = {
  Input: typeof NullableBooleanInput;
  inputProps: Partial<InputProps>;
};

type InputComponentInfos =
  | BasicInputComponentInfos
  | ChoicesInputComponentInfos
  | ConnectionInputComponentInfos
  | ListInputComponentInfos
  | ObjectInputComponentInfos
  | BooleanInputComponentInfos
  | null;

function getScalarComponentInfos(
  rawTypeName: string,
  fieldProps: CustomizationFieldsProps[FieldName],
  baseInputProps: Partial<InputProps>,
  options: GenerateInputOptions,
): InputComponentInfos {
  const { multiline, html, markdown, yaml, page, resettable } =
    fieldProps || {};
  const { asFilter } = options;
  const typeName =
    asFilter && rawTypeName === 'AWSDateTime' ? 'AWSDate' : rawTypeName;

  const inputProps: Partial<InputProps> = {
    ...baseInputProps,
    fullWidth: true,
  };

  switch (typeName) {
    case 'Int':
    case 'Float':
      return {
        Input: NumberInput,
        inputProps,
      };
    case 'Boolean':
      return {
        Input: NullableBooleanInput,
        inputProps,
      };
    case 'AWSDate':
      return {
        Input: DateInput,
        inputProps: {
          ...inputProps,
          // Transform `""` date into `null` date on submit
          // `parse` should be the one needed, but fact is that `format` is the one that makes it work
          format: (input: unknown) => {
            let res = input;
            if (inputProps.format) {
              res = inputProps.format(res);
            }
            if (res === '') {
              res = null;
            }
            return res;
          },
          parse: (input: unknown) => {
            let res = input;
            if (inputProps.parse) {
              res = inputProps.parse(res);
            }
            if (res === '') {
              res = null;
            }
            return res;
          },
        },
      };
    case 'AWSDateTime':
      return {
        Input: DateTimeInput,
        inputProps: {
          ...inputProps,
          // Transform `""` date into `null` date on submit
          // `parse` should be the one needed, but fact is that `format` is the one that makes it work
          format: (input: unknown) => {
            let res = input;
            if (inputProps.format) {
              res = inputProps.format(res);
            }
            if (res === '') {
              res = null;
            }
            return res;
          },
          parse: (input: unknown) => {
            let res = input;
            if (inputProps.parse) {
              res = inputProps.parse(res);
            }
            if (res === '') {
              res = null;
            }
            return res;
          },
        },
      };
    case 'ID':
    case 'String':
    default: {
      if (html) {
        return {
          Input: WysiwygTextInput,
          inputProps: { ...inputProps, wysiwygType: WysiwygType.HTML },
        };
      }
      if (markdown) {
        return {
          Input: WysiwygTextInput,
          inputProps: { ...inputProps, wysiwygType: WysiwygType.MARKDOWN },
        };
      }
      if (yaml) {
        return {
          Input: YamlTextInput,
          inputProps,
        };
      }
      if (page) {
        return {
          Input: PageConstructor,
          inputProps,
        };
      }
      inputProps.resettable = resettable;
      if (multiline) {
        inputProps.multiline = true;
        inputProps.rows = 5;
      }
      return { Input: TextInput, inputProps };
    }
  }
}

function getEnumComponentInfos(
  type: GQLFieldType | Introspection.Type,
  {
    fieldName,
    enumsInfos,
  }: {
    fieldName: string;
    enumsInfos: EnumsInfos;
  },
  fieldProps: CustomizationFieldsProps[FieldName],
  baseInputProps: Partial<InputProps>,
): InputComponentInfos {
  const { resettable } = fieldProps || {};
  const { name: typeName } = type;
  let enumValues: string[] | null = null;
  if (typeName) {
    enumValues = enumsInfos[typeName as EnumKey]; // Maybe should use typeguard instead, but the check is done below anyway
  }
  if (!enumValues || enumValues.length === 0) {
    throw new Error(
      `Could not find ENUM named ${typeName} (found on field "${fieldName}")`,
    );
  }

  return {
    Input: SelectInput,
    inputProps: {
      ...baseInputProps,
      choices: enumValues.map(value => ({ id: value, name: value })),
      resettable,
    },
  };
}

function getObjectComponentInfos(
  type: GQLFieldType | Introspection.Type,
  {
    fieldName,
    resource,
    options,
  }: {
    fieldName: string;
    resource: ResourceKey;
    options: GenerateInputOptions;
  },
  baseInputProps: Partial<InputProps>,
): InputComponentInfos {
  const inputProps: Partial<InputProps> = {
    ...baseInputProps,
    fullWidth: true,
  };

  const { isCreate } = options;
  const { connections, objectTypes } = projectInfos.resourcesInfos[resource];
  const connection = connections?.[fieldName];
  if (connection) {
    try {
      const connectedResourceInfos = getResourceInfos(connection.onResource);
      if (connection.isDirect) {
        return {
          Input: ConnectionInput,
          inputProps: {
            ...inputProps,
            connectionInfos: connection,
          },
        };
      }
      // Double-check that the connectionType matches
      if (connectedResourceInfos.connectionType.name === type.name) {
        const { assetResource } = projectInfos;
        // Hide asset connections on creation
        if (isCreate && resource === assetResource) {
          return null;
        }
        return {
          Input: ConnectionInput,
          inputProps: {
            ...inputProps,
            connectionInfos: connection,
          },
        };
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error(
      `Field "${fieldName}" was found to be connected to resource "${connection.onResource}" but typenames do not match`,
    );
  }
  const objectType = objectTypes[type.name || ''];
  if (objectType) {
    return {
      Input: ObjectInput,
      inputProps: {
        ...inputProps,
        objectType,
      },
    };
  }
  throw new Error(
    `Could not find a connection or an objectType on OBJECT field (on field "${fieldName}" as typename "${type.name}")`,
  );
}

function getListComponentInfos(
  type: GQLFieldType | Introspection.Type,
  subComponentInfos: NonNullable<InputComponentInfos>,
): InputComponentInfos {
  return {
    Input: ListInput,
    inputProps: {
      Input: subComponentInfos.Input,
      inputProps: subComponentInfos.inputProps,
    },
  };
}

function getComponentInfosFromType(
  type: GQLFieldType | Introspection.Type,
  {
    fieldName,
    resource,
    options,
  }: {
    fieldName: string;
    resource: ResourceKey;
    options: GenerateInputOptions;
  },
  additionnalFieldProps: CustomizationFieldsProps[FieldName] = {},
  baseSimpleInputProps: Partial<InputProps> = {},
): InputComponentInfos {
  const { kind, name, ofType } = type as GQLFieldType;
  const { asFilter, isCreate } = options;
  const customization = getResourceCustomization(resource);

  const fieldProps = {
    ...customization.fieldsProps?.[fieldName],
    ..._omitBy(additionnalFieldProps, _isUndefined),
  };
  const {
    required,
    disabled: rawDisabled,
    hidden: rawHidden,
    hiddenOnCreate,
  } = fieldProps || {};
  let simpleInputProps: Partial<InputProps> = baseSimpleInputProps;

  // Hidden field
  const hidden =
    !asFilter &&
    (rawHidden || (isCreate && hiddenOnCreate) || simpleInputProps.hidden);
  simpleInputProps.hidden = hidden;

  // Disabled field
  const disabled: boolean =
    hidden || (!asFilter && (simpleInputProps.disabled || rawDisabled));
  simpleInputProps.disabled = disabled;

  // Pass through validators and add required if needed
  const additionnalInputPropsValidate = simpleInputProps.validate
    ? _castArray(simpleInputProps.validate)
    : [];
  const validate =
    required && !asFilter && !hidden
      ? _uniq([...additionnalInputPropsValidate, requiredValidator()])
      : additionnalInputPropsValidate;
  if (validate.length > 0) {
    simpleInputProps.validate = validate;
  }

  simpleInputProps = _pickBy(simpleInputProps, v => v !== undefined);

  switch (kind) {
    case Introspection.Kind.NonNull: {
      if (!ofType) {
        throw new Error(
          `NON_NULL field type did not have an ofType value (found on field "${fieldName}")`,
        );
      }
      const componentInfos = getComponentInfosFromType(
        ofType,
        {
          fieldName,
          resource,
          options,
        },
        {
          required: true,
        },
        simpleInputProps,
      );
      if (!componentInfos) {
        // Required unknown input may not be that much required...
        return null;
      }
      return componentInfos;
    }
    case Introspection.Kind.Scalar: {
      if (!name) {
        throw new Error(
          `SCALAR field did not have a type name (found on field "${fieldName}")`,
        );
      }
      return getScalarComponentInfos(
        name,
        fieldProps,
        simpleInputProps,
        options,
      );
    }
    case Introspection.Kind.Enum: {
      if (!name) {
        throw new Error(
          `ENUM field did not have a type name (found on field "${fieldName}")`,
        );
      }
      const enumsInfos = getEnumsInfos();
      return getEnumComponentInfos(
        type,
        { fieldName, enumsInfos },
        fieldProps,
        simpleInputProps,
      );
    }
    case Introspection.Kind.List: {
      if (!ofType) {
        throw new Error(
          `LIST field did not have a type ofType (found on field "${fieldName}")`,
        );
      }
      if (Object.keys(simpleInputProps).length > 0) {
        console.warn(
          `simpleInputProps found but not handled yet in LIST field (found on field "${fieldName}")`,
          simpleInputProps,
        );
      }
      const ofInputComponentInfos = getComponentInfosFromType(
        ofType,
        {
          fieldName,
          resource,
          options,
        },
        {},
        simpleInputProps,
      );
      if (ofInputComponentInfos === null) {
        // A null infos means that there is just nothing to display, so pass it throught the list
        return null;
      }
      if (asFilter) {
        throw new Error(
          `LIST filter input is not yet handled (found on field "${fieldName}")`,
        );
      }
      if (!ofInputComponentInfos) {
        throw new Error(
          `Could not find subComponent of LIST field from it's ofType (found on field "${fieldName}")`,
        );
      }
      return getListComponentInfos(type, ofInputComponentInfos);
    }
    case Introspection.Kind.Object: {
      if (name === 'TransactionState') {
        return null;
      }
      if (asFilter) {
        throw new Error(
          `OBJECT filter input is not yet handled (found on field "${fieldName}")`,
        );
      }
      return getObjectComponentInfos(
        type,
        {
          fieldName,
          resource,
          options,
        },
        simpleInputProps,
      );
    }
    default:
      throw new Error(
        `Field of kind "${kind}" is not yet handled (found on field "${fieldName}")`,
      );
  }
}

function generateInputComponentInfos(
  resource: ResourceKey,
  field: GQLField,
  options: GenerateInputOptions,
  additionnalFieldProps: CustomizationFieldsProps[FieldName] = {},
): InputComponentInfos {
  const { name, type } = field;

  return getComponentInfosFromType(
    type,
    {
      fieldName: name,
      resource,
      options,
    },
    additionnalFieldProps,
  );
}

type GenerateInputOptions = {
  asFilter?: boolean;
  isCreate?: boolean;
};
export default function generateInput(
  resource: ResourceKey,
  field: Introspection.Field,
  options?: GenerateInputOptions & {
    ofObjectSource?: string;
    resettable?: boolean;
    [k: string]: any | undefined;
  },
  CustomComponent?: React.FC<InputProps>,
): React.ReactNode {
  const {
    ofObjectSource,
    isCreate,
    asFilter,
    resettable,
    hidden,
    ...forwardedProps
  } = options ?? {};
  const inputComponentInfos = generateInputComponentInfos(
    resource,
    field,
    { isCreate, asFilter },
    {
      resettable,
      hidden,
    },
  );

  if (inputComponentInfos) {
    if (CustomComponent) {
      inputComponentInfos.Input = CustomComponent;
    }
    const { Input, inputProps: { hidden: hiddenInput, ...inputProps } = {} } =
      inputComponentInfos;

    const input = (
      <Input
        {...inputProps}
        {...forwardedProps}
        key={field.name}
        source={
          ofObjectSource ? `${ofObjectSource}[${field.name}]` : field.name
        }
      />
    );

    if (!hiddenInput) return input;

    return (
      <ContainerForward hidden={hiddenInput} key={`container ${field.name}`}>
        {input}
      </ContainerForward>
    );
  }
  return null;
}
