import React, { Children, cloneElement, isValidElement, useState } from 'react';
import { useTranslate, useGetIdentity } from 'ra-core';
import clsx from 'clsx';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Menu,
  Button,
  Avatar,
  useMediaQuery,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { ClassName } from '../../types/styles';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    user: {
      overflow: 'hidden',
      display: 'flex',
    },
    userButton: {
      textTransform: 'none',
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    userName: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  }),
);

type CustomUserMenuProps = React.PropsWithChildren<{
  label?: string;
  icon?: React.ReactNode;
  logout: React.ReactNode;
  className?: ClassName;
}>;

const CustomUserMenu: React.FC<CustomUserMenuProps> = ({
  label = 'ra.auth.user_menu',
  icon = <AccountCircle />,
  logout,
  children,
  className = '',
}: CustomUserMenuProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const translate = useTranslate();
  const { loaded, identity } = useGetIdentity();
  const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));

  if (!logout && !children) return null;
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <div className={clsx(classes.user, className)}>
      {loaded && identity?.fullName && !isXSmall ? (
        <Button
          aria-label={label && translate(label, { _: label })}
          className={classes.userButton}
          color="inherit"
          startIcon={
            identity.avatar ? (
              <Avatar
                className={classes.avatar}
                src={identity.avatar}
                alt={identity.fullName}
              />
            ) : (
              icon
            )
          }
          onClick={handleMenu}
        >
          <span className={classes.userName}>{identity.fullName}</span>
        </Button>
      ) : (
        <IconButton
          aria-label={label && translate(label, { _: label })}
          aria-owns={open ? 'menu-appbar' : undefined}
          aria-haspopup
          color="inherit"
          onClick={handleMenu}
        >
          {identity?.avatar ? (
            <Avatar
              className={classes.avatar}
              src={identity.avatar}
              alt={identity.fullName}
            />
          ) : (
            icon
          )}
        </IconButton>
      )}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {Children.map(children, menuItem =>
          isValidElement(menuItem)
            ? cloneElement<any>(menuItem, {
                onClick: handleClose,
              })
            : null,
        )}
        {logout}
      </Menu>
    </div>
  );
};

export default CustomUserMenu;
