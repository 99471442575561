import { OneManyEnum, SubType, Type } from '../introspection';
import { MarkRequired, XOR } from '../types/utils';
import { GenericResourceKey } from './resourceKeys';

export const FRAGMENT_BASE_SUFFIX = 'FieldsBase';
export const FRAGMENT_FULL_SUFFIX = 'FieldsFull';

export type FieldName = string;

export type GenericResourceInfos<
  RKey extends GenericResourceKey = GenericResourceKey,
> = {
  type: Type;
  edgeType: Type;
  connectionType: Type;
  objectTypes: { [k in NonNullable<SubType['name']>]?: Type };
  fragments: {
    name: string;
    full: string;
    base: string;
  };
  query: {
    name: string;
    allName: string;
  };
  mutations: {
    create?: {
      name: string;
      dataInputTypename: string;
    };
    update?: {
      name: string;
      dataInputTypename: string;
    };
    delete?: {
      name: string;
    };
    transactionnal: boolean;
  };
  connections?: GenericResourceConnectionInfo<RKey>;
};

export type GenericResourceConnectionInfo<
  RKey extends GenericResourceKey = GenericResourceKey,
> = {
  [field in FieldName]: {
    /** Connected resource (+ onField) */
    onResource: RKey;
  } & XOR<
    {
      /** Connected field (+ onResource) */
      onField: FieldName;
      /** Either the connection have been defined on this field or the other one
          (Connections can be added only on the left field) */
      isLeft: boolean;
      /** Can hold one or many connection edges */
      type: OneManyEnum;
      /** Direct connection (reference) */
      isDirect: false;
    },
    {
      /** Direct connection (reference) */
      isDirect: true;
    }
  >;
};

export type GenericResourcesInfos<
  RKey extends GenericResourceKey = GenericResourceKey,
  RInfos = GenericResourceInfos<RKey>,
> = {
  [k in RKey]: RInfos;
};

/* *****
 * resourceInfos with connections
 */
type ResourceInfosWithConnections<
  RKey extends GenericResourceKey = GenericResourceKey,
> = MarkRequired<GenericResourceInfos<RKey>, 'connections'>;
export function resourceInfosHasConnections<
  RKey extends GenericResourceKey = GenericResourceKey,
>(
  resourceInfos: GenericResourceInfos<RKey>,
): resourceInfos is ResourceInfosWithConnections<RKey> {
  return (
    !!resourceInfos.connections &&
    Object.keys(resourceInfos.connections).length > 0
  );
}
