import React, { useCallback } from 'react';
import { sanitizeInputRestProps } from 'react-admin';
import {
  useInput,
  FieldTitle,
  InputProps as InputPropsType,
  useTranslate,
} from 'ra-core';

import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './WysiwygDraftjs.css';
import draftToHtml from 'draftjs-to-html';

import { TextFieldProps } from '@material-ui/core/TextField';
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';

import htmlToDraft from '../../lib/html-to-draftjs';

export enum WysiwygType {
  HTML,
  MARKDOWN,
}

function valueToDraft(
  value: string,
  wysiwygType: WysiwygType,
): ContentState | undefined {
  let contentState: ContentState;
  if (wysiwygType === WysiwygType.MARKDOWN) {
    const rawData = markdownToDraft(value);
    contentState = convertFromRaw(rawData);
  } else {
    const blocksFromHtml = htmlToDraft(value);
    if (blocksFromHtml) {
      const { contentBlocks, entityMap } = blocksFromHtml;
      contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
    }
    return undefined;
  }
  return contentState;
}

function draftToValue(content: ContentState, wysiwygType: WysiwygType): string {
  let value: string;
  if (!content.hasText()) {
    value = '';
  } else if (wysiwygType === WysiwygType.MARKDOWN) {
    value = draftToMarkdown(convertToRaw(content));
  } else {
    value = draftToHtml(convertToRaw(content));
  }
  return value;
}

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    label: {
      marginBottom: theme.spacing(2),
      position: 'relative',
      transform: 'none',
    },
    editor: {
      padding: theme.spacing(0.5, 1),
      border: '1px solid #F1F1F1',
      minHeight: 100,
      maxHeight: 300,
      overflow: 'auto',
    },
  }),
);

export type WysiwygTextInputProps = InputPropsType<TextFieldProps> & {
  wysiwygType?: WysiwygType;
};

const WysiwygTextInput: React.FC<WysiwygTextInputProps> = ({
  label,
  format,
  helperText,
  onBlur,
  onFocus,
  onChange,
  // options = {},
  parse,
  resource,
  source,
  record,
  validate,
  wysiwygType = WysiwygType.HTML,
  ...rest
}: WysiwygTextInputProps) => {
  const classes = useStyles();
  const translate = useTranslate();

  const {
    // id,
    input,
    isRequired,
    meta: { error, submitError, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'text',
    validate,
    ...rest,
  });

  const hasError = touched && !!(error || submitError);

  const [editorState, setEditorState] = React.useState(() => {
    const { value } = input;
    if (value) {
      const contentState = valueToDraft(value, wysiwygType);
      if (contentState) {
        return EditorState.createWithContent(contentState);
      }
    }
    return EditorState.createEmpty();
  });
  const handleEditorStateChange = useCallback(
    (newEditorState: EditorState) => {
      setEditorState(newEditorState);

      const currentContent = newEditorState.getCurrentContent();
      // Simulate event
      input.onChange({
        target: { value: draftToValue(currentContent, wysiwygType) },
      });
    },
    [input, wysiwygType],
  );
  const handleFocus = useCallback(
    (event: React.SyntheticEvent) => {
      input.onFocus(event as React.FocusEvent<HTMLElement>);
    },
    [input],
  );
  const handleBlur = useCallback(
    (event: React.SyntheticEvent) => {
      input.onBlur(event as React.FocusEvent<HTMLElement>);
    },
    [input],
  );

  return (
    <FormControl
      fullWidth
      margin="dense"
      required={isRequired}
      {...sanitizeInputRestProps(rest)}
    >
      <InputLabel shrink className={classes.label} error={hasError}>
        <FieldTitle source={source} resource={resource} />
      </InputLabel>
      <Editor
        toolbar={{
          // Default values can be found here https://jpuri.github.io/react-draft-wysiwyg/#/docs
          // Bellow are listed only the changes to defaults
          options: [
            'inline',
            'blockType',
            // 'fontSize',
            // 'fontFamily',
            'list',
            // 'textAlign',
            // 'colorPicker',
            'link',
            // 'embedded',
            // 'emoji',
            // 'image',
            'remove',
            'history',
          ],
          inline: {
            options: [
              'bold',
              'italic',
              'underline',
              'strikethrough',
              'monospace',
              // 'superscript',
              // 'subscript',
            ],
          },
          blockType: {
            inDropdown: false,
            options: [
              'Normal',
              'H1',
              'H2',
              'H3',
              // 'H4',
              // 'H5',
              // 'H6',
              'Blockquote',
              // 'Code',
            ],
          },
        }}
        localization={{
          locale: 'fr',
        }}
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        editorClassName={classes.editor}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <FormHelperText error={hasError}>
        {hasError ? translate(error) : helperText || <>&#8203;</>}
      </FormHelperText>
    </FormControl>
  );
};

export default WysiwygTextInput;
