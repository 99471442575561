import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { useRecordContext } from 'react-admin';

type Props = {
  label?: string; // eslint-disable-line react/no-unused-prop-types
  source: string;
};

export const DefaultAddressField: React.FC<Props> = props => {
  const { source } = props;
  const record: any = useRecordContext();
  const { contact: brwContact = {}, owner } = record || {};
  const ownrContact = owner?.edges[0]?.node.contact || {};
  const {
    streetAddress: brwAddr,
    postalCode: brwCode,
    addressLevel2: brwCity,
  } = brwContact;
  const isFallBack = !brwAddr && !brwCode && !brwCity;
  const value = isFallBack ? ownrContact[source] : brwContact[source];
  return (
    <Typography component="span" variant="body2">
      {value || 'N/A'}
    </Typography>
  );
};
