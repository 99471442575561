import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { LinkToType, linkToRecord, Link } from 'react-admin';
import get from 'lodash/get';

import { Borrower, Owner } from '../../types/schema';

type BorrowerNameFieldProps = {
  label?: string; // eslint-disable-line react/no-unused-prop-types
  record?: Borrower;
  source: string;
  link?: LinkToType;
};

export const BorrowerNameField: React.FC<BorrowerNameFieldProps> = props => {
  // useRecordContext is actually the future https://marmelab.com/blog/2021/04/07/react-admin-april-update.html#code-classlanguage-textuserecordcontextcode
  // const record: Borrower = useRecordContext(); // TODO - use if needed ?
  const { source, record, link } = props;
  const owner: Owner | undefined = record?.owner?.edges?.[0]?.node;
  const { contact } = record || {};
  const { givenName: bGN = undefined, familyName: bFN = undefined } =
    contact || {};
  const name = get(!bGN && !bFN && owner ? owner : record, source);

  // stop propagation on click
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.stopPropagation();
    },
    [],
  );

  if (!record) return null;

  const text = (
    <Typography component="span" variant="body2">
      {name || 'N/A'}
    </Typography>
  );
  if (!link) return text;

  const linkTo =
    typeof link === 'function'
      ? link(record, 'cpretBorrower')
      : linkToRecord(
          '/cpretBorrower',
          record?.id,
          link === true ? undefined : link,
        );
  return (
    <Link to={linkTo} onClick={handleClick}>
      {text}
    </Link>
  );
};
