import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import Color from 'color';
import CloseIcon from '@material-ui/icons/Close';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Backdrop,
  LinearProgress,
} from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';

import { History, PageInfo } from '../types/schema';
import { useResourceHistory } from './use-resource-history';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      position: 'sticky',
      top: theme.spacing(2),
      bottom: theme.spacing(2),
      maxHeight: `calc(100vh - ${theme.spacing(2) * 2}px)`,
      marginLeft: theme.spacing(2),
      zIndex: theme.zIndex.appBar - 1,
    },
    card: {
      height: '100%',
      backgroundColor: theme.palette.background.paper,
      transition: theme.transitions.create(['background-color', 'box-shadow']),
    },
    titleCard: {
      position: 'relative',
      display: 'block',
      height: '100%',
      zIndex: theme.zIndex.appBar - 1,
      borderLeft: `0px solid ${theme.palette.divider}`,
      cursor: 'pointer',
      backgroundColor: theme.palette.background.paper,
      transition: theme.transitions.create(['border-left']),
    },
    titleContent: {
      position: 'sticky',
      top: 0,
      padding: theme.spacing(8, 0),
    },
    title: {
      // textOrientation: 'upright',
      writingMode: 'vertical-rl',
    },
    titleClose: {
      position: 'absolute',
      top: theme.spacing(2),
      left: 0,
      width: '100%',
      display: 'flex',
      margin: 0,
      justifyContent: 'center',
    },
    drawer: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      right: 0,
      padding: 0,
      zIndex: theme.zIndex.appBar - 2,
      boxShadow: theme.shadows[0],
      backgroundColor: 'inherit',
      borderRadius: theme.shape.borderRadius,
      opacity: 0,

      transition: theme.transitions.create([
        'opacity',
        'paddingRight',
        'width',
        'box-shadow',
      ]),
    },
    drawerContent: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(2),
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    drawerContentWrapper: {
      minHeight: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    open: {
      zIndex: theme.zIndex.drawer,

      '& $card': {
        backgroundColor: theme.palette.specials.sncfLightGrey,
        boxShadow: 'none',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        width: 70,
      },
      '& $titleCard': {
        borderLeft: `1px solid ${theme.palette.divider}`,
      },
      '& $drawer': {
        width: 500,
        opacity: 1,
        paddingRight: 70,
        boxShadow: theme.shadows[4],
      },
    },
    backdrop: {
      backgroundColor: Color(theme.palette.background.paper)
        .alpha(0.1)
        .toString(),
      position: 'absolute',
      zIndex: theme.zIndex.appBar - 3,
    },
    centerWrapper: {
      height: '100%',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      textAlign: 'center',
    },
  }),
);

export interface HistoryPanelRenderProps {
  node: History;
}

export interface HistoryPanelProps {
  pageSize?: number;
  children(renderProps: HistoryPanelRenderProps): React.ReactNode;
  resource: string;
  id: string;
}
export const HistoryPanel: React.FC<HistoryPanelProps> = ({
  children,
  resource,
  id,
  pageSize,
}) => {
  const classes = useStyles();
  const scrollParentRef = React.useRef(null);
  const getScrollParentRef = useCallback(() => {
    return scrollParentRef.current;
  }, [scrollParentRef]);

  const [open, setOpen] = useState(false);
  const [nodes, setNodes] = React.useState<History[] | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [pageInfo, setPageInfo] = React.useState<PageInfo>({
    hasNextPage: true,
    hasPreviousPage: false,
  });
  const fetchHistory = useResourceHistory(resource, id);
  const { hasNextPage } = pageInfo;

  const loadMore = React.useCallback(async () => {
    try {
      setLoading(true);
      const result = await fetchHistory(pageInfo.endCursor, pageSize);
      setNodes(previousNodes =>
        [...(previousNodes || []), ...(result.nodes || [])].sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
      );
      setPageInfo(result.pageInfo);
    } finally {
      setLoading(false);
    }
  }, [fetchHistory, pageInfo, setPageInfo, setNodes, setLoading, pageSize]);

  const toggleOpen = useCallback(() => {
    setOpen(!open);
    if (!open) {
      loadMore();
    } else {
      setNodes(null);
      setPageInfo({
        hasNextPage: true,
        hasPreviousPage: false,
      });
    }
  }, [open, loadMore]);

  return (
    <>
      <div className={clsx(classes.root, { [classes.open]: open })}>
        <Card className={classes.card}>
          <CardHeader
            onClick={toggleOpen}
            title="Historique"
            titleTypographyProps={{ variant: 'h5', className: classes.title }}
            action={open ? <CloseIcon /> : null}
            classes={{
              root: classes.titleCard,
              content: classes.titleContent,
              action: classes.titleClose,
            }}
          />
          <CardContent className={classes.drawer}>
            {open && (
              <div className={classes.drawerContent} ref={scrollParentRef}>
                {nodes === null && loading && (
                  <div className={classes.centerWrapper}>
                    <LinearProgress />
                  </div>
                )}

                {nodes && nodes.length === 0 && (
                  <div className={classes.centerWrapper}>
                    L'historique est vide
                  </div>
                )}
                {nodes && nodes.length > 0 && (
                  <div className={classes.drawerContentWrapper}>
                    <InfiniteScroll
                      loadMore={loadMore}
                      hasMore={hasNextPage && !loading}
                      useWindow={false}
                      getScrollParent={getScrollParentRef}
                      isReverse
                    >
                      {nodes.map(node => children({ node }))}
                    </InfiniteScroll>
                  </div>
                )}
              </div>
            )}
          </CardContent>
        </Card>
      </div>
      <Backdrop className={classes.backdrop} open={open} onClick={toggleOpen} />
    </>
  );
};
